import React, { useContext } from 'react';
import classNames from 'classnames';
import SizeContext from 'antd/lib/config-provider/SizeContext';
import './ReadOnlyFormItem.less';

export interface ReadOnlyFormItemProps {
  value?: string;
  className?: string;
}

/** Adds the AntD form item special sauce to a standard span */
const ReadOnlyFormItem: React.FC<ReadOnlyFormItemProps> = (props) => {
  const size = useContext(SizeContext);

  // Shameless borrowed from AntD's implementation on the button component
  // https://github.com/ant-design/ant-design/blob/07b8fb12a66c79e454cb09009585ba647499f6ae/components/button/button.tsx#L231
  const sizeClassNameMap = { large: 'lg', small: 'sm', middle: undefined };
  const sizeCls = size ? sizeClassNameMap[size] || '' : '';
  const prefixCls = 'readonly-form-item';
  const classes = classNames(
    prefixCls,
    {
      [`${prefixCls}-${sizeCls}`]: sizeCls,
    },
    props.className
  );

  return <span className={classes}>{props.value}</span>;
};

export default ReadOnlyFormItem;