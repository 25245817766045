import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Col, Row, Space } from 'antd';
import { useParams } from 'react-router';
import { useQueryParam } from 'src/hooks';
import { NotificationUtil } from 'src/utils';
import SimpleRangeSelector from 'src/components/SimpleRangeSelector';
import PrintingReportTransactionPage from './PrintingReportTransactionPage';
import PrintingReportJournalPage from './PrintingReportJournalPage';
import PrintingReportAssignmentPage from './PrintingReportAssignmentPage';
import PrintingReportWarningPage from './PrintingReportWarningPage';
import PrintingReportSummaryPage from './PrintingReportSummaryPage';
import { SharedReportPageProps } from './SharedPageProps';
import PrintingReportAuditTrail from './PrintingReportAuditTrail';
import PrintingReportMappingItem from './PrintingReportMappingItem';

interface ReportItemConfig {
  /** Easy code to load from the url */
  key: string;

  /** What shows up on the report header */
  reportName: string;
  reportComponent: React.FC<any>;
  reportProperties?: () => any;

  /** @deprecated */
  renderReportHeader?: boolean;
  /** @deprecated */
  renderReportDatePicker?: boolean;

  additionalReports?: ReportItemConfig[];

  hideHeader?: boolean;
  hideDatePicker?: boolean;
}

const PrintingReportPage: React.FC = () => {
  const { reportName: reportNameParam } = useParams();
  const { extraKey: extraKeyParam } = useParams();
  const [expandedIdsQueryParam, setExpandedIdsQueryParam] = useQueryParam('expandedIds');
  const [selectedIdsQueryParam, setSelectedIdsQueryParam] = useQueryParam('selectedIds');
  const [reportStartDateQueryParam, setReportStartDateQueryParam] = useQueryParam('reportStartDate', moment().format('L')?.replaceAll('/', '-'));
  const [reportEndDateQueryParam, setReportEndDateQueryParam] = useQueryParam('reportEndDate', moment().format('L')?.replaceAll('/', '-'));
  // Business logic: Default the reporting dates to today if none is provided
  const [selectedDateRange, setSelectedDateRange] = useState<[moment.Moment | null, moment.Moment | null] | null>();
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

  // Query params have changed, which happens from time to time. But we only need to refresh when it ACTUALLY changes
  useEffect(() => {
    const startDate = reportStartDateQueryParam != null ? moment.utc(reportStartDateQueryParam, 'MM/DD/YYYY') : null;
    const endDate = reportEndDateQueryParam != null ? moment.utc(reportEndDateQueryParam, 'MM/DD/YYYY') : null;

    if (selectedDateRange == null) {
      setSelectedDateRange([startDate, endDate]);
      return;
    }

    if (startDate == null || endDate == null || selectedDateRange[0] == null || selectedDateRange[1] == null) {
      // If any are null, there is a pretty good chance we need to update either to set or to clear
      setSelectedDateRange([startDate, endDate]);
      return;
    }

    if (!startDate.isSame(selectedDateRange[0], 'day') || !endDate.isSame(selectedDateRange[1], 'day')) {
      setSelectedDateRange([startDate, endDate]);
    }
  }, [reportStartDateQueryParam, reportEndDateQueryParam]);

  // Change the expanded keys based on the query param
  useEffect(() => {
    let expandedIds: string[] = [];
    if (expandedIdsQueryParam != null) {
      try {
        // The IDs should be some sort of array
        expandedIds = JSON.parse(expandedIdsQueryParam) as string[];
      } catch (error) {
        NotificationUtil.error({
          key: 'PrintingPage',
          message: 'Error while expanding rows',
          error
        });
      }
    }

    // Need the timeout as the multiple "setState" will be batched in a weird way, causing some to be dropped if parsed slowly? Idk, this works
    setTimeout(() => { setExpandedKeys(expandedIds); }, 0);
  }, [expandedIdsQueryParam]);

  // Change the selected keys based on the query param
  useEffect(() => {
    let selectedIds: string[] = [];
    if (selectedIdsQueryParam != null) {
      try {
        // The IDs should be some sort of array
        selectedIds = JSON.parse(selectedIdsQueryParam) as string[];
      } catch (error) {
        NotificationUtil.error({
          key: 'PrintingPage',
          message: 'Error while selecting rows',
          error
        });
      }
    }

    // Need the timeout as the multiple "setState" will be batched in a weird way, causing some to be dropped if parsed slowly? Idk, this works
    setTimeout(() => { setSelectedKeys(selectedIds); }, 0);
  }, [selectedIdsQueryParam]);

  const handleDateRangeSelect = (values: [moment.Moment | null, moment.Moment | null] | null) => {
    // Any sanitizing will be done here
    setReportStartDateQueryParam(values?.[0]?.format('L').replaceAll('/', '-') ?? null);
    setReportEndDateQueryParam(values?.[1]?.format('L').replaceAll('/', '-') ?? null);
  };

  const renderReportHeader = (config: ReportItemConfig) => {
    if (config.hideHeader) {
      return null;
    }
    return <Row className='with-margin-bottom' justify='space-between'>
      <Col>
        <h1>{config.reportName}</h1>
      </Col>
      {!config.hideDatePicker && <Col>
        <Space size={12}>
          <SimpleRangeSelector
            maxDayRange={31}
            value={selectedDateRange}
            onChange={handleDateRangeSelect}
          />
        </Space>
      </Col>}
    </Row>;
  };

  const renderReport = (config?: (ReportItemConfig | ReportItemConfig[])) => {
    if (config == null) {
      return null;
    }

    const configs = !Array.isArray(config) ? [config] : config;
    const sharedProperties: SharedReportPageProps = {
      startDate: selectedDateRange != null ? selectedDateRange[0] : null,
      endDate: selectedDateRange != null ? selectedDateRange[1] : null,
      expandedKeys: expandedKeys,
      selectedKeys: selectedKeys,
    };

    return configs.map((report, index) => {
      return <div key={index}>
        {renderReportHeader(report)}
        <report.reportComponent {...sharedProperties} {...report.reportProperties?.()} />
        <hr className='page-break' />
        {renderReport(report.additionalReports)}
      </div>;
    });
  };

  const reportConfig: (ReportItemConfig)[] = [
    { key: 'warning',      reportName: 'Activity Reports - Warning',       reportComponent: PrintingReportWarningPage     },
    { key: 'summary',      reportName: 'Activity Reports - Summary',       reportComponent: PrintingReportSummaryPage     },
    { key: 'transaction',  reportName: 'Activity Reports - Transactions',  reportComponent: PrintingReportTransactionPage },
    { key: 'journal',      reportName: 'Activity Reports - Journals',      reportComponent: PrintingReportJournalPage     },
    { key: 'assignment',   reportName: 'Activity Reports - Assignments',   reportComponent: PrintingReportAssignmentPage  },
    // Dev Note: I cannot get this to look nice so I stopped caring
    { key: 'tile',         reportName: 'Activity Reports - Warning',       reportComponent: PrintingReportWarningPage, additionalReports: [
      { key: 'summary', reportName: 'Activity Reports - Summary', reportComponent: PrintingReportSummaryPage, hideDatePicker: true }
    ]},
    { key: 'audit',        reportName: 'Audit Trail Report',               reportComponent: PrintingReportAuditTrail      },
    { key: 'mapping',      reportName: 'Settings Report - Mapping',        reportComponent: PrintingReportMappingItem     }
  ];

  const reportName = reportNameParam?.toLowerCase() ?? '';
  const foundReport = reportConfig.find(x => x.key === reportName);

  return (
    <div className='report-page'>
      {renderReport(foundReport)}
    </div>
  );
};

export default PrintingReportPage;
