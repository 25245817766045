import { Alert, Button, Checkbox, Col, Layout, Row, Space } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import companyLogo from 'src/assets/company-logo-bw-with-name.svg';
import RouteConfig from 'src/config/RouteConfig';
import { useQueryParam } from 'src/hooks';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { ObjectUtil, StringUtil } from 'src/utils';

const { Content, Header } = Layout;

const PrintLayout: React.FC<React.PropsWithChildren> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [orientation, setOrientation] = useState<'portrait' | 'landscape' | 'none'>('none');
  const [returnUrlQueryParam, setReturnUrlQueryParam] = useQueryParam('returnUrl');
  // Purely for handling expanded data from the printed documents
  const [hasExpandedKeysQueryParam, setHasExpandedKeysQueryParam] = useQueryParam('hasExpandedQueryParams');
  const navigate = useNavigate();

  // Try to set the print header: https://stackoverflow.com/a/64510029
  const handlePrint = () => {
    const styleId = 'manual-print-style';
    let styleElement = document.getElementById(styleId);
    if (styleElement == null) {
      styleElement = document.createElement('style');
      styleElement.id = styleId;
      document.head.appendChild(styleElement);
    }

    // Dev Note: This is "rather hacky" and will fix the very odd TR overlap behavior, where the second page (only, of course) would "smush" the first row into the header. No idea why but setting a static break should fix things
    // Future Dev: If this causes issues, suggest that we have a toggle to "remove manual page breaks", which can skip the "page-break-before" portion of the code, rather than going down the printing CSS rabbit hole like I had to
    if (orientation === 'none') {
      styleElement.innerHTML = '';
    } else {
      styleElement.innerHTML = `@page {size: ${orientation}}`;
    }

    // If no expanded params, then we need the page break rule
    // if (!ObjectUtil.ToBoolean(hasExpandedKeysQueryParam)) {
    //   styleElement.innerHTML += `
    //     tbody tr:nth-child(${orientation === 'landscape' ? 19 : 27}) {
    //       page-break-before: always;
    //     }`;
    // }

    window.print();
  };

  const handleBack = () => {
    // Being a tad lazy with the try-catch
    try {
      if (!StringUtil.IsNullOrEmpty(returnUrlQueryParam)) {
        navigate(decodeURIComponent(returnUrlQueryParam));
        return;
      }
    } catch (error) {
      // Eat the error
    }
    navigate(RouteConfig.HOME());
  };

  const handleCheckbox = (e: CheckboxChangeEvent) => {
    setOrientation(e.target.checked ? 'landscape' : 'portrait');
  };

  return (
    <Layout className='print-layout'>
      {/* Banner */}
      <div className='with-print-none' style={{ marginBottom: 8 }}>
        <Alert
          banner
          showIcon
          type="info"
          message="Print Preview"
          description={
            <>
              <p>Your print preview has successfully been generated. You may expand or collapse rows that you missed on the previous page.</p>
              <p>Once you are ready, use your browser&apos;s print feature with Ctrl + P or choose an option below to print.</p>
              <Space>
                <Button onClick={handleBack}>Back</Button>
                <Button onClick={handlePrint}>Print</Button>
                <Checkbox onChange={handleCheckbox}>Landscape</Checkbox>
              </Space>
            </>
          }
        />
      </div>
      <Header className='header'>
        <Row justify='space-between' align='middle'>
          <Col className='text-column'>
            <span className='intro-text'>Account</span>
            <span className='spacer'> |</span>
            <span className='content'>{authContext.account?.displayName}</span>
            <br />
            <span className='intro-text'>Location</span>
            <span className='spacer'> |</span>
            <span className='content'>{authContext.location?.displayName}</span>
          </Col>
          <Col className='logo-column'>
            <div className='logo-container'>
              <img className='logo' src={companyLogo} alt='BackOffice Logo with Text' />
            </div>
          </Col>
        </Row>
      </Header>

      <Content className='content-container'>
        {props.children}
      </Content>
    </Layout>
  );
};

export default PrintLayout;
