import React, { useContext, useEffect, useState } from 'react';
import { Button, Form, Modal, Select, Skeleton } from 'antd';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import MappingItemRuleFilterCriteriaDTO from 'src/models/generated/MappingItemRuleFilterCriteriaDTO';
import { DefaultOptionType } from 'antd/lib/select';
import MappingItemRuleFilterDTO from 'src/models/generated/MappingItemRuleFilterDTO';
import MappingItemRuleModel from 'src/models/frontend/MappingItemRuleModel';
import MappingItemAssignmentDTO from 'src/models/generated/MappingItemAssignmentDTO';
import MappingItemRuleAssignmentDTO from 'src/models/generated/MappingItemRuleAssignmentDTO';
import MappingController from 'src/api/MappingController';
import { NotificationUtil, TableExpressions } from 'src/utils';

export interface RuleFilterModalNewProps {
  open: boolean;
  mappingKey: string;
  mappingItemKey: string;
  selectedRule?: MappingItemRuleModel | null;

  onOk?: (mappingRule: MappingItemRuleModel) => void;
  onCancel?: () => void;
}

// The goal of this component is to provide an easy way to edit one simple value. Anything beyond that should be it's own component
const RuleFilterModalNew: React.FC<RuleFilterModalNewProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [form] = Form.useForm();
  const [ruleFilterCriteria, setRuleFilterCriteria] = useState<MappingItemRuleFilterCriteriaDTO[]>([]);
  const [itemAssignments, setItemAssignments] = useState<MappingItemAssignmentDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // This will reset the form when the visibility or defaultValues have changed
  useEffect(() => {
    // This modal can be reused MANY times so we need to clear it out... this should probably be the standard practice for modals 😅
    if (!props.open) {
      unloadModalData();
      return;
    }
    loadModalData();
  }, [props.open]);

  const loadModalData = async () => {
    setLoading(true);
    try {
      const ruleFilterCriteriaResults = await MappingController.getMappingItemRuleFilterCriteria(authContext.account!.id, authContext.location!.id, props.mappingKey, props.mappingItemKey);
      const itemAssignmentResults = await MappingController.getMappingItemAssignments(authContext.account!.id, authContext.location!.id, props.mappingKey, props.mappingItemKey);

      const sortedRuleFilterCriteria = [...ruleFilterCriteriaResults.data].sort(TableExpressions.NumberSorter('definitionOrder'));
      const sortedItemAssignments = [...itemAssignmentResults.data]
        .sort(TableExpressions.NumberSorter('displayOrder'))
        .map(x => ({ ...x, selectedItemName: '' })); // Clear out the displayValue

      // Generate the selected values off of the available criteria
      let initialValues = sortedRuleFilterCriteria
        .map(rule => {
          let ruleFilters = props.selectedRule?.ruleFilters || [];
          return ruleFilters.find(x => rule.definitionKey === x.definitionKey) as MappingItemRuleFilterDTO;
        })
        .filter(x => x)
        .map<[string, string]>(x => ([x?.definitionKey, x?.filterCriteriaKey]));
        
      // Set the form directly from here. Setting an initial value on the form object will not allow us to reset later
      form.setFields(initialValues.map(([key, value]) => ({ name: key, value: value })));

      setRuleFilterCriteria(sortedRuleFilterCriteria);
      setItemAssignments(sortedItemAssignments);
    } catch (error) {
      NotificationUtil.error({
        key: 'MappingItemCustomTabNew',
        message: 'Error while loading Filter Criteria',
        error
      });
    }
    setLoading(false);
  };

  const unloadModalData = () => {
    setRuleFilterCriteria([]);
    setItemAssignments([]);
    setLoading(false);
    form.resetFields();
  };

  const handleModalCancel = () => {
    props.onCancel?.();
  };

  const handleModalOk = () => {
    // This will run the form's onFinish function
    form.submit();
  };

  const handleFormFinish = (values: Record<string, string>) => {
    // The form values coming in here are going to be interesting to return
    // I believe that we need to build a MappingItemRuleDTO through sheer force of will

    // ROUND 2: Keys should be the DefinitionKey from the ruleFilterCriteria
    // The value will be the selected filter criteria key

    const mappingItemRuleFilters = Object.entries(values).map(([key, value]) => {
      const selectedRuleFilterCriteria = ruleFilterCriteria.find(x => x.definitionKey === key);
      const selectedFilterCriteria = selectedRuleFilterCriteria?.filterCriteria.find(x => x.key === value);

      if (selectedRuleFilterCriteria == null || selectedFilterCriteria == null) {
        return undefined;
      }

      return MappingItemRuleFilterDTO.create({
        definitionKey: selectedRuleFilterCriteria.definitionKey,
        definitionOrder: selectedRuleFilterCriteria.definitionOrder,
        definitionDisplayValue: selectedRuleFilterCriteria.definitionDisplayValue,
        filterCriteriaKey: selectedFilterCriteria.key,
        filterCriteriaDisplayValue: selectedFilterCriteria.displayValue,
      });
    });

    // This should fix reference issues
    const ruleAssignments = [...itemAssignments]
      .map(MappingItemRuleAssignmentDTO.create)
      .map(x => ({...x, complete: undefined, displayValue: "", selectedItemName: ""})) as any as MappingItemAssignmentDTO[]; // Just trust me. This fixes the 'onNew' rule showing a green check when it should be empty. I don't want to create a whole model just for this

    let output = MappingItemRuleModel.create({
      ...props.selectedRule,
      displayReportOn: true,
      ruleAssignments: ruleAssignments,
      ruleFilters: mappingItemRuleFilters.filter(x => x) as MappingItemRuleFilterDTO[], // Types are hard
      // displayName: mappingItemRuleFilters.map(x => `${x.definitionDisplayValue}: ${x.filterCriteriaDisplayValue}`).join(' >> '),
      // ruleFilterDisplay: mappingItemRuleFilters.map(filter => filter.filterCriteriaDisplayValue).join(' >> '), // Do I even need this?
    });

    props.onOk?.(output);
  };

  return <Modal
    destroyOnClose // Indicates that we do not want to cache anything when reopening the modal
    style={{ top: 225 }}
    width={450}
    open={props.open}
    closable={!submitting}
    onCancel={handleModalCancel} // Handles clicks outside the modal window
    bodyStyle={{ paddingBottom: 'unset' }} // Makes the modal JUST a bit cleaner
    footer={<>
      <Button type='default' disabled={submitting} onClick={handleModalCancel}>Cancel</Button>
      <Button type='primary' disabled={loading || submitting} onClick={handleModalOk}>Save</Button>
    </>}
  >
    <h2>Rule Filter Criteria</h2>
    <Skeleton active loading={loading}>
      <Form
        size='large'
        layout='horizontal'
        requiredMark={false}
        form={form}
        onFinish={handleFormFinish}
      >
        {ruleFilterCriteria.map((criteria, index) => (
          <Form.Item
            key={index}
            label={criteria.definitionDisplayValue}
            // name={'key' + index}
            name={criteria.definitionKey}
            rules={[{ required: true, message: `${criteria.definitionDisplayValue} is required` }]}
          >
            <Select
              style={{ width: 250 }}
              options={criteria.filterCriteria.map<DefaultOptionType>(x => ({ label: x.displayValue, value: x.key }))}
              showSearch={true}
              filterOption={(input, option) =>
                {
                  let check = ((option!.label!) as string).toLowerCase().indexOf(input.toLowerCase()) >= 0;
                  return check;
                }
              }
            />
          </Form.Item>
        ))}
      </Form>
    </Skeleton>
  </Modal>;
};

export default RuleFilterModalNew;
