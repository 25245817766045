import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, DatePicker, Input, InputNumber, Modal, Popover, Select, Space, Switch } from 'antd';
import classNames from 'classnames';
import { ObjectUtil } from 'src/utils';
import SelectionOptionDTO from 'src/models/generated/SelectionOptionDTO';
import { DefaultOptionType } from 'antd/lib/select';
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import SimplePopover from './SimplePopover';

export interface MultiToolPickerProps {
  // I might need to just accept a string, not sure
  displayType: 'Label' | 'TextBox' | 'OnOff' | 'DatePicker' | 'ComboBox' | null;
  formatType: 'String' | 'Date' | 'Boolean' | 'DateTime' | 'Number' | null;

  options?: SelectionOptionDTO[];

  disabled?: boolean;
  loading?: boolean;

  // Used by Antd form
  value?: string;
  defaultValue?: string;
  onChange?: (value: any) => void;
  onFinish?: (value: string) => (Promise<void> | void);

  // Here because they probably should be
  /** This will apply to the rendered component */
  className?: string;
  /** This will apply to the rendered component */
  style?: React.CSSProperties;
  /** This will apply to the `<Space>` */
  componentStyle?: React.CSSProperties;


  /** When enabled, this component will initially render the value as text with an edit icon, both of which are clickable.
   * Clicking it will show the message contained in `warningMessage` as a modal before allowing editing of the underlying element, assuming they 'accept'.
   *
   * If no message is displayed, the modal is skipped and the component is shown
  */
  enableCarefulEntry?: boolean;
  /** The warning message to show  */
  warningMessage?: string;

  /**
   * Renders an additional 'Save' button next to the component, which will function exactly like what it says.
   *
   * When not enabled, the component will handle onFinish as it normally does
   */
  enableSaveBeforeFinish?: boolean;

  /**
   * Renders an information icon with a popover with the given ReactNode inside it
   */
  additionalInfo?: React.ReactNode;
}

const MultiToolPicker: React.FC<MultiToolPickerProps> = (props) => {
  const [enableCarefulEntry, setEnableCarefulEntry] = useState(props.enableCarefulEntry ?? false);
  const [componentValue, setComponentValue] = useState(props.defaultValue);

  useEffect(() => {
    setComponentValue(props.defaultValue);
  }, [props.defaultValue]);

  const formatValue = (value?: string | null) => {
    if (value == null) {
      return undefined;
    }

    switch (props.formatType) {
      case 'Date':
        // Can be replaced with 'MM/DD/YYYY'
        return moment(value).format('L');
      case 'DateTime':
        // Can be replaced with 'MM/DD/YYYY hh:mm A'
        return moment(value).format('L LT');
      case 'Boolean':
        return ObjectUtil.ToBoolean(value) ? 'True' : 'False';
      default:
        return value;
    }
  };

  const handleCarefulEntryClick = () => {
    if (props.warningMessage == null) {
      setEnableCarefulEntry(false);
      return;
    }

    Modal.confirm({
      className: 'multitool-warning-modal',
      title: 'Warning',
      content: props.warningMessage,
      okText: 'Agree',
      okType: 'primary',
      cancelText: 'Disagree',
      onOk: () => {
        setEnableCarefulEntry(false);
      }
    });
  };

  const handleComponentChange = (...args: any[]) => {
    // So I guess we really just need to properly handle the input. Switch, datepicker and combobox all should fire a result as soon as it is completed
    // Input changes but needs to be fired onBlur
    if (args.length < 1) {
      return;
    }

    // To be compliant with Antd's Forms, I think we need to return the original props for onChange
    props.onChange && (props.onChange as any)(...args); // Types are messy but some of the onChange events have multiple arguments so our hands are tied

    // Due to new changes, we will hold the value for now. Textbox is a very special case, so it needs a type guard
    const newValue = props.displayType === 'TextBox' && props.formatType !== 'Number' ? (args[0] as React.ChangeEvent<HTMLInputElement>).target.value : args[0];
    setComponentValue(newValue);

    // Do special stuff when enableSaveBeforeFinish is not enabled
    if (!props.enableSaveBeforeFinish) {
      if (props.displayType !== 'TextBox') {
        // Dev Note: We dont need to await this because there is nothing for us to wait
        props.onFinish && props.onFinish(formatValue(newValue) ?? '');
      }
    }
  };

  const handleComponentBlur = () => {
    if (props.enableSaveBeforeFinish || props.displayType !== 'TextBox') {
      return;
    }

    props.onFinish && props.onFinish(formatValue(componentValue) ?? '');
  };

  const handleSaveClick = async () => {
    // Otherwise, we can return most anything
    if (props.onFinish != null) {
      // onFinish can be a promise or nothing. If it is a promise, we want to sticky the loading till it is completed
      await props.onFinish(formatValue(componentValue) ?? '');
    }

    setEnableCarefulEntry(true);
  };

  const handleCancelClick = async () => {
    // Reset careful entry and given value
    setComponentValue(props.defaultValue ?? props.value);
    setEnableCarefulEntry(true);
  };

  const standardProps = {
    format: props.formatType === 'Date' ? 'L' : 'L LT', // Used by the datepickers
    disabled: props.disabled,
    loading: props.loading,
    style: {
      width: props.displayType === 'DatePicker' ? props.formatType === 'Date' ? 150 : 200
        : props.displayType === 'ComboBox' ? 300
          : props.displayType === 'TextBox' ? 400
            : undefined,
      ...props.componentStyle
    } as React.CSSProperties,
    className: classNames(props.className),
    onChange: handleComponentChange,
    onBlur: handleComponentBlur,
  };

  // Dev Note: Only render when enabled and if not a label
  const renderSaveButton = () => props.enableSaveBeforeFinish && props.displayType !== 'Label' && !enableCarefulEntry
    ? <>
      <Button type='primary' onClick={handleSaveClick} loading={props.loading} disabled={props.disabled}>Save</Button>
      <Button type='primary' danger onClick={handleCancelClick} disabled={props.loading}>Cancel</Button>
    </>
    : null;

  const renderInfoIcon = () => {
    // Dev Note: Yeah, not my best work, probably should have just had a property for setting html rather than a boolean. Maybe next time joe, maybe next time
    if (props.additionalInfo != null) {
      if (typeof props.additionalInfo === 'string') {
        return (<SimplePopover
          dangerouslySetHTML
          content={props.additionalInfo}
        />);
      }
      return (<SimplePopover
        content={props.additionalInfo}
      />);
    }
    return null;
  };

  // Pulls out the label renderer so that it can be used for the carefulEntry process. Handles the doubleClick for carefulEntry
  const renderComponentAsLabel = () => {
    // Dev Note: When the value comes in from the mapping pages, it is a return value and not great for display purposes. So, to combat that, we are going to look at the combo items and use those if they are sent in
    let outputText: string | null = null;
    if (props.options != null && props.options.length > 0) {
      // Dev Note: Default is first here, rather than second because of how the label is handled
      const finderValue = componentValue ?? props.value;
      const foundOption = props.options.find(x => x.returnValue == finderValue);
      if (foundOption != null) {
        outputText = foundOption.displayValue;
        // return <span style={props.componentStyle} onDoubleClick={enableCarefulEntry ? handleCarefulEntryClick : undefined}>{foundOption.displayValue}</span>;
      }
    }

    const formattedDefaultValue = formatValue(props.defaultValue);
    const formattedValue = formatValue(props.value);
    return <span style={props.componentStyle} onDoubleClick={enableCarefulEntry ? handleCarefulEntryClick : undefined}>{outputText ?? formattedValue ?? formattedDefaultValue}</span>;
  };

  const renderComponent = () => {
    switch (props.displayType) {
      case 'Label': {
        return renderComponentAsLabel();
      }
      case 'TextBox': {
        const formattedValue = formatValue(props.value ?? componentValue);
        if (props.formatType === 'Number') {
          return <InputNumber value={formattedValue} {...standardProps} />;
        }
        return <Input value={formattedValue} {...standardProps} />;
      }
      case 'OnOff': {
        // Dev Note: For Antd Form, Ensure valuePropName='checked'
        const formattedValue = ObjectUtil.ToBoolean(props.value ?? componentValue);
        return <Switch checked={formattedValue} {...standardProps} />;
      }
      case 'DatePicker': {
        // Dev Note: Clearing the datepicker causes moment to get angry and show 'invalid date' rather than nothing
        let formattedValue: moment.Moment | null = moment(props.value ?? componentValue);
        formattedValue = formattedValue.isValid() ? formattedValue : null;

        return props.formatType === 'Date'
          ? <DatePicker value={formattedValue} {...standardProps} />
          : <DatePicker showTime={{ minuteStep: 5, showSecond: false, use12Hours: true, format: 'h:mm a' }} value={formattedValue} {...standardProps} />;
      }
      case 'ComboBox': {
        const formattedValue = formatValue(props.value ?? componentValue);
        const options: DefaultOptionType[] = props.options != null
          ? props.options.map(x => ({ label: formatValue(x.displayValue), value: x.returnValue }))
          : [];

        return <Select options={options} value={formattedValue} {...standardProps} />;
      }
      default: {
        const formattedValue = formatValue(props.value ?? componentValue);
        return <span>{formattedValue}</span>;
      }
    }
  };

  const renderCarefulEntry = () => {
    if (enableCarefulEntry) {
      return <>
        {renderComponentAsLabel()}
        {props.displayType !== 'Label' && <EditOutlined onClick={handleCarefulEntryClick} />}
      </>;
    }
    return <>
      {renderComponent()}
      {renderSaveButton()}
    </>;
  };

  return <Space className='multi-tool-picker' style={props.style}>
    {renderCarefulEntry()}
    {renderInfoIcon()}
  </Space>;
};

export default MultiToolPicker;
