import React, { useContext, useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Table } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { NameOf, TableColumnBuilder } from 'src/utils';
import AccountController from 'src/api/AccountController';
import AccountDTO from 'src/models/generated/AccountDTO';
import { CacheContext } from 'src/providers/CacheContext';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import NotificationUtil from 'src/utils/NotificationUtil';
import StandardSingleEditModal from './modals/StandardSingleEditModal';

interface SelectAccountProps {
  disabled?: boolean;
  /** This will be the ID for the account */
  initialValue?: string;
  scroll?: TableProps<AccountDTO>['scroll'];
  onEdit?: (value: AccountDTO) => void;
  onChange?: (value: AccountDTO) => void;
  /** Called on doubleclick so that the item can be assumed to be selected */
  onSelect?: () => void;
  /** Called when loading is complete. Mainly used to select->redirect if there is only one element */
  onLoadComplete?: (accounts: AccountDTO[]) => void;
}

const SelectAccount: React.FC<SelectAccountProps> = (props) => {
  const cacheContext = useContext(CacheContext);
  const authContext = useContext(AuthenticationContext);
  const [selectedValue, setSelectedValue] = useState<AccountDTO>();
  const [tableData, setTableData] = useState<AccountDTO[]>();
  const [editNameModalValue, setEditNameModalValue] = useState<AccountDTO>();
  const [editNameModalVisible, setEditNameModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  // OnLoad
  useEffect(() => {
    loadTableData();
  }, []);

  // Try again when account get's loaded
  useEffect(() => {
    loadTableData();
  }, [props.initialValue]);

  const loadTableData = async (refresh: boolean = false) => {
    setLoading(true);
    try {
      const results = await cacheContext.getAccounts(refresh);
      results.sort((a, b) => a.displayName?.localeCompare(b.displayName!) ?? 0);
      setTableData(results);
      props.onLoadComplete?.(results);
      if (props.initialValue != null) {
        const initialValue = props.initialValue ?? '';
        const selectedValue = results.find(x => x.id === initialValue);

        // Send values into handler for code reuse
        handleSelectValue(selectedValue ? [selectedValue] : []);
      }
    } catch (error) {
      NotificationUtil.error({
        key: 'SelectAccount',
        message: 'Error while loading Accounts'
      });
    }
    setLoading(false);
  };

  const handleSelectValue = (records: AccountDTO[]) => {
    setSelectedValue(records[0]);
    props.onChange?.(records[0]);
  };

  const handleDoubleClick = () => {
    // First click should fire the handleSelectValue, so all we need to do is confirm that it has been selected
    props.onSelect?.();
  };

  const handleEditClick = (record: AccountDTO) => {
    setEditNameModalValue(record);
    setEditNameModalVisible(true);
  };

  const handleEditModalFinish = async (value: string) => {
    // SetLoading is pulling double duty here
    setLoading(true);
    try {
      let account = await AccountController.saveAccount(editNameModalValue!.id, value);
      // if editing the current account update the context
      if(authContext.account?.id == account.data.id)
      {
        await authContext.updateAccount(account.data);
      }

      // Success! Toss out a notification and reload the grid
      NotificationUtil.success({
        key: 'Select Account',
        message: 'Account has been updated'
      });
      await loadTableData(true);
      props.onEdit?.(account.data);
      setEditNameModalVisible(false);
    } catch (error) {
      NotificationUtil.error({
        key: 'Select Account',
        message: 'Error while saving Account',
        error
      });
    }
    setLoading(false);
  };

  const tableColumns: ColumnProps<AccountDTO>[] = [
    TableColumnBuilder.Create<AccountDTO>('displayName', 'Name')
      .AddSorter('Text')
      .AddRenderer('Ellipses')
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AccountDTO>('partnerKey', 'Partner')
      .Width(120)
      .AddSorter('Text')
      .AddRenderer('Ellipses')
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AccountDTO>('accountCode', 'Account')
      .Width(120)
      .AddSorter('Text')
      .AddRenderer('Ellipses')
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<AccountDTO>()
      .Key('Edit')
      .Width(50)
      .AddRenderer('Custom', (_, record) => (<Button type='link' icon={<EditOutlined />} onClick={() => handleEditClick(record)} />))
      .Build(),
  ];

  return (
    <div className='account-selection-component'>
      <Table
        rowKey={NameOf<AccountDTO>('id')}
        className='less-condensed-table striped-table borderless-table'
        rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
        scroll={props.scroll}
        loading={loading}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: selectedValue != null ? [selectedValue.id] : [],
          getCheckboxProps: () => ({ disabled: props.disabled }),
          onChange: (_, rows) => handleSelectValue(rows)
        }}
        onRow={record => ({
          onClick: () => handleSelectValue([record]),
          onDoubleClick: () => handleDoubleClick(),
        })}
        pagination={false}
        columns={tableColumns}
        dataSource={tableData}
      />
      <StandardSingleEditModal
        title='Edit Account Name'
        displayType='TextBox'
        formatType='String'
        defaultValue={editNameModalValue?.displayName}
        open={editNameModalVisible}
        onCancel={() => setEditNameModalVisible(false)}
        onFinish={handleEditModalFinish} />
    </div>
  );
};

export default SelectAccount;
