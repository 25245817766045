import React from 'react';
import classNames from 'classnames';
import StandardSVGProps from './StandardSVGProps';

const ReloadIconSVG: React.FC<StandardSVGProps> = (props) => {
  return (
    <span style={props.style} className={classNames('reload-icon-svg', 'svg', props.className)}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.775 4.23125C17.7813 2.2375 15.045 1 12.0063 1C5.92877 1 1.02002 5.9225 1.02002 12C1.02002 18.0775 5.92877 23 12.0063 23C17.135 23 21.4113 19.4937 22.635 14.75H19.775C18.6475 17.9538 15.595 20.25 12.0063 20.25C7.45502 20.25 3.75627 16.5513 3.75627 12C3.75627 7.44875 7.45502 3.75 12.0063 3.75C14.2888 3.75 16.3238 4.69875 17.8088 6.1975L13.3813 10.625H23.0063V1L19.775 4.23125Z" fill="#BFBBBD" />
      </svg>
    </span>
  );
};

export default ReloadIconSVG;
