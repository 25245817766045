import axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import AuditTrailDTO from 'src/models/generated/AuditTrailDTO';
import AuditTrailHistoryDTO from 'src/models/generated/AuditTrailHistoryDTO';
import AuditTrailItemFilterDTO from 'src/models/generated/AuditTrailItemFilterDTO';
import AuditTrailItemViewDTO from 'src/models/generated/AuditTrailItemViewDTO';

class AuditController {
  private prefix: string = "accounts"; // Dev Note: This is intentional. Endpoints DO start with this rather than the controller name

  // API_37
  async getAuditTrail(accountId: string, locationId: string, beginDate: moment.Moment, endDate: moment.Moment, optional?: { /* sortAscending?: boolean, */ auditTrailItemFilters?: AuditTrailItemFilterDTO[] }): Promise<AxiosResponse<AuditTrailDTO>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/audittrail`;
    let data = optional?.auditTrailItemFilters ?? [];
    let params: Record<string, string> = {
      begindate: beginDate.format(),
      enddate: endDate.format(),
    }

    // Removed Jul 5, 2022: We will sort on the frontend so it's not needed
    // if (optional != null) {
    //   params = {
    //     ...params,
    //     sortascend: optional.sortAscending ? 'true' : 'false'
    //   }
    // }

    const result = await axios.post<AuditTrailDTO>(route, data, {
      params: params
    });
    // Transform the results
    result.data = AuditTrailDTO.create(result.data);
    return result;
  }

  // API_38
  async getAuditTrailFilters(accountId: string, locationId: string): Promise<AxiosResponse<AuditTrailItemFilterDTO[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/audittrailfilters`;
    const result = await axios.get<AuditTrailItemFilterDTO[]>(route);

    // Transform the results
    result.data = result.data.map(x => AuditTrailItemFilterDTO.create(x));
    return result;
  }

  // API_39
  async getAuditTrailItemHistory(accountId: string, locationId: string, auditTrailItemId: string): Promise<AxiosResponse<AuditTrailHistoryDTO>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/audittrailhistory/${auditTrailItemId}`;
    const result = await axios.get<AuditTrailHistoryDTO>(route);

    // Transform the results
    result.data = AuditTrailHistoryDTO.create(result.data);
    return result;
  }

  // API_40
  async getAuditTrailItem(accountId: string, locationId: string, auditTrailItemId: string): Promise<AxiosResponse<AuditTrailItemViewDTO>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/audittrail/${auditTrailItemId}/view`;
    const result = await axios.get<AuditTrailItemViewDTO>(route);

    // Transform the results
    result.data = AuditTrailItemViewDTO.create(result.data);
    return result;
  }
}

export default new AuditController();
