import React from 'react';
import classNames from 'classnames';
import StandardSVGProps from './StandardSVGProps';

const ApproveIconSVG: React.FC<StandardSVGProps> = (props) => {
  return (
    <span style={props.style} className={classNames('approve-icon-svg', 'svg', props.className)}>
      <svg style={{paddingTop: 2, paddingBottom: 2}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 23H4.99502V9.8H1V23ZM22.9726 10.9C22.9726 9.69 22.0737 8.7 20.9751 8.7H14.673L15.6218 3.673L15.6517 3.321C15.6517 2.87 15.482 2.452 15.2123 2.155C14.6441 1.53513 13.666 1.53781 13.1013 2.16078L7.5818 8.249C7.21226 8.645 6.99253 9.195 6.99253 9.8V20.8C6.99253 22.01 7.89141 23 8.99004 23H17.9788C18.8078 23 19.5169 22.45 19.8166 21.658L22.8328 13.903C23.0918 12.9453 22.9726 11.887 22.9726 10.9Z" fill="#FFFFFF" />
      </svg>
    </span>
  );
};

export default ApproveIconSVG;
