import React, { useContext, useEffect, useState } from 'react';
import { Progress } from 'antd';
import { useNavigate } from 'react-router-dom';
import RouteConfig from 'src/config/RouteConfig';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { Sleep } from 'src/utils';
import { CacheContext } from 'src/providers/CacheContext';

const LogoutPage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const cacheContext = useContext(CacheContext);
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  // OnLoad
  useEffect(() => {
    // Dev Note: This is needed because React does not support async useEffects
    doLogout();
  }, []);

  const doLogout = async () => {
    // Create a batch of things to do all at once, await them together and return. Very smooth transition this way
    const advancePromise = advance(2000, 120, (val) => setProgress(val));
    const logoutPromise = authContext.logout();
    const cachePromise = cacheContext.clearCache();

    await Promise.all([advancePromise, logoutPromise, cachePromise]);

    navigate(RouteConfig.LOGIN_EMAIL());
  };

  const advance = async (totalMilliseconds: number, steps: number, onStep: (value: number) => void) => {
    const sleepMs = totalMilliseconds / steps;
    onStep(0);
    for (let currentStep = 0; currentStep <= steps; currentStep++) {
      const currentTotal = 100 / steps * currentStep;
      await Sleep(sleepMs);
      onStep(currentTotal);
    }
  };

  return (
    <div className='logout-page login-flow'>
      <h1>Logging Out</h1>

      <p>Please wait a moment while you are logged out.</p>
      <Progress type="circle" percent={progress} format={percent => percent?.toFixed(0)} />
    </div>
  );
};

export default LogoutPage;
