// This was inspired by, but not copied from https://www.npmjs.com/package/react-auth-code-input
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Input, InputRef, Space } from 'antd';
import classNames from 'classnames';
import './AuthCodeInput.less';

interface AuthCodeInputProps {
  /** I hate the name too. Number of characters that should be accepted/rendered */
  characters: number;
  containerClass?: string;
  inputClass?: string;

  /** Affects the numbers allows and keyboard on mobile.
   *
   * Default: Text
   */
  inputType?: 'number' | 'password' | 'text';
  loading?: boolean;
  disabled?: boolean;

  // Added to handle being a form components
  value?: string;

  onChange?: (value: string) => void;
}

const defaultProps: Partial<AuthCodeInputProps> = {
  containerClass: '',
  inputClass: '',
  inputType: 'text',
  loading: false,
  disabled: false,
};

const AuthCodeInput: React.FC<AuthCodeInputProps> = (outerProps) => {
  const props = { ...defaultProps, ...outerProps };
  const inputs: ReactNode[] = [];
  const inputRefs: React.RefObject<InputRef>[] = [];
  const [internalValue, setInternalValue] = useState<{ [key: string]: string }>({});

  // We need to update things when the props are changed
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const setValue = (value?: string | null) => {
    if (!inputRefs || inputRefs.length < 1) {
      return;
    }
    if (value == null) {
      // inputRefs.forEach(x => x.current?.setValue(''));
      setInternalValue(prev => {
        return Object.fromEntries(Object.entries(prev).map(([key, _]) => [key, '']));
      });
      return;
    }

    const cleanValue = value
      .slice(0, props.characters) // Get a string with max length of the characters (or less)
      .padEnd(props.characters); // Adds spaces for any remaining portion of the string

    // Save changes to state
    setInternalValue(prev => {
      return Object.fromEntries(Object.entries(cleanValue).map(([key, val]) => [key, (val).trim()]));
    });

    // Set next available for selection
    const lastIndex = Math.min(cleanValue.trim().length + 1, props.characters) - 1;
    inputRefs[lastIndex].current?.focus();
  };

  const onChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {

    //if input is NaN erase it
    if(isNaN(parseInt(e.target.value))){
      e.target.value = '';
    }
    const currentValue: string = (e.target.value ?? '').trim();



    // Move right when adding a char and not at last input
    if (currentValue.length > 0 && index + 1 < inputRefs.length) {
      inputRefs[index + 1].current?.focus({ cursor: 'end' });
    }

    // Save changes to state
    setInternalValue(prev => {
      let newValue = { ...prev };
      newValue[index] = currentValue;

      // Dev Note: Might not be the best place for it, but it will work
      // Submit the changes to onChange
      if (props.onChange != null) {
        const value = Object.entries(newValue).map(([_, val]) => val).join('').trim();
        props.onChange(value);
      }

      return newValue;
    });
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    // Special handler for backspace
    if (e.key === 'Backspace') {
      const currentValue: string = internalValue[index] ?? '';

      // Move the cursor left if the value is empty and not first
      if (index > 0 && currentValue.length === 0) {
        inputRefs[index - 1].current?.focus({ cursor: 'end' });
      }

      // Save changes to state
      setInternalValue(prev => {
        let newValue = { ...prev };
        newValue[index] = '';
        return newValue;
      });

      e.preventDefault();
    }
  };

  const handlePaste = (index: number, e: React.ClipboardEvent<HTMLInputElement>) => {
    const value = e.clipboardData.getData('Text');
    let newvalue = '';
    for(let i = 0; i < value.length; i++){
      let c = value.charAt(i);
      if(!isNaN(parseInt(c)))
      {
        newvalue = newvalue.concat(c);
      }

      else
      {
        newvalue = newvalue.concat(' ');
      }

    }
    setValue(newvalue);
    props.onChange && props.onChange(newvalue);
    // Need this or the onChange gets fired off
    e.preventDefault();
  };

  // Generate the inputs
  for (let i = 0; i < props.characters; i++) {
    const inputNumber = i;
    const inputRef = useRef<InputRef>(null);

    inputs.push(<Input
      key={`authcodeinput-${inputNumber}`}
      maxLength={1}
      ref={inputRef}
      type={props.inputType}
      value={internalValue[inputNumber]}
      disabled={props.disabled || props.loading}
      onChange={e => onChange(inputNumber, e)}
      onKeyDown={e => handleKeyDown(inputNumber, e)}
      onPaste={e => handlePaste(inputNumber, e)}
    />);
    inputRefs.push(inputRef);
  }

  const classes = classNames('auth-code-input', props.containerClass);

  return (<Space size='middle' className={classes}>{inputs}</Space>);
};

export default AuthCodeInput;
