import React, { useState } from 'react';
import { GetCurrentStateAsync } from 'src/utils';

/**
 * Adds an async value getter to the standard useState() hook
 */
export function useStateAsync<T>(initialState: T | (() => T)): [T, (value: T | ((prevState: T) => T)) => void, () => Promise<T>] {
  const [internal, setInternal] = useState<T>(initialState);

  // Needs to be wrapped in a function or react will get VERY excited (I recommend you leave this alone)
  const getInternalAsync = () => GetCurrentStateAsync<T>(setInternal);

  return [internal, setInternal, getInternalAsync];
}
