import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Checkbox, Divider, Form, Input } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { CacheContext } from 'src/providers/CacheContext';
import { NameOf, ObjectUtil, NotificationUtil, ErrorUtil } from 'src/utils';
import RouteConfig from 'src/config/RouteConfig';
import LoginService from './LoginService';

interface AgentLoginFormData {
  username: string;
  password: string;
  accountNumber: string;
}

const AgentLoginPage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const cacheContext = useContext(CacheContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const inputRef = useRef<any>(null); // This is for auto focusing onload
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState('');

  // OnLoad
  useEffect(() => {
    // Figure out if we are on the right page (happens on reload)
    ensureConsistentRoute();
    inputRef.current!.focus();
  }, []);

  const ensureConsistentRoute = async () => {
    const nextRoute = await LoginService.GetRouteWithSetAccountLocation(authContext, cacheContext, location);
    if (nextRoute !== location.pathname) {
      navigate(nextRoute);
    }
  };

  const handleFormFinish = async (values: AgentLoginFormData) => {
    // Trim the password
    values = ObjectUtil.TrimValues(values, 'username', 'password', 'accountNumber');

    setLoading(true);
    try {
      await authContext.login_agent(values.username, values.password, values.accountNumber);

      // Dev Note: We sent them directly to the select location page, since that is the only thing next. The agent shouldn't need two factor or anything like that
      NotificationUtil.closeAll();
      navigate(await LoginService.GetRouteWithSetAccountLocation(authContext, cacheContext, location));
    } catch (error) {
      const errorMessage = ErrorUtil.GetMessageFromError(error);
      setLoginError(errorMessage);
      // Put focus back to the input as loading will disable the box, removing focus
      inputRef.current!.focus();
    }
    setLoading(false);
  };

  return (
    <div className='agent-login-page login-flow'>
      <h1>Sign In</h1>

      {loginError.length > 0 && <Alert
        showIcon
        closable
        type='warning'
        message={loginError}
        description={<span>Please contact Back Office support at <Link to='//www.tbohelp.com' target="_blank" rel="noopener noreferrer">tbohelp.com</Link> for further assistance.</span>}
        style={{ marginBottom: 16, textAlign: 'left' }}
        onClose={() => setLoginError('')}
      />}

      <Form
        size='large'
        layout='vertical'
        requiredMark={false}
        form={form}
        onFinish={handleFormFinish}
        initialValues={{ email: authContext.auth?.emailAddress }}
      >
        <Form.Item
          name={NameOf<AgentLoginFormData>('username')}
          rules={[
            { required: true, message: 'Username is required' }
          ]}>
          <Input allowClear placeholder='Username' ref={inputRef} disabled={loading} />
        </Form.Item>
        <Form.Item
          name={NameOf<AgentLoginFormData>('password')}
          rules={[
            { required: true, message: 'Password is required' }
          ]}>
          <Input.Password allowClear placeholder='Password' autoComplete='password' disabled={loading} />
        </Form.Item>
        <Form.Item
          name={NameOf<AgentLoginFormData>('accountNumber')}
          rules={[
            { required: true, message: 'Account Number is required' }
          ]}>
          <Input allowClear placeholder='Account Number' />
        </Form.Item>

        <Form.Item style={{ marginTop: 8 }}>
          <Button type='primary' htmlType='submit' block loading={loading}>Continue</Button>
        </Form.Item>
      </Form>

      <p style={{ fontSize: 12 }}>By selecting sign in, you agree to our <Link to={RouteConfig.LINK.LOGIN_TERMS_OF_SERVICE}>Terms of Service</Link> and have acknowledged our <Link to={RouteConfig.LINK.LOGIN_PRIVACY_STATEMENT}>Privacy Policy</Link>.</p>
    </div>
  );
};

export default AgentLoginPage;
