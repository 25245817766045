import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Checkbox, Divider, Form, Input } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { CacheContext } from 'src/providers/CacheContext';
import { NameOf, ObjectUtil, NotificationUtil, ErrorUtil } from 'src/utils';
import RouteConfig from 'src/config/RouteConfig';
import LoginService from './LoginService';

const LoginActivateSuccessPage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const cacheContext = useContext(CacheContext);
  const location = useLocation();
  const navigate = useNavigate();

  // OnLoad
  useEffect(() => {
    // Figure out if we are on the right page (happens on reload)
    ensureConsistentRoute();
  }, []);

  const ensureConsistentRoute = async () => {
    const auth = await authContext.getAuth();
    if (auth?.authenticated) return;
    //if the user is authenticated they can stay here as long as they want
    //this is to solve the issue of wanting to pause at the activation success page and let the user press 'Continue'
    //while still redirecting to a login page if the user is not authenticated
    const nextRoute = await LoginService.GetRouteWithSetAccountLocation(authContext, cacheContext, location);
    if (nextRoute !== location.pathname) {
      navigate(nextRoute);
    }
  };

  const onFinish = async () => {
    navigate(await LoginService.GetRouteWithSetAccountLocation(authContext, cacheContext, location));
  };

  return (
    <div className='login-activate-success-page login-flow'>
      <h1>Welcome!</h1>
      <p>Your account has been successfully activated.</p>
      <Button size='large' type='primary' block onClick={onFinish}>Continue</Button>
    </div>
  );
};

export default LoginActivateSuccessPage;
