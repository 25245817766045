import moment from 'moment';
import { ObjectUtil } from 'src/utils';

interface AuditTrailItemDTO {
  id: string;
  created: moment.Moment | null;
  locationDisplayName: string;
  userDisplayName: string;
  name: string;
  type: string;
}
const AuditTrailItemDTO = {
  create: (initValues?: Partial<AuditTrailItemDTO>): AuditTrailItemDTO => {
    const defaults: AuditTrailItemDTO = {
      id: '',
      created: null,
      locationDisplayName: '',
      userDisplayName: '',
      name: '',
      type: '',
    };
    const fixedInitValues: Partial<AuditTrailItemDTO> = initValues != null
      ? {
        created: ObjectUtil.ToMoment(initValues.created),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default AuditTrailItemDTO;
