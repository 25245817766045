import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Col, Row, Space, Table, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { NameOf, NotificationUtil, TableExpressions, TableColumnBuilder, ObjectUtil } from 'src/utils';
import { useQueryParam } from 'src/hooks';
import ReportJournalDTO from 'src/models/generated/ReportJournalDTO';
import ReportJournalItemDTO from 'src/models/generated/ReportJournalItemDTO';
import ReportController from 'src/api/ReportController';
import LinkWithQuery from 'src/components/LinkWithQuery';
import RouteConfig from 'src/config/RouteConfig';
import PrintIconSVG from 'src/components/svgs/PrintIconSVG';
import { FilterValue } from 'antd/lib/table/interface';
import { tz } from 'moment-timezone';

interface ReportJournalsProps {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  refreshTrigger: number;
}

const ReportJournals: React.FC<ReportJournalsProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [journalIdQueryParam, setJournalIdQueryParam] = useQueryParam('journalId');
  const [referenceIdQueryParam, setReferenceIdQueryParam] = useQueryParam('journalReferenceId');
  const [tableData, setTableData] = useState<ReportJournalDTO[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<ReportJournalDTO[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [journalTableFiltersQueryParam     ,setJournalTableFiltersQueryParam] = useState<string>('');
  const [journalTableSortedColumnQueryParam,setJournalTableSortedColumnQueryParam] = useState<string>('');
  const [journalTableSortOrderQueryParam   ,setJournalTableSortOrderQueryParam] = useState<string>('');

  useEffect(() => {
    if (props.startDate != null && props.endDate != null) {
      loadTableData();
    }
  }, [props.startDate, props.endDate]);

  useEffect(() => {
    if(props.refreshTrigger > 0)
      refreshTableData();
  }, [props.refreshTrigger]);

  // Handle the filter query params
  useEffect(() => {
    if (journalIdQueryParam != null) {
      const filteredData = tableData.filter(x => x.journalId === journalIdQueryParam);
      setFilteredTableData(filteredData);
      return;
    }

    if (referenceIdQueryParam != null) {
      const filteredData = tableData.filter(x => x.displayReference === referenceIdQueryParam);
      setFilteredTableData(filteredData);
      setExpandedKeys(filteredData.map(x => x.journalId));
      return;
    }
    setFilteredTableData(tableData);
    if(!refreshing){
      setExpandedKeys([]);
    }
    else{
      setRefreshing(false);
    }
  }, [tableData, journalIdQueryParam, referenceIdQueryParam]);

  const loadTableData = async () => {
    if (props.startDate == null || props.endDate == null) {
      return;
    }

    setLoading(true);
    try {
      const results = await ReportController.getReportJournal(authContext.account!.id, authContext.location!.id, props.startDate, props.endDate);

      // Sort data
      let sortedData = [...results.data].map(x => ({
        ...x,
        // Sort inner array
        items: TableExpressions.Methods.FixSortOrderNumber(x.items.sort(TableExpressions.Sorters.NumberSorter('displayOrder')), 'displayOrder')
      }));
      
      sortedData = sortedData.map(x => {
        for(let key in x.displayCommentTimeStamps){
          x.displayComment = x.displayComment.replace(key, x.displayCommentTimeStamps[key].format('MM/DD/YYYY') + ' at ' + x.displayCommentTimeStamps[key].format('h:mm:ss a ') + tz(tz.guess()).zoneAbbr());
        }
        return x;
      });

      setTableData(sortedData);
    } catch (error) {
      NotificationUtil.error({
        key: 'ReportJournals',
        message: 'Error while loading Report data',
        error
      });
    }
    setLoading(false);
  };
  const refreshTableData = async () => {
    
    if (props.startDate == null || props.endDate == null) {
      return;
    }
    setRefreshing(true);
    setLoading(true);
    try {
      const results = await ReportController.getReportJournal(authContext.account!.id, authContext.location!.id, props.startDate, props.endDate);

      // Sort data
      let sortedData = [...results.data].map(x => ({
        ...x,
        // Sort inner array
        items: TableExpressions.Methods.FixSortOrderNumber(x.items.sort(TableExpressions.Sorters.NumberSorter('displayOrder')), 'displayOrder')
      }));
      sortedData = sortedData.map(x => {
        for(let key in x.displayCommentTimeStamps){
          x.displayComment = x.displayComment.replace(key, x.displayCommentTimeStamps[key].format('MM/DD/YYYY') + ' at ' + x.displayCommentTimeStamps[key].format('h:mm:ss a ') + tz(tz.guess()).zoneAbbr());
        }
        return x;
      });
      setTableData(sortedData);
    } catch (error) {
      NotificationUtil.error({
        key: 'ReportTransactions',
        message: 'Error while loading Report data',
        error
      });
    }
    setLoading(false);

  };

  const renderExpandCollapseAll = () => {
    if (expandedKeys.length > 0) {
      return <Button style={{marginBottom: '10px',marginRight:'10px'}} onClick={() => (setExpandedKeys([]))}>Collapse All</Button>;
    }
    return <Button style={{marginBottom: '10px',marginRight:'10px'}} onClick={() => (setExpandedKeys(tableData.map(x => x.journalId)))}>Expand All</Button>;
  };

  const renderClearAllFilter = () => {
    let output: React.ReactNode[] = [];
    if (journalIdQueryParam != null) {

      const filteredData = tableData.find(x => x.journalId === journalIdQueryParam);
      let journalName = journalIdQueryParam;
      if(filteredData != null)
        journalName = filteredData.displayReference;
      output.push(<Alert
        key='journalId'
        closable
        className='inline-alert'
        type='warning'
        message={`Filtering on Journal: ${journalName}`}
        onClose={() => setJournalIdQueryParam(null)}
      />);
    }

    if (referenceIdQueryParam != null) {
      output.push(<Alert
        key='referenceId'
        closable
        className='inline-alert'
        type='warning'
        message={`Filtering on Reference ID: ${referenceIdQueryParam}`}
        onClose={() => setReferenceIdQueryParam(null)}
      />);
    }

    return output;
  };

  const setKeys = () => {
    localStorage.setItem('journalExpandedIds', JSON.stringify(expandedKeys));
  };

  const renderPrintButton = () => {
    const url = new URL(location.href);
    url.searchParams.delete('route_id');
    const printingQueryParams: [string, string|null][] = [
      ['reportStartDate', props.startDate?.format('L')?.replaceAll('/', '-') ?? ''],
      ['reportEndDate', props.endDate?.format('L')?.replaceAll('/', '-') ?? ''],
      ['journalId', journalIdQueryParam],
      ['journalReferenceId', referenceIdQueryParam],
      ['returnUrl', url.pathname + url.search],
      ['journalFilters', journalTableFiltersQueryParam],
      ['journalSortedColumn', journalTableSortedColumnQueryParam],
      ['journalSortOrder', journalTableSortOrderQueryParam],
      //['expandedIds', JSON.stringify(expandedKeys)],
    ];

    return <LinkWithQuery openInNewTab to={RouteConfig.PRINTING_JOURNAL_REPORT()} additionalQueryParams={printingQueryParams}>
      <Button onClick={setKeys} style={{ height: 36 }}><Space><PrintIconSVG /><span>Print</span></Space></Button>
    </LinkWithQuery>;
  };

  const tableColumns: ColumnProps<ReportJournalDTO>[] = [
    TableColumnBuilder.Create<ReportJournalDTO>('displayDate', 'Date')
      .Width(140)
      .AddSorter('Date')
      .AddEnumFilterer(tableData.map(x => x.displayDate).sort((a, b) => a?.diff(b) ?? 0).filter(x => x != null && x?.year() > 2000).map(x => x?.format('L') ?? ''), 'Date')
      .AddRenderer('ShortDate')
      .Build(),
    TableColumnBuilder.Create<ReportJournalDTO>('displayType', 'Type')
      .Width(180)
      .AddSorter('Text')
      .AddEnumFilterer(tableData.map(x => x.displayType).sort((a, b) => a.localeCompare(b)), 'Exact Text')
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportJournalDTO>('displayName', 'Name')
      .Width(160)
      .AddSorter('Text')
      .AddTextFilterer()
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportJournalDTO>('displayReference', 'Reference')
      .Width(160)
      .AddSorter('Text')
      .AddTextFilterer()
      // Dev Note: It is entirely possible that we need this instead: ['transactionId', record.transactionId]
      .AddRenderer('Custom', (value, record) => <LinkWithQuery to={RouteConfig.REPORTS_TRANSACTIONS()} additionalQueryParams={[['transactionReferenceId', value], ['transactionType', record.displayType]]} includedQueryParams={['reportStartDate', 'reportEndDate']}>
        <Button type='link'>{value}</Button>
      </LinkWithQuery>)
      .Build(),
    TableColumnBuilder.Create<ReportJournalDTO>('displayDebit', 'Debit')
      .Width(120)
      .Align('right')
      .AddSorter('Number')
      .AddCurrencyFilterer()
      .AddRenderer('Currency', true)
      .Build(),
    TableColumnBuilder.Create<ReportJournalDTO>('displayCredit', 'Credit')
      .Width(120)
      .Align('right')
      .AddSorter('Number')
      .AddCurrencyFilterer()
      .AddRenderer('Currency', true)
      .Build(),
    TableColumnBuilder.Create<ReportJournalDTO>('displayStatus', 'Status')
      .Width(130)
      .AddSorter('Text')
      .AddEnumFilterer(tableData.map(x => x.displayStatus).sort((a, b) => a.localeCompare(b)), 'Exact Text')
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportJournalDTO>('displayComment', 'Comment')
      .AddSorter('Text')
      .AddTextFilterer()
      .AddRenderer('Ellipses')
      .Build(),
  ];

  const transactionColumns: ColumnProps<ReportJournalItemDTO>[] = [
    TableColumnBuilder.Create<ReportJournalItemDTO>('displayAccount', 'Account')
      .Width(180)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportJournalItemDTO>('displayName', 'Name')
      .Width(160)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportJournalItemDTO>('displayDescription', 'Description')
      .Width(540)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportJournalItemDTO>('displayDebit', 'Debit')
      .Width(100)
      .Align('right')
      .AddRenderer('Currency', true, true)
      .Build(),
    TableColumnBuilder.Create<ReportJournalItemDTO>('displayCredit', 'Credit')
      .Width(100)
      .Align('right')
      .AddRenderer('Currency', true, true)
      .Build(),
  ];

  const handleTableChange = (pagination: any, filters: Record<string, FilterValue | null>, sorter: any, extra: { currentDataSource: Array<ReportJournalDTO> }) => {
    //When printing we want to just show the table as it was with sorts filters and all
    setJournalTableFiltersQueryParam(JSON.stringify(filters));
    setJournalTableSortedColumnQueryParam(sorter.field);
    setJournalTableSortOrderQueryParam(sorter.order);
  };

  return (
    <div className='report-journal'>
      <Row justify='space-between' style={{ marginBottom: 16 }}>
        <Col>
          <p style={{width:800}}>This report provides details on the type of entry that will be posted into the Accounting Software based on the selections made within mapping. Expand a transaction to view the detailed debits, credits and corresponding information of the journal.</p>
        </Col>
        <Col flex={'100px'}>
          {renderPrintButton()}
        </Col>
      </Row>
      {renderExpandCollapseAll()}
      <Button style={{marginRight:'10px', marginBottom: '10px'}} onClick={refreshTableData}><Space><span>Refresh</span></Space></Button>
      {!loading && renderClearAllFilter()}
      <Table
        rowKey={NameOf<ReportJournalDTO>('journalId')}
        className='condensed-table striped-table borderless-table'
        rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
        loading={loading}
        pagination={false}
        columns={tableColumns}
        dataSource={filteredTableData}
        onChange={handleTableChange as any}
        expandable={{
          expandedRowKeys: expandedKeys,
          onExpandedRowsChange: keys => (setExpandedKeys([...keys])),
          expandIcon: (props) => {
            const onClick = (e: React.MouseEvent<HTMLElement>) => props.onExpand(props.record, e);
            return props.expanded ? <DownOutlined onClick={onClick} className='standard-chevrons' /> : <RightOutlined onClick={onClick} className='standard-chevrons' />;
          },
          // Might need to enhance this with onExpand to default them all open
          expandedRowRender: record => {
            // Add the first column from the calling row
            const expandedColumns: ColumnProps<ReportJournalItemDTO>[] = [
              TableColumnBuilder.Create<any>('displaySource', 'Type')
                .Width(170)
                .OnCell((_, index) => ({ rowSpan: index === 0 ? record.items.length : 0 }))
                .AddRenderer('Custom', () => record.displaySource)
                .Build(),
              ...transactionColumns
            ];

            return <div style={{marginLeft:30}} className='with-display-inline-block'>
              <Table
                rowKey={NameOf<ReportJournalItemDTO>('displayOrder')}
                className='condensed-table striped-table borderless-table'
                rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
                pagination={false}
                columns={expandedColumns}
                dataSource={record.items}
                summary={pageData => {
                  return <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align='right' className='with-border-top'>
                      <Typography.Text strong>{TableExpressions.Renderers.Currency('displayItemDebitTotalAmount', true)('', record)}</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align='right' className='with-border-top'>
                      <Typography.Text strong>{TableExpressions.Renderers.Currency('displayItemCreditTotalAmount', true)('', record)}</Typography.Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>;
                }}
              />
            </div>;
          },
        }}
      />
    </div>
  );
};

export default ReportJournals;
