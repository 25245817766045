import moment from 'moment';
import { ObjectUtil } from 'src/utils';

interface ReportAssignmentItemDTO {
  transactionId: string;
  journalId: string;
  displayOrder: number;
  displayDate: moment.Moment | null;
  displayType: string;
  displayJournal: string;
  displayReference: string;
  displayName: string;
  displayLineType: string;
  displayDescription: string;
  displayDebit: number;
  displayCredit: number;
  displayTaxable: string;
  displayMapping: string;
}
const ReportAssignmentItemDTO = {
  create: (initValues?: Partial<ReportAssignmentItemDTO>): ReportAssignmentItemDTO => {
    const defaults: ReportAssignmentItemDTO = {
      transactionId: '',
      journalId: '',
      displayOrder: 0,
      displayDate: null,
      displayType: '',
      displayJournal: '',
      displayReference: '',
      displayName: '',
      displayLineType: '',
      displayDescription: '',
      displayDebit: 0,
      displayCredit: 0,
      displayTaxable: '',
      displayMapping: '',
    };
    const fixedInitValues: Partial<ReportAssignmentItemDTO> = initValues != null
      ? {
        displayDate: ObjectUtil.ToMoment(initValues.displayDate),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default ReportAssignmentItemDTO;
