import { ArrayUtil, ObjectUtil } from 'src/utils';

interface ReportTransactionItemAssignmentDTO {
  key: string;
  displayName: string;
  displayDescription: string;
  displayOrder: number;
  displayValue: string;
  required: boolean;
  pickListType: string;
  selectedItemType: string;
  selectedItemName: string;
  pickListItemTypeFilter: string;
  pickListItemTypeFilterRequired: boolean;
}
const ReportTransactionItemAssignmentDTO = {
  create: (initValues?: Partial<ReportTransactionItemAssignmentDTO>): ReportTransactionItemAssignmentDTO => {
    const defaults: ReportTransactionItemAssignmentDTO = {
      key: '',
      displayName: '',
      displayDescription: '',
      displayOrder: 0,
      displayValue: '',
      required: false,
      pickListType: '',
      selectedItemType: '',
      selectedItemName: '',
      pickListItemTypeFilter: '',
      pickListItemTypeFilterRequired: false,
    };
    return { ...defaults, ...initValues };
  },
};

export default ReportTransactionItemAssignmentDTO;
