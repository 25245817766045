import { ArrayUtil } from 'src/utils';
import MappingFilterCriteriaDTO from './MappingFilterCriteriaDTO';

interface MappingItemRuleFilterCriteriaDTO {
  definitionKey: string;
  definitionOrder: number;
  definitionDisplayValue: string;
  filterCriteria: MappingFilterCriteriaDTO[];
}
const MappingItemRuleFilterCriteriaDTO = {
  create: (initValues?: Partial<MappingItemRuleFilterCriteriaDTO>): MappingItemRuleFilterCriteriaDTO => {
    const defaults: MappingItemRuleFilterCriteriaDTO = {
      definitionKey: '',
      definitionOrder: 0,
      definitionDisplayValue: '',
      filterCriteria: [],
    };
    const fixedInitValues: Partial<MappingItemRuleFilterCriteriaDTO> = initValues != null
      ? {
        filterCriteria: ArrayUtil.EasyMap(initValues.filterCriteria, MappingFilterCriteriaDTO.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default MappingItemRuleFilterCriteriaDTO;
