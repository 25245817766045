import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Col, Row, Space, Tabs, TabsProps } from 'antd';
import { useQueryParam } from 'src/hooks';
import ReportWarning from './ReportWarning';
import ReportSummary from './ReportSummary';
import ReportTransactions from './ReportTransactions';
import ReportJournals from './ReportJournals';
import ReportAssignments from './ReportAssignments';
import SimpleRangeSelector from 'src/components/SimpleRangeSelector';
import './ReportPage.less';

const ReportPage: React.FC = () => {
  const [tabQueryParam, setTabQueryParam] = useQueryParam('tab', '1');
  // Business logic: Default the reporting dates to today if none is provided
  const [reportStartDateQueryParam, setReportStartDateQueryParam] = useQueryParam('reportStartDate', moment().format('L')?.replaceAll('/', '-'));
  const [reportEndDateQueryParam, setReportEndDateQueryParam] = useQueryParam('reportEndDate', moment().format('L')?.replaceAll('/', '-'));
  const [selectedDateRange, setSelectedDateRange] = useState<[moment.Moment | null, moment.Moment | null] | null>();
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  useEffect(() => {
    // Query params have changed, which happens from time to time. But we only need to refresh when it ACTUALLY changes
    const startDate = reportStartDateQueryParam != null ? moment.utc(reportStartDateQueryParam, 'MM-DD-YYYY') : null;
    const endDate = reportEndDateQueryParam != null ? moment.utc(reportEndDateQueryParam, 'MM-DD-YYYY') : null;

    if (selectedDateRange == null) {
      setSelectedDateRange([startDate, endDate]);
      return;
    }

    if (startDate == null || endDate == null || selectedDateRange[0] == null || selectedDateRange[1] == null) {
      // If any are null, there is a pretty good chance we need to update either to set or to clear
      setSelectedDateRange([startDate, endDate]);
      return;
    }

    if (!startDate.isSame(selectedDateRange[0], 'day') || !endDate.isSame(selectedDateRange[1], 'day') ) {
      setSelectedDateRange([startDate, endDate]);
    }
  }, [reportStartDateQueryParam, reportEndDateQueryParam]);

  const handleTabOnChange = (key: string) => {
    setTabQueryParam(key);
  };

  const handleDateRangeSelect = (values: [moment.Moment | null, moment.Moment | null] | null) => {
    // Any sanitizing will be done here
    setReportStartDateQueryParam(values?.[0]?.format('L').replaceAll('/', '-') ?? null);
    setReportEndDateQueryParam(values?.[1]?.format('L').replaceAll('/', '-') ?? null);
  };
  const startDate = selectedDateRange != null ? selectedDateRange[0] : null;
  const endDate = selectedDateRange != null ? selectedDateRange[1] : null;
  const refreshTabs = () =>{
    setRefreshTrigger((refreshTrigger) => refreshTrigger + 1);
      return;
    };
  const items: TabsProps['items'] = [{
    key: '1',
    label: 'Warning',
    children: <ReportWarning startDate={startDate} endDate={endDate} refreshTrigger={refreshTrigger}/>
  }, {
    key: '2',
    label: 'Summary',
    children: <ReportSummary startDate={startDate} endDate={endDate} refreshTrigger={refreshTrigger} />
  }, {
    key: '3',
    label: 'Transactions',
    children: <ReportTransactions startDate={startDate} endDate={endDate} refreshTrigger={refreshTrigger} refreshTabs={refreshTabs}/>
  }, {
    key: '4',
    label: 'Journals',
    children: <ReportJournals startDate={startDate} endDate={endDate} refreshTrigger={refreshTrigger} />
  }, {
    key: '5',
    label: 'Assignments',
    children: <ReportAssignments startDate={startDate} endDate={endDate} refreshTrigger={refreshTrigger}/>
  }];

  

  return (
    <div className='report-page'>
      {/* Header */}
      <Row className='with-margin-bottom' justify='space-between'>
        <Col>
          <h1>Activity Reports</h1>
        </Col>
        <Col>
          <Space size={12}>
            <SimpleRangeSelector
              maxDayRange={31}
              value={selectedDateRange}
              onChange={handleDateRangeSelect}
            />
          </Space>
        </Col>
      </Row>

      {/* Content */}
      <Tabs
        activeKey={tabQueryParam ?? undefined}
        onChange={handleTabOnChange}
        items={items}
      />
    </div>
  );
};

export default ReportPage;
