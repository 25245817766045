import { ArrayUtil, ObjectUtil } from 'src/utils';
import MappingPickListItemDTO from './MappingPickListItemDTO';

interface MappingPickListDTO {
  showColumn1: boolean;
  showColumn2: boolean;
  showColumn3: boolean;
  showListTypeColumn: boolean;
  labelColumn1: string;
  labelColumn2: string;
  labelColumn3: string;
  labelTypeColumn: string;
  pickListItems: MappingPickListItemDTO[];
}
const MappingPickListDTO = {
  create: (initValues?: Partial<MappingPickListDTO>): MappingPickListDTO => {
    const defaults: MappingPickListDTO = {
      showColumn1: false,
      showColumn2: false,
      showColumn3: false,
      showListTypeColumn: false,
      labelColumn1: '',
      labelColumn2: '',
      labelColumn3: '',
      labelTypeColumn: '',
      pickListItems: [],
    };
    const fixedInitValues: Partial<MappingPickListDTO> = initValues != null
      ? {
        showColumn1: ObjectUtil.ToBoolean(initValues.showColumn1),
        showColumn2: ObjectUtil.ToBoolean(initValues.showColumn2),
        showColumn3: ObjectUtil.ToBoolean(initValues.showColumn3),
        showListTypeColumn: ObjectUtil.ToBoolean(initValues.showListTypeColumn),
        pickListItems: ArrayUtil.EasyMap(initValues.pickListItems, MappingPickListItemDTO.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default MappingPickListDTO;
