import { Form, Input, Button } from 'antd';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import UserController from 'src/api/UserController';
import AuthCodeInput from 'src/components/AuthCodeInput';
import DelayButton from 'src/components/DelayButton';
import RouteConfig from 'src/config/RouteConfig';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { FormatUtil, NameOf, NotificationUtil } from 'src/utils';

interface ProfileVerifyPhoneRequestFormData {
  confirmationCode: string;
}


const ProfileVerifyPhoneRequestPage: React.FC = (props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const authContext = useContext(AuthenticationContext);
  const [hasRequested, setHasRequested] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = async (values: ProfileVerifyPhoneRequestFormData) => {
    try {
      setLoading(true);
      //dev note not sure if we are still even using this page but we changed how we do the two factor phone number system and we needed a phone number for the api call the phone number I use here might not actually work
      const result = await UserController.addTwoFactorPhoneResponse(authContext.profile?.phoneNumber ?? '', values.confirmationCode);
      if (result.data) {
        NotificationUtil.success({
          key: 'ProfileVerifyPhoneRequestPage',
          message: 'Phone number has been validated successfully'
        });
        navigate(RouteConfig.PROFILE_HOME());
      } else {
        NotificationUtil.error({
          key: 'ProfileVerifyPhoneRequestPage',
          message: 'Code is invalid'
        });
      }
    } catch (error) {
      NotificationUtil.error({
        key: 'ProfileVerifyPhoneRequestPage',
        message: 'Error occurred while sending two factor request',
        error
      });
    }
    setLoading(false);
  };

  const onRequest = async () => {
    // Send request to API
    try {
      setLoading(true);
      await UserController.addTwoFactorPhoneRequest(authContext.profile?.phoneNumber ?? '');
      setHasRequested(true);
      NotificationUtil.success({
        key: 'ProfileVerifyPhoneRequestPage',
        message: 'Notification sent!'
      });
    } catch (error) {
      NotificationUtil.error({
        key: 'ProfileVerifyPhoneRequestPage',
        message: 'Error occurred while sending two factor request',
        error
      });
    }
    setLoading(false);
  };

  return (
    <div className='template-page'>
      <h1>Verifying Your Phone Number</h1>
      <p>We will send a notification to your mobile device. Standard rates may apply.</p>

      <Form
        layout='vertical'
        form={form}
        onFinish={onFinish}
        size='large'
      >
        <Form.Item name={NameOf<ProfileVerifyPhoneRequestFormData>('confirmationCode')}
          rules={[
            { required: true, message: 'Auth Code is required', min: 6 }
          ]}>
          <AuthCodeInput characters={6} disabled={!hasRequested} loading={loading} />
        </Form.Item>
        <Form.Item>
          {hasRequested && <p>A verification code has been sent to {FormatUtil.AsPhoneNumber(authContext.profile?.phoneNumber ?? '')}</p>}
          <DelayButton type={!hasRequested ? 'primary' : 'link'} delay={3} onClick={onRequest} loading={loading}>{hasRequested ? 'Resend Request' : 'Send Request'}</DelayButton>
        </Form.Item>
        {hasRequested &&
          <Form.Item>
            <Button type='primary' htmlType="submit" block loading={loading}>Validate</Button>
          </Form.Item>
        }
      </Form>
      <Link to={RouteConfig.PROFILE_HOME()}><Button size='large' type='primary'>Cancel</Button></Link>
    </div>
  );
};

export default ProfileVerifyPhoneRequestPage;

/**
 * Verify Phone
 *  ProfileVerifyPhoneRequestPage
 *  ProfileVerifyPhoneConfirmationCodePage
 *  ProfileVerifyPhoneResultPage
 */
