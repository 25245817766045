import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Checkbox, Divider, Form, Input } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { CacheContext } from 'src/providers/CacheContext';
import { NameOf, ObjectUtil, NotificationUtil, ErrorUtil } from 'src/utils';
import RouteConfig from 'src/config/RouteConfig';
import LoginService from './LoginService';
import AuthorizationController from 'src/api/AuthorizationController';
import AuthCodeInput from 'src/components/AuthCodeInput';
import DelayButton from 'src/components/DelayButton';
import axios from 'axios';

interface LoginTwoFactorFormData {
  authCode: string;
}

const LoginTwoFactorPage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const cacheContext = useContext(CacheContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // OnLoad
  useEffect(() => {
    // Figure out if we are on the right page (happens on reload)
    ensureConsistentRoute();
  }, []);

  const ensureConsistentRoute = async () => {
    const nextRoute = await LoginService.GetRouteWithSetAccountLocation(authContext, cacheContext, location);
    if (nextRoute !== location.pathname) {
      navigate(nextRoute);
    }
  };

  const resendCode = async () => {
    setLoading(true);
    await AuthorizationController.twoFactorRequest();
    setLoading(false);
  };

  const onFinish = async (values: LoginTwoFactorFormData) => {
    setLoading(true);
    try {
      await authContext.login_two_factor(values.authCode);

      // Close the notification and navigate
      NotificationUtil.close('LoginTwoFactorPage');
      navigate(await LoginService.GetRouteWithSetAccountLocation(authContext, cacheContext, location));
    } catch (error) {
      const errorMessage = ErrorUtil.GetMessageFromError(error);
      form.setFields([{
        name: NameOf<LoginTwoFactorFormData>('authCode'),
        errors: [errorMessage]
      }]);
      NotificationUtil.error({
        key: 'LoginTwoFactorPage',
        message: errorMessage,
        description: axios.isAxiosError(error) && error.response?.status === 401 ? 'Your session has expired, please refresh your browser and try again.' : undefined,
        duration: 0
      });
    }
    setLoading(false);
  };

  return (
    <div className='login-two-factor-page login-flow'>
      <h1>Sign In</h1>
      <p>A verification code has been sent to your email or mobile device. Please enter the code below to continue</p>

      <Form
        layout='vertical'
        form={form}
        onFinish={onFinish}
        size='large'
      >
        <Form.Item name={NameOf<LoginTwoFactorFormData>('authCode')}
          rules={[
            { required: true, message: 'Auth Code is required', min: 6 }
          ]}>
          <AuthCodeInput characters={6} loading={loading} />
        </Form.Item>
        <DelayButton block type='link' size='large' loading={loading} onClick={resendCode} delay={10}>Resend Code</DelayButton>
        <Form.Item>
          <Button block type='primary' htmlType="submit" loading={loading}>Validate</Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginTwoFactorPage;
