import { ObjectUtil } from 'src/utils';

interface MappingItemRuleFilterDefinitionDTO {
  key: string;
  displayValue: string;
  selected: boolean;
  selectedOrder: number;
}
const MappingItemRuleFilterDefinitionDTO = {
  create: (initValues?: Partial<MappingItemRuleFilterDefinitionDTO>): MappingItemRuleFilterDefinitionDTO => {
    const defaults: MappingItemRuleFilterDefinitionDTO = {
      key: '',
      displayValue: '',
      selected: false,
      selectedOrder: 0,
    };
    const fixedInitValues: Partial<MappingItemRuleFilterDefinitionDTO> = initValues != null
      ? {
        selected: ObjectUtil.ToBoolean(initValues.selected),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default MappingItemRuleFilterDefinitionDTO;
