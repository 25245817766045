import { Divider, Row, Col, Button } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import RouteConfig from 'src/config/RouteConfig';

const SimpleNavigation: React.FC = () => {
  return (<>
    <Divider orientation='left'>Use these to navigate around for now</Divider>
      <Row gutter={[8, 8]}>
        <Col>
          <Link to={RouteConfig.LINK.HOME}>
            <Button type='primary'>Home</Button>
          </Link>
        </Col>
        <Col>
          <Link to={RouteConfig.LINK.SELECT_LOCATION}>
            <Button type='primary'>Locations</Button>
          </Link>
        </Col>
        <Col>
          <Link to={RouteConfig.LINK.SETTINGS}>
            <Button type='primary'>Settings</Button>
          </Link>
        </Col>
        <Col>
          <Link to={RouteConfig.LINK.AUDIT}>
            <Button type='primary'>Audit</Button>
          </Link>
        </Col>
        <Col>
          <Link to={RouteConfig.LINK.PROFILE_HOME}>
            <Button type='primary'>Profile</Button>
          </Link>
        </Col>
        <Col>
          <Link to={RouteConfig.LINK.REPORTS}>
            <Button type='primary'>Reports</Button>
          </Link>
        </Col>

        <Col>
          <Link to={RouteConfig.LINK.STYLE_GUIDE}>
            <Button type='primary' danger>Style Guide</Button>
          </Link>
        </Col>
        <Col>
          <Link to={RouteConfig.LINK.LOGIN_EMAIL}>
            <Button type='primary' danger>Begin Login Flow</Button>
          </Link>
        </Col>
        <Col>
          <Link to={RouteConfig.LINK.LOGOUT}>
            <Button type='primary'>Logout</Button>
          </Link>
        </Col>
      </Row>
  </>);
};

export default SimpleNavigation;