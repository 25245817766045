import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Checkbox, Divider, Form, Input } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { CacheContext } from 'src/providers/CacheContext';
import { NameOf, ObjectUtil, NotificationUtil, ErrorUtil } from 'src/utils';
import RouteConfig from 'src/config/RouteConfig';
import LoginService from './LoginService';

interface LoginPasswordFormData {
  email?: string;
  password: string;
}

const LoginPasswordPage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const cacheContext = useContext(CacheContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const inputRef = useRef<any>(null); // This is for auto focusing onload
  const [loading, setLoading] = useState(false);

  // OnLoad
  useEffect(() => {
    // Figure out if we are on the right page (happens on reload)
    ensureConsistentRoute();
    inputRef.current!.focus();
  }, []);

  const ensureConsistentRoute = async () => {
    const nextRoute = await LoginService.GetRouteWithSetAccountLocation(authContext, cacheContext, location);
    if (nextRoute !== location.pathname) {
      navigate(nextRoute);
    }
  };

  const handleFormFinish = async (values: LoginPasswordFormData) => {
    // Trim the password
    values = ObjectUtil.TrimValues(values, 'password');

    setLoading(true);
    try {
      await authContext.login_password(values.password);

      // Dev Note: Slightly different here. There are other notifications that can show up here, so we want to close them all before heading into the app
      NotificationUtil.closeAll();
      navigate(await LoginService.GetRouteWithSetAccountLocation(authContext, cacheContext, location));
    } catch (error) {
      const errorMessage = ErrorUtil.GetMessageFromError(error);
      form.setFields([{
        name: NameOf<LoginPasswordFormData>('password'),
        errors: [errorMessage]
      }]);
      NotificationUtil.error({
        key: 'LoginPasswordPage',
        message: 'Error',
        description: errorMessage,
        duration: 0
      });
      // Put focus back to the input as loading will disable the box, removing focus
      inputRef.current!.focus();
    }
    setLoading(false);
  };

  return (
    <div className='login-password-page login-flow'>
      <h1>Sign In</h1>

      <Form
        size='large'
        layout='vertical'
        requiredMark={false}
        form={form}
        onFinish={handleFormFinish}
        initialValues={{ email: authContext.auth?.emailAddress }}
      >
        <Form.Item
          name={NameOf<LoginPasswordFormData>('email')}
        >
          <Input placeholder='Email address' allowClear disabled />
        </Form.Item>
        <Form.Item
          name={NameOf<LoginPasswordFormData>('password')}
          rules={[
            { required: true, message: 'Password is required' }
          ]}>
          <Input.Password allowClear placeholder='Password' autoComplete='password' ref={inputRef} disabled={loading} />
        </Form.Item>

        <Form.Item style={{ marginTop: 8 }}>
          <Button type='primary' htmlType='submit' block loading={loading}>Continue</Button>
        </Form.Item>
      </Form>

      <p style={{ fontSize: 12 }}>By selecting sign in, you agree to our <Link to={RouteConfig.LINK.LOGIN_TERMS_OF_SERVICE}>Terms of Service</Link> and have acknowledged our <Link to={RouteConfig.LINK.LOGIN_PRIVACY_STATEMENT}>Privacy Policy</Link>.</p>
      <Divider style={{ margin: '16px 0' }} />

      <Link to={RouteConfig.LOGIN_FORGOT_PASSWORD_STEP_1()}>
        <Button type='link' style={{ paddingTop: 'unset' }}>Forgot your Password?</Button>
      </Link>
    </div>
  );
};

export default LoginPasswordPage;
