class StringUtil {
  /**
   * Capitalizes the first character of each word in a given string
   * @param input Input string
   * @example "des moines" -> "Des Moines"
   */
  public ToTitleCase(input: string): string {
    if (input == null) {
      return input;
    }

    return input.split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  }

  /**
   * Trims whitespace from within the string, reducing duplicate spaces which are not rendered in html
   * @param input Input string
   * @example "   Des   Moines     " -> "Des Moines"
   */
  public TrimExtraSpaces(input: string): string {
    // Inspiration: https://stackoverflow.com/questions/7764319/how-to-remove-duplicate-white-spaces-in-a-string/7764370
    if (input == null) {
      return input;
    }
    return input
      .replace(/\s+/g, ' ') // Replaces 1+ spaces with 1 space
      .trim(); // Trims the end space
    // Removed this because trim did a better job for start/end whitespace: replace(/^\s+|\s+$/, '');
  }

  /**
   * Checks if string is null or empty, returning true if it is. Purely for convenience
   */
  // Dev Note: input string defining in the return type is basically magic, see here: https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
  public IsNullOrEmpty(input?: string | null): input is null | undefined {
    if (typeof input !== 'string') {
      // throw new Error('Input is not a string');
      // Dev Note: Ehhh, not sure if I wanna throw an error. Could be null or undefined, which is fine. Objects are not, so...
      return input == null;
    }

    // The type guard above pretty much guarantees that the string is not null or undefined, as those values have a different type, thank you javascript
    // Event typescript correctly determines that input is not optional at this point!
    return input.length < 1 || input.trim().length < 1;
  }

  /**
   * Takes a given key, or similarly constructed string, and turns it into a series of words. Often used for developer display
   *
   * @example
   *     'showFilterModalVisible' => 'Show Filter Modal Visible'
   *     'isTFAEnabled' => 'Is TFAEnabled'
   */
  public KeyToWords = (value: string): string => {
    if (value == null || value.length < 1) {
      return '';
    }

    let output = value
      .split(/(?=[A-Z])/) // Split based on capitalized characters
      .reduce((prev, curr, index, arr) => {
        // We are opting to merge single capital letters into the next word. This makes 'T F A Enabled' => 'TFAEnabled' which is expected
        // Dev Note: There are rare cases where you could have 'ModAValue', which is 'Mod AValue'. Yeah, but that's your fault for poor naming imo
        if (index > 0 && arr[index - 1].length < 2) {
          return prev + curr;
        }

        return prev + ' ' + curr;
      });

      // Ensure first letter is capitalized and return
      return output.charAt(0).toUpperCase() + output.substring(1);
  };
}

export default new StringUtil();
