import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PasswordResetFormData } from 'src/components/modals/passwordResetModal';
import SimpleNavigation from 'src/components/SimpleNavigation';
import RouteConfig from 'src/config/RouteConfig';

const ProfileResetPasswordConfirmPage: React.FC = () => {
  const navigate = useNavigate();

  const onFinish = (values: PasswordResetFormData) => {
    navigate(RouteConfig.LOGIN_ACTIVATE_SUCCESS());
  };

  return (
    <div className='template-page'>
      <h1>Password Reset</h1>
      <p>Password has been reset!</p>
      <SimpleNavigation />
    </div>
  );
};

export default ProfileResetPasswordConfirmPage;
