import { ArrayUtil } from 'src/utils';
import SelectionOptionDTO from './SelectionOptionDTO';

const SettingValueDisplayTypeValues = ['Label', 'TextBox', 'OnOff', 'DatePicker', 'ComboBox'] as const;
const SettingValueFormatTypeValues = ['String', 'Number', 'Boolean', 'Date', 'DateTime'] as const;
export type SettingValueDisplayTypeType = (typeof SettingValueDisplayTypeValues)[number];
export type SettingValueFormatTypeType = (typeof SettingValueFormatTypeValues)[number];

interface AdvancedSettingsDTO {
  key: string;
  displayName: string;
  displayDescription: string;
  displayInfo: string;
  displayWarning: string;
  displayOrder: number;
  settingValue: string;
  settingValueDisplayType: SettingValueDisplayTypeType,
  settingValueFormatType: SettingValueFormatTypeType,
  settingValueOptionCollection: SelectionOptionDTO[];
  // Update value indicators
  settingValue_IsUpdate: boolean;
}
const AdvancedSettingsDTO = {
  create: (initValues?: Partial<AdvancedSettingsDTO>): AdvancedSettingsDTO => {
    const defaults: AdvancedSettingsDTO = {
      key: '',
      displayName: '',
      displayDescription: '',
      displayInfo: '',
      displayWarning: '',
      displayOrder: 0,
      settingValue: '',
      settingValueDisplayType: 'Label',
      settingValueFormatType: 'String',
      settingValueOptionCollection: [],
      settingValue_IsUpdate: false,
    };
    const fixedInitValues: Partial<AdvancedSettingsDTO> = initValues != null
      ? {
        settingValueDisplayType: ArrayUtil.PickFromList(initValues.settingValueDisplayType, SettingValueDisplayTypeValues, 'Label'),
        settingValueFormatType: ArrayUtil.PickFromList(initValues.settingValueFormatType, SettingValueFormatTypeValues, 'String'),
        settingValueOptionCollection: ArrayUtil.EasyMap(initValues.settingValueOptionCollection, SelectionOptionDTO.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default AdvancedSettingsDTO;
