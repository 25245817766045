import axios, { AxiosError } from 'axios';
import ObjectUtil from './core/ObjectUtil';

/**
 * Utility used to format errors. Really only one thing here, so a function would probably have been a better idea. Oh well
 */
class ErrorUtil {
  private standardErrorMessage: string = 'An unknown error has occurred.';

  public GetMessageFromError(error: unknown) {
    // Idk what I am doing with this. There is error code all over the app at this point
    if (axios.isAxiosError(error)) {
      // Error now becomes an axios error through typescript magic
      // Also, the destructuring here looks nasty, but it works! But it's nasty lol
      const { response, response: { data, status } = {} } = (error) as AxiosError<string | Record<string, string>>;

      if (response == null || ObjectUtil.IsNullOrEmpty(data))
        return this.standardErrorMessage;
      if (status == 401)
        return 'Your session has expired, please refresh your browser and try again.';

      // Check for string
      if (typeof data === 'string')
        return data;

      // Otherwise, the error is likely an object
      return data.message || data.description || error.message;
    }

    return (error as Error)?.message ?? String(error);
  }
}

export default new ErrorUtil();
