import React, { useState } from 'react';
import { Modal } from 'antd';
import UserController from 'src/api/UserController';
import { NotificationUtil } from 'src/utils';
import PasswordResetForm, { PasswordResetFormData } from './PasswordResetForm';

export interface PasswordResetModalFormData {
  authCode: string;
}

export interface PasswordResetModalProps {
  open: boolean;
  onCancel: () => void;
}

const PasswordResetModal: React.FC<PasswordResetModalProps> = (props) => {
  const [loading, setLoading] = useState(false);

  const handleFinish = async (values: PasswordResetFormData) => {
    setLoading(true);
    try {
      const results = await UserController.passwordResetResponse(values.currentPassword, values.newPassword);
      NotificationUtil.success({
        key: 'PasswordResetModal',
        message: 'Password has been reset'
      });
      props.onCancel();
    } catch (error) {
      NotificationUtil.error({
        key: 'PasswordResetModal',
        message: 'Error while resetting password. Please try again later',
        error
      });
    }
    setLoading(false);
  };

  return <Modal
    className='password-reset-modal'
    centered
    destroyOnClose
    width={450}
    open={props.open}
    maskClosable={false}
    footer={null}
    onCancel={props.onCancel}
  >
    <h2>Password Reset</h2>
    <PasswordResetForm showCurrentPassword confirmButtonText='Reset Password' loading={loading} onFinish={handleFinish} />
  </Modal>;
};

export default PasswordResetModal;
