import { AxiosResponse } from 'axios';
import TileModel from 'src/models/frontend/TileModel';
import { AxiosUtil } from 'src/utils';

class AccountController {
  private prefix: string = "accounts";

  // API_43a
  async getActivityTiles(accountId: string, locationId: string, descending: boolean): Promise<AxiosResponse<TileModel[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/activity/tiles`;
    let params: Record<string, string> = {
      "sortdescending":descending?"true":"false"
    }

    return AxiosUtil.get<TileModel[]>(route, items => items.map(TileModel.create), { params });
  }

  // API_43b
  async getActivityTile(accountId: string, locationId: string, tileKey: string): Promise<AxiosResponse<TileModel[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/activity/tiles`;
    let params: Record<string, string> = {
      tilekey: tileKey
    }

    return AxiosUtil.get<TileModel[]>(route, items => items.map(TileModel.create), { params });
  }

  // API_43c
  async getPostedActivityTiles(accountId: string, locationId: string, startDate: moment.Moment, endDate: moment.Moment): Promise<AxiosResponse<TileModel[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/activity/tiles`;
    let params: Record<string, string | boolean> = {
      showhistory: true,
      startdate: startDate.format(),
      enddate: endDate.format(),
    }

    return AxiosUtil.get<TileModel[]>(route, items => items.map(TileModel.create), { params });
  }

  // API_44
  async approveActivityTile(accountId: string, locationId: string, tileKey: string, tileVersion: string): Promise<AxiosResponse<TileModel>>{
    let route = `${this.prefix}/${accountId}/locations/${locationId}/activity/tiles/${tileKey}/approve/${tileVersion}`;
    return AxiosUtil.post<TileModel>(route);
  }
}


export default new AccountController();
