import { ArrayUtil, ObjectUtil } from 'src/utils';
import AuditTrailItemViewRowValueDTO from './AuditTrailItemViewRowValueDTO';

interface AuditTrailItemViewRowDTO {
  /** Show Row at bottom if total row */
  isTotalRow: boolean;
  order: number;
  rowValues: AuditTrailItemViewRowValueDTO[];
}
const AuditTrailItemViewRowDTO = {
  create: (initValues?: Partial<AuditTrailItemViewRowDTO>): AuditTrailItemViewRowDTO => {
    const defaults: AuditTrailItemViewRowDTO = {
      isTotalRow: false,
      order: 0,
      rowValues: [],
    };
    const fixedInitValues: Partial<AuditTrailItemViewRowDTO> = initValues != null
      ? {
        isTotalRow: ObjectUtil.ToBoolean(initValues.isTotalRow),
        rowValues: ArrayUtil.EasyMap(initValues.rowValues, AuditTrailItemViewRowValueDTO.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default AuditTrailItemViewRowDTO;
