import { ObjectUtil } from 'src/utils';
import ReportTransactionItemAssignmentDTO from './ReportTransactionItemAssignmentDTO';

interface MappingItemAssignmentDTO {
  key: string;
  complete: boolean;
  displayName: string;
  displayDescription: string;
  displayOrder: number;
  displayValue: string;
  required: boolean;
  pickListType: string;
  selectedItemType: string;
  selectedItemName: string;
  pickListItemTypeFilter: string;
  pickListItemTypeFilterRequired: boolean;
}
const MappingItemAssignmentDTO = {
  create: (initValues?: Partial<MappingItemAssignmentDTO>): MappingItemAssignmentDTO => {
    const defaults: MappingItemAssignmentDTO = {
      key: '',
      complete: false,
      displayName: '',
      displayDescription: '',
      displayOrder: 0,
      displayValue: '',
      required: false,
      pickListType: '',
      selectedItemType: '',
      selectedItemName: '',
      pickListItemTypeFilter: '',
      pickListItemTypeFilterRequired: false,
    };
    const fixedInitValues: Partial<MappingItemAssignmentDTO> = initValues != null
      ? {
        complete: ObjectUtil.ToBoolean(initValues.complete),
        required: ObjectUtil.ToBoolean(initValues.required),
        pickListItemTypeFilterRequired: ObjectUtil.ToBoolean(initValues.pickListItemTypeFilterRequired),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
  /**
   * ~Specifically created because of the inconsistency between the two models, though the share very similar properties.~
   *
   * ~The MappingItemAssignment is ever so slightly different: the displayName needs to map to the displayValue and the displayName MUST BE BLANK. The rest is passed through the create method~
   *
   * Yeah, so I wasted hours of my life chasing a ghost. The displayName and displayValue are indeed messy. I assumed the Picklist needed the displayName, where the assignment used the displayValue.
   *
   * This is not the case
   *
   * I was accidentally setting the name, where I should have set the value. Ghosts man... so many hours wasted. And that is why this function exists and will continue to do so so I don't feel like I wasted my evening on nothing
   */
  fromReportTransactionItemAssignment: (initValues?: Partial<ReportTransactionItemAssignmentDTO>): MappingItemAssignmentDTO => {
    return MappingItemAssignmentDTO.create(initValues == null ? undefined : {...initValues, displayValue: initValues.displayValue, displayName: ''});
  },
};

export default MappingItemAssignmentDTO;
