import React, { useEffect, useState } from 'react';
import { useSearchParams, useLocation, useNavigate, ParamKeyValuePair } from 'react-router-dom';

/**
 * Because the provided query params helpers are stupid and obtusely complicated/verbose when they really don't need to be. Include async functionality because the queryParam variable is horribly unreliable
 * @param param The key used to defined the query parameter. If one already exists, the value will be populated automatically
 * @param defaultValue The **default value** for the query parameter. If a value is already in the URL, it will be used instead
 */
export function useQueryParam(param: string, defaultValue?: string): [string | null, (value?: string | null) => void, () => Promise<string | null>] {
  const betterSearchParams = new URLSearchParams(window.location.search);
  const queryParam = betterSearchParams.get(param);

  // Use the current query param, baring that use the defaultValue, or just set to null
  const [internal, setInternal] = useState<string | null>(queryParam ?? defaultValue ?? null);

  // Dev Note: We cannot rely on the searchParams as they can be wildly out of sync with reality. Best to get updates and do it ourselves
  const [searchParams, setSearchParams] = useSearchParams();

  // This has gone through many revisions and the current iteration will set the query param if it is different than the default value
  useEffect(() => {
    if (defaultValue != null) {
      // Default value needs to be stored in the URL, so put it up there
      // Using animationFrame as it waits just a frame or two, fast enough to be batched by react, hopefully, so we don't have lots of navigations and rerenders
      requestAnimationFrame(() => {
        // Check if the query param is already set, hence the default nature of the param
        const currentSearchParams = new URLSearchParams(window.location.search);
        if (!currentSearchParams.has(param)) {
          setQueryParam(defaultValue);
        }
      });
    }
  }, []);

  // Triggered when the search params change, hopefully
  useEffect(() => {
    const betterSearchParams = new URLSearchParams(window.location.search);
    const queryParam = betterSearchParams.get(param);

    // Only run the update if the param has changed. IE, from another component using the same key
    if (internal !== queryParam) {
      setQueryParam(queryParam);
    }
  }, [searchParams]);

  const setQueryParam = (value?: string | null) => {
    // Dev Note: We cannot use the searchParams provided by useSearchParams as it is unreliable. It tends to nuke the search params and that is not what we want
    const currentSearchParams = new URLSearchParams(window.location.search);
    if (value == null) {
      // Unset the value
      currentSearchParams.delete(param);
    } else {
      currentSearchParams.set(param, value);
    }

    setInternal(value ?? null);
    setSearchParams(currentSearchParams);
  };

  // Dev Note: Idk if I should use the async provider I have or just pull from the query params... hmmm
  const getQueryParamAsync = () => {
    return new Promise<string | null>((resolve) => {
      const betterSearchParams = new URLSearchParams(window.location.search);
      const queryParam = betterSearchParams.get(param);
      resolve(queryParam);
    });
  };

  return [internal, setQueryParam, getQueryParamAsync];
}
