import axios, { AxiosResponse } from 'axios';
import IntegrationDTO from 'src/models/generated/IntegrationDTO';
import { AxiosUtil } from 'src/utils';

class IntegrationController {
  private prefix: string = "accounts"; // Dev Note: This is intentional. Endpoints DO start with this rather than the controller name

  // API_20a
  getIntegrations(accountId: string, locationId: string): Promise<AxiosResponse<IntegrationDTO[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/integrations`;
    return AxiosUtil.get<IntegrationDTO[]>(route, items => items.map(IntegrationDTO.create));
  }

  // API_20b
  getIntegration(accountId: string, locationId: string, integrationKey: string): Promise<AxiosResponse<IntegrationDTO[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/integrations`;
    let params: Record<string, string> = {
      integrationkey: integrationKey
    }
    return AxiosUtil.get<IntegrationDTO[]>(route, items => items.map(IntegrationDTO.create), { params });
  }

  // API_21
  /** Returns a redirect url */
  getRedirectUrl(accountId: string, locationId: string, integrationKey: string): Promise<AxiosResponse<string>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/integrations/${integrationKey}/redirecturl`;
    return AxiosUtil.get<string>(route);
  }

  requestConnect(accountId: string, locationId: string, integrationKey: string, data: any): Promise<AxiosResponse<IntegrationDTO>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/integrations/${integrationKey}/connect`;
    return AxiosUtil.post<IntegrationDTO, any>(route, data);
  }

  // API_22
  requestDisconnect(accountId: string, locationId: string, integrationKey: string): Promise<AxiosResponse<IntegrationDTO>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/integrations/${integrationKey}/disconnect`;
    return AxiosUtil.post<IntegrationDTO, void>(route);
  }
}

export default new IntegrationController();
