import { v4 as uuidv4 } from 'uuid';
import { StringUtil } from 'src/utils';
import { ArrayUtil, ObjectUtil } from 'src/utils';
import TileTransactionSummaryItemDTO from '../generated/TileTransactionSummaryItemDTO';

const DisplayStatusIdValues = ['Warning', 'Unapproved', 'Approved', 'Posted'] as const;
export type DisplayStatusIdType = (typeof DisplayStatusIdValues)[number];

interface TileTransactionSummaryItemModel {
  /** Auto Generated field */
  key: string;

  displayTransactionType: string;
  displayCount: number;
  displayOrder: number;
  displayUnapproved: number;
  displayApproved: number;
  displayPosted: number;
  displayTotal: number;
}
const TileTransactionSummaryItemModel = {
  create: (initValues?: Partial<TileTransactionSummaryItemModel>): TileTransactionSummaryItemModel => {
    const defaults: TileTransactionSummaryItemModel = {
      key: '',
      displayTransactionType: '',
      displayCount: 0,
      displayOrder: 0,
      displayUnapproved: 0,
      displayApproved: 0,
      displayPosted: 0,
      displayTotal: 0,
    };
    const fixedInitValues: Partial<TileTransactionSummaryItemModel> = initValues != null
      ? {
        key: StringUtil.IsNullOrEmpty(initValues.key) ? uuidv4() : initValues.key,
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
  toDTO: (model: TileTransactionSummaryItemModel): TileTransactionSummaryItemDTO => ({
    displayTransactionType: model.displayTransactionType,
    displayCount: model.displayCount,
    displayOrder: model.displayOrder,
    displayUnapproved: model.displayUnapproved,
    displayApproved: model.displayApproved,
    displayPosted: model.displayPosted,
    displayTotal: model.displayTotal,
  }),
  fromDTO: (dto: TileTransactionSummaryItemDTO): TileTransactionSummaryItemModel => ({
    key: '',
    displayTransactionType: dto.displayTransactionType,
    displayCount: dto.displayCount,
    displayOrder: dto.displayOrder,
    displayUnapproved: dto.displayUnapproved,
    displayApproved: dto.displayApproved,
    displayPosted: dto.displayPosted,
    displayTotal: dto.displayTotal,
  })
};

export default TileTransactionSummaryItemModel;
