import moment from 'moment';
import { ArrayUtil, ObjectUtil } from 'src/utils';
import ReportTransactionItemDTO from './ReportTransactionItemDTO';
import { KeyValue } from '../frontend/common/KeyValueAndSimilar';

interface ReportTransactionDTO {
  transactionId: string;
  transactionVersion: string;
  transactionTypeKey: string;
  journalId: string;
  displayDate: moment.Moment | null;
  displayType: string;
  displayJournal: string;
  displayReference: string;
  displayName: string;
  displayTotal: number;
  displayStatus: string;
  actualStatus: string;
  displayComment: string;
  displayCommentTimeStamps: Record<string, moment.Moment>|null;
  displayItemTotalAmount: number;
  displayItemTotalAssignment: string;
  items: ReportTransactionItemDTO[];
}
const ReportTransactionDTO = {
  create: (initValues?: Partial<ReportTransactionDTO>): ReportTransactionDTO => {
    const defaults: ReportTransactionDTO = {
      transactionId: '',
      transactionVersion: '',
      transactionTypeKey: '',
      journalId: '',
      displayDate: null,
      displayType: '',
      displayJournal: '',
      displayReference: '',
      displayName: '',
      displayTotal: 0,
      displayStatus: '',
      actualStatus: '',
      displayComment: '',
      displayCommentTimeStamps: null,
      displayItemTotalAmount: 0,
      displayItemTotalAssignment: '',
      items: [],
    };
    const fixedInitValues: Partial<ReportTransactionDTO> = initValues != null
      ? {
        displayDate: ObjectUtil.ToMoment(initValues.displayDate),
        displayCommentTimeStamps: ObjectUtil.ToMomentDictionary(initValues.displayCommentTimeStamps),
        items: ArrayUtil.EasyMap(initValues.items, ReportTransactionItemDTO.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default ReportTransactionDTO;
