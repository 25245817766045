import React, { useContext } from 'react';
import { Layout } from 'antd';
import { AuthenticationContext } from '../providers/AuthenticationContext';
import UserSideNav from './UserSideNav';

const { Header, Content, Sider } = Layout;

const UserLayout: React.FC<React.PropsWithChildren> = (props) => {
  const authContext = useContext(AuthenticationContext);

  return (
    <Layout className='user-layout'>
      <Sider
        className='cider'
        defaultCollapsed
      >
        <UserSideNav menuCollapsed={false} />
      </Sider>

      <Layout className='content-layout'>
        <Header className="top-header">
          <span className='intro-text'>Account</span>
          <span className='spacer'>|</span>
          <span className='content'>{authContext.account?.displayName}</span>
          <span className='spacer'>|</span>
          <span className='content'>{authContext.account?.accountCode}</span>
        </Header>
        <Header className="bottom-header">
          <span className='intro-text'>Location</span>
          <span className='spacer'>|</span>
          <span className='content'>{authContext.location?.displayName}</span>
          <span className='spacer'>|</span>
          <span className='content'>{authContext.location?.partnerAccountNumber}</span>
        </Header>

        <Content className='content-container'>
            {props.children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default UserLayout;
