import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Skeleton, Tabs, TabsProps } from 'antd';
import { NotificationUtil, StringUtil, TableExpressions } from 'src/utils';
import { usePrompt } from 'src/hooks';
import MappingController from 'src/api/MappingController';
import MappingItemRuleModel from 'src/models/frontend/MappingItemRuleModel';
import MappingItemAssignmentDTO from 'src/models/generated/MappingItemAssignmentDTO';
import MappingItemDTO from 'src/models/generated/MappingItemDTO';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import MappingItemCustomTab from './MappingItemCustomTab';
import MappingItemDefaultTabNew from './MappingItemDefaultTabNew';
import DraggableTable from './DraggableTable';
import MappingItemCustomTabNew from './MappingItemCustomTabNew';

export interface MappingModalItemNewProps {
  // Id for the current mapping item. Should be specific for a given modal
  mappingKey: string;
  mappingItem: MappingItemDTO;
  hasUnsavedChanges: boolean;

  onMappingItemChange?: (value: MappingItemDTO) => void;

  /** Updates depending on unsaved changes. Mostly from custom mapping */
  onUnsavedChanges?: (value: boolean) => void;
}


const MappingModalItemNew: React.FC<MappingModalItemNewProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  // Item Assignments
  const [itemAssignments, setItemAssignments] = useState<MappingItemAssignmentDTO[]>([]);
  const [loadingItemAssignments, setLoadingItemAssignments] = useState(false);

  // Misc
  const [submittingMappingItem, setSubmittingMappingItem] = useState(false);

  useEffect(() => {
    if (StringUtil.IsNullOrEmpty(props.mappingKey)) {
      return;
    }
    loadAssignments();
  }, [props.mappingKey]);

  const loadAssignments = async () => {
    setLoadingItemAssignments(true);
    try {
      const results = await MappingController.getMappingItemAssignments(authContext.account!.id, authContext.location!.id, props.mappingKey, props.mappingItem.key);

      // Sort data
      const sortedData = [...results.data].sort((a, b) => (a.displayOrder - b.displayOrder));

      setItemAssignments(sortedData);
    } catch (error) {
      NotificationUtil.error({
        key: 'MappingModalItemNew',
        message: 'Error while loading Assignment data',
        error
      });
    }
    setLoadingItemAssignments(false);
  };

  const handleMappingItemChange = (record: MappingItemDTO) => {
    props.onMappingItemChange?.(record);
  };

  const handleUnsavedChanges = (value: boolean) => {
    props.onUnsavedChanges?.(value);
  };

  // Usually not hit but it's good to have a fallback loader
  if (StringUtil.IsNullOrEmpty(props.mappingKey) || props.mappingItem == null) {
    return <Skeleton active />;
  }

  const items: TabsProps['items'] = [];
  if (props.mappingItem.showDefaultMapping) {
    items.push({
      key: '1',
      label: 'Default Mapping',
      children: <MappingItemDefaultTabNew
        mappingKey={props.mappingKey}
        mappingItem={props.mappingItem}
        hasUnsavedChanges={props.hasUnsavedChanges}
        onChange={handleMappingItemChange} />
    });
  }

  if (props.mappingItem.showCustomMapping && props.mappingItem.enableMappingRules) {
    items.push({
      key: '2',
      label: 'Custom Mapping',
      children: <MappingItemCustomTabNew
        mappingKey={props.mappingKey}
        mappingItem={props.mappingItem}
        hasUnsavedChanges={props.hasUnsavedChanges}
        onChange={handleMappingItemChange}
        onUnsavedChanges={handleUnsavedChanges} />
    });
  }

  return <div className='mapping-modal-item'>
    <h2>{props.mappingItem.displayName}</h2>
    <p className='without-margin-bottom'>{props.mappingItem.displayDescription}</p>
    <Row>
      <Col flex='1 0 300px'>
        <Tabs
          defaultActiveKey="1"
          className='mapping-item-center-tabs'
          items={items}
        />
      </Col>
    </Row>
  </div>;
};

export default MappingModalItemNew;
