interface AccountDTO {
  id: string;
  displayName?: string;
  accountCode: string;
  partnerKey: string;
}
const AccountDTO = {
  create: (initValues?: Partial<AccountDTO>): AccountDTO => {
    const defaults: AccountDTO = {
      id: '',
      displayName: '',
      accountCode: '',
      partnerKey: '',
    };
    return { ...defaults, ...initValues };
  },
};

export default AccountDTO;
