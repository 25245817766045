import axios, { AxiosResponse } from 'axios';
import MappingDTO from 'src/models/generated/MappingDTO';
import MappingItemAssignmentDTO from 'src/models/generated/MappingItemAssignmentDTO';
import MappingItemDTO from 'src/models/generated/MappingItemDTO';
import MappingItemRuleDTO from 'src/models/generated/MappingItemRuleDTO';
import MappingItemRuleFilterCriteriaDTO from 'src/models/generated/MappingItemRuleFilterCriteriaDTO';
import MappingItemRuleFilterDefinitionDTO from 'src/models/generated/MappingItemRuleFilterDefinitionDTO';
import MappingPickListDTO from 'src/models/generated/MappingPickListDTO';
import { AxiosUtil } from 'src/utils';

class MappingController {
  private prefix: string = "accounts"; // Dev Note: This is intentional. Endpoints DO start with this rather than the controller name

  // API_23
  // Dev Note: Leaving this as a combined endpoint since the key or type can still return a list of items
  getMappings(accountId: string, locationId: string, optional?: { mappingKey?: string, mappingType?: string }): Promise<AxiosResponse<MappingDTO[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/mappings`;
    let params: Record<string, string | undefined> | undefined = optional
    ? {
      mappingkey: optional.mappingKey,
      mappingtype: optional.mappingType
    }
    : undefined
    return AxiosUtil.get<MappingDTO[]>(route, items => items.map(MappingDTO.create), { params });
  }

  // API_24
  updateMapping(accountId: string, locationId: string, mappingKey: string, data: MappingDTO): Promise<AxiosResponse<void>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/mappings/${mappingKey}/mapping`;
    return AxiosUtil.post<void, MappingDTO>(route, data);
  }

  // API_25
  getMappingItems(accountId: string, locationId: string, mappingKey: string): Promise<AxiosResponse<MappingItemDTO[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/mappings/${mappingKey}/mappingitems`;
    return AxiosUtil.get<MappingItemDTO[]>(route, items => items.map(MappingItemDTO.create));
  }

  // API_25a
  getMappingItem(accountId: string, locationId: string, mappingKey: string, mappingItemKey: string): Promise<AxiosResponse<MappingItemDTO>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/mappings/${mappingKey}/mappingitems`;
    let params: Record<string, string> = {
      mappingItemKey: mappingItemKey
    }

    // This returns an array, but should only return one, so we have some custom code here
    return AxiosUtil.get<MappingItemDTO>(route, (items: any) => MappingItemDTO.create(items[0]) , { params });
  }

  // API_26
  saveMappingItem(accountId: string, locationId: string, mappingKey: string, data: MappingItemDTO): Promise<AxiosResponse<void>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/mappings/${mappingKey}/mappingitem`;
    return AxiosUtil.post<void, MappingItemDTO>(route, data);
  }

  // API_27
  getMappingItemAssignments(accountId: string, locationId: string, mappingKey: string, mappingItemKey: string): Promise<AxiosResponse<MappingItemAssignmentDTO[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/mappings/${mappingKey}/mappingitems/${mappingItemKey}/assignments`;
    return AxiosUtil.get<MappingItemAssignmentDTO[]>(route, items => items.map(MappingItemAssignmentDTO.create));
  }

  // API_28
  saveMappingItemAssignments(accountId: string, locationId: string, mappingKey: string, mappingItemKey: string, data: MappingItemAssignmentDTO[]): Promise<AxiosResponse<void>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/mappings/${mappingKey}/mappingitems/${mappingItemKey}/assignments`;
    return AxiosUtil.post<void, MappingItemAssignmentDTO[]>(route, data);
  }

  // API_29
  getMappingItemRuleDefinitions(accountId: string, locationId: string, mappingKey: string, mappingItemKey: string): Promise<AxiosResponse<MappingItemRuleFilterDefinitionDTO[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/mappings/${mappingKey}/mappingitems/${mappingItemKey}/rulefilterdefinitions`;
    return AxiosUtil.get<MappingItemRuleFilterDefinitionDTO[]>(route, items => items.map(MappingItemRuleFilterDefinitionDTO.create));
  }

  // API_30
  saveMappingItemRuleDefinitions(accountId: string, locationId: string, mappingKey: string, mappingItemKey: string, data: MappingItemRuleFilterDefinitionDTO[]): Promise<AxiosResponse<void>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/mappings/${mappingKey}/mappingitems/${mappingItemKey}/rulefilterdefinitions`;
    return AxiosUtil.post<void, MappingItemRuleFilterDefinitionDTO[]>(route, data);
  }

  // API_31
  getMappingItemRuleFilterCriteria(accountId: string, locationId: string, mappingKey: string, mappingItemKey: string): Promise<AxiosResponse<MappingItemRuleFilterCriteriaDTO[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/mappings/${mappingKey}/mappingitems/${mappingItemKey}/rulefiltercriteria`;
    return AxiosUtil.get<MappingItemRuleFilterCriteriaDTO[]>(route, items => items.map(MappingItemRuleFilterCriteriaDTO.create));
  }

  // API_32
  getMappingItemRules(accountId: string, locationId: string, mappingKey: string, mappingItemKey: string): Promise<AxiosResponse<MappingItemRuleDTO[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/mappings/${mappingKey}/mappingitems/${mappingItemKey}/rules`;
    return AxiosUtil.get<MappingItemRuleDTO[]>(route, items => items.map(MappingItemRuleDTO.create));
  }

  // API_33
  saveMappingItemRules(accountId: string, locationId: string, mappingKey: string, mappingItemKey: string, data: MappingItemRuleDTO[]): Promise<AxiosResponse<void>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/mappings/${mappingKey}/mappingitems/${mappingItemKey}/rules`;
    return AxiosUtil.post<void, MappingItemRuleDTO[]>(route, data);
  }

  autoPopulateMappingRules(accountId: string, locationId: string, mappingKey: string, mappingItemKey: string): Promise<AxiosResponse<void>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/mappings/${mappingKey}/mappingitems/${mappingItemKey}/rules/populate`;
    return AxiosUtil.post<void, void>(route);
  }

  clearIncompleteRules(accountId: string, locationId: string, mappingKey: string, mappingItemKey: string): Promise<AxiosResponse<void>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/mappings/${mappingKey}/mappingitems/${mappingItemKey}/rules/clearincomplete`;
    return AxiosUtil.post(route);
  }

  /** Dev Note: Might move this to configuration controller, maybe note */
  // API_36
  getPickList(accountId: string, locationId: string, pickListType: string, refresh?: boolean): Promise<AxiosResponse<MappingPickListDTO>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/mappings/picklist/${pickListType}`;

    let params: Record<string, string | undefined> = {
      refresh: refresh ? 'true' : undefined
    }
    return AxiosUtil.get<MappingPickListDTO>(route, MappingPickListDTO.create, { params });
  }
}

export default new MappingController();
