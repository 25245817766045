import React, { useCallback, useContext, useEffect } from 'react';
import { History, Transition } from 'history';
import { UNSAFE_NavigationContext as NavigationContext } from 'react-router-dom';

// Copied and enhanced from: https://stackoverflow.com/questions/71572678/react-router-v-6-useprompt-typescript/71587163#71587163
export function useBlocker(blocker: (tx2: Transition) => void, when = true) {
  const navigator = useContext(NavigationContext).navigator as History;

  useEffect(() => {
    if (!when) {
      return;
    }

    console.log('[useBlocker] useEffect');

    const blockNav = navigator.block((tx) => {
      console.log('[useBlocker] running UnBlock', { tx });
      const autoUnblockingTx = {
        ...tx,
        retry() {
          blockNav();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return blockNav;
  }, [navigator, blocker, when]);
}

export function usePromptOld(message: string, when = true, userResponse: (value: boolean) => void) {
  const blocker = useCallback((tx: Transition) => {
    console.log('[usePrompt] running Blocker');
    const response = window.confirm(message);
    if (response) {
      tx.retry();
    }
    userResponse?.(response);
  }, [message, userResponse]);

  useBlocker(blocker, when);
}

// This one is a version to smash everything into one hook. I cannot wrap my head around it, maybe I am getting dumber, and so this exists now
export function usePrompt(message: string, when = true, userResponse: (value: boolean) => void) {
  const blocker = useCallback((tx: Transition) => {
    console.log('[usePrompt] running Blocker');
    const response = window.confirm(message);
    if (response) {
      tx.retry();
    }
    userResponse?.(response);
  }, [message, userResponse]);

  // useBlocker(blocker, when);
  const navigator = useContext(NavigationContext).navigator as History;
  // useEffect(() => {
  //   console.log('[usePrompt] Start useEffect');
  //   if (!when) {
  //     return;
  //   }

  //   return () => {
  //     console.log('[usePrompt] Attempting unload');
  //     const unblockFunc = navigator.block((tx) => {
  //       console.log('[usePrompt] block/retry???', `\n${navigator.location.pathname}${navigator.location.search} \n${tx.location.pathname}${tx.location.search}`);

  //       // This stops blocking because why not
  //       unblockFunc();
  //       tx.retry();
  //     });
  //   };
  // }, [navigator, blocker, when]);

  useEffect(() => {
    console.log('[usePrompt] start useeffect', {when: when});
    if (!when) {
      return;
    }

    // Concept: on unload, navigation will be blocked
    // This is run onUnload. The 'unblockFunc' will unblock the UI when called, allowing execution to continue
    const unblockFunc = navigator.block((tx) => {

      console.log('[usePrompt] running blocker');
      const autoUnblockingTx = {
        ...tx,
        retry() {
          // Unblock the UI and retry the navigation
          unblockFunc();
          tx.retry();
        },
      };

      blocker(autoUnblockingTx);
    });

    return unblockFunc;
  }, [navigator, blocker, when]);
}
