import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { NameOf, ObjectUtil } from 'src/utils';
import DelayButton from 'src/components/DelayButton';
import AuthCodeInput from 'src/components/AuthCodeInput';
import './PasswordResetForm.less';
import { validate } from 'uuid';

interface PasswordTestType {
  regex: RegExp;
  message: string;
  showOn?: 'match' | 'failure';
  errorOn?: 'match' | 'failure';

  // Indicates that the test should not be matched to succeed
  reverseTest?: boolean;
  // Indicates that the test message should only show when the test fails
  showOnlyWhenError?: boolean;
}

export interface PasswordResetFormData {
  newPassword: string;
  /** You should not use this properly, it's for the internal form object */
  confirmPassword: string;
  confirmationCode: string;
  currentPassword: string;
}

export interface PasswordResetFormProps {
  confirmButtonText: React.ReactNode;
  initialValues?: Partial<PasswordResetFormData>;
  showConfirmationCode?: boolean;
  showCurrentPassword?: boolean;
  loading?: boolean;
  onFinish?: (values: PasswordResetFormData) => void;
  /** Called when the 'Resend Code' button is clicked */
  onResendCode?: () => void;
}

const PasswordResetForm: React.FC<PasswordResetFormProps> = (props) => {
  const [form] = Form.useForm<PasswordResetFormData>();
  // This forces values from the form to update in realtime, so we can do something with that value immediately
  const passwordValue = Form.useWatch(NameOf<PasswordResetFormData>('newPassword'), form);

  const handleFinish = (values: PasswordResetFormData) => {
    // Trim the values since it makes sense a password with spaces is just wrong
    values = ObjectUtil.TrimValues(values);
    props.onFinish && props.onFinish(values);
  };

  const handleResendCode = () => {
    props.onResendCode && props.onResendCode();
  };

  // Trims a given form item value
  const trimInput = (name: keyof PasswordResetFormData) => {
    return (e: React.FocusEvent<HTMLInputElement, Element>) => {
      form.setFieldsValue({ [name]: (e.target.value || '').trim() });
    };
  };

  const tests: PasswordTestType[] = [
    { regex: /(?=.*[a-z])/, message: '1 lowercase letter' },
    { regex: /(?=.*[A-Z])/, message: '1 uppercase letter' },
    { regex: /(?=.*[0-9])/, message: '1 number' },
    { regex: /(?=.{8,})/, message: 'a length of 8 or more' },
    { regex: /[-@#!$%^&*()_+|~=`{}\\[\]:";'<>?,.\\/]/, message: '1 or more symbols' },
    { regex: /password/i, message: 'cannot include common phrase: password', reverseTest: true, showOnlyWhenError: true }
  ];

  const testResults = tests.map(x => {
    const result = x.regex.test(passwordValue ?? '');
    let isSuccess = result;
    let isHidden = false;

    // Certain tests should fail if they match, such as when matching insecure phrases
    if (x.reverseTest != null && x.reverseTest) {
      isSuccess = !isSuccess;
    }

    // Certain tests should be shown only when matched, such as when matching against common phrases
    if (x.showOnlyWhenError != null && x.showOnlyWhenError) {
      isHidden = isSuccess;
    }
    return { message: x.message, isSuccess, isHidden };
  });

  const renderPasswordHelp = () => {
    return <div className='password-help-text'>
      {testResults.map(x => {
        if (x.isHidden) {
          return null;
        }

        let className = x.isSuccess ? 'make-text-green' : 'make-text-red';
        let icon = x.isSuccess ? <CheckOutlined /> : <CloseOutlined />;
        return <p key={x.message} className={className}>{icon}{x.message}</p>;
      })}
    </div>;
  };

  return (
    <Form
      className='password-reset-form'
      layout='vertical'
      form={form}
      onFinish={handleFinish}
      size='large'
      requiredMark='optional'
      initialValues={props.initialValues}
    >
      {props.showConfirmationCode &&
        <Form.Item
          name={NameOf<PasswordResetFormData>('confirmationCode')}
          label="Confirmation Code"
          rules={[
            { required: true, message: 'Confirmation code is required' },
            { min: 6, message: 'Confirmation code is required' }
          ]}
        >
          <AuthCodeInput characters={6} loading={props.loading} />
        </Form.Item>
      }

      {props.showCurrentPassword &&
        <Form.Item
          name={NameOf<PasswordResetFormData>('currentPassword')}
          label="Current Password"
          rules={[{ required: true, message: 'Password is required' }]}
        >
          <Input.Password placeholder="Current Password" size='large' disabled={props.loading} onBlur={trimInput('currentPassword')} />
        </Form.Item>
      }

      <Form.Item
        name={NameOf<PasswordResetFormData>('newPassword')}
        label="New Password"
        hasFeedback
        validateStatus={testResults.some(x => !x.isSuccess && !x.isHidden) ? 'error' : 'success'}
        help={renderPasswordHelp()}
        tooltip='Password must have 1 uppercase letter, 1 number and be 8 characters or more'
        rules={[
          { required: true, message: 'Password is required' },
          {
            validator: (_, value) => {
              let res = tests.map(x => {
                const result = x.regex.test(value ?? '');
                let isSuccess = result;
                let isHidden = false;

                // Certain tests should fail if they match, such as when matching insecure phrases
                if (x.reverseTest != null && x.reverseTest) {
                  isSuccess = !isSuccess;
                }

                // Certain tests should be shown only when matched, such as when matching against common phrases
                if (x.showOnlyWhenError != null && x.showOnlyWhenError) {
                  isHidden = isSuccess;
                }
                return { message: x.message, isSuccess, isHidden };
              });
              if(res.some(x => !x.isSuccess))
              {
                return Promise.reject(new Error('The password does not fulfill the requirements'));
              }
              return Promise.resolve();
            }
          }
        ]}
      >
        <Input.Password placeholder='New Password' disabled={props.loading} onBlur={trimInput('newPassword')} />
      </Form.Item>

      <Form.Item
        name={NameOf<PasswordResetFormData>('confirmPassword')}
        label="Confirm Password"
        dependencies={[NameOf<PasswordResetFormData>('newPassword')]}
        hasFeedback
        rules={[
          { required: true, message: 'Please confirm your password' },
          {
            validator: (_, value) => {
              // Confirm password just needs to match
              if (!value || form.getFieldValue(NameOf<PasswordResetFormData>('newPassword')) === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            }
          }
        ]}
      >
        <Input.Password placeholder='Confirm Password' disabled={props.loading} onBlur={trimInput('confirmPassword')} />
      </Form.Item>

      {props.showConfirmationCode && <DelayButton type='link' style={{ fontWeight: '500' }} delay={60} loading={props.loading} onClick={handleResendCode}>Resend Code</DelayButton>}
      <Form.Item>
        <Button type="primary" htmlType='submit' block loading={props.loading}>{props.confirmButtonText}</Button>
      </Form.Item>
    </Form>
  );
};

export default PasswordResetForm;
