import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Col, Row, Select, Space, Table } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { ColumnProps } from 'antd/lib/table';
import { NameOf, NotificationUtil, StringUtil, TableColumnBuilder } from 'src/utils';
import { useQueryParam, useStateAsync } from 'src/hooks';
import ReloadIconSVG from 'src/components/svgs/ReloadIconSVG';
import DownloadIconSVG from 'src/components/svgs/DownloadIconSVG';
import AuditController from 'src/api/AuditController';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import AuditTrailItemDTO from 'src/models/generated/AuditTrailItemDTO';
import AuditTrailItemFilterDTO from 'src/models/generated/AuditTrailItemFilterDTO';
import AuditTrailHistoryModal from 'src/components/modals/AuditTrailHistoryModal';
import SimpleRangeSelector from 'src/components/SimpleRangeSelector';

const AuditTrailPage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const [itemQueryParam, setItemQueryParam] = useQueryParam('item');
  const [tableData, setTableData] = useState<AuditTrailItemDTO[]>([]);
  const [userFilterData, setUserFilterData] = useState<AuditTrailItemFilterDTO[]>([]); // This drives the user filter dropdown
  const [selectedUserFilter, setSelectedUserFilter, getSelectedUserFilterAsync] = useStateAsync<AuditTrailItemFilterDTO | null>(null); // The currently selected user filter
  const [selectedDateRange, setSelectedDateRange, getSelectedDateRangeAsync] = useStateAsync<[moment.Moment | null, moment.Moment | null] | null>([moment().subtract(1, 'day'), moment()]); // Current date range, defaults to 1 day
  const [selectedAuditItem, setSelectedAuditItem, getSelectedAuditItemAsync] = useStateAsync<AuditTrailItemDTO | null>(null); // Currently modal item
  const [modalVisible, setModalVisible] = useState(false);
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [loadingUserFilter, setLoadingUserFilter] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    // This exists because we need the filters to be there, even if not selected (even though they technically are) to load the table data
    await loadUserFilterData();
    await loadTableData();
  };

  const loadTableData = async () => {
    // Get values for the request
    const dateRange = await getSelectedDateRangeAsync();
    const userFilter = await getSelectedUserFilterAsync();

    // Can't do much if our values are not defined, so we can just leave
    if (dateRange == null || dateRange[0] == null || dateRange[1] == null || userFilter == null) {
      return;
    }

    setLoadingTableData(true);
    try {
      const startDate = dateRange[0].startOf('day');
      const endDate = dateRange[1].endOf('day');
      const userFilters = userFilter.filterType !== '' ? [userFilter] : [];

      const results = await AuditController.getAuditTrail(authContext.account!.id, authContext.location!.id, startDate, endDate, { auditTrailItemFilters: userFilters });

      // Sort data
      const sortedData = [...results.data.auditTrailItems];
      setTableData(sortedData);

      // Reopen the modal if one was open previously
      if (!StringUtil.IsNullOrEmpty(itemQueryParam)) {
        const found = sortedData.find(x => x.id === itemQueryParam);
        if (found != null) {
          setSelectedAuditItem(found);
          setModalVisible(true);
        }
      }
    } catch (error) {
      NotificationUtil.error({
        key: 'AuditTrailPage',
        message: 'Error while loading Audit Trail Items',
        error
      });
    }

    setLoadingTableData(false);
  };

  const loadUserFilterData = async () => {
    setLoadingUserFilter(true);
    try {
      const results = await AuditController.getAuditTrailFilters(authContext.account!.id, authContext.location!.id);

      // Add the select all a the top
      const data = [...results.data];
      const selectAllItem = AuditTrailItemFilterDTO.create({ displayValue: 'All Events' });
      data.unshift(selectAllItem);

      setUserFilterData(data);
      setSelectedUserFilter(selectAllItem);
    } catch (error) {
      NotificationUtil.error({
        key: 'AuditTrailPage',
        message: 'Error while loading Audit Trail Items',
        error
      });
    }
    setLoadingUserFilter(false);
  };

  const handleUserFilterSelect = (value: string, option: any) => {
    console.log('[handleUserFilterSelect]', value);
    const found = userFilterData.find(x => x.returnValue === value);
    console.log('[handleUserFilterSelect]', {value, found, option});
    if (found == null) {
      return;
    }
    setSelectedUserFilter(found);
    loadTableData();
  };

  const handleDateRangeSelect = (values: [moment.Moment | null, moment.Moment | null] | null) => {
    setSelectedDateRange(values);
    loadTableData();
  };

  const handleViewClick = (record: AuditTrailItemDTO) => {
    setSelectedAuditItem(record);
    setItemQueryParam(record.id);
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setItemQueryParam(null);
    setModalVisible(false);
  };

  const tableColumns: ColumnProps<AuditTrailItemDTO>[] = [
    TableColumnBuilder.Create<AuditTrailItemDTO>('created', 'Activity Date')
      .Width(200)
      .AddSorter('Date')
      .AddEnumFilterer(tableData.map(x => x.created).sort((a, b) => a?.diff(b) ?? 0).filter(x => x != null && x?.year() > 2000).map(x => x?.format('L') ?? ''), 'Date')
      .AddRenderer('LongDateWithTimeZone')
      .Build(),
    TableColumnBuilder.Create<AuditTrailItemDTO>('userDisplayName', 'User')
      .Width(200)
      .AddSorter('Text')
      .AddEnumFilterer(tableData.map(x => x.userDisplayName).sort())
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<AuditTrailItemDTO>('name', 'Event')
      .AddSorter('Text')
      .AddTextFilterer()
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<AuditTrailItemDTO>()
      .Key('view')
      .Width(100)
      .ClassName('without-padding')
      .AddRenderer('Custom', (_, record) => (<Button type='link' onClick={() => handleViewClick(record)}>View Activity</Button>))
      .Build(),
  ];

  return (
    <div className='audit-trail-page'>
      <h1>Audit Trail</h1>
      <p style={{maxWidth: 800}}>The Audit Trail keeps a record of specific details by location, including the date and time of an activity, the user who carried out the activity, and the event that took place. While login attempts, profile updates, and password changes are only visible to the user who made them, other changes can be seen by all users assigned to the account location. To view further details about a particular activity, click on the <b>&lsquo;View Activity&rsquo;</b> link.</p>
      <Row className='with-margin-bottom' justify='space-between'>
        <Col>
          <Space size={12}>
            <Select
              virtual={false}
              style={{ width: 220 }}
              disabled={loadingUserFilter}
              loading={loadingUserFilter}
              value={selectedUserFilter?.returnValue} // TODO: Please adjust
              options={userFilterData.map<DefaultOptionType>(x => ({ label: x.displayValue, value: x.returnValue }))}
              onChange={handleUserFilterSelect}
            />
            <SimpleRangeSelector
              maxDayRange={31}
              value={selectedDateRange}
              onChange={handleDateRangeSelect}
            />
          </Space>
        </Col>
        <Col>
          <Button type='link' icon={<ReloadIconSVG />} onClick={loadTableData} />
        </Col>
      </Row>

      <Table
        rowKey={NameOf<AuditTrailItemDTO>('id')}
        className='condensed-table striped-table borderless-table'
        rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
        loading={loadingTableData}
        pagination={false}
        columns={tableColumns}
        dataSource={tableData}
      />

      <AuditTrailHistoryModal auditItem={selectedAuditItem} open={modalVisible} onCancel={handleModalCancel} />
    </div>
  );
};

export default AuditTrailPage;

