import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Form,
  Input,
  Modal,
  Select,
  Skeleton,
  Switch,
  Tooltip,
} from 'antd';
import { CheckCircleFilled, CloseCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { NameOf, StringUtil } from 'src/utils';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import NotificationUtil from 'src/utils/NotificationUtil';
import UserController from 'src/api/UserController';
import UserProfileDTO from 'src/models/generated/UserProfileDTO';
import PhoneVerificationModal from 'src/components/PhoneVerificationModal';
import { PasswordResetModal } from 'src/components/modals/passwordResetModal';
import FormItemLabel from 'antd/es/form/FormItemLabel';

interface ProfileHomeFormData extends UserProfileDTO {}

const ProfileHomePage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const [form] = Form.useForm<ProfileHomeFormData>();
  const [formData, setFormData] = useState<Partial<UserProfileDTO>>({});
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [showPhoneVerificationModal, setShowPhoneVerificationModal] =
    useState(false);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(false);
  const [formDirty, setFormDirty] = useState(false);

  // This forces the given values to update in realtime, so we can do something with that value in the form itself
  const tfaEnabledValue = Form.useWatch(
    NameOf<ProfileHomeFormData>('tfaEnabled'),
    form
  );
  const phoneNumberValue = Form.useWatch(
    NameOf<ProfileHomeFormData>('phoneNumber'),
    form
  );

  useEffect(() => {
    loadForm();
  }, []);

  const loadForm = async () => {
    setLoading(true);
    const profileResult = await UserController.getMyProfile();

    // Tfa Preference cannot be text if the phone number has not been validated. So we need to adjust for that
    const { data } = profileResult;
    if (!data.phoneNumberValidated && data.tfaPreference === 'Text') {
      (data.tfaPreference as any) = undefined;
    }

    // Set the form directly from here. Setting an initial value on the form object will not allow us to reset later
    form.setFields(
      Object.entries(profileResult.data).map(([key, value]) => ({
        name: key,
        value: value,
      }))
    );

    setFormData(profileResult.data);
    setLoading(false);
  };

  const onFinish = async (values: ProfileHomeFormData) => {
    try {
      // Force a check on the TFA Preference to ensure we don't have two factor set to a number that does not exist
      console.log('[ProfileHome] submit form', {
        valuesPhone: values.phoneNumber,
        formPhone: formData.phoneNumber,
        previousValidated: formData.phoneNumberValidated,
        tfaPreference: values.tfaPreference,
        notificationsEnabled: values.notificationsEnabled,
      });

      

      // if (values.phoneNumber.length > 0 && (values.phoneNumber !== formData.phoneNumber || !formData.phoneNumberValidated) && values.tfaPreference !== 'Text') {
      if (
        values.tfaPreference !== 'Email' &&
        (values.phoneNumber !== formData.phoneNumber ||
          !formData.phoneNumberValidated)
      ) {
        /** Dev Note
         * I had this whole thing spun together about preventing the user from selecting text TFA or changing to it.
         * It had error messages changing when the phone number was cleared or just changed and it would give reasonable advice
         * However, it become immediately apparent that a simpler approach should be had: Just change it to 'Email'
         *  So simple... why didn't I start with that?
         */
        values.tfaPreference = 'Email';
        NotificationUtil.info({
          key: 'ProfileHomePage2',
          message: 'Two-Factor Preference',
          description: 'Your Two-Factor Preference has been reset to \'Email\'',
        });
      }

      setSubmitting(true);
      const result = await UserController.saveMyProfile(values);

      // Update the current profile data
      await authContext.updateProfile(result.data);

      // Save changes and update the
      setFormData(result.data);
      form.setFields(
        Object.entries(result.data).map(([key, value]) => ({
          name: key,
          value: value,
        }))
      );
      form.setFieldValue(NameOf<ProfileHomeFormData>('phoneNumber'), formatPhoneNumber(phoneNumberValue as string));

      if (
        !StringUtil.IsNullOrEmpty(result.data.phoneNumber) &&
        !result.data.phoneNumberValidated
      ) {
        // Dev Note: Simple yes or no modal, so no need for a whole component
        const saveModal = Modal.success({
          content:
            'Your changes have been saved. Next, we will need to verify your phone number',
          okText: 'Next',
          cancelText: 'Not Yet',
          onOk: () => {
            saveModal.destroy();
            setShowPhoneVerificationModal(true);
          },
        });
      } else {
        NotificationUtil.success({
          key: 'ProfileHomePage',
          message: 'Profile',
          description: 'Profile has been updated',
        });
      }
    } catch (error) {
      NotificationUtil.error({
        key: 'ProfileHomePage',
        message: 'Profile',
        description: 'Error while saving profile',
        error,
      });
    }
    setFormDirty(false);
    setSubmitting(false);
  };

  const handleVerifyPhoneNumberClick = async () => {
    // We want to show a modal at this point to verify the phone number. Should be 2 phases, so...
    form.setFieldValue(NameOf<ProfileHomeFormData>('phoneNumber'), formatPhoneNumber(phoneNumberValue as string));
    setShowPhoneVerificationModal(true);
  };

  const handlePhoneVerificationCancel = async () => {
    setShowPhoneVerificationModal(false);
  };

  const handlePhoneVerificationFinish = async (userProfile: UserProfileDTO) => {
    setFormData(userProfile);
    form.setFields(
      Object.entries(userProfile).map(([key, value]) => ({
        name: key,
        value: value,
      }))
    );
    setShowPhoneVerificationModal(false);
  };

  const handlePasswordResetClick = async () => {
    setShowPasswordResetModal(true);
  };

  const handleCancelPasswordReset = async () => {
    setShowPasswordResetModal(false);
  };

  const hasPhoneChanged = phoneNumberValue !== formData.phoneNumber;

  const onlyNumbers = (number: string) => {
    let ret: string = '';
    for (let i = 0; i < number?.length; i++) {
      if (number[i] >= '0' && number[i] <= '9') ret += number[i];
    }
    return ret;
  };

  function formatPhoneNumber(
    value: string):string{
      let number = onlyNumbers(value);
      if(number.length == 0)
        return '';
      return '(' + number.substring(0, 3) + ') ' + number.substring(3, 6) + '-' + number.substring(6);
    }
  

  //Wow this is dumb
  function forceFormatPhoneNumber(
    value: React.ChangeEvent<HTMLInputElement>
  ): void {
    let count = 0;
    let number = value.target.value as string;
    let ret: string = '';
    for (let i = 0; i < number.length; i++) {
      if (number[i] >= '0' && number[i] <= '9') ret += number[i];
      if (i == 0 && number[i] == '(' && count == 0) {
        ret += number[i];
        count++;
      }

      if (i == 4 && number[i] == ')' && count == 1) {
        ret += number[i];
        count++;
      }

      if (i == 5 && number[i] == ' ' && count == 2) {
        ret += number[i];
        count++;
      }

      if (i == 9 && number[i] == '-' && count == 3) {
        ret += number[i]; count++;
      }
    }
    if(ret.length > 10 + count){
      ret = ret.substring(0, 10 + count);
    }
    form.setFieldValue(NameOf<ProfileHomeFormData>('phoneNumber'), ret);
  }

  return (
    <div className="profile-home standard-form-layout">
      <h1>User Profile</h1>

      <Skeleton active loading={loading}>
        <Form
          size="large"
          layout="vertical"
          requiredMark={false}
          form={form}
          onFinish={onFinish}
          onFieldsChange={() => setFormDirty(true)} // Indicate that something has changed in the form
        >
          {/* First Name */}
          <Form.Item
            label="First Name"
            name={NameOf<ProfileHomeFormData>('firstName')}
            rules={[{ required: true, message: 'First Name is required' }]}
          >
            <Input allowClear />
          </Form.Item>

          {/* Last Name */}
          <Form.Item
            label="Last Name"
            name={NameOf<ProfileHomeFormData>('lastName')}
            rules={[{ required: true, message: 'Last Name is required' }]}
          >
            <Input allowClear />
          </Form.Item>

          {/* Display Name */}
          <Form.Item
            label="Display Name"
            name={NameOf<ProfileHomeFormData>('displayName')}
            rules={[{ required: true, message: 'Display Name is required' }]}
          >
            <Input allowClear />
          </Form.Item>

          {/* Email address */}
          <Form.Item
            label="Email address"
            name={NameOf<ProfileHomeFormData>('emailAddress')}
          >
            <Input disabled />
          </Form.Item>

          {/* Phone Number */}
          <Form.Item label="Phone Number" className="form-item-grouped-flexed">
            <Form.Item name={NameOf<ProfileHomeFormData>('phoneNumber')}>
              <Input
                onChange={(value) => forceFormatPhoneNumber(value)}
                placeholder="(___) ___-____"
                allowClear
                suffix={
                  onlyNumbers(phoneNumberValue as string).length == 0 ?
                  <></>:
                  onlyNumbers(phoneNumberValue as string).length == 10 ? (
                    <span className="make-text-green">
                      <Tooltip title="Phone number is valid">
                        <CheckCircleFilled className="standard-icon icon-with-white-background" />
                      </Tooltip>
                    </span>
                  ) : (
                    <span className="make-text-red">
                      <Tooltip title="Phone number is not not valid must be 10 digits">
                        <CloseCircleFilled className="standard-icon icon-with-white-background" />
                      </Tooltip>
                    </span>
                  )
                }
              />              
            </Form.Item>
            {!StringUtil.IsNullOrEmpty(phoneNumberValue as string) &&
              onlyNumbers(phoneNumberValue as string).length >= 10 && (
                <Form.Item>
                  <Button
                    style={{ width: 200 }}
                    disabled={formData.phoneNumberValidated && !hasPhoneChanged}
                    onClick={handleVerifyPhoneNumberClick}
                  >
                    Verify Phone Number
                  </Button>
                </Form.Item>
              )}
          </Form.Item>

          {/* warning emails enabled */}
          <Form.Item className="form-item-grouped-flexed">
            <Form.Item
              label="Warning Alerts Enabled"
              name={NameOf<ProfileHomeFormData>('notificationsEnabled')}
              tooltip={{title: 'If enabled, an email will be sent during overnight hours to alert you of any Warnings that require your attention in Accounting Link Online. If you are provisioned for multiple locations, you will receive one email for each location that contains Warnings. Turning off this notification will turn off your Warning notifications for all locations. We recommend enabling this setting if the Auto Approve Advanced Setting is set to True.', icon: <InfoCircleOutlined/>}}
              valuePropName='checked'
            >
              <Switch/>
            </Form.Item>
          </Form.Item>

          {/* Two-Factor Authentication */}
          <Form.Item className="form-item-grouped-flexed">
            <Form.Item
              label="Two-Factor Enabled"
              name={NameOf<ProfileHomeFormData>('tfaEnabled')}
              valuePropName="checked"
              rules={[{ required: true, message: 'Two Factor is required' }]}
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Two-Factor Preference"
              name={NameOf<ProfileHomeFormData>('tfaPreference')}
              valuePropName="value"
              rules={[{ required: true, message: 'Preference is required' }]}
            >
              <Select
                disabled={!tfaEnabledValue}
                options={[
                  { value: 'Email' },
                  { value: 'Text', disabled: !formData.phoneNumberValidated },
                ]}
              />
            </Form.Item>
          </Form.Item>

          {/* Change Password */}
          <Form.Item>
            <Button
              style={{ padding: '0 20px' }}
              disabled={submitting}
              onClick={handlePasswordResetClick}
            >
              Change Password
            </Button>
          </Form.Item>

          {/* Submit */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ padding: '0 20px' }}
              loading={loading || submitting}
              //I hate boolean logic
              disabled={!formDirty || ((onlyNumbers(phoneNumberValue as string).length != 10) && (onlyNumbers(phoneNumberValue as string).length != 0))}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Skeleton>

      <PhoneVerificationModal
        phonenumber={phoneNumberValue as string}
        open={showPhoneVerificationModal}
        onFinish={handlePhoneVerificationFinish}
        onCancel={handlePhoneVerificationCancel}
      />
      <PasswordResetModal
        open={showPasswordResetModal}
        onCancel={handleCancelPasswordReset}
      />
    </div>
  );
};

export default ProfileHomePage;
