import axios, { AxiosResponse } from 'axios';
import ReportAssignmentModel from 'src/models/frontend/ReportAssignmentModel';
import ReportJournalDTO from 'src/models/generated/ReportJournalDTO';
import ReportSummaryModel from 'src/models/frontend/ReportSummaryModel';
import ReportTransactionDTO from 'src/models/generated/ReportTransactionDTO';
import ReportWarningModel from 'src/models/frontend/ReportWarningModel';
import ReportMappingModel from 'src/models/frontend/ReportMappingModel';
import ReportTransactionReferenceDTO from 'src/models/generated/ReportTransactionReferenceDTO';
import { AxiosUtil } from 'src/utils';
import ReportTransactionItemSelectionValueDTO from 'src/models/generated/ReportTransactionItemSelectionValueDTO';
import ReportTransactionSelectionValueDTO from 'src/models/generated/ReportTransactionSelectionValueDTO';

class ReportController {
  private prefix: string = "accounts";

  // API_50
  async getReportWarning(accountId: string, locationId: string, startDate: moment.Moment, endDate: moment.Moment): Promise<AxiosResponse<ReportWarningModel[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/reports/warning`;
    let params: Record<string, string> = {
      startdate: startDate.format(),
      enddate: endDate.format(),
    }
    return AxiosUtil.get<ReportWarningModel[]>(route, items => items.map(ReportWarningModel.create), { params });
  }

  // API_51
  async getReportSummary(accountId: string, locationId: string, startDate: moment.Moment, endDate: moment.Moment): Promise<AxiosResponse<ReportSummaryModel[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/reports/summary`;
    let params: Record<string, string> = {
      startdate: startDate.format(),
      enddate: endDate.format(),
    }
    return AxiosUtil.get<ReportSummaryModel[]>(route, items => items.map(ReportSummaryModel.create), { params });
  }

  // API_52
  async getReportTransaction(accountId: string, locationId: string, startDate: moment.Moment, endDate: moment.Moment): Promise<AxiosResponse<ReportTransactionDTO[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/reports/transaction`;
    let params: Record<string, string> = {
      startdate: startDate.format(),
      enddate: endDate.format(),
    }
    return AxiosUtil.get<ReportTransactionDTO[]>(route, items => items.map(ReportTransactionDTO.create), { params });
  }

  // API_53
  async getReportTransactionApprove(accountId: string, locationId: string, transactions: ReportTransactionReferenceDTO[]): Promise<AxiosResponse<ReportTransactionDTO[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/reports/transaction/approve`;
    return AxiosUtil.post<ReportTransactionDTO[], ReportTransactionReferenceDTO[]>(route, transactions, items => items.map(ReportTransactionDTO.create));
  }

  // API_54
  async getReportTransactionVoid(accountId: string, locationId: string, transactions: ReportTransactionReferenceDTO[]): Promise<AxiosResponse<ReportTransactionDTO[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/reports/transaction/void`;
    return AxiosUtil.post<ReportTransactionDTO[], ReportTransactionReferenceDTO[]>(route, transactions, items => items.map(ReportTransactionDTO.create));
  }

  // API_55
  async getReportTransactionUnapprove(accountId: string, locationId: string, transactions: ReportTransactionReferenceDTO[]): Promise<AxiosResponse<ReportTransactionDTO[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/reports/transaction/unapprove`;
    return AxiosUtil.post<ReportTransactionDTO[], ReportTransactionReferenceDTO[]>(route, transactions, items => items.map(ReportTransactionDTO.create));
  }

  // API_56
  async getReportJournal(accountId: string, locationId: string, startDate: moment.Moment, endDate: moment.Moment): Promise<AxiosResponse<ReportJournalDTO[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/reports/journal`;
    let params: Record<string, string> = {
      startdate: startDate.format(),
      enddate: endDate.format(),
    }
    return AxiosUtil.get<ReportJournalDTO[]>(route, items => items.map(ReportJournalDTO.create), { params });
  }

  // API_57
  async getReportAssignment(accountId: string, locationId: string, startDate: moment.Moment, endDate: moment.Moment): Promise<AxiosResponse<ReportAssignmentModel[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/reports/assignment`;
    let params: Record<string, string> = {
      startdate: startDate.format(),
      enddate: endDate.format(),
    }
    return AxiosUtil.get<ReportAssignmentModel[]>(route, items => items.map(ReportAssignmentModel.create), { params });
  }

  // API_58
  async getReportMapping(accountId: string, locationId: string): Promise<AxiosResponse<ReportMappingModel[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/reports/mapping`;
    return AxiosUtil.get<ReportMappingModel[]>(route, items => items.map(ReportMappingModel.create));
  }

  // API_59
  async getTransactionModalValues(accountId: string, locationId: string, transactionTypeKey: string): Promise<AxiosResponse<ReportTransactionSelectionValueDTO>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/reports/transaction/${transactionTypeKey}/selectionvalues`;
    return AxiosUtil.get<ReportTransactionSelectionValueDTO>(route, ReportTransactionSelectionValueDTO.create);
  }

  // API_60
  async saveTransaction(accountId: string, locationId: string, transaction: ReportTransactionDTO): Promise<AxiosResponse<ReportTransactionDTO>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/reports/transaction/save`;
    return AxiosUtil.post<ReportTransactionDTO, ReportTransactionDTO>(route, transaction);
  }

  // API_61
  async revertTransaction(accountId: string, locationId: string, transaction: ReportTransactionReferenceDTO): Promise<AxiosResponse<ReportTransactionDTO>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/reports/transaction/revert`;
    return AxiosUtil.post<ReportTransactionDTO, ReportTransactionReferenceDTO>(route, transaction, ReportTransactionDTO.create);
  }
}

export default new ReportController();
