import { ArrayUtil } from 'src/utils';
import ReportAssignmentItemDTO from './ReportAssignmentItemDTO';

interface ReportAssignmentDTO {
  displayOrder: number;
  displayAccount: string;
  displayType: string;
  displayDebit: number;
  displayCredit: number;
  displayItemDebitTotalAmount: number;
  displayItemCreditTotalAmount: number;
  items: ReportAssignmentItemDTO[];
}
const ReportAssignmentDTO = {
  create: (initValues?: Partial<ReportAssignmentDTO>): ReportAssignmentDTO => {
    const defaults: ReportAssignmentDTO = {
      displayOrder: 0,
      displayAccount: '',
      displayType: '',
      displayDebit: 0,
      displayCredit: 0,
      displayItemDebitTotalAmount: 0,
      displayItemCreditTotalAmount: 0,
      items: [],
    };
    const fixedInitValues: Partial<ReportAssignmentDTO> = initValues != null
      ? {
        items: ArrayUtil.EasyMap(initValues.items, ReportAssignmentItemDTO.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default ReportAssignmentDTO;
