import { ArrayUtil } from 'src/utils';
import AuditTrailItemViewColumnDTO from './AuditTrailItemViewColumnDTO';
import AuditTrailItemViewRowDTO from './AuditTrailItemViewRowDTO';
import AuditTrailItemViewHeaderAttributeDTO from './AuditTrailItemViewHeaderAttributeDTO';
import AuditTrailItemViewFooterAttributeDTO from './AuditTrailItemViewFooterAttributeDTO';

interface AuditTrailItemViewDTO {
  auditTrailItemId: string;
  columns: AuditTrailItemViewColumnDTO[];
  rows: AuditTrailItemViewRowDTO[];
  headerAttributes: AuditTrailItemViewHeaderAttributeDTO[];
  footerAttributes: AuditTrailItemViewFooterAttributeDTO[];
}
const AuditTrailItemViewDTO = {
  create: (initValues?: Partial<AuditTrailItemViewDTO>): AuditTrailItemViewDTO => {
    const defaults: AuditTrailItemViewDTO = {
      auditTrailItemId: '',
      columns: [],
      rows: [],
      headerAttributes: [],
      footerAttributes: [],
    };
    const fixedInitValues: Partial<AuditTrailItemViewDTO> = initValues != null
      ? {
        columns: ArrayUtil.EasyMap(initValues.columns, AuditTrailItemViewColumnDTO.create),
        rows: ArrayUtil.EasyMap(initValues.rows, AuditTrailItemViewRowDTO.create),
        headerAttributes: ArrayUtil.EasyMap(initValues.headerAttributes, AuditTrailItemViewHeaderAttributeDTO.create),
        footerAttributes: ArrayUtil.EasyMap(initValues.footerAttributes, AuditTrailItemViewFooterAttributeDTO.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default AuditTrailItemViewDTO;
