import { CheckCircleFilled, CloseCircleFilled, EditOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';
import React, { useContext, useEffect, useState } from 'react';
import LocationDTO from 'src/models/generated/LocationDTO';
import { NameOf, TableColumnBuilder } from 'src/utils';
import NotificationUtil from 'src/utils/NotificationUtil';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { CacheContext } from 'src/providers/CacheContext';
import StandardSingleEditModal from './modals/StandardSingleEditModal';
import LocationController from 'src/api/LocationController';
import { tz } from 'moment-timezone';

interface SelectLocationProps {
  disabled?: boolean;
  /** This will be the ID for the account */
  initialValue?: string;
  scroll?: TableProps<LocationDTO>['scroll'];
  onEdit?: (value: LocationDTO) => void;
  onChange?: (value: LocationDTO) => void;
  /** Called on doubleclick so that the item can be assumed to be selected */
  onSelect?: () => void;
  /** Called when loading is complete. Mainly used to select->redirect if there is only one element */
  onLoadComplete?: (accounts: LocationDTO[]) => void;
}

const SelectLocation: React.FC<SelectLocationProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const cacheContext = useContext(CacheContext);
  const [selectedValue, setSelectedValue] = useState<LocationDTO>();
  const [tableData, setTableData] = useState<LocationDTO[]>();
  const [editNameModalValue, setEditNameModalValue] = useState<LocationDTO>();
  const [editNameModalVisible, setEditNameModalVisible] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  // OnLoad
  useEffect(() => {
    loadTableData();
  }, []);

  // Try again when account get's loaded
  useEffect(() => {
    loadTableData(true);
  }, [authContext.account, authContext.location]);

  const loadTableData = async (refresh: boolean = false) => {
    if (authContext.account == null) {
      // Can't do much without this!]
      return;
    }
    setLoading(true);
    try {
      const results = await cacheContext.getLocations(authContext.account!.id, refresh);
      results.sort((a, b) => a.displayName.localeCompare(b.displayName));
      results.forEach((x) => x.Status = x.mappingIssueCount == 0 && x.postingIssueCount == 0);

      //These columns will have 30+ if there is 30 or more of the item sorting numbers as strings is hard we set it back to 30+ when we render
      results.forEach((x) => {
         x.mappingIssueCount = x.mappingIssueCount.toString() == "30+" ? 30 : x.mappingIssueCount;
         x.postingIssueCount = x.postingIssueCount.toString() == "30+" ? 30 : x.postingIssueCount;
         x.openTileCount = x.openTileCount.toString() == "30+" ? 30 : x.openTileCount;
      });
      setTableData(results);
      props.onLoadComplete?.(results);
      if (props.initialValue != null) {
        const initialValue = props.initialValue ?? '';
        const selectedValue = results.find(x => x.id === initialValue);

        // Send values into handler for code reuse
        handleSelectValue(selectedValue ? [selectedValue] : []);
      }
    } catch (error) {
      NotificationUtil.error({
        key: 'SelectLocation',
        message: 'Error while loading Locations',
        error: error
      });
    }
    setLoading(false);
  };

  const handleSelectValue = (records: LocationDTO[]) => {
    props.onChange?.(records[0]);
    setSelectedValue(records[0]);
  };

  const handleDoubleClick = () => {
    // First click should fire the handleSelectValue, so all we need to do is confirm that it has been selected
    props.onSelect?.();
  };

  const handleEditClick = (record: LocationDTO) => {
    setEditNameModalValue(record);
    setEditNameModalVisible(true);
  };

  const handleEditModalFinish = async (value: string) => {
    // SetLoading is pulling double duty here
    setLoading(true);
    try {
      const updatedLocation: LocationDTO = { ...editNameModalValue!, displayName: value };
      await LocationController.saveLocation(authContext.account!.id, editNameModalValue!.id, updatedLocation);
      // if editing the current location update the contexts
      if(authContext.location?.id == updatedLocation.id)
      {
        await cacheContext.updateLocation(authContext.account?.id?? '', updatedLocation);
        await authContext.updateLocation(updatedLocation);
      }

      // Success! Reload grid and toss out a notification
      NotificationUtil.success({
        key: 'Select Location',
        message: 'Account has been updated'
      });
      await loadTableData(true);
      props.onEdit?.(updatedLocation);
      setEditNameModalVisible(false);
    } catch (error) {
      NotificationUtil.error({
        key: 'Select Location',
        message: 'Error while saving Location',
        error
      });
    }
    setLoading(false);
  };

  const tableColumns: ColumnProps<LocationDTO>[] = [
    TableColumnBuilder.Create<LocationDTO>('displayName', 'Name')
      .Width(280)
      .AddSorter('Text')
      .AddRenderer('Ellipses')
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<LocationDTO>('partnerAccountNumber', 'Partner Account')
      .Width(170)
      .AddSorter('Text')
      .AddRenderer('Ellipses')
      .AddTextFilterer()
      .Build(),
      TableColumnBuilder.Create<LocationDTO>('Status', 'Status')
        .Width(70)
        .Align('center')
        .AddSorter('Boolean')
        .AddRenderer('Custom', (_, record) => (record.Status ? <Tooltip mouseEnterDelay={1} title={'Refreshed ' + record.statusAsOf?.format('MM/DD/YYYY') + ' at ' + record.statusAsOf?.format('h:mm:ss a ') + tz(tz.guess()).zoneAbbr()}><div><CheckCircleFilled className='standard-icon make-text-green' /></div></Tooltip> : <Tooltip mouseEnterDelay={1} title={'Refreshed ' + record.statusAsOf?.format('MM/DD/YYYY') + ' at ' + record.statusAsOf?.format('h:mm:ss a ') + tz(tz.guess()).zoneAbbr()}><div><CloseCircleFilled className='standard-icon make-text-red'/></div></Tooltip>))
        .Build(),
      TableColumnBuilder.Create<LocationDTO>('mappingIssueCount', 'Mapping Issues')
          .Width(150)
          .AddSorter('Number')
          .AddRenderer('Custom', (_, record) => (<Tooltip mouseEnterDelay={1} title={'Refreshed ' + record.statusAsOf?.format('MM/DD/YYYY') + ' at ' + record.statusAsOf?.format('h:mm:ss a ') + tz(tz.guess()).zoneAbbr()}><div style={{marginRight:18}}>{record.mappingIssueCount >= 30 ? "30+" : record.mappingIssueCount}</div></Tooltip>))
          .Align('right')
          .Build(),
      TableColumnBuilder.Create<LocationDTO>('postingIssueCount', 'Posting Issues')
          .Width(150)
          .AddSorter('Number')
          .AddRenderer('Custom', (_, record) => (<Tooltip mouseEnterDelay={1} title={'Refreshed ' + record.statusAsOf?.format('MM/DD/YYYY') + ' at ' + record.statusAsOf?.format('h:mm:ss a ') + tz(tz.guess()).zoneAbbr()}><div style={{marginRight:18}}>{record.postingIssueCount >= 30 ? "30+" : record.postingIssueCount}</div></Tooltip>))
          .Align('right')
          .Build(),
      TableColumnBuilder.Create<LocationDTO>('openTileCount', 'Open Tiles')
          .Width(130)
          .AddSorter('Number')
          .AddRenderer('Custom', (_, record) => (<Tooltip mouseEnterDelay={1} title={'Refreshed ' + record.statusAsOf?.format('MM/DD/YYYY') + ' at ' + record.statusAsOf?.format('h:mm:ss a ') + tz(tz.guess()).zoneAbbr()}><div style={{marginRight:18}}>{record.openTileCount >= 30 ? "30+" : record.openTileCount}</div></Tooltip>))
          .Align('right')
          .Build(),
    TableColumnBuilder.Create<LocationDTO>()
      .Key('Edit')
      .Width(50)
      .AddRenderer('Custom', (_, record) => (<Button type='link' icon={<EditOutlined />} onClick={() => handleEditClick(record)} />))
      .Build(),
  ];

  return (
    <div className='locations-selection-component'>
      <Table
        rowKey={NameOf<LocationDTO>('id')}
        className='less-condensed-table striped-table borderless-table'
        rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
        scroll={props.scroll}
        loading={loading}
        rowSelection={{
          type: 'radio',
          selectedRowKeys: selectedValue != null ? [selectedValue.id] : [],
          getCheckboxProps: () => ({ disabled: props.disabled }),
          onChange: (_, rows) => handleSelectValue(rows)
        }}
        onRow={record => ({
          onClick: () => handleSelectValue([record]),
          onDoubleClick: () => handleDoubleClick(),
        })}
        pagination={false}
        columns={tableColumns}
        dataSource={tableData}
      />
      <StandardSingleEditModal
        title='Edit Location Name'
        displayType='TextBox'
        formatType='String'
        defaultValue={editNameModalValue?.displayName}
        open={editNameModalVisible}
        onCancel={() => setEditNameModalVisible(false)}
        onFinish={handleEditModalFinish}
      />
    </div>
  );
};

export default SelectLocation;
