import React from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import RouteConfig from 'src/config/RouteConfig';

const LoginTermsOfServicePage: React.FC = () => {
  return (
    <div className='terms-of-service'>
      {/* This will actually show up at the top right */}
      <Link to={RouteConfig.LOGIN_EMAIL()}>
        <button title='X' className='ant-modal-close'>
          <span className='ant-modal-close-x'>
            <CloseOutlined className='ant-modal-close-icon' />
          </span>
        </button>
      </Link>

      <div className='page-content'>
        <h1>Terms of Service</h1>

        <Card className='large-text-container'>
          <h2>A. GENERAL TERMS</h2>

    <h2>1. Agreement</h2>
    <p>Thank you for selecting the Services offered by the Back Office, LLC. (referred to as “Back Office,” “we,” “our,” or “us”). Review these Terms of Service (”Agreement”) thoroughly. Throughout this Agreement, the words “Back Office,” “us,” “we,” and “our” refer to our company, Back Office, and our Platform, as is appropriate in the context of the use of the words.</p>
    <p>This is an agreement (“Agreement”) between The Back Office, LLC (“Back Office"), the owner and operator of the <a href="http://www.tboffice.com">www.tboffice.com</a> website, any Back Office products and services, and the Back Office software and you (“you”, “your” or “user”), the customer and/or partner who has decided to subscribe to the Platform.</p>
    <p>By accepting electronically (for example, by clicking “I Agree”), installing, accessing, or using the Services, you agree to these terms as well as our Privacy Policy. We may amend our Terms of Service or the Privacy Policy and may notify you when we do so. PLEASE BE AWARE THAT THERE ARE ARBITRATION AND CLASS ACTION PROVISIONS THAT MAY AFFECT YOUR RIGHTS.</p>
    <p>If you do not agree to this Agreement, then you may not use the Services.</p>

    <h2>2. Signing Up For the Platform</h2>
    <p>Only Users who utilize shop management systems that Back Office is partnered with may utilize the Platform. If you utilize a shop management system Back Office is not partnered with, reach out to <a href="mailto:sales@accountinglink.net">sales@accountinglink.net</a> for details. If you utilize a shop management system that Back Office is partnered with, you must submit an order to your shop management system before being granted access to the Platform. The partnering shop management system will then review and forward your order to Back Office for processing. Shop management systems must fully complete the registration process by providing Back Office with current, complete, truthful, and accurate information as prompted by the applicable registration form. After signing up, Back Office, at its sole discretion, may review the application. Where required, Back Office may also assign you a username and password. You are entirely responsible for maintaining the confidentiality of your password and account and for any and all activities that occur under your account. You agree to notify Back Office immediately of any unauthorized use of your account or any other breach of security. Back Office will not be liable for any losses you incur as a result of someone else using your password or account, either with or without your knowledge. Users may share their login information with other users at their location, but please be aware that you could be held liable for losses incurred by Back Office or another party due to someone else using your account or password. Users may not register on behalf of other businesses or as a group collaboration. The person registering a user account represents and warrants that he/she has the proper authority to execute and bind the company to this Agreement. Back Office has the sole discretion in granting or denying any accounts.</p>

    <h2>3. User Representations and Warranties</h2>
    <p>As a user accessing services via the Platform, you represent and warrant the following:</p>
    <ul>
        <li>You own or have properly licensed all intellectual property used on the Platform</li>
        <li>You will not infringe on any copyright, moral right, trademark, or other intellectual property right and will not violate any right of privacy or right of publicity</li>
        <li>You do not violate any US state or federal laws or any third-party agreements</li>
        <li>You have not entered into any previous agreements which would limit its ability to undertake or perform this Agreement</li>
        <li>You have the ability and the right to grant the permissions required to effectuate this Agreement</li>
        <li>You will, in good faith, abide by this Agreement and all other restrictions and rules as stated in this Agreement or any other additional agreements.</li>
    </ul>
    <h2>4. Use of the Platform</h2>
    <p>You are responsible for your use of the Website, Services, and Platform. With respect to your use of any part of the Platform as a User, you agree to abide by the following:</p>
    <ul>
        <li>You will not copy, distribute, or disclose any part of the Platform in any medium, including without limitation by any automated or non-automated “scraping”;</li>
        <li>You will not attempt to interfere with, compromise the system integrity or security, or decipher any transmissions to or from the servers running the Platform;</li>
        <li>You will not use any robot, spider, crawler, scraper, or other automated means or interface not provided by us to access the Platform or to extract data;</li>
        <li>You will not use automated bots or other software to send more messages through our Platform than humanly possible;</li>
        <li>You will not take any action that imposes, or may impose at our sole discretion, an unreasonable or disproportionately large load on our infrastructure;</li>
        <li>You will not collect or harvest any personally identifiable information, including account names, from the Platform;</li>
        <li>You agree not to libel, defame, stalk, harass, bully, or harm another individual who uses our Platform;</li>
        <li>You agree not to impersonate any person or entity or misrepresent your affiliation with a person or entity;</li>
        <li>You agree that you will not hold Back Office responsible for your use of our Platform;</li>
        <li>You agree not to violate any requirements, procedures, policies, or regulations of networks connected to the Back Office;</li>
        <li>You agree not to interfere with or disrupt the Platform;</li>
        <li>You agree not to hack, spam, or phish us or other users;</li>
        <li>You agree to provide truthful and accurate content;</li>
        <li>You agree not to violate any law or regulation, and you are solely responsible for such violations;</li>
        <li>You agree not to use our Platform for any illegal or other unlawful activity; and</li>
        <li>You agree not to cause or aid in the disruption, destruction, manipulation, removal, disabling, or impairment of any portion of our Platform, including the de-indexing or de-caching of any portion of our Platform from a third-party’s website, such as by requesting its removal from a search engine.</li>
    </ul>
    <p>If you are discovered to be undertaking any of the aforementioned actions, your privileges to use our Platform may, at our discretion, be terminated or suspended. Generally, we will provide an explanation for any suspension or termination of your use of any of our platforms, but Back Office reserves the right to suspend or terminate any account at any time without notice or explanation.</p>

    <h2>5. Access and License Grant to You</h2>
<p>After registering and properly paying for our Platform, we grant you a personal, non-exclusive, revocable, commercial, limited license to download our software. If you are accessing our Platform via the internet, we grant you access to the Platform with the same restrictions as our license grant. As a User, you do not receive any ownership interest in any portion of the Platform; you merely receive the revocable rights listed above. All rights not explicitly granted are reserved for Back Office.</p>
<p>Please be aware that you are responsible for your use of our Platform. Additionally, you agree to abide by the following restrictions listed below:</p>
<ul>
    <li>You may not decompile, reverse engineer, disassemble, modify, distribute, or create derivative works or improvements to the Platform or any portion of it;</li>
    <li>You may not share your license or access with any other parties except as provided for in this Agreement;</li>
    <li>You may not violate or infringe other people's intellectual property, privacy, or other rights while using our Platform;</li>
    <li>You may not violate any laws, rules, or procedures of the United States;</li>
    <li>You may not violate any of our additional policies;</li>
    <li>You may not use our Platform except through specific channels provided by us;</li>
    <li>You may not use the Platform on a computer that is used to operate nuclear facilities, life support, or other mission-critical applications where life or property may be at stake;</li>
    <li>You may not sell, lease, loan, distribute, transfer, or sublicense the Platform or access to it or derive income from the use or provision of the Platform unless enabled through the functionality of our Platform.</li>
</ul>
<p>Please be aware that this is not an all-encompassing list of restrictions. If you breach any of these restrictions, we may revoke your license or your access to use our Platform at our discretion. Additionally, we may revoke your license or restrict your access to our Platform if we believe that your actions may harm us or any of our users. Failure by us to revoke your license or restrict access does not act as a waiver of your conduct.</p>

<h2>6. User Content</h2>
<p>Any content transmitted by the Shop Management System into the Platform shall be referred to as “User Content” throughout this agreement. All User Content submitted to the Platform will be owned by you. Please be aware that we are not required to host, display, migrate, or distribute any of your User Content, and we may refuse to accept or transmit any User Content. You agree that you are solely responsible for any User Content submitted, and you release us from any liability associated with any User Content submitted. Any User Content found to be in violation of this Agreement or that we determine to be harmful to the Platform may be modified, edited, or removed at our discretion. Back Office does not endorse and may not verify, monitor, or restrict any User Content submitted.</p>
<p>When submitting any User Content to our Platform, you represent and warrant that you own all rights to the User Content and you have paid for or otherwise have permission to use any User Content submitted.</p>
<p>When you submit any User Content to us, you grant Back Office, its Partners, Affiliates, Users, and Representatives, and assign a non-exclusive, fully-paid, royalty-free, irrevocable, worldwide, universal, transferable, assignable license to display, publicly perform, distribute, store, broadcast, transmit, reproduce, modify, prepare derivative works and otherwise use and reuse all or part of your User Content. It is important for you to grant us this license so that we may transmit your User Content to other users through our Platform. Additionally, although you own all User Content submitted by you, we own all layouts, arrangements, and metadata that are used to render your User Content through our Platform.</p>
<h2>7. Transmission Issues with the Platform</h2>
<p>You will immediately notify Back Office of any problems or issues with the Platform. You understand that we cannot guarantee that our Platform is error-free and that Back Office cannot be responsible for any interruption to the Platform due to faulty equipment, faulty transmission systems, power outages, other interruptions in transmission services, transmission systems that have been tampered with or any damage or destruction to our equipment or facilities.</p>
<h2>8. Platform Availability</h2>
<p>You will immediately notify Back Office of any problems or issues with the Platform. You understand that we cannot guarantee that our Platform is error-free and that Back Office cannot be responsible for any interruption to the Platform due to faulty equipment, faulty transmission systems, power outages, other interruptions in transmission services, transmission systems that have been tampered with or any damage or destruction to our equipment or facilities.</p>
<h2>9. Modification of Platform</h2>
<p>We reserve the right to alter, modify, update, or remove our Platform at any time. We may conduct such modifications to our Platform for security reasons, intellectual property, legal reasons, or various other reasons at our discretion, and we are not required to explain such modifications. For example, we may provide updates to fix security flaws or to respond to legal demands. Please note that this is a non-binding illustration of how we might exercise our rights under this section, and nothing in this section obligates us to take measures to update the Platform for security, legal or other purposes.</p>
<h2>10. System Requirements</h2>
<p>Please be aware that the Platform may not be available without the following: (1) a functional Wi-Fi network that can communicate with our products; (2) continuous internet service and a computer capable of connecting to the internet; and (3) other system requirements as specified by us. Please be aware that you are fully responsible for these requirements. Failure to obtain these requirements may result in unavailability or errors in the Platform.</p>
<p>While we aim to provide you with high-quality service through our Platform, all services may not be available 100% of the time. The Platform may be subject to interruptions for a variety of reasons, including Wi-Fi connectivity, mobile issues, cellphone service, and service provider uptime.</p>
<h2>11. Payments and Recurring Subscription</h2>
<p>In order to use and access our Platform, you may be required to pay a monthly or annual subscription fee or another fee as determined by your shop management system. All payments must be made to your shop management system directly for processing.</p>
<h2>12. Price Increases</h2>
<p>Partners and Users agree that Back Office may increase the price of their services after any subscription cycle, and Back Office reserves the right to do so. In the event of a price increase, Back Office shall notify Partners. Partners agree that Back Office has no obligation to offer any services for a price that was offered to the User or Partner in the past. As the Partner is responsible for handling the billing to Users, Back Office is not responsible for notifying Users of price changes.</p>
<h2>13. Refunds</h2>
<p>At Back Office, we want you to be satisfied with our Platform and any services offered. With that being said, we do not offer refunds; however, we will work with our Users and Partners to make sure they can get the full benefit out of Back Office services.</p>
<p>________________________________________</p><br></br>
<h1>B. ADDITIONAL TERMS</h1>
<h2>14. Data Storage</h2>
<p>Upon payment, Back Office shall provide reasonable storage space for any User Content submitted by you. However, in the event that storage capacities provided by Back Office are abused due to misuse or incorrect usage, we reserve the right to review, audit, and/or charge for excessive data storage.</p>
<h2>15. Data Migration</h2>
<p>Upon termination of your subscription to the Platform by either the User, Partner, or Back Office, the Platform and any User Content stored on the Platform may become inaccessible until the subscription is restored to active and paid status. The User Content data inside the Platform belongs to you, the User. We provide data migration services, though this service is not included in your Platform subscription and must be paid for separately.</p>

<h2>16. Your Usage Information</h2>
<p>Through the Platform, we may collect your usage data. This usage date will be used by Back Office to determine how you use our Platform and how we can improve or modify our Platform. The information collected may be used and disseminated per our Privacy Policy.</p>

<h2>17. Intellectual Property</h2>
<p>The name “Back Office”, the design of the Back Office Platform along with the Back Office created text, writings, images, templates, scripts, graphics, interactive features, and any trademarks or logos contained therein ("Marks"), are owned by or licensed to Back Office, subject to copyright and other intellectual property rights under US and foreign laws and international conventions. Back Office reserves all rights not expressly granted in and to the Platform. You agree to not engage in the use, copying, or distribution of anything contained within the Platform unless we have given express written permission.</p>

<h2>18. Idea Submission</h2>
<p>Back Office or any of its employees do not accept or consider unsolicited ideas, including but not limited to ideas relating to processes, technologies, product enhancements, or product names. Please do not submit any unsolicited ideas, content, suggestions, or other works (“Submissions”) in any form to Back Office. The sole purpose of this policy is to avoid potential misunderstandings or disputes when Back Office’s products might seem similar to ideas you submitted to Back Office. If, despite our request that you not send us your ideas, you agree to the following: (1) your Submissions and their contents will automatically become the property of Back Office without any compensation to you; (2) Back Office may use or redistribute the Submissions and their contents for any purpose and in any way; (3) there is no obligation for Back Office to review the Submission; and (4) there is no obligation to keep any Submissions confidential.</p>

<h2>19. Electronic Communications</h2>
<p>The communications between you and Back Office use electronic means, whether you visit the Platform or send Back Office emails, or whether the Back Office posts notices on the Platform or communications with you via mobile notifications or e-mail. For contractual purposes, you (1) consent to receive communications from Back Office in an electronic form; and (2) agree that all terms, conditions, agreements, notices, disclosures, and other communications that Back Office provides to you electronically satisfy any legal requirement that such communications would satisfy if it were to be in writing. The foregoing does not affect your statutory rights.</p>

<h2>20. Relationship of Parties</h2>
<p>The User agrees that Back Office is acting as an independent contractor in performing any services contemplated under this Agreement and that the relationship between the Back Office and User or Partner shall not constitute a partnership, joint venture or agency. Neither Back Office nor any of Back Office’s employees or agents (1) is an employee, agent or legal representative of the User, or (2) shall have any authority to represent the User or to enter into any contracts or assume any liabilities on behalf of the User. Neither the Partner nor User (1) is an employee, agent or legal representative of Back Office, or (2) shall have any authority to represent Back Office or to enter into any contracts or assume any liabilities on behalf of Back Office.</p>
<p>________________________________________</p><br></br>
<h1>C. WARRANTY DECLARATION AND LIMITATION OF LIABILITY</h1>
<h2>21. Disclaimer</h2>
<p>DUE TO THE NATURE OF INTERNET AVAILABILITY AND ACCESSIBILITY, BACK OFFICE CANNOT GUARANTEE THAT THERE WILL BE NO DOWNTIME OR OTHER INTERRUPTIONS IN SERVICE REGARDING THE PLATFORM OR ANY BACK OFFICE SOFTWARE. BACK OFFICE DOES NOT REPRESENT OR WARRANT THAT THE PLATFORM WILL BE ERROR-FREE OR UNINTERRUPTED; THAT DEFECTS WILL BE CORRECTED; OR THAT THE PLATFORM OR THE SERVER THAT MAKES THE PLATFORM AVAILABLE IS FREE FROM ANY HARMFUL COMPONENTS. WITHOUT LIMITING THE ABOVE, THE PLATFORM, BACK OFFICE SOFTWARE AND ANY OTHER MATERIALS PROVIDED TO THE USER ARE PROVIDED "AS IS," WITHOUT ANY WARRANTY OF ANY KIND, AND BACK OFFICE MAKES NO WARRANTIES, EXPRESS OR IMPLIED, BY OPERATION OF LAW OR OTHERWISE, AND EXPRESSLY DISCLAIM ALL WARRANTIES, EXPRESS, IMPLIED OR STATUTORY, INCLUDING BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, TITLE, CUSTOM, TRADE, QUIET ENJOYMENT, SYSTEM INTEGRATION AND FREEDOM FROM COMPUTER VIRUS. ADDITIONALLY, BACK OFFICE DOES NOT REPRESENT OR WARRANT (1) THAT THERE ARE NO VIRUSES OR OTHER HARMFUL COMPONENTS ON THE PLATFORM OR ANY DOWNLOADABLE SOFTWARE, (2) THAT A PARTY'S SECURITY METHODS EMPLOYED WILL BE SUFFICIENT IN ALL CIRCUMSTANCES OR IN THE FACE OF ALL ATTACKS, OR (3) ANY INFORMATION WILL BE CORRECT, COMPLETE, OR ACCURATE. SOME JURISDICTIONS LIMIT THE ABILITY TO DISCLAIM ALL WARRANTIES, SO THIS CLAUSE OR SOME PORTIONS OF THIS CLAUSE MAY NOT APPLY TO YOU.</p>

<h2>22. Limitation of Liability</h2>
<p>IN NO EVENT SHALL BACK OFFICE, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS, BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES, WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT THE COMPANY IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, RESULTING FROM (1) YOUR USE OR INABILITY TO USE THE PLATFORM OR ANY ERRORS, MISTAKES, OR INACCURACIES FOUND WITHIN THE PLATFORM, (2) ANY PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR PLATFORM, (3) ANY INTERRUPTION, MISINFORMATION, INCOMPLETE INFORMATION, OR CESSATION OF TRANSMISSION TO OR FROM OUR PLATFORM TO YOU, (4) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH OUR PLATFORM VIA A THIRD PARTY, (5) ANY FAILURES OR DISRUPTIONS WHETHER INTENTIONAL OR UNINTENTIONAL, (6) ANY ACTION TAKEN IN CONNECTION WITH COPYRIGHT OR OTHER INTELLECTUAL PROPERTY OWNERS OR (7) Lost profits. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION. SOME STATES DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OF CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU AND YOU MAY ALSO HAVE OTHER LEGAL RIGHTS THAT VARY FROM STATE TO STATE. Specifically, in those jurisdictions not allowed, we do not disclaim liability for: (1) death or personal injury caused by Back Office’s negligence or that of any of its officers, employees or agents; (2) fraudulent misrepresentation; or (3) any liability which it is not lawful to exclude either now or IN THE FUTURE. WHERE A TOTAL DISCLAIMER OF LIABILITY IS DISALLOWED, YOU AGREE THAT OUR TOTAL LIABILITY TO YOU SHALL NOT EXCEED THE AMOUNTS YOU HAVE PAID TO USE OUR PLATFORM.</p>

<h2>23. Termination</h2>
<p>You may cancel and terminate your subscription by contacting your shop management system to terminate your account. Partners may contact us at www.support@accountinglink.net or via their electronic form to submit the cancelation. Please be aware that upon termination, access to our Platform may become immediately disabled. Upon termination, you will not be entitled to any refunds or proration of any subscription fees paid except as stated in this Agreement. We may terminate your subscription if we determine that: (1) you have violated any applicable laws while using our Platform; (2) If you have violated this Agreement or any other of our agreements pertaining to your use of our Platform; or (3) if we believe that any of your actions may harm Back Office, at our sole decision or discretion. In the event of termination, we will strive to provide you with a timely explanation; however, we are not required to do so.</p>

<h2>24. Indemnity</h2>
<p>You agree to defend, indemnify and hold harmless Back Office, its officers, directors, employees, and agents from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from:</p>
<ul>
  <li>your use of and access to the Back Office Platform;</li>
  <li>your violation of any term of this Agreement; or</li>
  <li>your violation of any third-party right, including without limitation any copyright, property, or privacy right.</li>
</ul>
<p>This defense and indemnification obligation will survive this Agreement and your use of the Back Office Platform. You also agree that you have a duty to defend us against such claims, and we may require you to pay for an attorney(s) of our choice in such cases. You agree that this indemnity extends to requiring you to pay for our reasonable attorneys’ fees, court costs, and disbursements. In the event of a claim such as the one described in this paragraph, we may elect to settle with the party/parties making the claim, and you shall be liable for the damages as though we had proceeded with a trial.</p>
<h2>25. Copyrights</h2>
<p>We take copyright infringement very seriously. If you believe that any content owned by you has been infringed upon please send us a message which contains:</p>
<ul>
  <li>Your name.</li>
  <li>The name of the party whose copyright has been infringed if different from your name.</li>
  <li>The name and description of the work that is being infringed.</li>
  <li>The location on our Platform of the infringing copy.</li>
  <li>A statement that you have a good faith belief that the use of the copyrighted work described above is not authorized by the copyright owner (or by a third party who is legally entitled to do so on behalf of the copyright owner) and is not otherwise permitted by law.</li>
  <li>A statement that you swear, under penalty of perjury, that the information contained in this notification is accurate and that you are the copyright owner or have an exclusive right in law to bring infringement proceedings with respect to its use.</li>
</ul>
<p>You must sign this notification and send it to our Copyright Agent: Copyright Agent of Back Office, support@accountinglink.net.</p>
<p>________________________________________</p><br></br>
<h1>D. GOVERNING LAW</h1>
<h2>26. Choice of Law</h2>
<p>This Agreement shall be governed by the laws in force in the state of Iowa. The offer and acceptance of this contract are deemed to have occurred in the state of Iowa.</p>

<h2>27. Disputes</h2>
<p>Any dispute relating in any way to your visit to the Platform or our Platform shall be submitted to confidential arbitration in Des Moines, Iowa. Arbitration under this Agreement shall be conducted pursuant to the applicable rules (“Rules”) then prevailing at the American Arbitration Association. The arbitration shall be conducted by one (1) arbitrator as selected pursuant to the Rules; the arbitrator's award shall be final and binding and may be entered as a judgment in any court of competent jurisdiction. Each party shall be responsible for its own arbitration fees and costs. To the fullest extent permitted by applicable law, no arbitration under this Agreement shall be joined to an arbitration involving any other party subject to this Agreement, whether through class action proceedings or otherwise. Where allowed by the Rules, Back Office may make any and all appearances telephonically. You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of, related to or connected with the use of the Platform or this Agreement must be filed within one (1) year after such claim or cause of action arose or be forever banned. In the event that the law does not permit the abovementioned dispute to be resolved through arbitration or if this arbitration agreement is unenforceable, you agree that any actions and disputes shall be brought solely in a court of competent jurisdiction located within Polk County, IA.</p>

<h2>28. Severability</h2>
<p>In the event that a provision of this Agreement is found to be unlawful, conflicting with another provision of the Agreement, or otherwise unenforceable, the Agreement will remain in force as though it had been entered into without that unenforceable provision being included in it.</p>
<p>If two or more provisions of this Agreement or any other agreement you may have with Back Office are deemed to conflict with each other’s operation, Back Office shall have the sole right to elect which provision remains in force.</p>
<h2>29. Non-Waiver</h2>
<p>We reserve all rights permitted to us under this Agreement as well as under the provisions of any applicable law. Our non-enforcement of any particular provision or provisions of this Agreement or any applicable law should not be construed as our waiver of the right to enforce that same provision under the same or different circumstances at any time in the future.</p>

<h2>30. California Users</h2>
<p>Pursuant to California Civil Code Section 1789.3, any questions about pricing, complaints, or inquiries about Back Office must be sent to our agent for notice to: support@accountinglink.net.</p>
<p>Lastly, California Users are also entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210.</p>
<p>________________________________________</p><br></br>
<h1>E. GENERAL</h1>
<h2>31. Assignment and Survival</h2>
<p>You may not assign your rights and/or obligations under this Agreement to any other party without our prior written consent. We may assign our rights and/or obligations under this Agreement to any other party at our discretion. All portions of this Agreement that would reasonably be believed to survive termination of this Agreement shall survive and remain in full force upon termination, including but not limited to the Limitation of Liabilities, Disclaimers, Licensing, Indemnification, and Arbitration sections.
</p>
<h2>32. Entire Agreement</h2>
<p>This Agreement, along with the Privacy Policy and any other supporting agreements provided by Back Office, constitutes the complete and exclusive understanding and agreement between the parties regarding the subject matter herein and supersede all prior or contemporaneous agreements or understandings, written or oral, relating to its subject matter. Any waiver, modification, or amendment of any provision of this Agreement will be effective only if in writing and signed by a duly authorized representative of each party.</p>

<h2>33. Amendments</h2>
<p>We may amend this Agreement from time to time. When we amend this Agreement, we will update this page and indicate the date that it was last modified, or we may email you. You may refuse to agree to the amendments, but if you do, you must immediately cease using our Platform.</p>

<p>You may refuse to agree to the amendments, but if you do, you must immediately cease using our Platform.</p>
        </Card>
      </div>
    </div>
  );
};

export default LoginTermsOfServicePage;
