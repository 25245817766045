import axios, { AxiosResponse } from 'axios';
import LocationDTO from 'src/models/generated/LocationDTO';
import { AxiosUtil } from 'src/utils';

class LocationController {
  private prefix: string = "accounts"; // Dev Note: This is intentional. Endpoints DO start with this rather than the controller name

  // API_18a
  getLocation(accountId: string, locationId: string): Promise<AxiosResponse<LocationDTO[]>> {
    let route = `${this.prefix}/${accountId}/locations`;
    let params: Record<string, string> = {
      locationid: locationId
    }
    return AxiosUtil.get<LocationDTO[]>(route, items => items.map(LocationDTO.create), { params });
  }

  // API_18b
  getLocations(accountId: string): Promise<AxiosResponse<LocationDTO[]>> {
    let route = `${this.prefix}/${accountId}/locations`;
    return AxiosUtil.get<LocationDTO[]>(route, items => items.map(LocationDTO.create));
  }

  // API_19
  saveLocation(accountId: string, locationId: string, data: LocationDTO): Promise<AxiosResponse<LocationDTO>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}`;
    return AxiosUtil.post<LocationDTO, LocationDTO>(route, data, LocationDTO.create, { withObjectSplit: true });
  }
}

export default new LocationController();
