interface AuditTrailItemViewColumnDTO {
  key: string;
  label: string;
  /** LEFT / MIDDLE / RIGHT */
  justify: string;
}
const AuditTrailItemViewColumnDTO = {
  create: (initValues?: Partial<AuditTrailItemViewColumnDTO>): AuditTrailItemViewColumnDTO => {
    const defaults: AuditTrailItemViewColumnDTO = {
      key: '',
      label: '',
      justify: '',
    };
    return { ...defaults, ...initValues };
  },
};

export default AuditTrailItemViewColumnDTO;
