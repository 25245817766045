import { ArrayUtil, ObjectUtil } from 'src/utils';
import SelectionOptionDTO from './SelectionOptionDTO';

const MappingTypeValues = ['Transaction', 'List'] as const;
const ProcessTypeDisplayTypeValues = ['Label', 'TextBox', 'OnOff', 'DatePicker', 'ComboBox'] as const;
const ProcessTypeFormatTypeValues = ['String', 'Date', 'Boolean', 'DateTime', 'Number'] as const;
export type MappingTypeType = (typeof MappingTypeValues )[number]; // Changed to MappingTypeType as TypeType sounded dumb
export type ProcessTypeDisplayTypeType = (typeof ProcessTypeDisplayTypeValues )[number];
export type ProcessTypeFormatTypeType = (typeof ProcessTypeFormatTypeValues )[number];

interface MappingDTO {
  key: string;
  type: MappingTypeType | null;
  displayName: string;
  displayInfo: string;
  displayDescription: string;
  displayOrder: number;

  process: boolean;
  processType: string;
  processTypeDisplayType: ProcessTypeDisplayTypeType | null;
  processTypeDisplayInfo: string;
  processTypeDisplayWarning: string;
  processTypeFormatType: ProcessTypeFormatTypeType | null;
  processTypeOptionCollection: SelectionOptionDTO[];
  complete: boolean;
  statusMessage: string;

  // Update value indicators
  process_IsUpdate: boolean;
  processType_IsUpdate: boolean;
}
const MappingDTO = {
  create: (initValues?: Partial<MappingDTO>): MappingDTO => {
    const defaults: MappingDTO = {
      key: '',
      type: null,
      displayName: '',
      displayInfo: '',
      displayDescription: '',
      displayOrder: 0,
      process: false,
      processType: '',
      processTypeDisplayType: null,
      processTypeDisplayInfo: '',
      processTypeDisplayWarning: '',
      processTypeFormatType: null,
      processTypeOptionCollection: [],
      complete: false,
      statusMessage: '',
      process_IsUpdate: false,
      processType_IsUpdate: false,
    };
    const fixedInitValues: Partial<MappingDTO> = initValues != null
      ? {
        type: ArrayUtil.PickFromList(initValues.type, MappingTypeValues),
        processTypeDisplayType: ArrayUtil.PickFromList(initValues.processTypeDisplayType, ProcessTypeDisplayTypeValues),
        processTypeFormatType: ArrayUtil.PickFromList(initValues.processTypeFormatType, ProcessTypeFormatTypeValues),
        processTypeOptionCollection: ArrayUtil.EasyMap(initValues.processTypeOptionCollection, SelectionOptionDTO.create),
        complete: ObjectUtil.ToBoolean(initValues.complete),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default MappingDTO;
