import { ArrayUtil } from 'src/utils';

const DisplayValueFormatTypeValues = ['String', 'Number', 'Boolean', 'Date', 'DateTime'] as const;
export type DisplayValueFormatTypeType = (typeof DisplayValueFormatTypeValues)[number];

interface AuditTrailItemViewHeaderAttributeDTO {
  order: number;
  column: number;
  displayLabel: string;
  displayValue: string;
  displayValueFormatType: DisplayValueFormatTypeType;
}
const AuditTrailItemViewHeaderAttributeDTO = {
  create: (initValues?: Partial<AuditTrailItemViewHeaderAttributeDTO>): AuditTrailItemViewHeaderAttributeDTO => {
    const defaults: AuditTrailItemViewHeaderAttributeDTO = {
      order: 0,
      column: 0,
      displayLabel: '',
      displayValue: '',
      displayValueFormatType: 'String',
    };
    const fixedInitValues: Partial<AuditTrailItemViewHeaderAttributeDTO> = initValues != null
      ? {
        displayValueFormatType: ArrayUtil.PickFromList(initValues.displayValueFormatType, DisplayValueFormatTypeValues, 'String'),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default AuditTrailItemViewHeaderAttributeDTO;
