// I'd like a way to create better columns, so going to try a fluent API type thingy
import React, { ReactElement, useRef } from 'react';
import moment from 'moment';
import { Button, Checkbox, CheckboxOptionType, DatePicker, Input, InputRef, Menu, Space } from 'antd';
import { FilterTwoTone, SearchOutlined } from '@ant-design/icons';
import { ColumnProps } from 'antd/lib/table';
import { FilterDropdownProps, FilterValue } from 'antd/lib/table/interface';
import TableExpressions, { BooleanRenderType, MyTypographyProps } from './TableExpressions';
import StringUtil from './StringUtil';
import './TableColumnBuilder.less';

type VerticalAlignType = 'baseline' | 'sub' | 'super' | 'text-top' | 'text-bottom' | 'middle' | 'top' | 'bottom';
type RenderType = 'ShortDate' | 'LongDate' | 'LongDateWithTimeZone' | 'Currency' | 'Typography' | 'Ellipses' | 'Text' | 'Boolean' | 'Custom';

class TableColumnBuilder<T> {
  private internalOptions: ColumnProps<T> = {};
  private mode: 'Standard' | 'Custom' = 'Standard';
  private key?: keyof T;
  private friendlyName?: string;
  private sorter?: 'Text' | 'Number' | 'Boolean' | 'Date';
  private renderer?: RenderType;
  /** Can be either an array of booleans for currency and date or a function for Ellipses */
  private renderOptions: any[] = [];
  private filterer?: 'Text' | 'Date' | 'Enum' | 'Currency';
  private filterCompareType?: 'Text' | 'Exact Text' | 'Date' | 'Currency';
  private enumValues?: ColumnProps<T>['filters'];
  private verticalAlign?: VerticalAlignType;
  private filteredValue?: Record<string, FilterValue | null>;

  /**
   * A hands-off version of the standard `Create` method. You **must** set key or dataIndex manually
   * @returns An instantiated `TableColumnBuilder` object
   */
  static Create<T>(): TableColumnBuilder<T>;
  /**
   * Creates a new `TableColumnBuilder` returning itself to be used in a fluent manner
   * @param keyOrDataIndex A key or dataIndex for a given record `T`
   * @param friendlyName A name that is used wherever a title would be useful, such as the filter dropdown. Also used as the `Title` if one is not provided
   * @returns An instantiated `TableColumnBuilder` object
   */
  static Create<T>(keyOrDataIndex: keyof T): TableColumnBuilder<T>;
  static Create<T>(keyOrDataIndex: keyof T, friendlyName?: string): TableColumnBuilder<T>;
  static Create<T>(keyOrDataIndex?: keyof T, friendlyName?: string): TableColumnBuilder<T> {
    const me = new TableColumnBuilder<T>();
    if (keyOrDataIndex == null) {
      me.mode = 'Custom';
      return me;
    }
    me.key = keyOrDataIndex;
    me.friendlyName = friendlyName;
    me.internalOptions.title = friendlyName;
    return me;
  }

  /**
   * Custom version of the standard `Create` method without keyof checks. Useful when creating button columns. Otherwise, functions identically to `Create`
   * @param keyOrDataIndex A key or dataIndex for a given record `T`
   * @param friendlyName Optional: A name that is used wherever a title would be useful, such as the filter dropdown. Also used as the `Title` if one is not provided
   * @returns An instantiated `TableColumnBuilder` object
   */
  static CreateCustom<T>(key: string, friendlyName?: string) {
    const me = new TableColumnBuilder<T>();
    me.key = key as keyof T; // Kind of hacky but its just for key, which is only 'keyof T' for type convenience
    me.friendlyName = friendlyName;
    me.internalOptions.title = friendlyName;
    return me;
  }

  public Build(): ColumnProps<T> {
    // Sanity check
    if (this.key == null) {
      throw new Error('Key or DataIndex must be provided');
    }

    let options = { ...this.internalOptions };
    const friendlyName = this.GetFriendlyName();

    // Add various options
    options.key = this.key as string;
    options.dataIndex = this.key as string;

    // Add special classes
    if (this.verticalAlign != null) {
      // TODO: Clean this up
      options.className = `${!StringUtil.IsNullOrEmpty(options.className) ? options.className : ''} vertical-${this.verticalAlign}`;
    }

    // Add sorter
    switch (this.sorter) {
      case 'Text':
        options.sorter = TableExpressions.Sorters.StringSorter<T>(this.key);
        break;
      case 'Number':
        options.sorter = TableExpressions.Sorters.NumberSorter<T>(this.key);
        break;
      case 'Boolean':
        options.sorter = TableExpressions.Sorters.BooleanSorter<T>(this.key);
        break;
      case 'Date':
        options.sorter = TableExpressions.Sorters.DateSorter<T>(this.key);
        break;
      default:
        break;
    }

    // Add renders
    switch (this.renderer) {
      case 'ShortDate':
        options.render = TableExpressions.Renderers.ShortDate(this.key, ...this.renderOptions);
        break;
      case 'LongDate':
        options.render = TableExpressions.Renderers.LongDate(this.key);
        break;
      case 'LongDateWithTimeZone':
        options.render = TableExpressions.Renderers.LongDateTimeWithTimeZone(this.key);
        break;
      case 'Currency':
        options.render = TableExpressions.Renderers.Currency(this.key, ...this.renderOptions);
        break;
      case 'Ellipses':
        options.render = TableExpressions.Renderers.Ellipses(this.key, ...this.renderOptions);
        break;
      case 'Typography':
        options.render = TableExpressions.Renderers.Typography(this.key, this.renderOptions[0], this.renderOptions[1]);
        break;
      case 'Text':
        options.render = TableExpressions.Renderers.General(this.key);
        break;
      case 'Boolean':
        options.render = TableExpressions.Renderers.Boolean(this.key, this.renderOptions[0], this.renderOptions[1]);
        break;
      case 'Custom':
        // Custom should already have the renderer set
        break;
      default:
        options.render = TableExpressions.Renderers.General(this.key);
        break;
    }

    // Add filterers
    options.filterIcon = (filtered: any) => <FilterTwoTone style={{ fontSize: 16 }} twoToneColor={filtered ? '#1890ff' : '#bfbfbf'} />;
    switch (this.filterer) {
      case 'Currency':
      case 'Text': {
        const searchRef = useRef<InputRef>(null);
        options.filterDropdown = this.TextFilterDropdown(searchRef, friendlyName);
        options.onFilterDropdownOpenChange = (open: any) => {
          if (open) {
            setTimeout(() => searchRef.current?.select(), 100);
          }
        };
        break;
      }
      case 'Date': {
        const datePickerRef = useRef<null>(null);
        options.filterDropdown = this.DateFilterDropdown(datePickerRef, friendlyName);
        options.onFilterDropdownOpenChange = (open: any) => {
          if (open) {
            // Did I mention that types are hard?
            setTimeout(() => (datePickerRef.current as any).focus(), 100);
          }
        };
        break;
      }
      case 'Enum': {
        // options.filters = this.enumValues;
        const enumValues = this.enumValues?.map<CheckboxOptionType>(x => ({ label: x.text, value: x.value })) ?? [];
        options.filterDropdown = this.EnumFilterDropdown(enumValues);
        break;
      }
      default:
        break;
    }

    // Add onFilter event
    // This is the filter function and can be handled several different ways
    switch (this.filterCompareType) {
      case 'Text':
        options.onFilter = (value: string | number | boolean, record: T) => {
          const valueString = String(value || '').toLowerCase();
          const recordString = String(record[this.key!] || '').toLowerCase();

          return recordString.indexOf(valueString) > -1;
        };
        break;
      case 'Exact Text':
        options.onFilter = (value: string | number | boolean, record: T) => {
          const valueString = String(value || '').toLowerCase();
          const recordString = String(record[this.key!] || '').toLowerCase();

          return recordString.indexOf(valueString) === 0;
        };
        break;
      case 'Currency':
        options.onFilter = (value: string | number | boolean, record: T) => {
          // Really stretching this out. Business logic creeping into my framework code...
          // const valueCurrency = TableExpressions.Renderers.Currency('value', ...this.renderOptions)('', { value });
          let recordCurrency = TableExpressions.Renderers.Currency(this.key!, ...this.renderOptions)('', record);

          // HARDCORE BUSINESS LOGIC DECISION
          // Remove the commas as we need to search around them
          recordCurrency = recordCurrency.replaceAll(',', '');

          return recordCurrency.indexOf(String(value)) > -1;
        };
        break;
      case 'Date':
        options.onFilter = (value: string | number | boolean, record: T) => {
          // Types are hard
          const valueDate = moment(value as string).local();
          const recordDate = moment(record[this.key!] as string).local();

          // Sanity checks
          if (!valueDate.isValid()) return true;
          if (!recordDate.isValid()) return false;

          // Within a day means we are good
          return recordDate.isSame(valueDate, 'day');
        };
        break;
      default:
        break;
    }

    // Add controlled Filter option
    if (this.filteredValue != null) {
      // If the value even exists, we are in controlled mode, so we need to atleast return a null
      options.filteredValue = this.filteredValue[this.key as string] != null ? this.filteredValue[this.key as string] : null;
    }

    return options;
  }

  /* Standard Elements */
  public Title(value: ColumnProps<T>['title'] | undefined): this {
    this.internalOptions.title = value;
    return this;
  }

  public FriendlyName(value: string): this {
    this.friendlyName = value;
    return this;
  }

  public ColSpan(value: ColumnProps<T>['colSpan']): this {
    this.internalOptions.colSpan = value;
    return this;
  }

  public RowSpan(value: ColumnProps<T>['rowSpan']): this {
    this.internalOptions.rowSpan = value;
    return this;
  }

  public Width(value: ColumnProps<T>['width']): this {
    this.internalOptions.width = value;
    return this;
  }

  public Align(value: ColumnProps<T>['align']): this {
    this.internalOptions.align = value;
    return this;
  }

  public VerticalAlign(value: VerticalAlignType): this {
    this.verticalAlign = value;
    return this;
  }

  public ClassName(value: ColumnProps<T>['className'] | ColumnProps<T>['className'][]): this {
    // I thought that allowing arrays would be useful without too much extra effort
    if (Array.isArray(value)) {
      this.internalOptions.className = value.filter(x => StringUtil.IsNullOrEmpty(x)).reduce((a, b) => (`${a} ${b}`), '');
    } else {
      this.internalOptions.className = value != null ? value : undefined;
    }
    return this;
  }

  public OnCell(value: ColumnProps<T>['onCell']): this {
    this.internalOptions.onCell = value;
    return this;
  }

  public Key(value: ColumnProps<T>['key']): this {
    this.key = value as any;
    return this;
  }

  /**
   * Controlled filter value. State object is accepted here with the .build() function taking care of the rest
   *
   * **Warning:** *Setting this value on any one column will require you to put it on all columns **with a filter**. This is a framework restriction, not mine*
   *
   * **Note:** You will also need to add an onChange event to the table
   *
   *     onChange={(_, filters) => { setFilteredInfo(filters); }}
   * @param value Filter State object. Expected to be `Record<string, FilterValue | null>`
   */
  public FilteredValue(value: Record<string, FilterValue | null>): this {
    this.filteredValue = value;
    return this;
  }

  public DefaultSortOrder(value: ColumnProps<T>['defaultSortOrder']): this {
    this.internalOptions.defaultSortOrder = value;
    return this;
  }

  public DefaultFilteredValue(value: ColumnProps<T>['defaultFilteredValue']): this {
    this.internalOptions.defaultFilteredValue = value;
    return this;
  }

  /* Less standard functions */
  public AddSorter(sorterType: 'Text' | 'Number' | 'Boolean' | 'Date'): this {
    this.sorter = sorterType;
    return this;
  }

  public AddRenderer(renderType: 'Currency', removeCurrencySymbol?: boolean, renderBlankIfZero?: boolean, renderSimpleZero?: boolean): this;
  public AddRenderer(renderType: 'ShortDate', renderOldOrEmptyDates?: boolean): this;
  public AddRenderer(renderType: 'Ellipses', valueOverride?: (record: T) => React.ReactNode): this;
  public AddRenderer(renderType: 'Typography', props: MyTypographyProps, valueOverride?: (record: T) => React.ReactNode): this;
  public AddRenderer(renderType: 'Boolean', booleanType?: BooleanRenderType, hideFalseValues?: boolean): this;
  public AddRenderer(renderType: 'Custom', renderFunc: ColumnProps<T>['render']): this;
  public AddRenderer(renderType: Exclude<RenderType, 'Currency' | 'Typography'>): this;
  public AddRenderer(renderType: RenderType, ...renderOptions: (boolean | ColumnProps<T>['render'] | MyTypographyProps | BooleanRenderType)[]): this {
    this.renderer = renderType;

    // Special cases, my favorite
    if (renderType === 'Custom' && renderOptions.length === 1 && typeof renderOptions[0] === 'function') {
      this.internalOptions.render = renderOptions[0];
      return this;
    }

    // These show up for Currency and ShortDate
    this.renderOptions = renderOptions as boolean[];
    return this;
  }

  /* Special things */
  public AddCurrencyFilterer(): this {
    this.filterer = 'Currency';
    this.filterCompareType = 'Currency';
    return this;
  }

  public AddTextFilterer(): this {
    this.filterer = 'Text';
    this.filterCompareType = 'Text';
    return this;
  }

  public AddExactTextFilterer(): this {
    this.filterer = 'Text';
    this.filterCompareType = 'Exact Text';
    return this;
  }

  public AddDateFilterer(): this {
    this.filterer = 'Date';
    this.filterCompareType = 'Date';
    return this;
  }

  /** A simplified version that allows an array of strings. Distinct will be applied automatically and the results rendered as expected */
  public AddEnumFilterer(enumValues: string[]): this;
  public AddEnumFilterer(enumValues: string[], comparisonType: 'Text' | 'Exact Text' | 'Date'): this;
  public AddEnumFilterer(enumValues: ColumnProps<T>['filters']): this;
  public AddEnumFilterer(enumValues: ColumnProps<T>['filters'], comparisonType: 'Text' | 'Exact Text' | 'Date'): this;
  public AddEnumFilterer(enumValues: ColumnProps<T>['filters'] | string[], comparisonType: 'Text' | 'Exact Text' | 'Date' = 'Text'): this {
    // Do some work on the enum values to check what version we got sent in
    // if (!Array.isArray(enumValues) || enumValues.length == null || enumValues.length < 1) {
    if (!Array.isArray(enumValues)) {
      throw new TypeError('EnumValues must be an array!');
    }

    if (typeof enumValues[0] === 'string') {
      // enumValues are an array of strings. Get distinct values and convert to something we can work with
      const newValues = [...new Set(enumValues as string[])];
      this.enumValues = newValues.map(x => ({ text: x, value: x }));
    } else {
      // We assume they sent in the proper array, because we use Typescript
      this.enumValues = enumValues as ColumnProps<T>['filters'];
    }

    // Okay, we can set the filter type now
    this.filterer = 'Enum';
    this.filterCompareType = comparisonType;
    return this;
  }

  /* Internal Methods */
  private GetFriendlyName(potentialValue?: string): string {
    return potentialValue
      || this.friendlyName
      || (['string', 'number'].includes(typeof this.internalOptions.title) ? String(this.internalOptions.title) : '')
      || StringUtil.ToTitleCase(this.key as string);
  }

  private TextFilterDropdown(searchRef: React.RefObject<InputRef>, placeholder: string): ColumnProps<T>['filterDropdown'] {
    return ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => {
      return <Space size='middle' direction='vertical' style={{ padding: 12 }}>
        <Input
          allowClear
          ref={searchRef}
          placeholder={`Search ${placeholder}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={e => {
            // I think you need both prevent and stop to avoid accidentally triggering the sort method
            e.preventDefault();
            e.stopPropagation();
            confirm();
          }}
        />
        <Space className='with-equal-grid-container'>
          <Button
            block
            type="primary"
            icon={<SearchOutlined />}
            onClick={() => confirm()}
          >
            Search
          </Button>
          <Button
            block
            onClick={() => { clearFilters(); confirm(); }}
          >
            Reset
          </Button>
        </Space>
      </Space>;
    };
  }

  private DateFilterDropdown(searchRef: React.MutableRefObject<null>, placeholder: string): ColumnProps<T>['filterDropdown'] {
    return ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => {
      return <Space size='middle' direction='vertical' style={{ padding: 12 }}>
        <DatePicker
          ref={searchRef}
          placeholder={`Search ${placeholder}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e != null ? [e] : [])}
          onKeyDown={e => {
            if (e.key !== 'Enter') {
              return;
            }
            e.preventDefault();
            e.stopPropagation();
            confirm();
          }}
        />
        <Space className='with-equal-grid-container'>
          <Button
            block
            type="primary"
            icon={<SearchOutlined />}
            onClick={() => confirm()}
          >
            Search
          </Button>
          <Button
            block
            onClick={() => { clearFilters(); confirm(); }}
          >
            Reset
          </Button>
        </Space>
      </Space>;
    };
  }

  private EnumFilterDropdown(enumValues: CheckboxOptionType[]): ColumnProps<T>['filterDropdown'] {
    // Dev Note: Using <Menu> as it is what AntD does for their filterer. They use like 90 more properties, but this should be fine for our purposes
    return ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => {
      return <Space size={4} direction='vertical' style={{ padding: 12, paddingTop: 4 }}>
        <Menu
          selectable
          multiple
          onSelect={x => (setSelectedKeys(x.selectedKeys))}
          onDeselect={x => (setSelectedKeys(x.selectedKeys))}
          selectedKeys={selectedKeys as string[]}
          items={enumValues.map((value, index) => {
            return {
              key: value.value as React.Key,
              label: (
                <>
                  <Checkbox checked={selectedKeys.includes(value.value as React.Key)} />
                  <span>{value.label}</span>
                </>
              )
            };
          })}
        />
        <Space className='with-equal-grid-container'>
          <Button
            block
            type="primary"
            icon={<SearchOutlined />}
            onClick={() => confirm()}
          >
            Search
          </Button>
          <Button
            block
            onClick={() => { clearFilters?.(); confirm(); }}
          >
            Reset
          </Button>
        </Space>
      </Space>;
    };
  }
}

export default TableColumnBuilder;
