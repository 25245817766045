import { Button, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SelectLocation from 'src/components/SelectLocation';
import RouteConfig from 'src/config/RouteConfig';
import LocationDTO from 'src/models/generated/LocationDTO';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { CacheContext } from 'src/providers/CacheContext';
import { NotificationUtil } from 'src/utils';
import LoginService from './login/LoginService';

const SelectLocationPage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const cacheContext = useContext(CacheContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedLocation, setSelectedLocation] = useState<LocationDTO>();
  const [loading, setLoading] = useState(false);

  const handleOnSelect = async () => {
    setLoading(true);
    // AuthContext sets the returnUrl on app start. We don't need it anymore so clean it up
    await authContext.updateReturnUrl(null);

    await authContext.updateLocation(selectedLocation!);
    setLoading(false);
    NotificationUtil.success({
      key: 'SelectLocationPage',
      message: 'Updated Location'
    });
    navigate(await LoginService.GetRouteWithSetAccountLocation(authContext, cacheContext, location));
  };

  const handleLocationEdit = async (location : LocationDTO) => {
    if(authContext.location?.id == location.id)
    {
      setSelectedLocation(location);
    }
  };

  return (
    <div className='location-table-layout'>
      <h1>Please Select a Location</h1>

      <SelectLocation
        initialValue={authContext.location?.id}
        disabled={loading}
        onChange={setSelectedLocation}
        onSelect={handleOnSelect}
        onEdit={handleLocationEdit}
      />

      <Row justify='end' style={{ marginTop: 16 }}>
        <Button
          type='primary'
          size='large'
          style={{ padding: '0 32px' }}
          disabled={selectedLocation == null}
          loading={loading}
          onClick={handleOnSelect}
        >
          Select
        </Button>
      </Row>
    </div>
  );
};

export default SelectLocationPage;
