import React from 'react';
import { Link, LinkProps, NavLink, useLocation } from 'react-router-dom';
import { StringUtil } from 'src/utils';

interface LinkWithQueryProps {
  /** The location of the Link, used like a normal `<Link>` component */
  to: string;

  /** The state to include for the next page. **Warning: State will not be sent to a new tab/window** */
  state?: any;

  /** Indicates that the component should render a <NavLink> rather than a <Link>. Option normally used for menus */
  asNavLink?: boolean;

  /** Opens the link in a new tab. This is ignored if `target` is set */
  openInNewTab?: boolean;

  target?: string;

  disabled?: boolean;

  /**
   * Additional query parameters for the route.
   *
   * Note: These are unaffected by `includedQueryParams`
   */
  additionalQueryParams?: [string, string | null][];

  /** A list of query params to be included beyond the standard set */
  includedQueryParams?: string[];

  /** Indicates that we should include all query parameters */
  includeAllQueryParams?: boolean;
}

const coreParameters = ['return_url', 'route_id', 'account'];

/**
 * A `<Link>` component wrapper that will include various query parameters automatically.
 * By default, it will only include the set of core parameters which can be expanded with the `extraQueryParams` or `includeAllQueryParams` parameter
 */
const LinkWithQuery: React.FC<React.PropsWithChildren<LinkWithQueryProps>> = (props) => {
  const location = useLocation();
  let to = props.to;

  let urlSearchParams = new URLSearchParams(location.search);
  if (!props.includeAllQueryParams) {
    const includedParams = [...coreParameters, ...(props.includedQueryParams ?? [])];
    const newURLSearchParams = new URLSearchParams();

    // Take all of the parameters we want preserved, check if they exist and add them to the temp object
    includedParams.forEach(x => urlSearchParams.has(x) && newURLSearchParams.set(x, urlSearchParams.get(x)!));

    // Update params to be the reduced list
    urlSearchParams = newURLSearchParams;
  }

  // Add additional query params to he already established list
  if (Array.isArray(props.additionalQueryParams)) {
    props.additionalQueryParams.forEach(([left, right]) => {
      if (right != null) {
        urlSearchParams.set(left, right);
      }
    });
  }

  // Patch to allow for to arguments that have parameters in them
  if (to.includes('?')) {
    const toSearchParams = new URLSearchParams(to.substring(to.indexOf('?')));
    for (const iterator of toSearchParams.entries()) {
      urlSearchParams.set(iterator[0], iterator[1]);
    }
    to = to.substring(0, to.indexOf('?'));
  }

  const linkProps: LinkProps = {
    to: {
      pathname: to,
      search: '?' + urlSearchParams.toString()
    },
    target: !StringUtil.IsNullOrEmpty(props.target) ? props.target : props.openInNewTab ? '_blank' : undefined,
    state: props.state,
  };

  if (props.disabled) {
    return (
      <p className='without-margin'>
        {props.children}
      </p>
    );
  }

  if (props.asNavLink) {
    return (
      <NavLink {...linkProps}>
        {props.children}
      </NavLink>
    );
  }

  return (
    <Link {...linkProps}>
      {props.children}
    </Link>
  );
};

export default LinkWithQuery;