import React from 'react';
import { Button, Space, Spin, Table, Tooltip, Typography } from 'antd';
import classNames from 'classnames';
import { NameOf, TableColumnBuilder, TableExpressions } from 'src/utils';
import { ColumnProps } from 'antd/lib/table';
import TileModel from 'src/models/frontend/TileModel';
import TileTransactionSummaryItemModel from 'src/models/frontend/TileTransactionSummaryItemModel';
import DownloadIconSVG from './svgs/DownloadIconSVG';
import ReloadIconSVG from './svgs/ReloadIconSVG';
import RouteConfig from 'src/config/RouteConfig';
import LinkWithQuery from './LinkWithQuery';
import SimplePopover from './SimplePopover';
import './ActivityTile.less';
import PrintIconSVG from './svgs/PrintIconSVG';

interface ActivityTileProps {
  tileData: TileModel;

  onApprove: (tile: TileModel) => void;
  onRefresh: (tileKey: string) => void;
}

const ActivityTile: React.FC<ActivityTileProps> = (props) => {
  const startEndQueryParams: [string, string][] = [
    ['reportStartDate', props.tileData.displayDate?.format('L')?.replaceAll('/', '-') ?? ''],
    ['reportEndDate', props.tileData.displayDate?.format('L')?.replaceAll('/', '-') ?? ''],
    // maybe have the tile key in here? props.tileData.key
  ];

  const tileHeaderRender = (status: string) => {
    const additionalQueryParams: [string, string][] = [
      ['transactionStatus', status],
      ...startEndQueryParams
    ];

    return <LinkWithQuery to={RouteConfig.REPORTS_TRANSACTIONS()} additionalQueryParams={additionalQueryParams}>
      {status}
    </LinkWithQuery>;
  };

  const tileCurrencyRender = (modelKey: keyof TileTransactionSummaryItemModel, status: string): ColumnProps<TileTransactionSummaryItemModel>['render'] => {
    return (value, record) => {
      const additionalQueryParams: [string, string][] = [
        ['transactionType', record.displayTransactionType],
        ['transactionStatus', status],
        ['reportStartDate', props.tileData.displayDate?.format('L')?.replaceAll('/', '-') ?? ''],
        ['reportEndDate', props.tileData.displayDate?.format('L')?.replaceAll('/', '-') ?? ''],
      ];

      return <LinkWithQuery to={RouteConfig.REPORTS_TRANSACTIONS()} additionalQueryParams={additionalQueryParams}>
        {TableExpressions.Renderers.Currency(modelKey, true, false, false)(value, record)}
      </LinkWithQuery>;
    };
  };

  const tileTransactionTypeRender = (): ColumnProps<TileTransactionSummaryItemModel>['render'] => {
    return (value, record) => {
      const additionalQueryParams: [string, string][] = [
        ['transactionType', record.displayTransactionType],
        ...startEndQueryParams
      ];

      return <LinkWithQuery to={RouteConfig.REPORTS_TRANSACTIONS()} additionalQueryParams={additionalQueryParams}>
        {value}
      </LinkWithQuery>;
    };
  };

  const tableColumns: ColumnProps<TileTransactionSummaryItemModel>[] = [
    TableColumnBuilder.Create<TileTransactionSummaryItemModel>('displayTransactionType', 'Transaction Type')
      .AddRenderer('Custom', tileTransactionTypeRender())
      .Build(),

    TableColumnBuilder.Create<TileTransactionSummaryItemModel>('displayCount', 'Count')
      .Width(65)
      .Align('right')
      .AddRenderer('Text')
      .Build(),

    TableColumnBuilder.Create<TileTransactionSummaryItemModel>('displayUnapproved', 'Unapproved')
      .Title(tileHeaderRender('Unapproved'))
      .Width(110)
      .Align('right')
      .AddRenderer('Custom', tileCurrencyRender('displayUnapproved', 'Unapproved'))
      .Build(),

    TableColumnBuilder.Create<TileTransactionSummaryItemModel>('displayApproved', 'Approved')
      .Title(tileHeaderRender('Approved'))
      .Width(100)
      .Align('right')
      .AddRenderer('Custom', tileCurrencyRender('displayApproved', 'Approved'))
      .Build(),

    TableColumnBuilder.Create<TileTransactionSummaryItemModel>('displayPosted', 'Posted')
      .Title(tileHeaderRender('Posted'))
      .Width(90)
      .Align('right')
      .AddRenderer('Custom', tileCurrencyRender('displayPosted', 'Posted'))
      .Build(),

    TableColumnBuilder.Create<TileTransactionSummaryItemModel>('displayTotal', 'Total')
      .Width(100)
      .ClassName('is-totals-column')
      .Align('right')
      .AddRenderer('Currency', true, false, true)
      .Build(),
  ];

  // Generate the correct className
  const statusClasses = classNames(
    props.tileData.displayStatusId === 'Approved' && 'make-text-dark-grey',
    props.tileData.displayStatusId === 'Posted' && 'make-text-dark-grey',
    props.tileData.displayStatusId === 'Unapproved' && 'make-text-green',
    props.tileData.displayStatusId === 'Warning' && 'make-text-red'
  );

  const tileClasses = classNames(
    'activity-tile',
    props.tileData.displayStatusId === 'Posted' && 'is-posted',

  );
  const ApproveTooltip = 'Approving the day will result in the transactions being Approved and sent to your Accounting System.';

  /// Buttons ///
  // Approved - Nothing
  // Posted - Nothing
  // Unapproved - Show Approve button
  // Warning - Show review button

  return (
    <div className={tileClasses}>
      {/* Left */}
      <div className='item'>
        <h2>{TableExpressions.Renderers.ShortDate('displayDate')('', props.tileData)}</h2>
        <Typography.Text strong className={statusClasses}>{props.tileData.displayStatusMessage}</Typography.Text>
        <SimplePopover
          dangerouslySetHTML
          content={props.tileData.displayStatusInfo}
        />
        <Spin spinning={props.tileData.displayStatusMessage.toLowerCase() === 'waiting to be posted'} style={{marginLeft: 10}}></Spin>
      </div>
      {/* Center */}
      <div className='item'>
        <Table
          className='condensed-table borderless-table'
          rowKey={NameOf<TileModel>('key')}
          size='small'
          pagination={false}
          columns={tableColumns}
          dataSource={props.tileData.transactionSummaryItems}
        />
      </div>
      {/* Right */}
      <div className='item'>
        <Space size={12} direction='vertical' style={{ width: '100%' }}>
          {props.tileData.displayStatusId === 'Approved' &&
            <Tooltip title={'Items have already been approved!'}><Button block disabled type='primary'>Approve</Button></Tooltip>
          }
          {props.tileData.displayStatusId === 'Unapproved' &&
            <Tooltip title={ApproveTooltip} mouseEnterDelay={1}><Button onClick={() => props.onApprove(props.tileData)} block type='primary' className='green'>Approve</Button></Tooltip>
          }
          {props.tileData.displayStatusId === 'Warning' &&
            <LinkWithQuery to={RouteConfig.REPORTS_WARNING()} additionalQueryParams={startEndQueryParams}>
              <Button danger block type='primary'>Review</Button>
            </LinkWithQuery>
          }
          <Space size={8}>
            <LinkWithQuery openInNewTab to={RouteConfig.PRINTING_TILE_REPORT()} additionalQueryParams={startEndQueryParams}>
              <Button type='link' icon={<PrintIconSVG />} />
            </LinkWithQuery>
            <Button type='link' icon={<ReloadIconSVG />} onClick={() => props.onRefresh(props.tileData.key)} />
          </Space>
          <LinkWithQuery to={props.tileData.displayStatusId === 'Warning' ? RouteConfig.REPORTS_WARNING() : RouteConfig.REPORTS_SUMMARY()} additionalQueryParams={startEndQueryParams}>
            View Details
          </LinkWithQuery>
        </Space>
      </div>
    </div>
  );
};

export default ActivityTile;