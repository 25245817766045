import { ArrayUtil } from 'src/utils';

const ValueFormatTypeValues = ['String', 'Number', 'Boolean', 'Date', 'DateTime'] as const;
export type ValueFormatTypeType = (typeof ValueFormatTypeValues)[number];

interface AuditTrailItemViewRowValueDTO {
  columnKey: string;
  value: string;
  valueFormatType: ValueFormatTypeType;
}
const AuditTrailItemViewRowValueDTO = {
  create: (initValues?: Partial<AuditTrailItemViewRowValueDTO>): AuditTrailItemViewRowValueDTO => {
    const defaults: AuditTrailItemViewRowValueDTO = {
      columnKey: '',
      value: '',
      valueFormatType: 'String',
    };
    const fixedInitValues: Partial<AuditTrailItemViewRowValueDTO> = initValues != null
      ? {
        valueFormatType: ArrayUtil.PickFromList(initValues.valueFormatType, ValueFormatTypeValues, 'String'),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default AuditTrailItemViewRowValueDTO;
