import axios, { AxiosResponse } from 'axios';
import AdvancedSettingsDTO from 'src/models/generated/AdvancedSettingsDTO';

class ConfigurationController {
  private prefix: string = "accounts"; // Dev Note: This is intentional. Endpoints DO start with this rather than the controller name

  // API_34a
  async getAdvancedSetting(accountId: string, locationId: string, advanceSettingId: string): Promise<AxiosResponse<AdvancedSettingsDTO[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/mappings/advancedsettings`;
    const result = await axios.get<AdvancedSettingsDTO[]>(route, {
      params: {
        advancesettingid: advanceSettingId
      }
    });
    // Transform the results
    result.data = result.data.map(x => AdvancedSettingsDTO.create(x));
    return result;
  }

  // API_34b
  async getAdvancedSettings(accountId: string, locationId: string): Promise<AxiosResponse<AdvancedSettingsDTO[]>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/mappings/advancedsettings`;
    const result = await axios.get<AdvancedSettingsDTO[]>(route);
    // Transform the results
    result.data = result.data.map(x => AdvancedSettingsDTO.create(x));
    return result;
  }

  // API_35
  saveAdvancedSetting(accountId: string, locationId: string, data: AdvancedSettingsDTO): Promise<AxiosResponse<void>> {
    let route = `${this.prefix}/${accountId}/locations/${locationId}/mappings/advancedsetting`;
    return axios.post(route, data);
  }
}

export default new ConfigurationController();
