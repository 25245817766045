import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Checkbox, Divider, Form, Input } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { CacheContext } from 'src/providers/CacheContext';
import { NameOf, ObjectUtil, NotificationUtil, ErrorUtil } from 'src/utils';
import RouteConfig from 'src/config/RouteConfig';
import LoginService from './LoginService';
import SelectLocation from 'src/components/SelectLocation';
import LocationDTO from 'src/models/generated/LocationDTO';

const LoginLocationSelectionPage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const cacheContext = useContext(CacheContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedLocation, setSelectedLocation] = useState<LocationDTO>();
  const [loading, setLoading] = useState(false);

  // OnLoad
  useEffect(() => {
    // Figure out if we are on the right page (happens on reload)
    ensureConsistentRoute();
  }, []);

  const ensureConsistentRoute = async () => {
    const nextRoute = await LoginService.GetRouteWithSetAccountLocation(authContext, cacheContext, location);
    if (nextRoute !== location.pathname) {
      navigate(nextRoute);
    }
  };

  const handleOnSelect = async () => {
    setLoading(true);
    try {
      await authContext.updateLocation(selectedLocation!);

      // Close any notifications and navigate to the next page
      NotificationUtil.close('LoginLocationSelectionPage');
      navigate(await LoginService.GetRouteWithSetAccountLocation(authContext, cacheContext, location));
    } catch (error) {
      NotificationUtil.error({
        key: 'LoginLocationSelectionPage',
        message: 'Accounts',
        description: 'Error while selecting location',
        error
      });
    }
    setLoading(false);
  };

  const handleLocationEdit = async (location : LocationDTO) => {
    if(authContext.location?.id == location.id || authContext.location == null)
    {
      setSelectedLocation(location);
    }
  };

  return (
    <div className='login-location-selection-page'>
      <h1>Please Select a Location</h1>

      <SelectLocation
        disabled={loading}
        scroll={{ y: 600 }}
        onChange={setSelectedLocation}
        onSelect={handleOnSelect}
        onEdit={handleLocationEdit}
      />
      <br />
      <Button
        type='primary'
        block
        disabled={selectedLocation == null}
        loading={loading}
        onClick={handleOnSelect}
      >
        Select
      </Button>
    </div>
  );
};

export default LoginLocationSelectionPage;
