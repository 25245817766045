import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Checkbox, Divider, Form, Input } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { CacheContext } from 'src/providers/CacheContext';
import { NameOf, ObjectUtil, NotificationUtil, ErrorUtil } from 'src/utils';
import RouteConfig from 'src/config/RouteConfig';
import LoginService from './LoginService';
import { PasswordResetForm, PasswordResetFormData } from 'src/components/modals/passwordResetModal';
import AuthorizationController from 'src/api/AuthorizationController';

const LoginActivatePage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const cacheContext = useContext(CacheContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // OnLoad
  useEffect(() => {
    // Figure out if we are on the right page (happens on reload)
    ensureConsistentRoute();
  }, []);

  const ensureConsistentRoute = async () => {
    const nextRoute = await LoginService.GetRouteWithSetAccountLocation(authContext, cacheContext, location);
    if (nextRoute !== location.pathname) {
      navigate(nextRoute);
    }
  };

  const handleFinish = async (values: PasswordResetFormData) => {
    // Trim the email
    values = ObjectUtil.TrimValues(values, 'confirmationCode', 'newPassword');

    setLoading(true);
    try {
      await authContext.login_activate(values.confirmationCode, values.newPassword);

      // Close the notification and navigate
      NotificationUtil.close('LoginActivatePage');
      navigate(await LoginService.GetRouteWithSetAccountLocation(authContext, cacheContext, location));
    } catch (error) {
      const errorMessage = ErrorUtil.GetMessageFromError(error);
      NotificationUtil.error({
        key: 'LoginActivatePage',
        message: 'Activation',
        description: 'Error while activating account',
        error
      });
    }
    setLoading(false);
  };

  const handleResendCode = async () => {
    try {
      await AuthorizationController.initialActivationRequest();
    } catch (error) {
      NotificationUtil.error({
        key: 'LoginActivatePage',
        message: 'Activation',
        description: 'Error while resending notification. Please refresh your browser and try again.',
        error
      });
    }
  };

  return (
    <div className='login-activate-page login-flow'>
      <h1>Activate Account</h1>
      <p>A verification code has been sent to your email. Please enter the code below to continue</p>
      <PasswordResetForm showConfirmationCode confirmButtonText='Validate' loading={loading} onFinish={handleFinish} onResendCode={handleResendCode} />
    </div>
  );
};

export default LoginActivatePage;
