import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Checkbox, Divider, Form, Input } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { CacheContext } from 'src/providers/CacheContext';
import { NameOf, ObjectUtil, NotificationUtil, ErrorUtil } from 'src/utils';
import RouteConfig from 'src/config/RouteConfig';
import LoginService from './LoginService';
import AuthorizationController from 'src/api/AuthorizationController';
import { PasswordResetForm, PasswordResetFormData } from 'src/components/modals/passwordResetModal';

const LoginForgotPasswordStep2Page: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const cacheContext = useContext(CacheContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // OnLoad
  useEffect(() => {
    // Figure out if we are on the right page (happens on reload)
    ensureConsistentRoute();
  }, []);

  const ensureConsistentRoute = async () => {
    const nextRoute = await LoginService.GetRouteWithSetAccountLocation(authContext, cacheContext, location);
    if (nextRoute !== location.pathname) {
      navigate(nextRoute);
    }
  };

  const handleFinish = async (values: PasswordResetFormData) => {
    // We have the email from the previous page, so we should be able to safely submit a request from here
    setLoading(true);

    try {
      const result = await AuthorizationController.passwordResetResponse(values.confirmationCode, values.newPassword);
      NotificationUtil.close('LoginForgotPasswordStep2Page');
      navigate(RouteConfig.LOGIN_FORGOT_PASSWORD_STEP_3());
    } catch (error) {
      NotificationUtil.error({
        key: 'LoginForgotPasswordStep2Page',
        message: 'Error while sending confirmation email',
        error
      });
    }

    setLoading(false);
  };

  const handleResendCode = async () => {
    try {
      const result = await AuthorizationController.passwordResetRequest();
    } catch (error) {
      NotificationUtil.error({
        key: 'LoginForgotPasswordStep2Page',
        message: 'Error while resending notification. Please refresh your browser and try again.',
        error
      });
    }
  };

  return (
    <div className='login-forgot-password-step-2-page login-flow'>
      <h1>Password Reset</h1>
      <p>Please check your email ({authContext.auth?.emailAddress}) for the confirmation code. This may take up to 5 minutes to arrive</p>

      <PasswordResetForm showConfirmationCode confirmButtonText='Reset Password' loading={loading} onFinish={handleFinish} onResendCode={handleResendCode} />
    </div>
  );
};

export default LoginForgotPasswordStep2Page;
