import React, { useEffect } from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import { useStateAsync } from 'src/hooks';
import { RangePickerProps } from 'antd/lib/date-picker';
import { RangeType } from 'rc-picker/lib/RangePicker'; // What a pain

export interface SimpleRangeSelectorProps {
  // Yeah, I don't have time for this. You get get full values only
  // allowPartialValue?: boolean;

  /** Indicates the number of days that are selectable */
  maxDayRange: number;

  /** Allows the clear value to be sent through the onChange event. This shows up as [null, null] when the user clears the input */
  allowClearValue?: boolean;

  disabled?: boolean;

  // Props pulled from RangePicker
  defaultValue?: RangePickerProps['defaultValue'];
  value?: RangePickerProps['value'];
  onChange?: RangePickerProps['onChange'];
}

const SimpleRangeSelector: React.FC<SimpleRangeSelectorProps> = (props) => {
  const [selectedDateRange, setSelectedDateRange, getSelectedDateRangeAsync] = useStateAsync<[moment.Moment | null, moment.Moment | null] | null>(props.defaultValue ?? null);
  const [tempSelectedDateRange, setTempSelectedDateRange, getTempSelectedDateRange] = useStateAsync<[moment.Moment | null, moment.Moment | null] | null>([null, null]);
  const [isOpen, setIsOpen, getIsOpenAsync] = useStateAsync(false);
  const [currentDirection, setCurrentDirection, getCurrentDirectionAsync] = useStateAsync<RangeType | null>(null);
  const [formatString, setFormatString, getFormatStringAsync] = useStateAsync<[string, string]>(['', '']);

  useEffect(() => {
    if (props.value === undefined) {
      return;
    }
    setSelectedDateRange(props.value);
  }, [props.value]);

  const handleDateRangeSelect = (values: [moment.Moment | null, moment.Moment | null] | null, formatString: [string, string]) => {
    setSelectedDateRange(values);
    setFormatString(formatString);

    // Dev Note: Moved the onChange event to when the calendar is closed
  };

  const handleOpenChange = async (open: boolean) => {
    setIsOpen(open);
    setCurrentDirection(prev => open ? prev : null);

    // Save temp values if we open the datepicker
    if (open) {
      setTempSelectedDateRange(selectedDateRange);
    }

    // When closing, fire onChange
    if (!open) {
      if (props.onChange != null) {
        const selectedDateRangeAsync = await getSelectedDateRangeAsync();
        const formatStringAsync = await getFormatStringAsync();
        const tempSelectedDateRangeAsync = await getTempSelectedDateRange();

        if (selectedDateRangeAsync != null && selectedDateRangeAsync[0] != null && selectedDateRangeAsync[1] != null) {
          if (moment.duration(selectedDateRangeAsync[1].diff(selectedDateRangeAsync[0])).asDays() > props.maxDayRange) {
            // Range not allowed, which happens when the user prematurely closes the picker
            setSelectedDateRange(tempSelectedDateRangeAsync);
            return;
          }
        }
        props.onChange(selectedDateRangeAsync, formatStringAsync);
      }
    }
  };

  return (<DatePicker.RangePicker
    disabled={props.disabled}
    format={['MM/DD/YYYY', 'MM/DD/YY', 'M/D/YYYY', 'M/D/YY', 'MMDDYYYY', 'MMDDYY']}
    defaultPickerValue={selectedDateRange == null ? undefined : [selectedDateRange[0]!, selectedDateRange[1]!]} // Moves the pre selected months to the left, which makes WAY more sense for history
    ranges={{
      'Today': [moment().startOf('day'), moment().endOf('day')],
      'Yesterday': [moment().subtract(1, 'day').startOf('day'), moment().subtract(1, 'day').endOf('day')],
      'Prior Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
      'Month to Date': [moment().startOf('month'), moment()],
      'Last 7': [moment().subtract(6, 'day'), moment()],
      'Last 31': [moment().subtract(30, 'day'), moment()],
    }}
    value={selectedDateRange}
    onChange={handleDateRangeSelect}

    // Custom rendering because we want to change the hoverover date
    dateRender={current => {
      return (
        <div className="ant-picker-cell-inner" style={{ width: '100%' }} title={current.format('L')}>
          {current.date()}
        </div>
      );
    }}


    // Attempting to limit the before and after days without fully blocking out everything
    onCalendarChange={(values, _, info) => {
      setSelectedDateRange(values);
      setCurrentDirection(info.range);
    }}
    onOpenChange={handleOpenChange}
    disabledDate={date => {
      if (currentDirection == null || selectedDateRange == null) {
        return false;
      }

      // We can only select up to 31 days, so keep it in that range. Range selector will automatically remove the negative options
      if (currentDirection === 'start') {
        return moment.duration(date.diff(selectedDateRange[0])).asDays() > props.maxDayRange;
      }

      return moment.duration(selectedDateRange[1]?.diff(date)).asDays() > props.maxDayRange;
    }}

  // The next 3 properties handle capping the selectable range by disabling dates, clearing the range on focus and partially updating when the first option is selected
  // disabledDate={date => {
  //   if (selectedDateRange?.[0] == null) {
  //     return false;
  //   }

  //   // We can only select up to 31 days, so keep it in that range. Range selector will automatically remove the negative options
  //   return moment.duration(date.diff(selectedDateRange[0])).asDays() > props.maxDayRange;
  // }}
  // // Absolutely needed to forcibly update the selected dates
  // onCalendarChange={(values) => setSelectedDateRange(values)}
  // onOpenChange={async (e) => {
  //   const asyncSelectedDateRange = await selectedDateRangeAsync();
  //   if (e) {
  //     setTempSelectedDateRange(asyncSelectedDateRange);
  //     setSelectedDateRange([asyncSelectedDateRange?.[0] ?? null, asyncSelectedDateRange?.[0] ?? null]);
  //   } else {
  //     if (asyncSelectedDateRange?.[0] == null || asyncSelectedDateRange?.[1] == null) {
  //       // Reverse changes if a full range is not selected
  //       setSelectedDateRange(tempSelectedDateRange);
  //     }
  //     setTempSelectedDateRange([null, null]);
  //   }
  // }}
  />);
};

export default SimpleRangeSelector;