interface MappingItemRuleFilterDTO {
  // Dev note: Left some help for future me

  /** Value comes from either **MappingItemRuleFilterDefinitionDTO** or **MappingItemRuleFilterCriteriaDTO** */
  definitionKey: string;
  /** Value comes from either **MappingItemRuleFilterDefinitionDTO** or **MappingItemRuleFilterCriteriaDTO** */
  definitionOrder: number;
  /** Value comes from either **MappingItemRuleFilterDefinitionDTO** or **MappingItemRuleFilterCriteriaDTO** */
  definitionDisplayValue: string;

  /** Value comes from **MappingFilterCriteriaDTO** */
  filterCriteriaKey: string;
  /** Value comes from **MappingFilterCriteriaDTO** */
  filterCriteriaDisplayValue: string;
}
const MappingItemRuleFilterDTO = {
  create: (initValues?: Partial<MappingItemRuleFilterDTO>): MappingItemRuleFilterDTO => {
    const defaults: MappingItemRuleFilterDTO = {
      definitionKey: '',
      definitionOrder: 0,
      definitionDisplayValue: '',
      filterCriteriaKey: '',
      filterCriteriaDisplayValue: '',
    };
    return { ...defaults, ...initValues };
  },
};

export default MappingItemRuleFilterDTO;
