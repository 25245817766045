import { ArrayUtil } from 'src/utils';
import ReportTransactionItemAssignmentDTO from './ReportTransactionItemAssignmentDTO';

interface ReportTransactionItemSelectionValueDTO {
  transactionLineTypeKey: string;
  displayTransactionLineType: string;
  displayAssignments: ReportTransactionItemAssignmentDTO[];
  allowAddDelete: boolean;
}
const ReportTransactionItemSelectionValueDTO = {
  create: (initValues?: Partial<ReportTransactionItemSelectionValueDTO>): ReportTransactionItemSelectionValueDTO => {
    const defaults: ReportTransactionItemSelectionValueDTO = {
      transactionLineTypeKey: '',
      displayTransactionLineType: '',
      displayAssignments: [],
      allowAddDelete: false,
    };
    const fixedInitValues: Partial<ReportTransactionItemSelectionValueDTO> = initValues != null
      ? {
        displayAssignments: ArrayUtil.EasyMap(initValues.displayAssignments, ReportTransactionItemAssignmentDTO.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default ReportTransactionItemSelectionValueDTO;
