import React, { useState } from 'react';

/**
 * A useState implementation that handles multiple similar state objects in one.
 *
 * Returns 2 functions, the first to retrieve the value and second to set it.
 */
export function useMultiState<T>(initialValue: { [key: React.Key]: T } = {}): [(index: React.Key) => T, (value: T, index: React.Key) => void] {
  // Dev Note: There is support for an initialValue only because this shares the React useState function signature
  const [internalValue, setInternalValue] = useState<{ [key: React.Key]: T }>(initialValue);

  const setSubmitting = (value: T, index: React.Key) => {
    setInternalValue(previous => {
      let temp = { ...previous };
      temp[index] = value;
      return temp;
    });
  };

  const getSubmitting = (index: React.Key) => {
    return internalValue[index];
  };

  return [getSubmitting, setSubmitting];
}
