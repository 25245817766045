import RouteConfig from './RouteConfig';
import PageInterface from '../core/PageInterface';

// Layouts
import UserLayout from 'src/layouts/UserLayout';
import PublicLayout from 'src/layouts/PublicLayout';
import PrintLayout from 'src/layouts/PrintLayout';

/* Pages */
import HomePage from 'src/pages/HomePage';
import StyleGuide from 'src/pages/0Dev/StyleGuide';

// Login
import LoginEmailPage from 'src/pages/login/LoginEmailPage';
import LoginActivatePage from 'src/pages/login/LoginActivatePage';
import LoginActivateSuccessPage from 'src/pages/login/LoginActivateSuccessPage';
import LoginPasswordPage from 'src/pages/login/LoginPasswordPage';
import LoginTwoFactorPage from 'src/pages/login/LoginTwoFactorPage';
import LoginForgotPasswordStep1Page from 'src/pages/login/LoginForgotPasswordStep1Page';
import LoginForgotPasswordStep2Page from 'src/pages/login/LoginForgotPasswordStep2Page';
import LoginForgotPasswordStep3Page from 'src/pages/login/LoginForgotPasswordStep3Page';
import LoginAccountSelectionPage from 'src/pages/login/LoginAccountSelectionPage';
import LoginLocationSelectionPage from 'src/pages/login/LoginLocationSelectionPage';
import LoginTermsOfServicePage from 'src/pages/login/LoginTermsOfServicePage';
import LoginPrivacyStatementPage from 'src/pages/login/LoginPrivacyStatementPage';
import LogoutPage from 'src/pages/login/Logout';

// Profile
import ProfileHomePage from 'src/pages/profile/ProfileHomePage';
import ProfileResetPasswordPage from 'src/pages/profile/ProfileResetPasswordPage';
import ProfileResetPasswordConfirmPage from 'src/pages/profile/ProfileResetPasswordConfirmPage';
import ProfileVerifyPhoneRequestPage from 'src/pages/profile/ProfileVerifyPhoneRequestPage';

// Printing
import PrintingReportPage from 'src/pages/printing/PrintingReportPage';
// import PrintingReportTransactionPage from 'src/pages/printing/PrintingReportTransactionPage';
// import PrintingReportJournalPage from 'src/pages/printing/PrintingReportJournalPage';

// Misc
import SelectAccountPage from 'src/pages/SelectAccountPage';
import SelectLocationPage from 'src/pages/SelectLocationPage';
import LocationSettingsPage from 'src/pages/locationSettings/LocationSettingsPage';
import ReportPage from 'src/pages/report/ReportPage';
import AuditTrailPage from 'src/pages/AuditTrailPage';
import AgentLoginPage from 'src/pages/login/AgentLoginPage';

class NavigationPages {
  public MyPages: PageInterface[] = [
    // Core Pages
    { key: 'home', route: RouteConfig.LINK.HOME, privateComponent: HomePage },

    // Login Pages
    { key: 'login-email',                   route: RouteConfig.LINK.LOGIN_EMAIL,                   privateComponent: LoginEmailPage,                 publicComponent: LoginEmailPage,                 privateLayout: PublicLayout },
    { key: 'agent-login',                   route: RouteConfig.LINK.LOGIN_AGENT,                   privateComponent: AgentLoginPage,                 publicComponent: AgentLoginPage,                 privateLayout: PublicLayout },
    { key: 'login-activate',                route: RouteConfig.LINK.LOGIN_ACTIVATE,                privateComponent: LoginActivatePage,              publicComponent: LoginActivatePage,              privateLayout: PublicLayout },
    { key: 'login-activate-success',        route: RouteConfig.LINK.LOGIN_ACTIVATE_SUCCESS,        privateComponent: LoginActivateSuccessPage,       publicComponent: LoginActivateSuccessPage,       privateLayout: PublicLayout },
    { key: 'login-password',                route: RouteConfig.LINK.LOGIN_PASSWORD,                privateComponent: LoginPasswordPage,              publicComponent: LoginPasswordPage,              privateLayout: PublicLayout },
    { key: 'login-two-factor',              route: RouteConfig.LINK.LOGIN_TWO_FACTOR,              privateComponent: LoginTwoFactorPage,             publicComponent: LoginTwoFactorPage,             privateLayout: PublicLayout },
    { key: 'login-forgot-password-step-1',  route: RouteConfig.LINK.LOGIN_FORGOT_PASSWORD_STEP_1,  privateComponent: LoginForgotPasswordStep1Page,   publicComponent: LoginForgotPasswordStep1Page,   privateLayout: PublicLayout },
    { key: 'login-forgot-password-step-2',  route: RouteConfig.LINK.LOGIN_FORGOT_PASSWORD_STEP_2,  privateComponent: LoginForgotPasswordStep2Page,   publicComponent: LoginForgotPasswordStep2Page,   privateLayout: PublicLayout },
    { key: 'login-forgot-password-step-3',  route: RouteConfig.LINK.LOGIN_FORGOT_PASSWORD_STEP_3,  privateComponent: LoginForgotPasswordStep3Page,   publicComponent: LoginForgotPasswordStep3Page,   privateLayout: PublicLayout },
    { key: 'login-account-selection',       route: RouteConfig.LINK.LOGIN_ACCOUNT_SELECTION,       privateComponent: LoginAccountSelectionPage,      publicComponent: LoginAccountSelectionPage,      privateLayout: PublicLayout },
    { key: 'login-location-selection',      route: RouteConfig.LINK.LOGIN_LOCATION_SELECTION,      privateComponent: LoginLocationSelectionPage,     publicComponent: LoginLocationSelectionPage,     privateLayout: PublicLayout },
    { key: 'login-terms-of-service',        route: RouteConfig.LINK.LOGIN_TERMS_OF_SERVICE,        privateComponent: LoginTermsOfServicePage,        publicComponent: LoginTermsOfServicePage,        privateLayout: PublicLayout },
    { key: 'login-privacy-statement',       route: RouteConfig.LINK.LOGIN_PRIVACY_STATEMENT,       privateComponent: LoginPrivacyStatementPage,      publicComponent: LoginPrivacyStatementPage,      privateLayout: PublicLayout },
    { key: 'logout',                        route: RouteConfig.LINK.LOGOUT,                        privateComponent: LogoutPage,                     publicComponent: LogoutPage,                     privateLayout: PublicLayout },

    // Profile
    { key: 'profile-profile',                     route: RouteConfig.LINK.PROFILE_HOME,                   privateComponent: ProfileHomePage,                 privateLayout: UserLayout   },
    { key: 'profile-reset-password',              route: RouteConfig.LINK.PROFILE_RESET_PASSWORD,         privateComponent: ProfileResetPasswordPage,        privateLayout: PublicLayout },
    { key: 'profile-reset-password-confirmation', route: RouteConfig.LINK.PROFILE_RESET_PASSWORD_CONFIRM, privateComponent: ProfileResetPasswordConfirmPage, privateLayout: PublicLayout },
    { key: 'profile-verify-phone',                route: RouteConfig.LINK.PROFILE_VERIFY_PHONE,           privateComponent: ProfileVerifyPhoneRequestPage,   privateLayout: PublicLayout },

    // Printing
    { key: 'printing-report-base',  route: RouteConfig.LINK.PRINTING_REPORT_BASE,          privateComponent: PrintingReportPage, privateLayout: PrintLayout},
    // { key: 'printing-report-base', route: RouteConfig.LINK.PRINTING_TILE_REPORT,         privateComponent: PrintingReportPage, privateLayout: PrintLayout},
    // { key: 'printing-transaction-report', route: RouteConfig.LINK.PRINTING_TRANSACTION_REPORT,  privateComponent: PrintingReportPage, privateLayout: PrintLayout},
    // { key: 'printing-journal-report',     route: RouteConfig.LINK.PRINTING_JOURNAL_REPORT,      privateComponent: PrintingReportPage, privateLayout: PrintLayout},

    // Misc
    { key: 'select-account',    route: RouteConfig.LINK.SELECT_ACCOUNT,     privateComponent: SelectAccountPage,    privateLayout: UserLayout },
    { key: 'select-location',   route: RouteConfig.LINK.SELECT_LOCATION,    privateComponent: SelectLocationPage,   privateLayout: UserLayout },
    { key: 'location-settings', route: RouteConfig.LINK.SETTINGS,           privateComponent: LocationSettingsPage,                           },
    { key: 'reports',           route: RouteConfig.LINK.REPORTS,            privateComponent: ReportPage,                                     },
    { key: 'audit',             route: RouteConfig.LINK.AUDIT,              privateComponent: AuditTrailPage,                                 },

    // Development Pages
    { key: 'style-guide', route: RouteConfig.LINK.STYLE_GUIDE, privateComponent: StyleGuide, publicComponent: StyleGuide },
  ];

  constructor() {
    // If we are running in dev, we really want to report key errors if the dev is paying attention
    if (process.env.NODE_ENV === 'development') {
      const keys = this.MyPages.map(x => x.key);
      const keySet = new Set(keys[0]);

      keys.forEach(key => {
        const previousSize = keySet.size;
        keySet.add(key);
        if (keySet.size === previousSize) {
          console.error(`Warning! Duplicate key found in the ConfigurePages.ts file. Please revise this key: ${key}`);
        }
      });
    }
  }
}

const Pages = new NavigationPages();
export default Pages;
