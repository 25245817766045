import { ArrayUtil, ObjectUtil } from 'src/utils';
import MappingItemRuleFilterDTO from './MappingItemRuleFilterDTO';
import MappingItemRuleAssignmentDTO from './MappingItemRuleAssignmentDTO';

interface MappingItemRuleDTO {
  complete: boolean;
  displayName: string;
  displayOrder: number;
  displayReportOn: boolean;
  ruleAssignments: MappingItemRuleAssignmentDTO[];
  ruleFilters: MappingItemRuleFilterDTO[];
}
const MappingItemRuleDTO = {
  create: (initValues?: Partial<MappingItemRuleDTO>): MappingItemRuleDTO => {
    const defaults: MappingItemRuleDTO = {
      complete: false,
      displayName: '',
      displayOrder: 0,
      displayReportOn: false,
      ruleAssignments: [],
      ruleFilters: [],
    };
    const fixedInitValues: Partial<MappingItemRuleDTO> = initValues != null
      ? {
        complete: ObjectUtil.ToBoolean(initValues.complete),
        displayReportOn: ObjectUtil.ToBoolean(initValues.displayReportOn),
        ruleAssignments: ArrayUtil.EasyMap(initValues.ruleAssignments, MappingItemRuleAssignmentDTO.create),
        ruleFilters: ArrayUtil.EasyMap(initValues.ruleFilters, MappingItemRuleFilterDTO.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default MappingItemRuleDTO;
