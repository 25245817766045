interface AuditTrailItemFilterDTO {
  filterType: string;
  returnValue: string;
  displayValue: string;
}
const AuditTrailItemFilterDTO = {
  create: (initValues?: Partial<AuditTrailItemFilterDTO>): AuditTrailItemFilterDTO => {
    const defaults: AuditTrailItemFilterDTO = {
      filterType: '',
      returnValue: '',
      displayValue: '',
    };
    return { ...defaults, ...initValues };
  },
};

export default AuditTrailItemFilterDTO;
