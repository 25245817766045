import { CheckCircleFilled, CloseCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Popover, Row, Skeleton, Space, Tabs, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { NotificationUtil } from 'src/utils';
import MappingController from 'src/api/MappingController';
import MappingDTO from 'src/models/generated/MappingDTO';
import MappingItemDTO from 'src/models/generated/MappingItemDTO';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import MappingModalItem from './MappingModalItem';
import SimplePopover from 'src/components/SimplePopover';
import './MappingModal.less';
import PrintIconSVG from 'src/components/svgs/PrintIconSVG';
import LinkWithQuery from 'src/components/LinkWithQuery';
import RouteConfig from 'src/config/RouteConfig';
import DraggableTable from './DraggableTable';
import { usePrompt, useStateAsync } from 'src/hooks';
import MappingModalItemNew from './MappingModalItemNew';

export interface MappingModalNewProps {
  open: boolean;
  mappingKey: string;
  onClose: () => void;
}

const MappingModalNew: React.FC<MappingModalNewProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [modalData, setModalData] = useState<MappingDTO | null>(null);
  const [tableData, setTableData, getTableDataAsync] = useStateAsync<MappingItemDTO[]>([]);
  const [loadingModelData, setLoadingModelData] = useState(false);
  const [loadingTableData, setLoadingTableData] = useState(false);
  const [reloadingTab, setReloadingTab] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [selectedTab, setSelectedTab] = useState<string | undefined>();

  usePrompt('Unsaved changes!', !!props.open && hasChanges, (choice) => (choice && props.onClose?.()));

  useEffect(() => {
    if (!props.open) {
      unloadModalData();
      return;
    }
    loadModalData();
    loadTableData();
  }, [props.open, props.mappingKey]);

  const loadModalData = async () => {
    setLoadingModelData(true);
    try {
      const results = await MappingController.getMappings(authContext.account!.id, authContext.location!.id, { mappingKey: props.mappingKey });

      setModalData(results.data[0]);
    } catch (error) {
      NotificationUtil.error({
        key: 'MappingModalNew',
        message: 'Error while loading Mapping data',
        error
      });
    }
    setLoadingModelData(false);
  };

  const loadTableData = async () => {
    setLoadingTableData(true);
    try {
      const results = await MappingController.getMappingItems(authContext.account!.id, authContext.location!.id, props.mappingKey);

      // Sort data
      const sortedData = [...results.data].sort((a, b) => (a.displayOrder - b.displayOrder));

      setTableData(sortedData);
    } catch (error) {
      NotificationUtil.error({
        key: 'MappingModalNew',
        message: 'Error while loading Mapping Items'
      });
    }
    setLoadingTableData(false);
  };

  const unloadModalData = () => {
    setModalData(null);
    setTableData([]);
    setLoadingModelData(false);
    setLoadingTableData(false);
    setHasChanges(false);
    setSelectedTab(undefined);
  };

  const handleMappingItemChange = async (record: MappingItemDTO) => {
    let myTableData = [...await getTableDataAsync()];
    const foundIndex = myTableData.findIndex(x => x.key === record.key);
    if (foundIndex < 0) {
      return;
    }

    // Fetch a new record
    setReloadingTab(true);
    const results = await MappingController.getMappingItem(authContext.account!.id, authContext.location!.id, props.mappingKey, record.key);
    record.complete = results.data.complete;
    setReloadingTab(false);

    myTableData.splice(foundIndex, 1, record);
    setTableData(myTableData);
  };

  const handleOnClose = () => {
    // Check unsaved changes
    if (!hasChanges) {
      setTimeout(() => {
        props.onClose?.();
      }, 1);
      return;
    }

    // Show the warning
    Modal.confirm({
      title: 'Pending changes',
      content: 'You have made some changes that have not yet been saved, continue?',
      okText: 'Continue',
      okButtonProps: { danger: true, type: 'primary' },
      onOk: () => {
        setHasChanges(false);
        // Force reload the tab somehow
        setTimeout(() => (props.onClose?.()), 1);
      },
      cancelText: 'Cancel',
      cancelButtonProps: { type: 'primary' },
    });
  };

  const handleOnChange = (key: string) => {
    if (hasChanges) {
      Modal.confirm({
        title: 'Pending changes',
        content: 'You have made some changes that have not yet been saved, continue?',
        okText: 'Continue',
        okButtonProps: { danger: true, type: 'primary' },
        onOk: () => {
          setHasChanges(false);
          // Force reload the tab somehow
          setTimeout(() => (setSelectedTab(key)), 1);
        },
        cancelText: 'Cancel',
        cancelButtonProps: { type: 'primary' },
      });
    } else {
      setSelectedTab(key);
    }
  };

  const renderMappingTabHeader = (record: MappingItemDTO) => {
    return (
      <Row justify='space-between' align='middle'>
        <span>
          <span>{record.displayName}</span>
          <SimplePopover
            dangerouslySetHTML
            content={record.displayInfo}
          />
        </span>

        {!record.complete && <CloseCircleFilled className='standard-icon icon-with-white-background make-text-red without-margin-important' />}
        {record.complete && <CheckCircleFilled className='standard-icon icon-with-white-background make-text-green without-margin-important' />}
      </Row>
    );
  };

  const renderPrintButton = () => {
    const url = new URL(location.href);
    url.searchParams.delete('route_id');
    const printingQueryParams: [string, string][] = [
      ['mappingItemId', props.mappingKey ?? ''],
      ['returnUrl', url.pathname + url.search],
    ];

    return <LinkWithQuery openInNewTab to={RouteConfig.PRINTING_MAPPING_ITEM()} additionalQueryParams={printingQueryParams}>
      <Button style={{ height: 36 }}><Space><PrintIconSVG /><span>Print</span></Space></Button>
    </LinkWithQuery>;
  };

  return <Modal
    className='mapping-edit-item-modal'
    style={{ top: 100 }}
    width={99999} // Endgame boss health right here :D
    closable={false}
    maskClosable={false}
    open={props.open}
    onCancel={handleOnClose}
    footer={<>
      <Button type='primary' onClick={handleOnClose}>Done</Button>
    </>}
  >
    <Skeleton active loading={loadingModelData}>
      <div>
        <Row justify='space-between' align='middle' className='with-margin-bottom'>
          <Col><h2 className='without-margin-bottom'>{modalData?.displayName || 'Title'}</h2></Col>
          <Col>{renderPrintButton()}</Col>
        </Row>
        <p>{modalData?.displayDescription || 'Descriptive text here'}</p>
        <Skeleton active loading={loadingTableData}>
          <Tabs
            activeKey={selectedTab}
            style={{ marginTop: 20 }}
            tabPosition='left'
            tabBarStyle={{ width: 275 }}
            className='mapping-item-tabs'
            onChange={handleOnChange}
            items={tableData.map(record => {
              return {
                key: record.key,
                label: renderMappingTabHeader(record),
                children: <MappingModalItemNew mappingKey={props.mappingKey} mappingItem={record} hasUnsavedChanges={hasChanges} onMappingItemChange={handleMappingItemChange} onUnsavedChanges={value => setHasChanges(value)} />
              };
            })}
          />
        </Skeleton>
      </div>
    </Skeleton>
  </Modal>;
};

export default MappingModalNew;
