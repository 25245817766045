import { Button, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { CacheContext } from 'src/providers/CacheContext';
import { NotificationUtil } from 'src/utils';
import AccountDTO from 'src/models/generated/AccountDTO';
import SelectAccount from 'src/components/SelectAccount';
import LoginService from './login/LoginService';

const SelectAccountPage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const cacheContext = useContext(CacheContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedAccount, setSelectedAccount] = useState<AccountDTO>();
  const [loading, setLoading] = useState(false);

  const handleOnSelect = async () => {
    // Select account and clear the location. The LoginService will take them from there
    setLoading(true);
    await authContext.updateAccount(selectedAccount!);
    await authContext.updateLocation(null);
    await authContext.updateReturnUrl(null);
    setLoading(false);
    NotificationUtil.success({
      key: 'SelectAccountPage',
      message: 'Updated Account',
      description: 'Account Updated. Please select a location.'
    });

    // Send the user to the correct page
    navigate(await LoginService.GetRouteWithSetAccountLocation(authContext, cacheContext, location));
  };

  const handleAccountEdit = (account: AccountDTO) => {
    if(authContext.account?.id == account.id) {
      setSelectedAccount(account);
    }
  };

  return (
    <div className='standard-table-layout'>
      <h1>Please Select an Account</h1>

      <SelectAccount
        initialValue={authContext.account?.id}
        disabled={loading}
        onChange={setSelectedAccount}
        onSelect={handleOnSelect}
        onEdit={handleAccountEdit}
      />

      <Row justify='end' style={{ marginTop: 16 }}>
        <Button
          type='primary'
          size='large'
          style={{ padding: '0 32px' }}
          disabled={selectedAccount == null}
          loading={loading}
          onClick={handleOnSelect}
        >
          Select
        </Button>
      </Row>
    </div>
  );
};

export default SelectAccountPage;
