import * as React from 'react';
import { Avatar, Button, Divider, Dropdown, Menu, MenuProps, Popconfirm, Popover } from 'antd';
import { BarChartOutlined, BookOutlined, HomeOutlined, MenuOutlined, PoweroffOutlined, SettingOutlined, UserOutlined, UserSwitchOutlined, QuestionOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import RouteConfig from '../config/RouteConfig';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import LinkWithQuery from 'src/components/LinkWithQuery';

const { SubMenu } = Menu;

interface SideNavProps {
  menuCollapsed: boolean;
  isMobile?: boolean;
}

export declare type MenuItemType = {
  key: string;
  title?: string | React.ReactNode;
  icon?: React.ReactNode;
  url?: string;
  // A function in case user permissions / roles change without this component unmounting and remounting.
  shouldRender?: () => boolean;
  subMenu?: MenuItemType[];
  // newTab?: boolean;
};

const UserSideNav: React.FC<SideNavProps> = (props) => {
  let location = useLocation();
  const authContext = React.useContext(AuthenticationContext);

  const sideMenuItems: MenuItemType[] = [
    {
      key: 'home',
      title: 'Home',
      url: RouteConfig.LINK.HOME,
      icon: <HomeOutlined />
    }, {
      key: 'locations',
      title: 'Locations',
      url: RouteConfig.LINK.SELECT_LOCATION,
      icon: <MenuOutlined />
    }, {
      key: 'settings',
      title: 'Settings',
      url: RouteConfig.LINK.SETTINGS,
      icon: <SettingOutlined />
    }, {
      key: 'audit',
      title: 'Audit Trail',
      url: RouteConfig.LINK.AUDIT,
      icon: <BookOutlined />
    }, {
      key: 'reports',
      title: 'Reports',
      url: RouteConfig.LINK.REPORTS,
      icon: <BarChartOutlined />
    }
  ];

  const getSelectedKeysForNav = (menu: MenuItemType[], path: string): string[] => {
    let selectedKeys: string[] = [];
    menu.forEach((s) => {
      if (s.key === 'home' && path == s.url) {
        selectedKeys.push(s.key);
      }
      if (s.subMenu) {
        selectedKeys = selectedKeys.concat(getSelectedKeysForNav(s.subMenu, path));
      } else if (s.key !== 'home' && s.url && path.indexOf(s.url) >= 0) {
        selectedKeys.push(s.key);
      }
    });
    return selectedKeys;
  };

  const getOpenKeysForNav = (menu: MenuItemType[], path: string): string[] => {
    const selectedKeys: string[] = [];
    menu.forEach((s) => {
      if (s.subMenu) {
        if (getSelectedKeysForNav(s.subMenu, path).length > 0) {
          selectedKeys.push(s.key);
        }
      }
    });
    return selectedKeys;
  };

  const [selectedKeys, setSelectedKeys] = useState<string[]>(getSelectedKeysForNav(sideMenuItems, location.pathname));
  const [openKeys, setOpenKeys] = useState<string[]>(getOpenKeysForNav(sideMenuItems, location.pathname));

  useEffect(() => {
    setSelectedKeys(getSelectedKeysForNav(sideMenuItems, location.pathname));
    setOpenKeys(getOpenKeysForNav(sideMenuItems, location.pathname));
  }, [location.pathname]);

  const onOpenSubMenuChange = (keys: React.Key[]) => {
    if (!props.menuCollapsed) {
      setOpenKeys(keys as string[]);
    }
  };

  const renderDefaultMenu = (item: MenuItemType, depth: number) => {
    if ((item.shouldRender && item.shouldRender() === true) || item.shouldRender === undefined) {
      if (item.subMenu) {
        const subMenuItems = item.subMenu.map((s) => renderDefaultMenu(s, depth + 1)).filter((e) => !!e);
        if (subMenuItems.length > 0) {
          return (
            <SubMenu key={item.key} title={item.title} style={{ marginLeft: 5 * depth }}>
              {subMenuItems}
            </SubMenu>
          );
        }
        return null;
      } else {
        return (
          <Menu.Item key={item.key} icon={item.icon}>
            <LinkWithQuery asNavLink to={item.url ?? ''}>
              <span style={{ marginLeft: 5 * depth }}>{item.title}</span>
            </LinkWithQuery>
          </Menu.Item>
        );
      }
    }
    return null;
  };


  // <Link to="/" className="header-logo">
  //   <img src={process.env.PUBLIC_URL + '/logo.svg'} alt="QCI" />
  //   <img src={process.env.PUBLIC_URL + '/logo.svg'} alt="QCI" className="header-logo-icon" />
  // </Link>
  // <Menu mode="horizontal">
  //   <Menu.Item key="topMenu">
  //     <Button type="link">
  //       <PhoneOutlined /> Top Menu <DownOutlined />
  //     </Button>
  //   </Menu.Item>
  // </Menu>

  // Dev note: See here on a lower menu item: https://github.com/ant-design/ant-design/issues/13572#issuecomment-527409028
  // I've thought about moving the whole sider into here, I really have. Not sure how I want to have that pan out
  // I think that <Sider> is part of the layout, while the nav is not
  const menuItems: Required<MenuProps>['items'] = [
    {
      key: 'home',
      label: <LinkWithQuery asNavLink to={RouteConfig.LINK.PROFILE_HOME}>Profile</LinkWithQuery>,
      title: '', // Has to be set or the dropdown will have a second popover for some very strange reason
      icon: <UserOutlined />
    }, {
      key: 'switch-accounts',
      label: authContext.auth?.role === 'Agent'
        ? <Popover
          zIndex={1051}
          // trigger={['click']}
          content={<div>
            <p>Account switching is not supported for Agents</p>
          </div>}
        >
          {authContext.auth?.role === 'Agent'
            ? <p className='without-margin'>Switch Account</p>
            : <LinkWithQuery asNavLink to={RouteConfig.LINK.SELECT_ACCOUNT}>Switch Account?</LinkWithQuery>}
        </Popover>
        : <LinkWithQuery asNavLink to={RouteConfig.LINK.SELECT_ACCOUNT}>Switch Account?</LinkWithQuery>,
      title: '',
      icon: <UserSwitchOutlined />,
      disabled: authContext.auth?.role === 'Agent'
    }, {
      key:'help',
      label: <a target="_blank" rel="noopener noreferrer" href='http://www.tbohelp.com/'>Help</a>,
      title: '',
      icon:<QuestionOutlined/>
    },{
      key: 'logout',
      label: <LinkWithQuery asNavLink to={RouteConfig.LINK.LOGOUT}>LogOut</LinkWithQuery>,
      title: '',
      icon: <PoweroffOutlined />
    }
  ];

  return (
    <div className='user-side-nav'>
      <div className='upper-section'>
        <div className='header-image-container'>
          <img src={process.env.PUBLIC_URL + '/logo512.png'} alt="BackOffice Logo" />
        </div>
        <Menu
          className='main-menu'
          mode="inline"
          defaultSelectedKeys={['home']}
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          onOpenChange={onOpenSubMenuChange}
        >
          {sideMenuItems.map((m) => renderDefaultMenu(m, 0))}
        </Menu>
      </div>
      <span style={{ marginTop: 'auto', display: 'hidden' }}></span>
      <div className='lower-section'>
        <span style={{ display: 'block', margin: 8 }}><Divider style={{ margin: 'unset', borderTopColor: 'white', borderTopWidth: 2 }} /></span>
        <Dropdown
          trigger={['click']}
          align={{ offset: [68, -50] }}
          menu={{ items: menuItems }}
          overlayClassName='sidenav-dropdown-menu'
        >
          <Avatar size={48} style={{ fontSize: 22, cursor: 'pointer' }}>{authContext.profile?.firstName.slice(0, 1).toUpperCase() ?? ''}</Avatar>
        </Dropdown>
      </div>
    </div>
  );
};

export default UserSideNav;
