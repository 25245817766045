import { v4 as uuidv4 } from 'uuid';
import { ArrayUtil, ObjectUtil, StringUtil } from 'src/utils';
import ReportAssignmentDTO from '../generated/ReportAssignmentDTO';
import ReportAssignmentItemModel from './ReportAssignmentItemModel';
import ReportAssignmentItemDTO from '../generated/ReportAssignmentItemDTO';

interface ReportAssignmentModel {
  /** Auto Generated field */
  key: string;

  displayOrder: number;
  displayAccount: string;
  displayType: string;
  displayDebit: number;
  displayCredit: number;
  displayItemDebitTotalAmount: number;
  displayItemCreditTotalAmount: number;
  items: ReportAssignmentItemModel[];
}
const ReportAssignmentModel = {
  create: (initValues?: Partial<ReportAssignmentModel>): ReportAssignmentModel => {
    const defaults: ReportAssignmentModel = {
      key: '',
      displayOrder: 0,
      displayAccount: '',
      displayType: '',
      displayDebit: 0,
      displayCredit: 0,
      displayItemDebitTotalAmount: 0,
      displayItemCreditTotalAmount: 0,
      items: [],
    };
    const fixedInitValues: Partial<ReportAssignmentModel> = initValues != null
      ? {
        key: StringUtil.IsNullOrEmpty(initValues.key) ? uuidv4() : initValues.key,
        items: ArrayUtil.EasyMap(initValues.items, ReportAssignmentItemModel.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
  toDTO: (model: ReportAssignmentModel): ReportAssignmentDTO => (ReportAssignmentDTO.create({
    ...model,
    items: ArrayUtil.EasyMap(model.items, ReportAssignmentItemDTO.create)
  })),
  fromDTO: (dto: ReportAssignmentDTO): ReportAssignmentModel => (ReportAssignmentModel.create({
    ...dto,
    items: ArrayUtil.EasyMap(dto.items, ReportAssignmentItemModel.create)
  }))
};

export default ReportAssignmentModel;
