import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PasswordResetFormData, PasswordResetForm } from 'src/components/modals/passwordResetModal';
import RouteConfig from 'src/config/RouteConfig';

const ProfileResetPasswordPage: React.FC = () => {
  const navigate = useNavigate();

  const onFinish = (values: PasswordResetFormData) => {
    navigate(RouteConfig.PROFILE_RESET_PASSWORD_CONFIRM());
  };

  return (
    <div className='profile-password-reset password-reset-flow'>
      <h1>Password Reset</h1>
      <PasswordResetForm onFinish={onFinish} confirmButtonText='Reset Password'/>
    </div>
  );
};

export default ProfileResetPasswordPage;
