import ObjectUtil from './ObjectUtil';
import StringUtil from './StringUtil';

class DebugUtil {
  /** When true, debug will automatically be set to true for localhost */
  private localhostExempt = true;
  /** Additional hosts that can be exempted to automatically set debug to true */
  private additionalDevHosts: string[] = [];
  /** Adds a key to localStorage for devHosts for convenience */
  private addLocalStorageKey = true;
  /** The key used in localStorage */
  private debugKey = 'debug';

  constructor() {
    const item = localStorage.getItem(this.debugKey);
    const isDevHost = this.isDevHost();

    if (isDevHost && this.addLocalStorageKey && item == null) {
      localStorage.setItem(this.debugKey, '');
    }
  }

  /**
   * Checks if debug is enabled. Localhost will automatically set value to true
   *
   * Debug can be forcedly changed by setting the localStorage value 'debug' to 'true' or 'false'
   */
  public isDebugEnabled(): boolean {
    const debugValue = this.GetDebugValue();
    const isDevHost = this.isDevHost();

    if (debugValue == null) {
      return isDevHost;
    }

    return debugValue;
  }

  /**
   * Gets the current environment from process.env
   */
  public GetCurrentEnvironment(): 'development' | 'production' | 'test' {
    return process.env.NODE_ENV;
  }

  /**
   * Gets the current debug value and converts it to a boolean. If null, then it is unset
   */
  private GetDebugValue(): boolean | null {
    const value = localStorage.getItem(this.debugKey);
    if (StringUtil.IsNullOrEmpty(value)) {
      return null;
    }
    return ObjectUtil.ToBoolean(value);
  }

  private isDevHost(): boolean {
    const currentShortUrl: string = (window.location.hostname || '').toLowerCase();
    const allowedUrls: string[] = [
      this.localhostExempt ? 'localhost' : '',
      ...this.additionalDevHosts];
    return allowedUrls.some(x => x.toLowerCase() === currentShortUrl);
  }
}

export default new DebugUtil();
