import React, { useEffect } from 'react';
import { Button, Form, FormProps, Modal } from 'antd';
import { NameOf } from 'src/utils';
import MultiToolPicker, { MultiToolPickerProps } from '../MultiToolPicker';

interface StandardSingleEditFormData {
  specialValue: string;
}

export interface StandardSingleEditModalProps {
  open: boolean;

  required?: boolean;
  loading?: boolean;
  /** Title for the modal */
  title?: string;
  /** The label for the internal Form.Item component */
  label?: string;
  /** Optional description, which is rendered above the element */
  description?: React.ReactNode;
  defaultValue?: string;
  /** The form component layout. Defaults to horizontal */
  formLayout?: FormProps['layout'];
  /** displayType for the MultiToolPicker. Defaults to TextBox */
  displayType?: MultiToolPickerProps['displayType'];
  /** formatType for the MultiToolPicker. Defaults to String */
  formatType?: MultiToolPickerProps['formatType'];
  /** options for the MultiToolPicker */
  options?: MultiToolPickerProps['options'];

  onCancel: () => void;
  onFinish: (value: string) => void;
}

// The goal of this component is to provide an easy way to edit one simple value. Anything beyond that should be it's own component
const StandardSingleEditModal: React.FC<StandardSingleEditModalProps> = (props) => {
  const [form] = Form.useForm<StandardSingleEditFormData>();

  // This will reset the form when the visibility or defaultValues have changed
  useEffect(() => {
    if (props.open) {
      form.setFields([{ name: NameOf<StandardSingleEditFormData>('specialValue'), value: props.defaultValue }]);
    } else {
      form.resetFields();
    }
  }, [props.open, props.defaultValue]);

  const handleModalCancel = () => {
    // All we need to do is close, hopefully the caller is listening!
    props.onCancel && props.onCancel();
  };

  const handleModalOk = () => {
    // Force submit the form, which we can then properly handle on the form finish, getting the values as well
    form.submit();
  };

  const handleFormFinish = (values: StandardSingleEditFormData) => {
    props.onFinish && props.onFinish(values.specialValue);
  };

  const multiToolProps: MultiToolPickerProps = {
    formatType: props.formatType ?? 'String',
    displayType: props.displayType ?? 'TextBox',
    options: props.options,
    loading: props.loading,
    style: { display: 'block' }
  };

  const renderDescription = () => {
    if (props.description == null) {
      return null;
    }
    if (React.isValidElement(props.description)) {
      return props.description;
    }

    // Primitives end up here, so render them inside of a <p> tag
    return <p>{props.description}</p>;
  };

  return (<Modal
    centered
    className='standard-single-edit-modal'
    width={440}
    title={props.title}
    open={props.open}
    onCancel={handleModalCancel} // Handles clicks outside the modal window
    footer={<>
      <Button type='default' onClick={handleModalCancel}>Cancel</Button>
      <Button type='primary' onClick={handleModalOk}>Ok</Button>
    </>}
  >
    {renderDescription()}
    <Form
      size='large'
      layout={props.formLayout ?? 'horizontal'}
      requiredMark={false}
      form={form}
      onFinish={handleFormFinish}
    >
      <Form.Item
        label={props.label}
        name={NameOf<StandardSingleEditFormData>('specialValue')}
      >
        <MultiToolPicker {...multiToolProps} />
      </Form.Item>
    </Form>
  </Modal>);
};

export default StandardSingleEditModal;
