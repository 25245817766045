import axios from 'axios';
import moment from 'moment';
import { LocalStorageUtil, ObjectUtil } from 'src/utils';
import sleep from 'src/utils/core/SleepUtil';

const InitializeAxios = (): void => {
  axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}`;

  // We accept and use only json
  axios.defaults.headers.common['Accept'] = 'application/json';
  axios.defaults.headers.common['Content-Type'] = 'application/json';

  /** Interceptors **/
  /**** ORDER MATTERS! REQUEST INTERCEPTORS ARE RUN IN REVERSE ORDER FROM WHEN THEY ARE ADDED ****/

  /**
   * Delay
   *  Add an artificial delay so we can test the loading logic
   */
  // axios.interceptors.response.use(async (response) => {
  //   await sleep(300);

  //   return response;
  // });

  /**
   * CamelCase Keys
   * Our API returns keys that are not camelCase but instead are Pascal case.
   * This will properly camel case them, preserving symbols (most for underscore) and adjusting for various custom keys (TFA -> tfa)
   */
  axios.interceptors.response.use((response) => {
    let converted = ObjectUtil.CamelCaseKeys(response.data, true, ['TFA', 'tfa']);
    // if (process.env.NODE_ENV === 'development') {
    //   console.log('API: 1. Changing response data via CamelCase', { original: response.data, converted }); // JB - Remove this console.warn
    // }

    response.data = converted;
    return response;
  });

  /**
   * Proxy support
   *  Proxies the request through the Azure Function. This must be the first request interceptor or you will have a very bad time
   */
  if (process.env.REACT_APP_USE_PROXY != null && process.env.REACT_APP_USE_PROXY.trim().localeCompare('true') === 0) {
    axios.interceptors.request.use(
      request => {
        request.data = {
          url: request.url,
          params: request.params,
          data: request.data != null ? request.data : undefined,
          method: request.method,
          auth: axios.defaults.headers.common['Authorization']
        };
        request.method = 'post';
        request.baseURL = process.env.REACT_APP_BEARER_URL;
        request.params = {};

        // Add a param so we have SOME way of knowing what the request is when looking at dev tools
        // Generate a decent appox of the query params by manually building it, filtering empty values
        const paramsAsString = request.data.params != null
          ? '?' + Object.entries(request.data.params).filter(([key, value]) => value).map(([key, value]) => `${key}=${value}`).join('&')
          : '';

        // Converted to array.join as it is far easier to reac
        request.url = [
          '/api/apiProxy/',
          request.data.url.substring(request.data.url.lastIndexOf('/') + 1),
          paramsAsString
        ].join('');

        return request;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  /**
   * Object To Array
   *  Post requests sometimes need to be translated from object -> array. This will do that if the matching config value is found
   */
  axios.interceptors.request.use(
    request => {
      const original = request.data;
      const isSplitEnabled = request.withObjectSplit != null && !!request.withObjectSplit;
      if (original == null || !isSplitEnabled) {
        return request;
      }

      // Okay, split the data into an array, break it into key/value pairs and assign it back to the request
      // Dev Note (Jul 5, 2022): Added support for arrays
      const converted = Array.isArray(original)
        ? original.map(ObjectUtil.ToKeyValuePairs) // Map for an array, otherwise you end up with an array of arrays with numbers as the key, which isn't usually wanted
        : ObjectUtil.ToKeyValuePairs(original);
      request.data = converted;

      // if (process.env.NODE_ENV === 'development') {
      //   console.log('API: Changed request data via Object To Array', { original, converted }); // JB - Remove this console.warn
      // }

      return request;
    },
    error => {
      return Promise.reject(error);
    }
  );

  /**
   * Key Match (UnCamelCase)
   *  Updates the request's keys to match what is expected. Includes overrides for weird camel cased keys such as 'tfaEnabled' which should be 'TFAEnabled'
   */
  axios.interceptors.request.use(
    request => {
      const original = request.data;
      if (request.data == null) {
        return request;
      }

      // We need to unCamelCase the request, which handles the bulk of the values (Updated 5/8/22 to handle everything in the util class!)
      let converted = ObjectUtil.UnCamelCaseKeys(original, true, ['tfa', 'TFA']);

      request.data = converted;

      // if (process.env.NODE_ENV === 'development') {
      //   console.log('API: Changed request data via Key Match', { original, converted }); // JB - Remove this console.warn
      // }

      return request;
    },
    error => {
      return Promise.reject(error);
    }
  );

  /**
   * Dates Converter
   *  Updates the request's dates to be in the proper format as we cannot just return a Moment to the API. It will also need to be in UTC format
   */
  axios.interceptors.request.use(
    request => {
      const original = request.data;
      if (original == null) {
        return request;
      }

      // Dev Note: Types are ALL weird because of the array type guard
      let convertMomentsFunc = (obj: any | any[]): any | any[] => {
        // Arrays are fun and break things if not accounted for
        if (Array.isArray(obj)) {
          return obj.map(convertMomentsFunc);
        }

        return Object.fromEntries(Object.entries(obj).map(([key, value]) => {
          // Checks for intrinsic types (string/number/etc.), null and undefined
          if (value == null || typeof value !== 'object') {
            return [key, value];
          }

          // Check for moment. If not, then it's potentially a deeply nested object, so we should dig into that
          if (moment.isMoment(value)) {
            value = value.toISOString();
          } else {
            value = convertMomentsFunc(value);
          }
          return [key, value];
        }));
      };

      // We need to unCamelCase the request, which handles the bulk of the values (Updated 5/8/22 to handle everything in the util class!)
      let converted = convertMomentsFunc(original);

      request.data = converted;

      // if (process.env.NODE_ENV === 'development') {
      //   console.log('API: Changed request data via Dates Converted', { original, converted }); // JB - Remove this console.warn
      // }

      return request;
    },
    error => {
      return Promise.reject(error);
    }
  );
};

const SetAccessToken = (accessToken?: string): void => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

  // Add an interceptor to update the session expiration timer
  axios.interceptors.response.use(response => {
    LocalStorageUtil.AuthExpiration = moment().add(55, 'minute');
    // LocalStorageUtil.AuthExpiration = moment().add(20, 'second');

    // Dev Note: You MUST return a response even if you do nothing with it
    return response;
  });
};

const DeleteAccessToken = (): void => {
  delete axios.defaults.headers.common['Authorization'];
};

export { InitializeAxios, SetAccessToken, DeleteAccessToken };
