import { ObjectUtil } from 'src/utils';

interface MappingItemDTO {
  key: string;
  displayName: string;
  displayDescription: string;
  displayInfo: string;
  displayOrder: number;
  complete: boolean;
  enableMappingRules: boolean;
  showCustomMapping: boolean;
  showDefaultMapping: boolean;

  // Update value indicators
  enableMappingRules_IsUpdate: boolean;
}
const MappingItemDTO = {
  create: (initValues?: Partial<MappingItemDTO>): MappingItemDTO => {
    const defaults: MappingItemDTO = {
      key: '',
      displayName: '',
      displayDescription: '',
      displayInfo: '',
      displayOrder: 0,
      complete: false,
      enableMappingRules: false,
      showCustomMapping: false,
      showDefaultMapping: false,
      enableMappingRules_IsUpdate: false,
    };
    const fixedInitValues: Partial<MappingItemDTO> = initValues != null
      ? {
        complete: ObjectUtil.ToBoolean(initValues.complete),
        enableMappingRules: ObjectUtil.ToBoolean(initValues.enableMappingRules),
        showCustomMapping: ObjectUtil.ToBoolean(initValues.showCustomMapping),
        showDefaultMapping: ObjectUtil.ToBoolean(initValues.showDefaultMapping),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default MappingItemDTO;
