import React from 'react';
import classNames from 'classnames';
import StandardSVGProps from './StandardSVGProps';

const PrintIconSVG: React.FC<StandardSVGProps> = (props) => {
  return (
    <span style={props.style} className={classNames('print-icon-svg', 'svg', props.className)}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.34106 9.22433V2.22433H18.3411V9.22433" stroke="#BFBBBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6.34106 18.2243H4.34106C3.81063 18.2243 3.30192 18.0136 2.92685 17.6385C2.55178 17.2635 2.34106 16.7548 2.34106 16.2243V11.2243C2.34106 10.6939 2.55178 10.1852 2.92685 9.81012C3.30192 9.43505 3.81063 9.22433 4.34106 9.22433H20.3411C20.8715 9.22433 21.3802 9.43505 21.7553 9.81012C22.1304 10.1852 22.3411 10.6939 22.3411 11.2243V16.2243C22.3411 16.7548 22.1304 17.2635 21.7553 17.6385C21.3802 18.0136 20.8715 18.2243 20.3411 18.2243H18.3411" stroke="#BFBBBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M18.3411 14.2243H6.34106V22.2243H18.3411V14.2243Z" stroke="#BFBBBD" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </span>
  );
};

export default PrintIconSVG;
