import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, FormProps, Input, Modal, Row, Select, Skeleton } from 'antd';
import { NameOf, NotificationUtil } from 'src/utils';
import MultiToolPicker, { MultiToolPickerProps } from '../MultiToolPicker';
import ReportTransactionItemDTO from 'src/models/generated/ReportTransactionItemDTO';
import ReportController from 'src/api/ReportController';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import ReportTransactionSelectionValueDTO from 'src/models/generated/ReportTransactionSelectionValueDTO';
import ReportTransactionItemSelectionValueDTO from 'src/models/generated/ReportTransactionItemSelectionValueDTO';
import { EditFilled } from '@ant-design/icons';
import PickListSelectorModal from './PickListSelectorModal';
import MappingItemAssignmentDTO from 'src/models/generated/MappingItemAssignmentDTO';
import MappingPickListItemModel from 'src/models/frontend/MappingPickListItemModal';
import ReportTransactionItemAssignmentDTO from 'src/models/generated/ReportTransactionItemAssignmentDTO';
import { CacheContext } from 'src/providers/CacheContext';

interface TransactionItemModalFormData {
  specialValue: string;
}

export interface TransactionItemModalProps {
  open: boolean;
  /** Can be found on the reportTransactionDTO */
  transactionTypeKey: string;
  /** Undefined for new, filled in for edit */
  transactionItem?: ReportTransactionItemDTO;

  onOk?: (transactionItem: ReportTransactionItemDTO) => void;
  onCancel?: () => void;
}

// The goal of this component is to provide an easy way to edit one simple value. Anything beyond that should be it's own component
const TransactionItemModal: React.FC<TransactionItemModalProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const cacheContext = useContext(CacheContext);
  // const [form] = Form.useForm<TransactionItemModalFormData>();
  const [form] = Form.useForm();
  const [transactionItem, setTransactionItem] = useState(ReportTransactionItemDTO.create());
  const [itemTypeValues, setItemTypeValues] = useState<ReportTransactionItemSelectionValueDTO[]>([]);
  const [selectedLineType, setSelectedLineType] = useState<ReportTransactionItemSelectionValueDTO>();
  const [loading, setLoading] = useState(false);

  // PickList
  const [selectedAssignment, setSelectedAssignment] = useState<ReportTransactionItemAssignmentDTO | null>(null);
  const [picklistAssignment, setPicklistAssignment] = useState<MappingItemAssignmentDTO | null>(null);
  const [pickListModalVisible, setPickListModalVisible] = useState(false);

  // This will reset the form when the visibility or defaultValues have changed
  useEffect(() => {
    // This modal can be reused MANY times so we need to clear it out... this should probably be the standard practice for modals 😅
    if (!props.open) {
      unloadModalData();
      return;
    }
    loadModalData();
  }, [props.open]);

  /// Modal Functions
  const loadModalData = async () => {
    setLoading(true);
    try {
      const results = await ReportController.getTransactionModalValues(authContext.account!.id, authContext.location!.id, props.transactionTypeKey);
      // We get back a ReportTransactionSelectionValueDTO, but all we we REALLY need is the values inside of it, so just grab those
      const myItemTypeValues = results.data.transactionItemSelectionValues;
      setItemTypeValues(myItemTypeValues);

      if (props.transactionItem == null || props.transactionItem == undefined) {
        // Dev Note: Manually setting the amount like this because it looks better on the form
        setTransactionItem({...ReportTransactionItemDTO.create(),allowAddDelete: true, displayAmount: '' as any});
        setSelectedLineType(undefined);

        // Reset the form
        form.resetFields();
      } else {
        // We are editing, so find the ItemType in the SelectionValues, get the assignments, and populate those
        const foundItemType = myItemTypeValues.find(x => x.displayTransactionLineType === props.transactionItem!.displayLineType);
        let displayAssignments: ReportTransactionItemAssignmentDTO[] = [];
        if (foundItemType != null && Array.isArray(props.transactionItem.displayAssignments)) {
          // The assignments need to be loaded from the foundItem then the values from transactionItem need to be applied to it. Uhg
          displayAssignments = [...foundItemType.displayAssignments.map(x => {
            // It is incredibly tempting to match on the displayOrder...
            const foundAssignment = props.transactionItem!.displayAssignments.find(y => y.displayName === x.displayName);
            let value = ReportTransactionItemAssignmentDTO.create({...x});
            if (foundAssignment != null) {
              value.selectedItemName = foundAssignment.selectedItemName;
              value.displayValue = foundAssignment.displayValue;
              value.displayName = foundAssignment.displayName;
            }

            return value;
          })];
        }

        const myTransactionItem = ReportTransactionItemDTO.create(
          {
            ...props.transactionItem,
            displayAssignments
          });
        setTransactionItem(myTransactionItem);
        setSelectedLineType(foundItemType);
        
        // Update the form
        form.setFieldsValue(myTransactionItem);
      }
    } catch (error) {
      NotificationUtil.error({
        key: 'TransactionItemModal',
        message: 'Error while loading Transaction',
        error
      });
    }
    setLoading(false);
  };

  const unloadModalData = () => {
    form.resetFields();
    setTransactionItem(ReportTransactionItemDTO.create());
    setItemTypeValues([]);
    setSelectedLineType(undefined);
    setSelectedAssignment(null);
    setPicklistAssignment(null);
    setPickListModalVisible(false);
    setLoading(false);
  };

  const handleModalCancel = () => {
    props.onCancel?.();
  };

  const handleModalOk = () => {
    // This will run the form's onFinish function
    form.submit();
  };

  const handleItemTypeOnFinish = (value: string) => {
    const foundItem = itemTypeValues.find(x => x.transactionLineTypeKey === value);
    if (foundItem == null || transactionItem == null) {
      return;
    }

    setSelectedLineType(foundItem);

    // Update the assignments
    setTransactionItem({
      ...transactionItem,
      // We have to do this because it needs to clone all of the objects as well
      displayAssignments: [...foundItem.displayAssignments.map(x => ({ ...x }))]
    });

    // Update the form
    selectedLineType?.displayAssignments.map((_, i) => form.setFieldValue(`displayAssignments[${i}]`, undefined));
    // form.setFieldValue(`displayAssignments[${0}]`, undefined);
    // form.setFieldValue('displayAssignments', foundItem.displayAssignments.map(x => null));
  };
  /// End Modal Functions

  /// PickList Functions
  const handleAssignmentClick = async (record: ReportTransactionItemAssignmentDTO) => {
    // Open the picklist modal
    setSelectedAssignment(record);
    // Dev Note: The MappingItemAssignment must have the displayName cleared out. Why is this a function? Look at the comments to find out!
    setPicklistAssignment(MappingItemAssignmentDTO.fromReportTransactionItemAssignment(record));
    setPickListModalVisible(true);
  };

  const handlePickListClose = () => {
    setPickListModalVisible(false);
    setPicklistAssignment(null);
    setSelectedAssignment(null);
  };

  const handlePickListSelect = async (item: MappingPickListItemModel) => {
    // We need to update the transactionItem with the newly selected item
    if (transactionItem == null || selectedAssignment == null) {
      return;
    }

    const myTransactionItem = { ...transactionItem };
    const foundAssignmentIndex = myTransactionItem.displayAssignments.findIndex(x => x.displayOrder === selectedAssignment.displayOrder);
    if (foundAssignmentIndex < 0) {
      return;
    }
    
    const pickListColumns = await cacheContext.getPickList(authContext.account!.id, authContext.location!.id, selectedAssignment.pickListType , false);
    const nameColumnIndex = [pickListColumns.labelColumn1, pickListColumns.labelColumn2, pickListColumns.labelColumn3].findIndex(label => label.toLowerCase() === 'name');
    const itemName = [item.column1Value, item.column2Value, item.column3Value][Math.max(nameColumnIndex, 0)];

    myTransactionItem.displayAssignments[foundAssignmentIndex].selectedItemName = item.displayValue;
    myTransactionItem.displayAssignments[foundAssignmentIndex].displayValue = item.returnValue;
    myTransactionItem.displayAssignments[foundAssignmentIndex].selectedItemType = item.columnItemTypeValue.toUpperCase();

    setTransactionItem(myTransactionItem);

    // Update the form
    form.setFieldValue(`displayAssignments[${foundAssignmentIndex}]`, item.displayValue);

    // Close the picklist
    setPickListModalVisible(false);
    setPicklistAssignment(null);
    setSelectedAssignment(null);
  };
  /// End PickList Functions

  const handleFormFinish = (values: ReportTransactionItemDTO) => {
    // Concat the values onto the props for edit
    const myValues = ReportTransactionItemDTO.create({ ...props.transactionItem, ...values });

    // Fix LineType and assignments
    myValues.transactionItemId = crypto.randomUUID();
    myValues.transactionLineTypeKey = selectedLineType?.transactionLineTypeKey ?? '';
    myValues.displayAssignments = [...transactionItem.displayAssignments];
    myValues.displayAssignment = myValues.displayAssignments.filter(x => x.key === 'ACCOUNT').map(x => x.displayValue)[0];
    myValues.displayLineType = selectedLineType?.displayTransactionLineType ?? '';

    props.onOk?.(myValues);
  };

  return <Modal
    style={{ top: 225 }}
    width={550}
    open={props.open}
    onCancel={handleModalCancel} // Handles clicks outside the modal window
    bodyStyle={{ paddingBottom: 'unset' }} // Makes the modal JUST a bit cleaner
    footer={<>
      <Button type='default' onClick={handleModalCancel}>Cancel</Button>
      <Button type='primary' onClick={handleModalOk}>Save</Button>
    </>}
  >
    <h2>{props.transactionItem == null ? 'Add' : 'Edit'} Transaction Line</h2>

    <Skeleton active loading={loading}>
      <Form
        size='large'
        layout='horizontal'
        labelCol={{ flex: '160px' }}
        requiredMark={false}
        form={form}
        onFinish={handleFormFinish}
      >
        {/* Item Type */}
        <Form.Item
          label={'Line Type'}
          rules={[{ required: true, message: 'Line Type is required' }]}
          name={NameOf<ReportTransactionItemDTO>('displayLineType')}
        >
          <MultiToolPicker
            // The styles are a hold over from the Advanced Settings page and this will expand it out to the whole space
            style={{ display: 'block' }}
            componentStyle={{ width: '100%' }}
            displayType='ComboBox'
            formatType='String'
            options={itemTypeValues.filter(x => x.allowAddDelete).map((x, i) => ({ displayValue: x.displayTransactionLineType, returnValue: x.transactionLineTypeKey, displayOrder: i }))}
            onFinish={handleItemTypeOnFinish}
          />
        </Form.Item>

        {/* Description */}
        <Form.Item
          label={'Description'}
          rules={[{ required: true, message: 'Description is required' }]}
          name={NameOf<ReportTransactionItemDTO>('displayDescription')}
        >
          <Input.TextArea size='middle' />
        </Form.Item>

        {/* Amount */}
        <Form.Item
          label={'Amount'}
          rules={[{ required: true, message: 'Amount is required' }]}
          name={NameOf<ReportTransactionItemDTO>('displayAmount')}
        >
          <MultiToolPicker
            style={{ display: 'block' }}
            componentStyle={{ width: '100%' }}
            displayType='TextBox'
            formatType='Number'
          />
        </Form.Item>

        {transactionItem.displayAssignments.map((assignment, i) => {
          let name = assignment.displayName;
          return <Form.Item
            key={assignment.displayOrder}
            label={<span className='with-whitespace-break'>{name}</span>}
            name={`displayAssignments[${i}]`}
            getValueProps={() => ({value: assignment.selectedItemName})}
            initialValue={assignment.selectedItemName}
            rules={[{required: assignment.required, message: name + ' is required'}]}
          >
            <Select
              options={[]}
              onKeyDown={(e) => (['Space', 'Enter', 'NumpadEnter'].includes(e.code) && handleAssignmentClick(assignment))}
              onClick={() => handleAssignmentClick(assignment)}
              suffixIcon={<EditFilled />}
              open={false} // Prevents dropdown from opening so we can show the modal onclick
              value={assignment.selectedItemName}
            />
          </Form.Item>;
        })}

      </Form>
    </Skeleton>

    <PickListSelectorModal open={pickListModalVisible} assignment={picklistAssignment} onCancel={handlePickListClose} onSelect={handlePickListSelect} />
  </Modal>;
};

export default TransactionItemModal;
