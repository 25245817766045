interface ReportTransactionReferenceDTO {
  transactionId: string;
  transactionVersion: string;
}
const ReportTransactionReferenceDTO = {
  create: (initValues?: Partial<ReportTransactionReferenceDTO>): ReportTransactionReferenceDTO => {
    const defaults: ReportTransactionReferenceDTO = {
      transactionId: '',
      transactionVersion: '',
    };
    return { ...defaults, ...initValues };
  },
};

export default ReportTransactionReferenceDTO;
