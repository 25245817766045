import React from 'react';
import { Card } from 'antd';
import { Link } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import RouteConfig from 'src/config/RouteConfig';

const LoginPrivacyStatementPage: React.FC = () => {
  return (
    <div className='privacy-statement'>
      {/* This will actually show up at the top right */}
      <Link to={RouteConfig.LOGIN_EMAIL()}>
        <button title='X' className='ant-modal-close'>
          <span className='ant-modal-close-x'>
            <CloseOutlined className='ant-modal-close-icon' />
          </span>
        </button>
      </Link>

      <div className='page-content'>
        <h1>Privacy Policy</h1>

        <Card className='large-text-container'>
        <h2>SCOPE OF THIS PRIVACY POLICY AND OUR ROLE</h2>
    <p>The Back Office is dedicated to protecting your personal information and informing you about how we use your information. This privacy policy applies to your use of the Back Office services, including our website and other related software (collectively “Platform”). This Privacy Policy should be read in conjunction with the Terms of Service. All capitalized proper nouns will have the same definitions and meanings as defined by the Terms of Service. Please review this Privacy Policy periodically, as we may revise it from time to time. If you do not agree with or accept our Privacy Policy in its entirety, you must not access or use the Platform. If you use the Platform following a change to the terms of this Privacy Policy, you agree to accept the revised policies.</p>

    <h2>PERSONAL INFORMATION WE COLLECT</h2>
    <p>At Back Office, we collect personally identifiable information (“PII”) and non-personally identifiable (“Non-PII”) information from you. Personally identifiable information is information that can be used to identify you personally. Non-personally identifiable information is information that must be combined with other information to identify you personally.</p>
    
    <h3>Personally Identifiable Information Collected</h3>
    <p>You will not be required to provide us with any information when you visit our Platform. However, in order to fully use our Platform, we may collect PII such as your name, address, email, and telephone number.</p>

    <h3>Personally Identifiable Information Collected For Use</h3>
    <p>In addition to the information listed above, we may collect licensing information, employee information, and business information from Users.</p>

    <h3>Non-Identifying Information</h3>
    <p>Whenever you use our website, we may collect non-identifying information from you, such as your IP address, location information, age, zip code, gender, browsing history, search history, registration history, interactions with the website, location, referring URL, browser, operating system, data usage, data transferred, and Internet Service Provider. We may also collect information including but not limited to postings you make on the public areas of our website, messages you send to us, and correspondence we receive from other members or third parties about your activities or postings.</p>

    <h2>HOW WE USE YOUR INFORMATION</h2>
    <p>Some of your information will be visible to other Users of the Platform to facilitate communication between Users. We will never sell your information without your permission; however, you agree that we may use your information in the following ways:</p>
    <ul>
        <li>To provide services to you and to operate the Back Office Platform.</li>
        <li>To enhance or improve our Users’ experiences.</li>
        <li>To contact you via email, text, or electronic communications regarding or related to the Back Office Platform.</li>
        <li>To share with other Users and third parties as required and as permitted by you.</li>
        <li>To process your transactions.</li>
        <li>To share your information with third-party partners or third parties hired by us to perform functions and provide services to us subject to confidentiality obligations consistent with this Privacy Policy and on the condition that the third parties use your information only on our behalf and pursuant to our instructions.</li>
    </ul>
    <p>Additionally, we may give your information to law enforcement if we are compelled to by court order, if there has been a violation of any US laws, or if a violation of the Terms of Service or the Privacy Policy has occurred.</p>

    <h2>YOUR PERSONAL INFORMATION RIGHTS AND CHOICES</h2>
    <p>You will be able to access any information contained in your account through our Platform. You may edit that information by removing or changing the information listed in your account. If you have any questions or wish to review, change, or access any of the information collected by us, please contact us at <a href="mailto:support@accountinglink.net">support@accountinglink.net</a>. Additionally, if you wish to opt out of our data collection practices or would like us to remove any of your information, please contact us at <a href="mailto:support@accountinglink.net">support@accountinglink.net</a>. After you have canceled your account, please be aware that we may keep inaccessible copies of your information subject to our data retention policies.</p>

    <h3>Cookies and Tracking</h3>
    <p>We use cookies to provide our Platform and related services to you. Therefore, must have cookies enabled in your browser to use our Platform. Please be aware that some or all of our cookies may be accessible by third parties. We may also employ other tracking methods to understand how you use and interact with our Platform.</p>

    <h3>Third-Party Access to Your Information</h3>
    <p>Although you are entering into an Agreement with Back Office to disclose your information to us, we do use third-party individuals and organizations to assist us, including contractors, web hosts, and others, to allow you to access the Platform.</p>
    <p>Throughout the course of the provision of our services to you, we may delegate our authority to collect, access, use, and disseminate your information. It is, therefore, necessary that you grant the third parties we may use in the course of our business the same rights that you afford us under this Privacy Policy. For this reason, you hereby agree that for every authorization which you grant to us in this Privacy Policy, you also grant to any third party that we may hire, contract, or otherwise retain the services of for the purpose of operating, maintaining, repairing, or otherwise improving or preserving our website or its underlying files or systems. You agree not to hold us liable for the actions of any of these third parties, even if we would normally be held vicariously liable for their actions, and that you must take legal action against them directly should they commit any tort or other actionable wrong against you.</p>

    <h3>Law Enforcement</h3>
    <p>You agree that we may disclose your information to authorities if compelled to by a court order. Additionally, you agree that we may disclose your information if we reasonably believe that you have violated US laws or the terms of our Terms of Service or Privacy Policy or if we believe that a third party is at risk of bodily harm. In the event that we receive a subpoena affecting your privacy, we may elect to notify you to give you an opportunity to file a motion to quash the subpoena, or we may attempt to quash it ourselves, but we are not obligated to do either. We may also proactively report you and release your information without receiving any request from third parties where we believe that it is proper to do so for legal reasons, where your actions violate any law of the United States or any other country having jurisdiction over us, our Platform, or our Terms of Service. You release us from any damages that may arise from or relate to the release of your information to a request from law enforcement agencies or private litigants. We may release your information under the conditions listed in this paragraph, whether it is to individuals or entities and to any state or federal authorities, as required.</p>

    <h3>Opt-Out of Commercial, Non-Commercial Communications and Do Not Track</h3>
    <p>If you decide to provide us with your contact information, you agree that we may send you communications via text and email. However, you may unsubscribe from certain communications by notifying Back Office that you no longer wish to receive these communications; we will endeavor to promptly remove you from our once we have received that request. We currently do not offer functionality for you to opt out through “do not track” listings. If you wish to opt out of certain communications or information collection, please contact us at <a href="mailto:support@accountinglink.net">support@accountinglink.net</a>.</p>

    <h2>THE BACK OFFICE PLATFORM</h2>
    <h3>Third Parties</h3>
    <p>Back Office or other Users may post links to third-party websites on Platform, which may include information that we have no control over. When accessing a third-party site through our Platform, you acknowledge that you are aware that these third-party websites are not screened for privacy or security issues by us, and you release us from any liability for the conduct of these third-party websites.</p>
    <p>Please be aware that this Privacy Policy, and any other policies in place, in addition to any amendments, do not create rights enforceable by third parties. Back Office bears no responsibility for the information collected or used by any advertiser or third-party website. You must review their Terms of Service and Privacy to understand how their information collection practices work.</p>

    <h3>Security Measures</h3>
    <p>We make reasonable attempts to protect your information by using physical and electronic safeguards. For this reason, we use SSL certificates to enhance our Platform security and encryption to enhance our application security. However, as this is the Internet, we can make no guarantees as to the security or privacy of your information. For this reason, we recommend that you use anti-virus software, routine credit checks, firewalls, and other precautions to protect yourself from security and privacy threats.</p>

    <h3>Your California Privacy Rights</h3>
    <p>Back Office permits residents of the State of California to use its Platform and complies with the California Business and Professions Code §§ 22575-22579. If you are a California resident, you may request certain information regarding our disclosure of personal information to any third parties for their direct marketing purposes. Various provisions throughout this Privacy Policy address the requirements of the Californian privacy statutes. Although we do not disseminate your information to third parties without permission, you must presume that we collect electronic information from all visitors. You may contact us at <a href="mailto:support@accountinglink.net">support@accountinglink.net</a> with any questions.</p>

    <h3>Age Compliance</h3>
    <p>We intend to fully comply with American and international laws respecting children’s privacy, including COPPA. Therefore, we do not collect or process any information for any persons under the age of 13. If you are under 13 and using our Platform, please stop immediately and do not submit any information to us. In the event that we have inadvertently collected any information from Users under the age of 13, please contact us immediately.</p>

    <h3>International Transfer</h3>
    <p>Your information may be transferred to - and maintained on - computers located outside of your state, province, country, or other governmental jurisdiction where the privacy laws may not be as protective as those in your jurisdiction. Your consent to this Privacy Policy, followed by your submission of such information, represents your agreement to that transfer. PII and Non-PII that are submitted to Back Office will be collected, processed, stored, disclosed, and disposed of in accordance with applicable U.S. law and this policy. If you are a non-U.S. member, you acknowledge and agree that Back Office may collect and use your Information and disclose it to other entities outside your resident jurisdiction. In addition, such information may be stored on servers located outside your resident jurisdiction. U.S. law may not provide the degree of protection for information that is available in other countries.</p>

    <h3>Merger and Acquisition</h3>
    <p>In the event that the Back Office is involved in a bankruptcy, merger, acquisition, reorganization, or sale of assets, your information may be sold or transferred as part of that transaction. Please be aware that once the information is transferred, your privacy rights may change.</p>

    <h2>Amendments</h2>
    <p>Like our Terms of Service, we may amend this Privacy Policy from time to time. When we amend this Privacy Policy, we will modify the date listed on this Agreement, or we may contact you. You must agree to the amendments as a condition of your continued use of our Platform. If you do not agree, you must immediately cease using our Platform and notify us of your refusal to agree by e-mailing us at <a href="mailto:support@accountinglink.net">support@accountinglink.net</a>.</p>
</Card>
      </div>
    </div>
  );
};

export default LoginPrivacyStatementPage;
