import React, { useContext, useEffect, useState } from 'react';
import { CheckCircleFilled, CloseCircleFilled, DownOutlined, EditFilled, InfoCircleOutlined, PlusOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Col, Input, Modal, Popover, Row, Select, Space, Switch, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { NameOf, NotificationUtil, TableColumnBuilder, TableExpressions } from 'src/utils';
import { useStateAsync } from 'src/hooks';
import MappingController from 'src/api/MappingController';
import MappingItemRuleModel from 'src/models/frontend/MappingItemRuleModel';
import MappingPickListItemModel from 'src/models/frontend/MappingPickListItemModal';
import MappingItemAssignmentDTO from 'src/models/generated/MappingItemAssignmentDTO';
import MappingItemDTO from 'src/models/generated/MappingItemDTO';
import MappingItemRuleFilterCriteriaDTO from 'src/models/generated/MappingItemRuleFilterCriteriaDTO';
import MappingItemRuleFilterDefinitionDTO from 'src/models/generated/MappingItemRuleFilterDefinitionDTO';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import PickListSelectorModal from '../PickListSelectorModal';
import RuleFilterModal from '../RuleFilterModal';
import RuleFilterDefinitionModal from '../RuleFilterDefinitionModal';

import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { MenuOutlined } from '@ant-design/icons';
import RuleFilterDefinitionModalNew from '../RuleFilterDefinitionModalNew';
import RuleFilterModalNew from '../RuleFilterModalNew';
import { CacheContext } from 'src/providers/CacheContext';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const DraggableRow = ({ children, ...props }: RowProps) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({ id: props['data-row-key'] });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 })?.replace(
      /translate3d\(([^,]+),/,
      'translate3d(0,',
    ),
    transition,
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{ touchAction: 'none', cursor: 'move' }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

export interface MappingItemCustomTabNewProps {
  mappingKey: string;
  mappingItem: MappingItemDTO;
  hasUnsavedChanges: boolean;

  /** Called when I say so */
  onChange?: (value: MappingItemDTO) => void;

  /** Updates depending on unsaved changes. Mostly from custom mapping */
  onUnsavedChanges?: (value: boolean) => void;
}

const MappingItemCustomTabNew: React.FC<MappingItemCustomTabNewProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const cacheContext = useContext(CacheContext);

  // Rule Data
  const [ruleData, setRuleData, getRuleDataAsync] = useStateAsync<MappingItemRuleModel[]>([]);
  const [selectedRule, setSelectedRule] = useState<MappingItemRuleModel | null>(null);
  const [loadingRules, setLoadingRules] = useState(false);
  const [submittingRules, setSubmittingRules] = useState(false);

  // Rule Filter
  const [ruleFilterModalVisible, setRuleFilterModalVisible] = useState(false);

  // Rule Definition
  const [ruleDefinitions, setRuleDefinitions, getRuleDefinitionsAsync] = useStateAsync<MappingItemRuleFilterDefinitionDTO[]>([]); // This will now hold the temp definitions after the modal closes
  const [loadingRuleDefinitions, setLoadingRuleDefinitions] = useState(false);
  const [ruleDefinitionVisible, setRuleDefinitionVisible] = useState(false);

  // Rule Table
  const [selectedAssignment, setSelectedAssignment] = useState<MappingItemAssignmentDTO | null>(null);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [pickListModalVisible, setPickListModalVisible] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  
  useEffect(() => {
    loadCurrentRules();
    loadRuleDefinitions();
  }, [props.mappingKey]);

  /* Rule Definitions */
  const loadRuleDefinitions = async () => {
    setLoadingRuleDefinitions(true);
    try {
      const results = await MappingController.getMappingItemRuleDefinitions(authContext.account!.id, authContext.location!.id, props.mappingKey, props.mappingItem.key);
      setRuleDefinitions(results.data);
    } catch (error) {
      NotificationUtil.error({
        key: 'MappingItemCustomTabNew',
        message: 'Error while loading Rule Definitions',
        error
      });
    }
    setLoadingRuleDefinitions(false);
  };

  const handleShowRuleDefinitionModal = () => {
    setRuleDefinitionVisible(true);
  };

  const handleRuleDefinitionModalClose = () => {
    setRuleDefinitionVisible(false);
  };

  const handleRuleDefinitionModalOk = async (newRuleDefinitions: MappingItemRuleFilterDefinitionDTO[]) => {
    setRuleDefinitions(newRuleDefinitions);
    setRuleDefinitionVisible(false);
  };
  /* End Rule Definitions */

  /** Rule Filter */
  const handleShowRuleFilterModal = (rule?: MappingItemRuleModel | null) => {
    console.log('[CustomTab] opening rule', rule);
    setSelectedRule(rule || null);
    setRuleFilterModalVisible(true);
  };

  const handleRuleFilterModalClose = () => {
    setRuleFilterModalVisible(false);
    setSelectedRule(null);
  };

  const handleRuleFilterModalOk = async (mappingItem: MappingItemRuleModel) => {
    // Indicate that we have unsaved changes
    props.onUnsavedChanges?.(true);

    let myRuleData = [...await getRuleDataAsync()];

    // Check if we are adding or updating, based on the selectedRule, removing the old rule as needed
    if (selectedRule != null) {
      myRuleData = myRuleData.filter(x => x.displayOrder !== selectedRule.displayOrder);
    }
    // Update the new rule to be at the end
    else{
      mappingItem.displayOrder = ruleData.length;
    }
    

    myRuleData = [...myRuleData, mappingItem]
      .sort(TableExpressions.NumberSorter('displayOrder')) // Sort them according to the displayOrder
      .map((item, index) => ({ ...item, displayOrder: index + 1 })); // Reset the order so there are no gaps

    setRuleData(myRuleData);
    setRuleFilterModalVisible(false);
  };
  /** End Rule Filter */

  /** Rules */
  const loadCurrentRules = async () => {
    setLoadingRules(true);
    try {
      const results = await MappingController.getMappingItemRules(authContext.account!.id, authContext.location!.id, props.mappingKey, props.mappingItem.key);
      setRuleData(results.data.map(MappingItemRuleModel.fromDTO));
    } catch (error) {
      NotificationUtil.error({
        key: 'MappingItemCustomTabNew',
        message: 'Error while loading Rules',
        error
      });
    }
    setLoadingRules(false);
  };

  const handleSaveRules = async () => {
    setSubmittingRules(true);
    try {
      // Dev Note: The draggable does not like to be update on the fly, so we will need to reorder onSave. I believe this is due to the displayOrder also being the index on the table
      let myRuleData = await getRuleDataAsync();
      myRuleData = myRuleData.map((item, index) => ({ ...item, displayOrder: index + 1 })); // Reset the order so there are no gaps

      // Save those new rules
      await MappingController.saveMappingItemRules(authContext.account!.id, authContext.location!.id, props.mappingKey, props.mappingItem.key, myRuleData.map(MappingItemRuleModel.toDTO));

      // Reload the data to get the complete properties filled out
      await loadCurrentRules();

      // Looks good, propagate the changes up since it holds the collection we used
      props.onChange?.(props.mappingItem);

      // Unblock the user since we have saved the rule changes
      props.onUnsavedChanges?.(false);
      NotificationUtil.success({
        key: 'MappingItemCustomTabNew',
        message: 'Successfully saved changes to custom mapping',
      });
    } catch (error) {
      NotificationUtil.error({
        key: 'MappingItemCustomTabNew',
        message: 'Error while saving Custom Rules',
        error
      });
    }
    setSubmittingRules(false);
  };

  const handleAutoPopulate = async () => {
    setSubmittingRules(true);
    try{

      await MappingController.autoPopulateMappingRules(authContext.account!.id, authContext.location!.id, props.mappingKey, props.mappingItem.key);
      await loadCurrentRules();
      props.onChange?.(props.mappingItem);

      props.onUnsavedChanges?.(false);
      NotificationUtil.success({
        key: 'MappingItemCustomTabNew',
        message: 'Successfully saved changes to custom mapping',
      });
    } catch (error) {
      NotificationUtil.error({
        key: 'MappingItemCustomTabNew',
        message: 'Error while saving Custom Rules',
        error
      });
    }
    setSubmittingRules(false);
  };

  const handleClearIncomplete = async () => {
    if(!props.hasUnsavedChanges){
      finishClearIncomplete();
    }
    else{
      // Show the warning
      Modal.confirm({
      title: 'Pending changes',
      content: 'You have made some changes that have not yet been saved. Please ensure your Custom Mapping assignments are saved prior to continuing.',
      okText: 'Continue',
      okButtonProps: { danger: true, type: 'primary' },
      onOk: () =>{
        finishClearIncomplete();
      },
      cancelText: 'Cancel',
      cancelButtonProps: { type: 'primary' },
      });
    }
    
  };
  const finishClearIncomplete =async() => {
    setSubmittingRules(true);
    try {
      await MappingController.clearIncompleteRules(authContext.account!.id, authContext.location!.id, props.mappingKey, props.mappingItem.key);
      await loadCurrentRules();
      props.onChange?.(props.mappingItem);
      props.onUnsavedChanges?.(false);
      NotificationUtil.success({
        key: 'MappingItemCustomTabNew',
        message: 'Successfully saved changes to custom mapping',
      });
    } catch (error) {
      NotificationUtil.error({
        key: 'MappingItemCustomTabNew',
        message: 'Error while saving Custom Rules',
        error
      });
    }

    setSubmittingRules(false);
  };

  const renderExpandCollapseAll = () => {
    if (expandedKeys.length > 0) {
      return <Button size='large' onClick={() => (setExpandedKeys([]))}>Collapse All</Button>;
    }
    return <Button size='large' onClick={() => (setExpandedKeys(ruleData.map(x => x.displayOrder)))}>Expand All</Button>;
  };
  /** End Rules */

  /** Picklist */
  const handleShowPicklistModal = async (rule: MappingItemRuleModel, assignment: MappingItemAssignmentDTO) => {
    setSelectedRule(rule);
    setSelectedAssignment(assignment);
    setPickListModalVisible(true);
  };

  const handlePickListClose = () => {
    setPickListModalVisible(false);
    setSelectedAssignment(null);
    setSelectedRule(null);
  };

  
  // Should be called when the user has chosen their selection and will close the modal accordingly
  const handlePickListSelect = async (item: MappingPickListItemModel) => {
    // We are waiting to save, so no loader
    try {
      if (selectedRule == null || selectedAssignment == null) {
        return;
      }

      // Indicate that we have unsaved changes
      props.onUnsavedChanges?.(true);

      // No API call. Instead, we are updating the value in array
      setRuleData(prev => {
        // TODO: Needs cleanup
        const ruleIndex = ruleData.findIndex(x => x.displayOrder === selectedRule.displayOrder);
        const assignmentIndex = ruleData[ruleIndex].ruleAssignments.findIndex(x => x.displayOrder === selectedAssignment.displayOrder);

        const rule = prev[ruleIndex];
        const assignment = rule.ruleAssignments[assignmentIndex];
        assignment.displayValue = item.returnValue;
        assignment.selectedItemType = item.columnItemTypeValue.toUpperCase();
        assignment.selectedItemName = item.displayValue;
        rule.ruleAssignments[assignmentIndex] = assignment;
        prev[ruleIndex] = rule;

        return prev;
      });
    } catch (error) {
      NotificationUtil.error({
        key: 'MappingItemDefaultTab',
        message: 'Error while saving Mapping Item Assignment'
      });
    }

    // Close the modal
    handlePickListClose();
  };
  /** End Picklist */

  // Specifically designed for the draggable table
  const onDragEnd = async ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setRuleData((previous) => {
        if (active.id !== over?.id) {
          props.onUnsavedChanges?.(true);
        }
        const activeIndex = previous.findIndex((i) => i.displayOrder === active.id);
        const overIndex = previous.findIndex((i) => i.displayOrder === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const renderCheckmark = (value?: boolean | null) => {
    if (value == null) {
      return null;
    }
    return value
      ? <CheckCircleFilled className='standard-icon icon-with-white-background make-text-green without-margin-important' />
      : <CloseCircleFilled className='standard-icon icon-with-white-background make-text-red without-margin-important' />;
  };

  const tableColumns: ColumnProps<MappingItemRuleModel>[] = [
    {
      key: 'sort',
    },
    TableColumnBuilder.Create<MappingItemRuleModel>('key', '')
      .Width(25)
      .AddRenderer('Custom', (_, record) => (renderCheckmark(record.complete)))
      .Build(),
    TableColumnBuilder.Create<MappingItemRuleModel>('displayName', 'Rule Name')
      .Width(300)
      .AddRenderer('Text')
      .Build(),
    TableColumnBuilder.Create<MappingItemRuleModel>('ruleFilterDisplay', 'Rule Filter')
      .AddRenderer('Text')
      .Build(),
    TableColumnBuilder.Create<MappingItemRuleModel>('ruleAssignments', 'Assignments')
      .AddRenderer('Custom', (_, record) => (record.ruleAssignments.map((x, i) => <div key={i}>{x.displayName} = {x.selectedItemName}</div>)))
      .Build(),
  ];

  return <div className='mapping-item-custom-tab'>
    <Row justify='space-between'>
      <Col>
        <Space className='with-margin-bottom'>
          {/* Change Rule Definition */}
          <Popover
            trigger={ruleData.length > 0 ? 'click' : ''} // This should disable it, rather than needing a whole copy
            overlayStyle={{ maxWidth: 360 }}
            content={<div>
              <p>You must remove all rule filters before changing this mapping item&apos;s custom definition.</p>
              <h3>Current Custom Definition:</h3>
              <div style={{ paddingLeft: 16 }}>
                {ruleDefinitions.filter(x => x.selected).map((x, index) => <p key={index} style={{ margin: 8 }}>{x.displayValue}</p>)}
              </div>
            </div>}
          >
            <Button size='large' disabled={ruleData.length > 0} onClick={handleShowRuleDefinitionModal}>Change Rule Definition</Button>
          </Popover>

          {/* Add Rule Filter */}
          <Popover
            trigger={ruleDefinitions.filter(x => x.selectedOrder > 0).length < 1 ? 'click' : ''} // This should disable it, rather than needing a whole copy
            overlayStyle={{ maxWidth: 360 }}
            content={<div>
              <p>You must add a Rule Definition before a Rule Filter can be created.</p>
            </div>}
          >
            <Button size='large' disabled={ruleDefinitions.filter(x => x.selectedOrder > 0).length < 1} onClick={() => handleShowRuleFilterModal(null)}>Add Rule Filter <PlusOutlined /></Button>
          </Popover>

          {/* Auto-Populate rules */}
          { <Popover
              trigger={ruleDefinitions.filter(x => x.selectedOrder > 0 ).length < 1 ? 'click' : 'hover'}
              overlayStyle={{ maxWidth: 360 }}
              content={
                <div><p>Auto Add automatically builds Custom Mapping rules for any account not already assigned to a rule. After auto-adding the rules, assign the rules created to the desired accounts.</p></div>
              }>
                <Button size='large' disabled={ruleDefinitions.filter(x => x.selectedOrder > 0).length < 1} onClick={handleAutoPopulate}>Auto Add</Button>
            </Popover>}

          {/* Clear Incomplete rules */}
          {ruleData.filter(x => x.complete === false).length > 0 && <Popover
              trigger={ruleDefinitions.filter(x => x.selectedOrder > 0 ).length < 1 ? 'click' : 'hover'}
              overlayStyle={{ maxWidth: 360 }}
              content={
                <div><p>Clearing unassigned rules will remove any Custom Mapping rules that do not have an account assigned.</p></div>
              }>
                <Button size='large' onClick={handleClearIncomplete}>Clear Unassigned</Button>
            </Popover>}

          {/* Delete Selected */}
          {selectedKeys.length > 0 && <Button danger size='large' type='primary' onClick={() => {
            setRuleData(prev => {
              props.onUnsavedChanges?.(true);
              const values = [...prev].filter(x => !selectedKeys.includes(x.displayOrder));
              setSelectedKeys([]);
              return values;
            });
          }}>Delete Selected</Button>}

          {/* Save Changes */}
          {props.hasUnsavedChanges && <Button size='large' type='primary' onClick={handleSaveRules}>Save Changes</Button>}

        </Space>
      </Col>
      <Col>
          {ruleData.length > 0 && renderExpandCollapseAll()}
      </Col>
    </Row>

    <DndContext onDragEnd={onDragEnd}>
      <SortableContext
        items={ruleData.map((i) => i.displayOrder)} // This will need to match the rowKey on the table
        strategy={verticalListSortingStrategy}
      >
        <Table
          rowKey={NameOf<MappingItemRuleModel>('displayOrder')}
          className='condensed-table striped-table borderless-table'
          rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
          loading={loadingRules || loadingRuleDefinitions || submittingRules}
          rowSelection={{
            type: 'checkbox',
            // selectedRowKeys: selectedKeys,
            onChange: (keys) => setSelectedKeys(keys)
          }}
          expandable={{
            expandedRowKeys: expandedKeys,
            onExpandedRowsChange: (keys) => {setExpandedKeys([...keys]);},
            expandIcon: (props) => {
              const onClick = (e: React.MouseEvent<HTMLElement>) => props.onExpand(props.record, e);
              return props.expanded ? <DownOutlined onClick={onClick} style={{ fontSize: 18, verticalAlign: 'middle' }} /> : <RightOutlined onClick={onClick} style={{ fontSize: 18, verticalAlign: 'middle' }} />;
            },
            expandedRowRender: (record, index) => <div style={{ margin: 0 }}>
              <Row className='with-margin-bottom' hidden={props.mappingKey.includes('LIST') && props.mappingKey !== 'ACCOUNTLIST'}>
                <Col flex='200px'>Show In Report</Col>
                <Col flex='350px'>
                  <Switch
                    checked={record.displayReportOn}
                    onChange={value => setRuleData(prev => {
                      props.onUnsavedChanges?.(true);
                      const values = [...prev];
                      values[index].displayReportOn = value;
                      return values;
                    })}
                  />
                </Col>
              </Row>
              <Row className='with-margin-bottom'>
                <Col flex='200px'>Rule Name</Col>
                <Col flex='350px'>
                  <Input
                    value={record.displayName}
                    onChange={value => setRuleData(prev => {
                      props.onUnsavedChanges?.(true);
                      const values = [...prev];
                      values[index].displayName = value.target.value;
                      return values;
                    })}
                  />
                </Col>
              </Row>
              <Row className='with-margin-bottom'>
                <Col flex='200px'>Rule Filter</Col>
                <Col flex='350px'>
                  <Select
                    style={{ minWidth: 220, maxWidth: 350 }}
                    onKeyDown={(e) => (['Space', 'Enter', 'NumpadEnter'].includes(e.code) && handleShowRuleFilterModal(record))}
                    onClick={() => handleShowRuleFilterModal(record)}
                    suffixIcon={<EditFilled />}
                    open={false} // Prevents dropdown from opening so we can show the modal onclick
                    value={record.ruleFilterDisplay}
                  />
                </Col>
              </Row>

              {record.ruleAssignments.map(assignment => (
                <Row key={assignment.key} className='with-margin-bottom'>
                  <Col flex='200px'>
                    <Row justify='space-between' align='middle' style={{ height: '100%' }}>
                      <span style={{ alignSelf: 'flex-start' }}>{assignment.displayName}</span>
                      <Row align='middle' style={{ paddingRight: 8 }}>{renderCheckmark(assignment.complete)}</Row>
                    </Row>
                  </Col>
                  <Col flex='350px'>
                    <Select
                      style={{ minWidth: 220 }}
                      options={[]}
                      onKeyDown={(e) => (['Space', 'Enter', 'NumpadEnter'].includes(e.code) && handleShowPicklistModal(record, assignment))}
                      onClick={() => handleShowPicklistModal(record, assignment)}
                      suffixIcon={<EditFilled />}
                      open={false} // Prevents dropdown from opening so we can show the modal onclick
                      value={assignment.selectedItemName}
                    />
                  </Col>
                </Row>
              ))}
            </div>,
          }}
          pagination={false}
          columns={tableColumns}
          dataSource={ruleData}
          components={{
            body: {
              row: DraggableRow,
            },
          }}
        />
      </SortableContext>
    </DndContext>

    <RuleFilterDefinitionModalNew
      open={ruleDefinitionVisible}
      mappingKey={props.mappingKey}
      mappingItemKey={props.mappingItem.key}
      onCancel={handleRuleDefinitionModalClose}
      onOk={handleRuleDefinitionModalOk}
    />
    <RuleFilterModalNew
      open={ruleFilterModalVisible}
      mappingKey={props.mappingKey}
      mappingItemKey={props.mappingItem.key}
      selectedRule={selectedRule}
      onCancel={handleRuleFilterModalClose}
      onOk={handleRuleFilterModalOk}
    />
    <PickListSelectorModal
      open={pickListModalVisible}
      assignment={selectedAssignment}
      onCancel={handlePickListClose}
      onSelect={handlePickListSelect}
    />
  </div>;
};

export default MappingItemCustomTabNew;