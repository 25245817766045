interface MappingFilterCriteriaDTO {
  key: string;
  displayValue: string;
}
const MappingFilterCriteriaDTO = {
  create: (initValues?: Partial<MappingFilterCriteriaDTO>): MappingFilterCriteriaDTO => {
    const defaults: MappingFilterCriteriaDTO = {
      key: '',
      displayValue: '',
    };
    return { ...defaults, ...initValues };
  },
};

export default MappingFilterCriteriaDTO;
