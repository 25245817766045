interface AuditTrailItemViewFooterAttributeDTO {
  order: number;
  displayLabel: string;
  displayValue: string;
}
const AuditTrailItemViewFooterAttributeDTO = {
  create: (initValues?: Partial<AuditTrailItemViewFooterAttributeDTO>): AuditTrailItemViewFooterAttributeDTO => {
    const defaults: AuditTrailItemViewFooterAttributeDTO = {
      order: 0,
      displayLabel: '',
      displayValue: '',
    };
    return { ...defaults, ...initValues };
  },
};

export default AuditTrailItemViewFooterAttributeDTO;
