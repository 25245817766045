import React, { useContext, useMemo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthenticationContext } from '../providers/AuthenticationContext';
import Pages from 'src/config/PageConfig';
import PublicLayout from 'src/layouts/PublicLayout';
import UserLayout from 'src/layouts/UserLayout';
import NotFoundComponent from 'src/components/NotFoundComponent';
import LoadingComponent from 'src/components/LoadingComponent';
import RouteConfig from 'src/config/RouteConfig';
import SessionWatcher from 'src/components/SessionWatcher';

const App: React.FC = (): JSX.Element => {
  const authContext = useContext(AuthenticationContext);

  // Let's try to generate the pages
  const renderPages = (): JSX.Element[] => {
    if (!authContext.isInitialized) {
      // Called when not initialized. Should return the layout and a loading animation
      // Dev Note: The goal here is to provide a nice reload experience, so we aren't just showing a huge loading bar. Instead, the layout remains while the data is loaded from the API, given the cached bearer token
      let pages = Pages.MyPages
        .map(page => {
          const Layout = (page.privateComponent != null ? page.privateLayout : page.publicLayout) ?? UserLayout;

          return <Route
            key={page.key}
            path={page.route}
            element={<Layout><LoadingComponent /></Layout>}
          />;
        });

      pages.push(<Route key='404' path='*' element={<LoadingComponent />} />);
      return pages;
    } else if (authContext.isLoggedIn) {
      // Call after initialization and when logged in
      // Dev Note: This function can, and probably should, be updated to not filter out routes without a private component. Instead, it should render private, then public, then NotFound in that order, for all routes
      let pages = Pages.MyPages
        .filter(x => x.privateComponent != null)
        .map(page => {
          const Layout = page.privateLayout ?? UserLayout;
          const Component = page.privateComponent ?? NotFoundComponent;

          return <Route
            key={page.key}
            path={page.route}
            element={<Layout><Component /></Layout>}
          />;
        });

      // Add the 404 page
      pages.push(<Route key='404' path='*' element={<UserLayout><NotFoundComponent /></UserLayout>} />);
      return pages;
    } else {
      // Initialized and not logged in
      let pages = Pages.MyPages
        .filter(x => x.publicComponent != null)
        .map(page => {
          const Layout = page.publicLayout ?? PublicLayout;
          const Component = page.publicComponent ?? NotFoundComponent;

          return <Route
            key={page.key}
            path={page.route}
            element={<Layout><Component /></Layout>}
          />;
        });

      // Add the 404 page
      // Dev Note: This would be a perfect place to have a not found page or similar, rather than a direct link to the login page
      const redirectUrl = authContext.userRole === 'agent' ? RouteConfig.LOGIN_AGENT() : RouteConfig.LOGIN_EMAIL();
      pages.push(<Route key='404' path='*' element={<UserLayout><Navigate replace to={redirectUrl} /></UserLayout>} />);
      return pages;
    }
  };

  let renderedPages: JSX.Element[] = useMemo(renderPages, [authContext.isInitialized, authContext.isLoggedIn]);

  // Done rendering pages
  return (
    <>
      <SessionWatcher />
      <Routes key='routes'>
        {renderedPages}
      </Routes>
    </>
  );
};

export default App;
