import React, { useContext, useEffect, useState } from 'react';
import { Space, Table, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { NameOf, NotificationUtil, TableExpressions, TableColumnBuilder } from 'src/utils';
import ReportController from 'src/api/ReportController';
import ReportSummaryDTO from 'src/models/generated/ReportSummaryDTO';
import ReportSummaryItemDTO from 'src/models/generated/ReportSummaryItemDTO';
import { SharedReportPageProps } from './SharedPageProps';

const PrintingReportSummaryPage: React.FC<SharedReportPageProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [tableData, setTableData] = useState<ReportSummaryDTO[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadTableData();
  }, [props.startDate, props.endDate]);

  const loadTableData = async () => {
    if (authContext.account == null || authContext.location == null || props.startDate == null && props.endDate == null) {
      return;
    }

    setLoading(true);
    try {
      const results = await ReportController.getReportSummary(authContext.account.id, authContext.location.id, props.startDate!, props.endDate!);

      // Sort data
      let sortedData = [...results.data].sort(TableExpressions.Sorters.NumberSorter('displayOrder')).map(x => ({
        ...x,
        // Sort inner array
        items: TableExpressions.Methods.FixSortOrderNumber(x.items.sort(TableExpressions.Sorters.NumberSorter('displayOrder')), 'displayOrder')
      }));

      // Now sort the outer array
      sortedData = TableExpressions.Methods.FixSortOrderNumber(sortedData, 'displayOrder');

      setTableData(sortedData);
    } catch (error) {
      NotificationUtil.error({
        key: 'PrintingReportWarning',
        message: 'Error while loading Report data',
        error
      });
    }
    setLoading(false);
  };

  const tableColumns: ColumnProps<ReportSummaryItemDTO>[] = [
    TableColumnBuilder.Create<ReportSummaryItemDTO>('displayType', 'Type')
      .Width(300)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportSummaryItemDTO>('displayCount', 'Count')
      .Width(110)
      .Align('right')
      .Build(),
    TableColumnBuilder.Create<ReportSummaryItemDTO>('displayAmount', 'Amount')
      .Width(160)
      .Align('right')
      .AddRenderer('Currency', true, true)
      .Build(),
  ];

  // Naming is hard, and I am being a bit lazy here but a proper implementation is messy
  const tableWithoutCountColumns: ColumnProps<ReportSummaryItemDTO>[] = [tableColumns[0], tableColumns[2]];

  // TODO: Attach a loader to the table render event
  // TODO: Add a "Data not found" or something when nothing is loaded
  // TODO: Do that for the original report as well
  return (
    <div className='printing-report-summary'>
      {tableData.map((record, index) => (
        <div key={index} className='with-margin-bottom'>
          {/* This forces the title and table into the same row */}
          <Space direction='vertical'>
            <h2>{record.displayName}</h2>
            <Table
              rowKey={NameOf<ReportSummaryItemDTO>('displayOrder')}
              className='less-condensed-table striped-table borderless-table'
              rowClassName={(_, index) => (index % 2 ? 'striped-row' : '')}
              size='small'
              pagination={false}
              columns={record.showCountColumn ? tableColumns : tableWithoutCountColumns}
              dataSource={record.items}
              summary={pageData => {
                if (record.showCountColumn) {
                  return <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>
                      <Typography.Text strong>Total</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align='right' className='with-border-top'>
                      <Typography.Text strong>{record.displayItemTotalCount}</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align='right' className='with-border-top'>
                      <Typography.Text strong>{TableExpressions.Renderers.Currency('displayItemTotalAmount', true)('', record)}</Typography.Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>;
                }

                return <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <Typography.Text strong>Total</Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align='right' className='with-border-top'>
                    <Typography.Text strong>{TableExpressions.Renderers.Currency('displayItemTotalAmount', true)('', record)}</Typography.Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>;
              }}
            />
          </Space>
        </div>
      ))}
    </div>
  );
};

export default PrintingReportSummaryPage;
