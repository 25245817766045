interface SelectionOptionDTO {
  returnValue: string;
  displayValue: string;
  displayOrder: number;
}
const SelectionOptionDTO = {
  create: (initValues?: Partial<SelectionOptionDTO>): SelectionOptionDTO => {
    const defaults: SelectionOptionDTO = {
      returnValue: '',
      displayValue: '',
      displayOrder: 0,
    };
    return { ...defaults, ...initValues };
  },
};

export default SelectionOptionDTO;
