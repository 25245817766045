import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Divider, Modal, Row, Skeleton, Space } from 'antd';
import { Link } from 'react-router-dom';
import IntegrationController from 'src/api/IntegrationController';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { NotificationUtil, Sleep, StringUtil } from 'src/utils';
import { useInterval } from 'src/hooks';
import SageIntacctConnectModal, { SageIntacctConnectModalProps } from 'src/components/modals/SageIntacctConnectModal';
import IntegrationDTO from 'src/models/generated/IntegrationDTO';

const LocationSettingsIntegrations: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const windowRef = useRef<Window | null>(null);
  const [tableData, setTableData] = useState<IntegrationDTO[]>([]);
  const [selectedIntegrationKey, setSelectedIntegrationKey] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingOther, setLoadingOther] = useState(false); // Cannot be bothered for a proper name, uhgggg
  const [submitting, setSubmitting] = useState(false);
  const [sageConnectionModalVisible, setSageConnectionModalVisible] = useState(false);

  // OnLoad
  useEffect(() => {
    loadTableData();
  }, []);

  // Check the window ref to see if we need to refresh
  useInterval(() => {
    if (windowRef.current != null && windowRef.current.closed) {
      checkSelectedIntegration();
    }
  }, 1000);

  // Another interval check against the API every 7 seconds in case the tab isn't closed, which many people won't do
  useInterval(() => {
    if (windowRef.current != null) {
      checkSelectedIntegration();
    }
  }, 7000);

  // Check the currently selected integration to see if we have connected or not
  const checkSelectedIntegration = async () => {
    // Check if we have a key or are already loading
    if (StringUtil.IsNullOrEmpty(selectedIntegrationKey) || loadingOther) {
      return;
    }

    // Load from API
    setLoadingOther(true);
    const results = await IntegrationController.getIntegrations(authContext.account!.id, authContext.location!.id);
    const connectingItem = results.data.find(x => x.key === selectedIntegrationKey);
    if (connectingItem == null) {
      // Something horrible has happened
      NotificationUtil.error({
        key: 'LocationSettingsIntegrations',
        message: 'Error while refreshing Integrations',
        description: 'Please refresh your browser window. If the problem persists, contact support'
      });
    } else if (connectingItem.connected) {
      // Reset the flags, we have a winner
      windowRef.current = null;
      setSelectedIntegrationKey('');

      // I could very well just push the updates into the Array but I didn't generate the URLs and am feeling lazy
      loadTableData();
    }
    setLoadingOther(false);
  };

  const loadTableData = async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    try {
      const results = await IntegrationController.getIntegrations(authContext.account!.id, authContext.location!.id);

      // displayOrder isn't a number, so we sort on strings
      const sortedData = [...results.data].sort((a, b) => (a.displayOrder ?? '').localeCompare(b.displayOrder ?? ''));

      setTableData(sortedData);
    } catch (error) {
      NotificationUtil.error({
        key: 'LocationSettingsIntegrations',
        message: 'Error while loading Integrations',
        description: 'Please refresh your browser window. If the problem persists, contact support',
        error
      });
    }
    setLoading(false);
  };

  const usesPopupAuth = (key: string) => {
    return ['QBOUS', 'QBOCA'].includes(key.toUpperCase());
  };

  const handleDisconnectClick = (integration: IntegrationDTO) => {
    Modal.confirm({
      title: 'Do you want to disconnect this integration?',
      content: <div>
        This will disconnect this location from {integration.displayName}. You can reconnect at a later time but will need the proper credentials to do so.
      </div>,
      okText: 'Disconnect',
      okType: 'primary',
      okButtonProps: { danger: true },
      onOk: async () => {
        setSubmitting(true);
        try {
          const result = await IntegrationController.requestDisconnect(authContext.account!.id, authContext.location!.id, integration.key);

          // Looks good, reload page
          await loadTableData();
        } catch (error) {
          NotificationUtil.error({
            key: 'LocationSettingsIntegrations',
            message: 'Error while loading Integrations',
            description: 'Please refresh your browser window. If the problem persists, contact support'
          });
        }
        setSubmitting(false);
      }
    });
  };

  // Runs when the user clicks connect on the integration in the UI
  const handleConnectClick = async (integration: IntegrationDTO) => {
    try {
        if (usesPopupAuth(integration.key)) {
          setSelectedIntegrationKey(integration.key);
          const result = await IntegrationController.getRedirectUrl(authContext.account!.id, authContext.location!.id, integration.key!);
          // We need to do a little work on the URL. It can't just be the whole url, but needs to start with "//" idk why, it just does
          // So this will remove the http or https or whatever from the start, unless it doesn't have it, then we just ignore it #futureProofing
          let connectURL = result.data.indexOf('//') > 0 ? result.data.slice(result.data.indexOf('//')) : result.data;
          windowRef.current = window.open(connectURL, '_blank');
          
        } else if (integration.key === 'SAGEINT') {      
          setSageConnectionModalVisible(true);
          setSelectedIntegrationKey(integration.key);
        }
    }
    catch (error) {
      NotificationUtil.error({
        key: 'LocationSettingsIntegrations',
        message: 'Error while loading Integrations',
        description: 'Please refresh your browser window. If the problem persists, contact support'
      });
    }
    
  };

  const handleCancelConnection = () => {
    setSageConnectionModalVisible(false);
  };

  // runs when the user is entering credentials through a modal rather than 
  // an OAuth popup, and they press save
  const handleConnectionModalSubmit = async (formData: any) => {
    
    setSageConnectionModalVisible(false);
    setLoading(true);
    
    try {
      await IntegrationController.requestConnect(authContext.account!.id, authContext.location!.id, selectedIntegrationKey, formData);
      await loadTableData();
    } catch (error) {
      let integration = tableData.find(i => i.key = selectedIntegrationKey);
      NotificationUtil.error({
        key: 'LocationSettingsIntegrations',
        message: 'Error while connecting to ' + integration?.displayName,
        description: 'Something went wrong',
        error
      });
    }

    setLoading(false);
    
  };

  const renderContent = () => {
    const pages = tableData.map(x => {
      return <div key={x.key}>
        <h2>{x.displayName}</h2>
        {/* Connected details */}
        {x.connected && <Row>
          <Col flex='150px'>Connected</Col>
          <Col flex='300px'>{x.connectedDate?.format('LLL')}</Col>
          <Col>
            <Button type='primary' size='large' onClick={() => handleDisconnectClick(x)}>Disconnect</Button>
          </Col>
        </Row>}
        {x.connected && <Row>
          <Col flex='150px'>Message</Col>
          <Col flex='300px'>{x.connectedMessage}</Col>
        </Row>}

        {/* Not connected message */}
        {!x.connected && <Row>
          <Col flex='150px'>Not Connected</Col>
          <Col flex='300px'>Select &apos;Connect&apos; to start!</Col>
          <Col>
            <Button type='primary' size='large' onClick={() => handleConnectClick(x)}>Connect</Button>
          </Col>
        </Row>}
        <Divider />
      </div>;
    });

    // The divider is in here because it looks way better not to have it hanging out outside the loader
    return <>
      <Divider />
      {pages}
    </>;
  };

  // Looks like we will have a list
  return (
    <div className='location-settings-integrations'>
      <p style={{width: 800}}>Accounting Link is designed to export to the Accounting System of your choice. To establish the connection between Accounting Link and the Accounting System, simply click <b>&lsquo;Connect&rsquo;</b> and follow the prompts to connect to the appropriate Company File. Once completed, refresh this page. Please note that if the connection is lost to the Accounting System, transactions will no longer be processed. To restore the connection, select <b>&lsquo;Connect&rsquo;</b>.</p>
      {loading && <Skeleton active />}
      {!loading && renderContent()}
      <SageIntacctConnectModal 
        open={sageConnectionModalVisible}
        onCancel={handleCancelConnection}
        onSubmit={handleConnectionModalSubmit}
      ></SageIntacctConnectModal>

      <Modal
        className='integration-verification-modal'
        centered
        width={600}
        open={!StringUtil.IsNullOrEmpty(selectedIntegrationKey) && usesPopupAuth(selectedIntegrationKey)}
        footer={<>
          <Button type='primary' onClick={() => handleConnectClick(tableData.find(x => x.key === selectedIntegrationKey)!)}>Connect</Button>
          <Button onClick={() => {
            setSelectedIntegrationKey('');
            windowRef.current = null;
          }}>Cancel</Button></>}
        closable={false}
        maskClosable={false}
      >
        <h2>Connecting...</h2>
        <p>A new tab or window will open momentarily where you can proceed with the connection process. If you accidentally closed the window, click 'Connect' below to relaunch the window. If you do not wish to connect at this time, click 'Cancel' below.</p>
      </Modal>
    </div>
  );
};

export default LocationSettingsIntegrations;
