import React, { createContext, useState } from 'react';
import MappingPickListDTO from 'src/models/generated/MappingPickListDTO';
import MappingController from 'src/api/MappingController';
import MappingPickListModel from 'src/models/frontend/MappingPickListModel';
import AccountDTO from 'src/models/generated/AccountDTO';
import LocationDTO from 'src/models/generated/LocationDTO';
import AccountController from 'src/api/AccountController';
import LocationController from 'src/api/LocationController';
import IntegrationDTO from 'src/models/generated/IntegrationDTO';
import IntegrationController from 'src/api/IntegrationController';

export interface CacheContextType {
  // Going to be a place to load data. So far, we only have the picklist stuff, which will be mostly static per session

  // These are used for debugging information. Using them anywhere else can and will cause issues
  /** DEBUG USE ONLY, DO NOT USE */
  pickLists: { [key: string]: MappingPickListModel };
  /** DEBUG USE ONLY, DO NOT USE */
  accounts: AccountDTO[];
  /** DEBUG USE ONLY, DO NOT USE */
  locations: { [key: string]: LocationDTO[] };
  integrations: { [key: string]: IntegrationDTO[] };

  /** Loads a given pick list from the api, caching the value for future calls */
  getPickList: (accountId: string, locationId: string, pickListType: string, refresh?: boolean) => Promise<MappingPickListModel>;
  getAccounts: (refresh?: boolean) => Promise<AccountDTO[]>;
  getLocations: (accountId: string, refresh?: boolean) => Promise<LocationDTO[]>;
  updateLocation: (accountId: string, newLocation: LocationDTO) => Promise<void>;
  getIntegrations: (accountId: string, locationId: string) => Promise<IntegrationDTO[]>;

  /** Clears all caches */
  clearCache: () => Promise<void>;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const CacheContext = createContext<CacheContextType>(undefined!);

export const CacheProvider = (props: React.PropsWithChildren<any>) => {
  const [pickLists, setPickLists] = useState<{ [key: string]: MappingPickListModel }>({});
  const [accounts, setAccounts] = useState<AccountDTO[]>([]);
  const [locations, setLocations] = useState<{ [key: string]: LocationDTO[] }>({});
  const [integrations, setIntegrations] = useState<{ [key: string]: IntegrationDTO[] }>({});

  const getAccounts = async (refresh: boolean = false) => {
    // Check the accounts for accounts
    if (!refresh && Object.keys(accounts).length > 0) {
      return Object.values(accounts);
    }

    // Okay, no accounts, load them up
    const results = await AccountController.getAccounts();

    // We do not need the functional approach since we simply overriding the previous object
    setAccounts(results.data);

    return results.data;
  };

  const getLocations = async (accountId: string, refresh: boolean = false) => {
    // We can look directly at the PickList object because we have not entered the async context yet
    const key = accountId;
    const foundLocations = locations[key];

    if (!refresh && foundLocations != null && foundLocations.length > 0) {
      return foundLocations;
    }

    // No locations, loading required
    const results = await LocationController.getLocations(accountId);

    // Since we had an await, we will need to do the functional approach
    setLocations(prev => {
      prev[key] = results.data;
      return prev;
    });

    return results.data;
  };

  const updateLocation = async (accountId: string, newLocation: LocationDTO) => {
    const foundLocations = locations[accountId];
    if(foundLocations != null && foundLocations.length > 0){
      foundLocations.splice(foundLocations.findIndex(x => x.id == newLocation.id), 1, newLocation);
      setLocations(prev =>{
      prev[accountId] = foundLocations;
      return prev;
    });
    }
  };

  const getPickList = async (accountId: string, locationId: string, pickListType: string, refresh: boolean = false) => {
    // We can look directly at the PickList object because we have not entered the async context yet
    const pickListKey = `${accountId}-${locationId}-${pickListType}`;
    let foundPickList = pickLists[pickListKey];

    if (foundPickList == null || refresh) {
      // Welp, we will need to load from the API now
      const pickListRequest = new Promise<MappingPickListDTO>((resolve, reject) => {
        MappingController.getPickList(accountId, locationId, pickListType, refresh)
          .then(x => resolve(x.data))
          .catch(err => reject(err));
      });

      // Convert result to the model format and then loop, giving the pickListItems a key. It doesn't have to be valid, just unique for a given pickList array
      foundPickList = MappingPickListModel.fromDTO(await pickListRequest);
      foundPickList.pickListItems.forEach((x, i) => x.key = i.toString());

      // Okay, now we can take the result and put it into the state. We must use the functional approach after the await
      setPickLists(prev => {
        prev[pickListKey] = foundPickList;
        return prev;
      });
    }

    return Promise.resolve(foundPickList);
  };

  const getIntegrations = async (accountId: string, locationId: string, refresh: boolean = false) => {
    const key = accountId + locationId;
    let foundIntegrations = integrations[key];

    if (!foundIntegrations || refresh) {
      const newIntegrations = new Promise<IntegrationDTO[]>((resolve, reject) => {
        IntegrationController.getIntegrations(accountId, locationId)
          .then(x => resolve(x.data))
          .catch(err => reject(err));
      });
  
      foundIntegrations = await newIntegrations;
      setIntegrations(prev => {
        prev[key]=foundIntegrations;
        return prev;
      });
    }

    return Promise.resolve(foundIntegrations);
  };

  const clearCache = async (): Promise<void> => {
    // Clear cache by setting them to default
    return new Promise<void>((resolve) => {
      setPickLists({});
      setAccounts([]);
      setLocations({});

      resolve();
    });
  };

  return (
    <CacheContext.Provider value={{ getPickList, getAccounts, getLocations, clearCache, getIntegrations, updateLocation, pickLists, accounts, locations, integrations }}>
      {props.children}
    </CacheContext.Provider>
  );
};
