import { CheckCircleFilled } from '@ant-design/icons';
import { Button, Form, Modal, Space } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import UserController from 'src/api/UserController';
import UserProfileDTO from 'src/models/generated/UserProfileDTO';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { NameOf, NotificationUtil } from 'src/utils';
import AuthCodeInput from './AuthCodeInput';
import DelayButton from './DelayButton';

export interface PhoneVerificationModalFormData {
  authCode: string;
}

export interface PhoneVerificationModalProps {
  phonenumber: string;
  open: boolean;
  onCancel: () => void;
  onFinish: (userProfile: UserProfileDTO) => void;
}

const PhoneVerificationModal: React.FC<PhoneVerificationModalProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [form] = Form.useForm();
  const [sendingVerificationCode, setSendingVerificationCode] = useState(false);

  useEffect(() => {
    if (!props.open) {
      return;
    }
    form.resetFields();
    sendVerificationCode();
  }, [props.open]);

  const sendVerificationCode = async () => {
    setSendingVerificationCode(true);
    try {
      const results = await UserController.addTwoFactorPhoneRequest(props.phonenumber);
      NotificationUtil.success({
        key: 'PhoneVerificationModal',
        message: 'Notification Sent!',
        description: 'Please check your mobile device'
      });
    } catch (error) {
      NotificationUtil.error({
        key: 'PhoneVerificationModal',
        message: 'Error while sending SMS. Please refresh your browser and try again',
        error
      });
    }
    setSendingVerificationCode(false);
  };

  const handleFinish = async (values: PhoneVerificationModalFormData) => {
    setSendingVerificationCode(true);
    try {
      const results = await UserController.addTwoFactorPhoneResponse(props.phonenumber, values.authCode);
      NotificationUtil.success({
        key: 'PhoneVerificationModal',
        message: 'Phone Verification',
        description: 'Success! Please verify your Two-Factor Preference'
      });
      props.onFinish && props.onFinish(results.data);
    } catch (error) {
      NotificationUtil.error({
        key: 'PhoneVerificationModal',
        message: 'Phone Verification',
        description: 'Error while saving verification. Please try again later',
        error
      });
    }
    setSendingVerificationCode(false);
  };

  return <Modal
    className='phone-verification-modal'
    centered
    width={450}
    open={props.open}
    footer={null}
    maskClosable={false}
    onCancel={props.onCancel}
  >
    <h2>Phone Verification</h2>
    <p><span className='make-text-green with-text-size-extra-large'><CheckCircleFilled className='icon-with-white-background' /></span> <span className='with-margin-left-small'>SMS verification code sent to {props.phonenumber}</span></p>
    <p>Please enter the confirmation code that was sent to your device</p>

    <Form
      layout='vertical'
      size='large'
      style={{ width: 333 }}
      form={form}
      onFinish={handleFinish}
    >
      <Form.Item
        name={NameOf<PhoneVerificationModalFormData>('authCode')}
        rules={[
          { required: true, message: 'Auth Code is required', min: 6 }
        ]}>
        <AuthCodeInput characters={6} />
      </Form.Item>
      <DelayButton type='link' size='large' delay={30} className='with-margin-bottom' block onClick={sendVerificationCode}>Resend Code</DelayButton>
      <Space className='with-equal-grid-container with-margin-bottom'>
        <Button type='primary' htmlType='submit' block loading={sendingVerificationCode}>Validate</Button>
        <Button type='primary' block loading={sendingVerificationCode} onClick={props.onCancel}>Cancel</Button>
      </Space>
    </Form>
  </Modal>;
};

export default PhoneVerificationModal;
