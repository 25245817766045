import React from 'react';
import { Tabs, TabsProps } from 'antd';
import { StringUtil } from 'src/utils';
import { useQueryParam } from 'src/hooks';
import LocationSettingsAdvancedSettings from './LocationSettingsAdvancedSettings';
import LocationSettingsDetails from './LocationSettingsDetails';
import LocationSettingsIntegrations from './LocationSettingsIntegrations';
import LocationSettingsMapping from './LocationSettingsMapping';

const LocationSettingsPage: React.FC = (props) => {
  const [tabQueryParam, setTabQueryParam] = useQueryParam('tab');

  const handleTabOnChange = (key: string) => {
    setTabQueryParam(key);
  };

  const items: TabsProps['items'] = [{
    key: '1',
    label: 'Location Details',
    children: <LocationSettingsDetails />
  }, {
    key: '2',
    label: 'Integrations',
    children: <LocationSettingsIntegrations />
  }, {
    key: '3',
    label: 'Mapping',
    children: <LocationSettingsMapping />
  }, {
    key: '4',
    label: 'Advanced Settings',
    children: <LocationSettingsAdvancedSettings />
  }];

  return (
    <div className='location-settings-page'>
      <h1>Settings</h1>
      <Tabs
        defaultActiveKey={!StringUtil.IsNullOrEmpty(tabQueryParam) ? tabQueryParam : undefined}
        onChange={handleTabOnChange}
        items={items}
      />
    </div>
  );
};

export default LocationSettingsPage;
