import React from 'react';
import { Popover, PopoverProps } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import './SimplePopover.less';

interface CommonProps {
  trigger?: PopoverProps['trigger'];
  placement?: PopoverProps['placement'];
}
// Dev Note: Conditional React props. This will change the type for 'content' when 'dangerouslySetHTML' is true or false. This is NEEDED for the internal dangerouslySetInnerHTML
type ConditionalProps = {
  /** Popover content as a react element. If an html string, please set **dangerouslySetHTML** to true */
  content: React.ReactNode;
  dangerouslySetHTML?: false;
} | {
  /** Popover content that is passed to **dangerouslySetHTML** */
  content: string;
  dangerouslySetHTML: true;
};

export type SimplePopoverProps = CommonProps & ConditionalProps;

/** Designed as a simplified popover since most of the ones we use are basically the same. This will handle dangerously setting the html for us when enabled */
const SimplePopover: React.FC<React.PropsWithChildren<SimplePopoverProps>> = (props) => {
  const content = props.dangerouslySetHTML
    ? <div className='popover-custom-content-container' dangerouslySetInnerHTML={{ __html: props.content }}></div>
    : <div className='popover-custom-content-container'>{props.content}</div>;
  const children = props.children != null ? props.children : <InfoCircleOutlined className='standard-icon' />;
  const trigger = props.trigger != null ? props.trigger : 'click';
  const placement = props.placement != null ? props.placement : 'right';

  return (<Popover
    trigger={trigger}
    content={content}
    placement={placement}
  >
    {children}
  </Popover>);
};

export default SimplePopover;