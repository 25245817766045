import moment from 'moment';
import { ObjectUtil } from 'src/utils';

interface LocationDTO {
  id: string;
  locationKey: string;
  displayName: string;
  partnerAccountNumber: string;
  partnerDisplayName: string;
  beginDate: moment.Moment | null;
  mappingIssueCount: number;
  postingIssueCount: number;
  openTileCount: number;
  statusAsOf: moment.Moment | null;
  Status: boolean;
}
const LocationDTO = {
  create: (initValues?: Partial<LocationDTO>): LocationDTO => {
    const defaults: LocationDTO = {
      id: '',
      locationKey: '',
      displayName: '',
      partnerAccountNumber: '',
      partnerDisplayName: '',
      beginDate: null,
      mappingIssueCount: 0,
      postingIssueCount: 0,
      openTileCount: 0,
      statusAsOf: null,
      Status: true
    };
    const fixedInitValues: Partial<LocationDTO> = initValues != null
      ? {
        beginDate: ObjectUtil.ToMoment(initValues.beginDate),
        statusAsOf: ObjectUtil.ToMoment(initValues.statusAsOf),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default LocationDTO;
