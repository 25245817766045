import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { ArrayUtil, ObjectUtil, StringUtil } from 'src/utils';
import ReportWarningDTO, { LinkTypeType, LinkTypeValues } from '../generated/ReportWarningDTO';

interface ReportWarningModel {
  /** Auto Generated field */
  key: string;

  /** This will be NULL when the 'LinkType' = MAPPING */
  displayDate: moment.Moment | null;
  displayType: string;
  displayDescription: string;
  displayWarning: string;
  displayWarningTimeStamps: Record<string, moment.Moment>|null;
  linkType: LinkTypeType | null;
  /** If 'LinkType' = Transaction then this will be the TransactionId to filter the transaction report for */
  linkId: string;
}
const ReportWarningModel = {
  create: (initValues?: Partial<ReportWarningModel>): ReportWarningModel => {
    const defaults: ReportWarningModel = {
      key: '',
      displayDate: null,
      displayType: '',
      displayDescription: '',
      displayWarning: '',
      displayWarningTimeStamps: null,
      linkType: null,
      linkId: '',
    };
    const fixedInitValues: Partial<ReportWarningModel> = initValues != null
      ? {
        key: StringUtil.IsNullOrEmpty(initValues.key) ? uuidv4() : initValues.key,
        displayDate: ObjectUtil.ToMoment(initValues.displayDate),
        linkType: ArrayUtil.PickFromList(initValues.linkType, LinkTypeValues),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
  toDTO: (model: ReportWarningModel): ReportWarningDTO => ({
    displayDate: model.displayDate,
    displayType: model.displayType,
    displayDescription: model.displayDescription,
    displayWarning: model.displayWarning,
    displayWarningTimeStamps: model.displayWarningTimeStamps,
    linkType: model.linkType,
    linkId: model.linkId,
  }),
  fromDTO: (dto: ReportWarningDTO): ReportWarningModel => ({
    key: '',
    displayDate: dto.displayDate,
    displayType: dto.displayType,
    displayDescription: dto.displayDescription,
    displayWarning: dto.displayWarning,
    displayWarningTimeStamps: dto.displayWarningTimeStamps,
    linkType: dto.linkType,
    linkId: dto.linkId,
  })
};

export default ReportWarningModel;
