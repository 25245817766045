import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row, Space, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { NameOf, NotificationUtil, TableExpressions, TableColumnBuilder, ObjectUtil } from 'src/utils';
import { useQueryParam } from 'src/hooks';
import ReportController from 'src/api/ReportController';
import ReportWarningModel from 'src/models/frontend/ReportWarningModel';
import LinkWithQuery from 'src/components/LinkWithQuery';
import RouteConfig from 'src/config/RouteConfig';
import PrintIconSVG from 'src/components/svgs/PrintIconSVG';
import moment, { isMoment } from 'moment';
import { tz } from 'moment-timezone';

interface ReportWarningProps {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  refreshTrigger: number;
}

const ReportWarning: React.FC<ReportWarningProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [tableData, setTableData] = useState<ReportWarningModel[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.startDate != null && props.endDate != null) {
      loadTableData();
    }
  }, [props.startDate, props.endDate]);
  
  useEffect(() => {
    if(props.refreshTrigger > 0)
      loadTableData();
  }, [props.refreshTrigger]);

  const loadTableData = async () => {
    if (props.startDate == null || props.endDate == null) {
      return;
    }

    setLoading(true);
    try {
      const results = await ReportController.getReportWarning(authContext.account!.id, authContext.location!.id, props.startDate, props.endDate);

      // Sort data
      let sortedData = [...results.data].sort(TableExpressions.Sorters.DateSorter('displayDate'));
      sortedData = sortedData.map(x => {
        for(let key in x.displayWarningTimeStamps){
          let mo = moment(x.displayWarningTimeStamps[key]);
          x.displayWarning = x.displayWarning.replace(key, mo.format('MM/DD/YYYY') + ' at ' + mo.format('h:mm:ss a ') + tz(tz.guess()).zoneAbbr());
        }
        return x;
      });

      setTableData(sortedData);
    } catch (error) {
      NotificationUtil.error({
        key: 'ReportWarning',
        message: 'Error while loading Report data',
        error
      });
    }
    setLoading(false);
  };

  const renderPrintButton = () => {
    const url = new URL(location.href);
    url.searchParams.delete('route_id');
    const printingQueryParams: [string, string][] = [
      ['reportStartDate', props.startDate?.format('L')?.replaceAll('/', '-') ?? ''],
      ['reportEndDate', props.endDate?.format('L')?.replaceAll('/', '-') ?? ''],
      ['returnUrl', url.pathname + url.search],
    ];

    return <LinkWithQuery openInNewTab to={RouteConfig.PRINTING_WARNING_REPORT()} additionalQueryParams={printingQueryParams}>
      <Button style={{ height: 36 }}><Space><PrintIconSVG /><span>Print</span></Space></Button>
    </LinkWithQuery>;
  };

  const tableColumns: ColumnProps<ReportWarningModel>[] = [
    TableColumnBuilder.Create<ReportWarningModel>('displayType', 'Type')
      .Width(180)
      .AddSorter('Text')
      .AddEnumFilterer(tableData.map(x => x.displayType).sort((a, b) => a.localeCompare(b)))
      .AddRenderer('Custom', (value, record) => {
        if (record.linkType === 'Mapping') {
          return <LinkWithQuery to={RouteConfig.SETTINGS_MAPPING()}>
            <Button type='link'>{value}</Button>
          </LinkWithQuery>;
        }
        if (record.linkType === 'Transaction') {
          return <LinkWithQuery to={RouteConfig.REPORTS_TRANSACTIONS()} additionalQueryParams={[['transactionId', record.linkId]]} includedQueryParams={['reportStartDate', 'reportEndDate']}>
            <Button type='link'>{value}</Button>
          </LinkWithQuery>;
        }
        else {
          // Probably should not do this ¯\_(ツ)_/¯
          return TableExpressions.Renderers.Ellipses('displayType')(value, record);
        }
      })
      .Build(),
    TableColumnBuilder.Create<ReportWarningModel>('displayDate', 'Date')
      .Width(180)
      .AddSorter('Date')
      .AddEnumFilterer(tableData.map(x => x.displayDate).sort((a, b) => a?.diff(b) ?? 0).filter(x => x != null && x?.year() > 2000).map(x => x?.format('L') ?? ''), 'Date')
      .AddRenderer('ShortDate', false)
      .Build(),
    TableColumnBuilder.Create<ReportWarningModel>('displayDescription', 'Description')
      .AddSorter('Text')
      .AddTextFilterer()
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportWarningModel>('displayWarning', 'Warning')
      .AddSorter('Text')
      .AddTextFilterer()
      .AddRenderer('Ellipses')
      .Build(),
  ];

  return (
    <div className='report-warning'>
      <Row justify='space-between' style={{ marginBottom: 16 }}>
        <Col>
          <p style={{width: 800}}>Before transactions can be approved or posted to the Accounting System, it is important to resolve any mapping or posting issues. To address any issues, simply click on the displayed warning to be directed to the appropriate page. Once the issue has been resolved, return to the Home screen to continue. Please keep in mind that posting issues are date-specific, while mapping issues are visible until resolved.</p>
        </Col>
        <Col flex={'100px'}>
          {renderPrintButton()}
        </Col>
      </Row>
      <Button style={{marginRight:'10px', marginBottom: '10px'}} onClick={loadTableData}><Space><span>Refresh</span></Space></Button>
      <Table
        rowKey={NameOf<ReportWarningModel>('key')}
        className='condensed-table striped-table borderless-table'
        rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
        loading={loading}
        pagination={false}
        columns={tableColumns}
        dataSource={tableData}
      />
    </div>
  );
};

export default ReportWarning;
