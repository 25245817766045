import { ObjectUtil } from 'src/utils';

interface ReportSummaryItemDTO {
  displayOrder: number;
  displayType: string;
  displayCount: number;
  displayAmount: number;
}
const ReportSummaryItemDTO = {
  create: (initValues?: Partial<ReportSummaryItemDTO>): ReportSummaryItemDTO => {
    const defaults: ReportSummaryItemDTO = {
      displayOrder: 0,
      displayType: '',
      displayCount: 0,
      displayAmount: 0,
    };
    return { ...defaults, ...initValues };
  },
};

export default ReportSummaryItemDTO;
