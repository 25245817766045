import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Checkbox, Divider, Form, Input } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { CacheContext } from 'src/providers/CacheContext';
import { NameOf, ObjectUtil, NotificationUtil, ErrorUtil } from 'src/utils';
import RouteConfig from 'src/config/RouteConfig';
import LoginService from './LoginService';
import SelectAccount from 'src/components/SelectAccount';
import AccountDTO from 'src/models/generated/AccountDTO';

const LoginAccountSelectionPage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const cacheContext = useContext(CacheContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedAccount, setSelectedAccount] = useState<AccountDTO>();
  const [loading, setLoading] = useState(false);

  // OnLoad
  useEffect(() => {
    // Figure out if we are on the right page (happens on reload)
    ensureConsistentRoute();
  }, []);

  const ensureConsistentRoute = async () => {
    const nextRoute = await LoginService.GetRouteWithSetAccountLocation(authContext, cacheContext, location);
    if (nextRoute !== location.pathname) {
      navigate(nextRoute);
    }
  };

  const handleOnSelect = async () => {
    if (selectedAccount == null) {
      return;
    }
    setLoading(true);
    try {
      await authContext.updateAccount(selectedAccount);

      // Close any notifications and navigate to the next page
      NotificationUtil.close('LoginAccountSelectionPage');

      // LoginService.SetAccountLocationAndNavigate(authContext, cacheContext, navigate);
      navigate(await LoginService.GetRouteWithSetAccountLocation(authContext, cacheContext, location));
    } catch (error) {
      NotificationUtil.error({
        key: 'LoginAccountSelectionPage',
        message: 'Accounts',
        description: 'Error while selecting account',
        error
      });
    }
    setLoading(false);
  };

  const handleAccountEdit = (account: AccountDTO) => {
    if(authContext.account?.id == account.id || authContext.account == null) {
      setSelectedAccount(account);
    }
  };

  return (
    <div className='login-account-selection-page'>
      <h1>Please Select an Account</h1>

      <SelectAccount
        disabled={loading}
        scroll={{ y: 600 }}
        onChange={setSelectedAccount}
        onSelect={handleOnSelect}
        onEdit={handleAccountEdit}
      />
      <br />
      <Button
        type='primary'
        block
        disabled={selectedAccount == null}
        loading={loading}
        onClick={handleOnSelect}
      >
        Select
      </Button>
    </div>
  );
};

export default LoginAccountSelectionPage;
