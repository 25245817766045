import { ArrayUtil } from 'src/utils';
import AuditTrailItemDTO from './AuditTrailItemDTO';

interface AuditTrailHistoryDTO {
  warningMessage: string;
  auditTrailItems: AuditTrailItemDTO[];
}
const AuditTrailHistoryDTO = {
  create: (initValues?: Partial<AuditTrailHistoryDTO>): AuditTrailHistoryDTO => {
    const defaults: AuditTrailHistoryDTO = {
      warningMessage: '',
      auditTrailItems: [],
    };
    const fixedInitValues: Partial<AuditTrailHistoryDTO> = initValues != null
      ? {
        auditTrailItems: ArrayUtil.EasyMap(initValues.auditTrailItems, AuditTrailItemDTO.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default AuditTrailHistoryDTO;
