import { v4 as uuidv4 } from 'uuid';
import { StringUtil } from 'src/utils';
import MappingPickListDTO from '../generated/MappingPickListDTO';
import MappingPickListItemModal from './MappingPickListItemModal';

interface MappingPickListModel {
  key: string;
  showColumn1: boolean;
  showColumn2: boolean;
  showColumn3: boolean;
  showListTypeColumn: boolean;
  labelColumn1: string;
  labelColumn2: string;
  labelColumn3: string;
  labelTypeColumn: string;
  pickListItems: MappingPickListItemModal[];
}
const MappingPickListModel = {
  create: (initValues?: Partial<MappingPickListModel>): MappingPickListModel => {
    const defaults: MappingPickListModel = {
      key: '',
      showColumn1: false,
      showColumn2: false,
      showColumn3: false,
      showListTypeColumn: false,
      labelColumn1: '',
      labelColumn2: '',
      labelColumn3: '',
      labelTypeColumn: '',
      pickListItems: [],
    };
    const fixedInitValues: Partial<MappingPickListModel> = initValues != null
      ? {
        key: StringUtil.IsNullOrEmpty(initValues.key) ? uuidv4() : initValues.key,
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
  toDTO: (model: MappingPickListModel): MappingPickListDTO => ({
    showColumn1: model.showColumn1,
    showColumn2: model.showColumn2,
    showColumn3: model.showColumn3,
    showListTypeColumn: model.showListTypeColumn,
    labelColumn1: model.labelColumn1,
    labelColumn2: model.labelColumn2,
    labelColumn3: model.labelColumn3,
    labelTypeColumn: model.labelTypeColumn,
    pickListItems: model.pickListItems.map(MappingPickListItemModal.toDTO),
  }),
  fromDTO: (dto: MappingPickListDTO): MappingPickListModel => ({
    key: '',
    showColumn1: dto.showColumn1,
    showColumn2: dto.showColumn2,
    showColumn3: dto.showColumn3,
    showListTypeColumn: dto.showListTypeColumn,
    labelColumn1: dto.labelColumn1,
    labelColumn2: dto.labelColumn2,
    labelColumn3: dto.labelColumn3,
    labelTypeColumn: dto.labelTypeColumn,
    pickListItems: dto.pickListItems.map(MappingPickListItemModal.fromDTO),
  })
};

export default MappingPickListModel;
