import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Checkbox, Col, Modal, Row, Space, Table, Tooltip, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { DownOutlined, EditOutlined, RightOutlined } from '@ant-design/icons';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { NameOf, NotificationUtil, TableExpressions, TableColumnBuilder } from 'src/utils';
import ReportController from 'src/api/ReportController';
import ReportTransactionItemDTO from 'src/models/generated/ReportTransactionItemDTO';
import ReportTransactionDTO from 'src/models/generated/ReportTransactionDTO';
import TransactionItemModal from 'src/components/modals/TransactionItemModal';

interface ReportTransactionExpanderProps {
  data: ReportTransactionDTO;
  onChange?: (data: ReportTransactionDTO) => void;
}

const ReportTransactionExpander: React.FC<ReportTransactionExpanderProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [tableData, setTableData] = useState<ReportTransactionItemDTO[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [hasDirtyData, setHasDirtyData] = useState(false);
  const [loading, setLoading] = useState(false);

  // Modal values
  const [selectedTransactionItem, setSelectedTransactionItem] = useState<ReportTransactionItemDTO>();
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (props.data != null) {
      // We really only need to worry about the items and honestly, I hope this doesn't make anything angry
      setTableData(props.data.items.map(ReportTransactionItemDTO.create));
    }
  }, [props.data]);

  const handleOpenModal = (item?: ReportTransactionItemDTO) => {
    setSelectedTransactionItem(item);
    setModalVisible(true);
  };

  const handleModalOk = (item: ReportTransactionItemDTO) => {
    // Save this value in the collection or something, idk
    if (props.data == null) {
      return;
    }
    
    // We don't have a key to work with, so we need to just fake it for now
    if (selectedTransactionItem != null) {
      const myTableData = [...tableData];
      item.transactionItemId = selectedTransactionItem.transactionItemId;
      let myAssignments = [...selectedTransactionItem.displayAssignments];
      item.displayAssignments.forEach(assignment => {
        let oldAssignment = myAssignments.find(a => a.key === assignment.key);
        if (oldAssignment) {
          oldAssignment.selectedItemType = assignment.selectedItemType;
          oldAssignment.selectedItemName = assignment.selectedItemName;
          oldAssignment.displayValue = assignment.displayValue;
        } else {
          myAssignments.push(assignment);
        }
      });
      
      item.displayAssignments = myAssignments;      
      
      myTableData.splice(myTableData.findIndex(x => x.displayOrder === item.displayOrder), 1, item);

      // This should then force React to update the table
      setTableData(myTableData);
    } else {
      // We key off of the displayOrder, so we need to set the new one to be the next available
      item.displayOrder = Math.max(...tableData.map(x => x.displayOrder)) + 1;
      setTableData([...tableData, item]);
    }

    setModalVisible(false);
    setHasDirtyData(true);
    setSelectedTransactionItem(undefined);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedTransactionItem(undefined);
  };

  const handleDeleteClick = async () => {
    if (props.data == null) {
      return;
    }

    const myTableData = tableData.filter(x => !selectedKeys.includes(x.displayOrder));
    setTableData(myTableData);
    setHasDirtyData(true);
    setSelectedKeys([]);
  };

  const handleSaveChangesClick = async () => {
    if (props.data == null) {
      return;
    }

    setLoading(true);
    try {
      // Concatenate the tableData we have been working with
      const data = ReportTransactionDTO.create({...props.data, items: tableData});
      const results = await ReportController.saveTransaction(authContext.account!.id, authContext.location!.id, data);
      // Save changes and send results up
      setTableData(results.data.items);
      // setHasDirtyData(false);
      // props.onChange?.(results.data);
      setHasDirtyData(false);
      props.onChange?.(data);
    } catch(error: any){
      Modal.warning({
        title: 'Warning',
        content: error.response.data,
        okText: 'Ok'
      });
    }
    setLoading(false);
  };

  const tableColumns: ColumnProps<ReportTransactionItemDTO>[] = [
    TableColumnBuilder.Create<ReportTransactionItemDTO>('displayLineType', 'Line Type')
      .Width(140)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportTransactionItemDTO>('displayDescription', 'Description')
      .Width(500)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportTransactionItemDTO>('displayAmount', 'Amount')
      .Width(90)
      .Align('right')
      .AddRenderer('Currency', true)
      .Build(),
    TableColumnBuilder.Create<ReportTransactionItemDTO>('displayAssignment', 'Assignment')
      .Width(1000)
      .AddRenderer('Ellipses')
      .Build(),

    TableColumnBuilder.Create<ReportTransactionItemDTO>()
      .Key('edit')
      .AddRenderer('Custom', (_, record) => (<Button type='link' className='dimensionless-button' icon={<EditOutlined className='with-font-size-large' />} onClick={() => handleOpenModal(record)} />))
      .Build(),
  ];

  const canEdit = props.data.displayStatus.toLowerCase() === 'unapproved';
  const canAdd = canEdit;
  const canSave = canEdit && hasDirtyData;
  const canDelete = canEdit && selectedKeys.length > 0;
  const hasUndeleteable = tableData.filter(x => selectedKeys.includes(x.displayOrder) && !x.allowAddDelete).length > 0;

  if(!canEdit)
    tableColumns.pop();

  return (
    <div className='report-transaction-expander'>
      <Space className='with-margin-bottom'>
        {canAdd && <Button type='default' onClick={() => handleOpenModal()}>Add New Line</Button>}
        {canSave && <Button type='primary' className='green' onClick={handleSaveChangesClick}>Save Changes</Button>}
        {canDelete && <Button danger type='primary' onClick={handleDeleteClick}>Delete</Button>}
      </Space>
      <Table
        rowKey={NameOf<ReportTransactionItemDTO>('displayOrder')}
        className='condensed-table striped-table borderless-table'
        rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
        loading={loading}
        pagination={false}
        columns={tableColumns}
        dataSource={tableData}
        rowSelection={canEdit ? {
          type: 'checkbox',
          selectedRowKeys: selectedKeys,
          onChange: (keys) => setSelectedKeys(keys),
          renderCell(checked, record, index, node) {
            if (!record.allowAddDelete) {
              return <Tooltip title="This item can not be deleted">{node}</Tooltip>;
            }
            return node;
          },
          getCheckboxProps(record) {
            let checkboxProps:any = {};
            if(!record.allowAddDelete){
              checkboxProps.disabled = true;
            }
            return checkboxProps;
          },
        } : undefined}
        
        summary={pageData => {
          const total = pageData.map(x => x.displayAmount).reduce((a, b) => a + b, 0);
          const totalValue =  TableExpressions.Renderers.Currency('total', true)('', { total });
          return <Table.Summary.Row>
            <Table.Summary.Cell index={0} colSpan={canEdit ? 3 : 2}></Table.Summary.Cell>
            <Table.Summary.Cell index={1} align='right' className='with-border-top'>
              <Typography.Text strong>{totalValue}</Typography.Text>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={2}>
              <Typography.Text>{props.data.displayItemTotalAssignment}</Typography.Text>
            </Table.Summary.Cell>
          </Table.Summary.Row>;
        }}
      />
      <TransactionItemModal open={modalVisible} transactionTypeKey={props.data?.transactionTypeKey ?? ''} transactionItem={selectedTransactionItem} onCancel={handleModalClose} onOk={handleModalOk} />
    </div>
  );
};

export default ReportTransactionExpander;
