import React, { useContext, useEffect, useState } from 'react';
import { Table, Typography, Alert } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { NameOf, NotificationUtil, TableExpressions, TableColumnBuilder } from 'src/utils';
import { useQueryParam } from 'src/hooks';
import ReportController from 'src/api/ReportController';
import ReportTransactionItemDTO from 'src/models/generated/ReportTransactionItemDTO';
import ReportTransactionDTO from 'src/models/generated/ReportTransactionDTO';
import { SharedReportPageProps } from './SharedPageProps';
import { render } from 'react-dom';
import { FilterValue, SortOrder } from 'antd/lib/table/interface';
import { tz } from 'moment-timezone';

const PrintingReportTransactionPage: React.FC<SharedReportPageProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [transactionIdQueryParam, setTransactionIdQueryParam] = useQueryParam('transactionId');
  const [journalIdQueryParam, setJournalIdQueryParam] = useQueryParam('transactionJournalId');
  const [referenceIdQueryParam, setReferenceIdQueryParam] = useQueryParam('transactionReferenceId');
  const [transactionTypeQueryParam, setTransactionTypeQueryParam] = useQueryParam('transactionType');
  const [transactionStatusQueryParam, setTransactionStatusQueryParam] = useQueryParam('transactionStatus');
  
  const [transactionTableFiltersQueryParam, setTransactionTableFiltersQueryParam] = useQueryParam('transactionFilters');
  const [transactionTableSortedColumnQueryParam, setTransactionSortedColumnQueryParam] = useQueryParam('transactionSortedColumn');
  const [transactionTableSortOrderQueryParam, setTransactionSortOrderQueryParam] = useQueryParam('transactionSortOrder');
  // Purely to handle passing data back to the PrintLayout
  const [hasExpandedKeysQueryParam, setHasExpandedKeysQueryParam] = useQueryParam('hasExpandedQueryParams');
  const [filterValues, setFilterValues] = useState<Record<string, FilterValue | null>>();

  const [tableData, setTableData] = useState<ReportTransactionDTO[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<ReportTransactionDTO[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadTableData();
  }, [props.startDate, props.endDate]);

  // useEffect(() => {
  //   mySetExpandedKeys(props.expandedKeys);
  // }, [props.expandedKeys]);

  useEffect(() => {
    setSelectedKeys(props.selectedKeys);
  }, [props.selectedKeys]);

  // Handle the filter query params
  useEffect(() => {
    let filteredData = [...tableData];

    if (transactionIdQueryParam != null) {
      filteredData = filteredData.filter(x => x.transactionId === transactionIdQueryParam);
    }

    if (journalIdQueryParam != null) {
      filteredData = filteredData.filter(x => x.journalId === journalIdQueryParam);
    }

    if (referenceIdQueryParam != null) {
      filteredData = filteredData.filter(x => x.displayJournal === referenceIdQueryParam);
    }

    if (transactionTypeQueryParam != null) {
      filteredData = filteredData.filter(x => x.displayType === transactionTypeQueryParam);
    }

    if (transactionStatusQueryParam != null) {
      filteredData = filteredData.filter(x => x.displayStatus === transactionStatusQueryParam);
    }

    setFilteredTableData(filteredData);
  }, [tableData, transactionIdQueryParam, journalIdQueryParam, referenceIdQueryParam, transactionTypeQueryParam, transactionStatusQueryParam]);

  const loadTableData = async () => {
    console.log('props!', props);
    if (authContext.account == null || authContext.location == null || props.startDate == null && props.endDate == null) {
      return;
    }

    setLoading(true);
    try {
      const results = await ReportController.getReportTransaction(authContext.account.id, authContext.location.id, props.startDate!, props.endDate!);
      
      // Sort data
      let sortedData = [...results.data].map(x => ({
        ...x,
        // Sort inner array
        items: TableExpressions.Methods.FixSortOrderNumber(x.items.sort(TableExpressions.Sorters.NumberSorter('displayOrder')), 'displayOrder')
      }));
      sortedData = sortedData.map(x => {
        for(let key in x.displayCommentTimeStamps){
          x.displayComment = x.displayComment.replace(key, x.displayCommentTimeStamps[key].format('MM/DD/YYYY') + ' at ' + x.displayCommentTimeStamps[key].format('h:mm:ss a ') + tz(tz.guess()).zoneAbbr());
        }
        return x;
      });
      setTableData(sortedData);
      if(transactionTableFiltersQueryParam !== ''){
        const filters = JSON.parse(transactionTableFiltersQueryParam??'') as Record<string, FilterValue | null>;
      setFilterValues(filters);
      }
      
      mySetExpandedKeys(JSON.parse(localStorage.getItem('transactionExpandedIds') ?? ''));
    } catch (error) {
      NotificationUtil.error({
        key: 'PrintingReportTransaction',
        message: 'Error while loading Report data',
        error
      });
    }
    setLoading(false);
  };

  const mySetExpandedKeys = (keys: React.Key[]) => {
    setHasExpandedKeysQueryParam(`${Array.isArray(keys) && keys.length > 0}`);
    setExpandedKeys(keys);
  };

  const renderClearAllFilter = () => {
    let output: React.ReactNode[] = [];
    if (transactionIdQueryParam != null) {

      output.push(<Alert
        style={{margin: '0 10px 10px 0px' }}
        key='transactionId'
        closable
        className='inline-alert'
        type='warning'
        message={`Filtering on Transaction: ${tableData.find(x=> x.transactionId === transactionIdQueryParam)?.displayReference}`}
        onClose={() => setTransactionIdQueryParam(null)}
      />);
    }

    if (journalIdQueryParam != null) {
      output.push(<Alert
        style={{margin: '0 10px 10px 0px' }}
        key='journalId'
        closable
        className='inline-alert'
        type='warning'
        message={`Filtering on Journal ID: ${journalIdQueryParam}`}
        onClose={() => setJournalIdQueryParam(null)}
      />);
    }

    if (referenceIdQueryParam != null) {
      output.push(<Alert
        style={{margin: '0 10px 10px 0px' }}
        key='referenceId'
        closable
        className='inline-alert'
        type='warning'
        message={`Filtering on Reference: ${referenceIdQueryParam}`}
        onClose={() => setReferenceIdQueryParam(null)}
      />);
    }

    if (transactionTypeQueryParam != null) {
      output.push(<Alert
        style={{margin: '0 10px 10px 0px' }}
        key='transactionType'
        closable
        className='inline-alert'
        type='warning'
        message={`Filtering on Type: ${transactionTypeQueryParam}`}
        onClose={() => setTransactionTypeQueryParam(null)}
      />);
    }

    if (transactionStatusQueryParam != null) {
      output.push(<Alert
        style={{margin: '0 10px 10px 0px' }}
        key='transactionStatus'
        closable
        className='inline-alert'
        type='warning'
        message={`Filtering on Status: ${transactionStatusQueryParam}`}
        onClose={() => setTransactionStatusQueryParam(null)}
      />);
    }

    return output;
  };
  const tableColumns: ColumnProps<ReportTransactionDTO>[] = [
    TableColumnBuilder.Create<ReportTransactionDTO>('displayDate', 'Date')
      .Width(100)
      .AddSorter('Date')
      .AddEnumFilterer(tableData.map(x => x.displayDate).sort((a, b) => a?.diff(b) ?? 0).filter(x => x != null && x?.year() > 2000).map(x => x?.format('L') ?? ''), 'Date')
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .AddRenderer('ShortDate')
      .Build(),
    TableColumnBuilder.Create<ReportTransactionDTO>('displayType', 'Type')
      .Width(160)
      .AddSorter('Text')
      .AddEnumFilterer(tableData.map(x => x.displayType).sort((a, b) => a.localeCompare(b)), 'Exact Text')
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportTransactionDTO>('displayJournal', 'Journal')
      .Width(200)
      .AddSorter('Text')
      .AddRenderer('Ellipses')
      .AddTextFilterer()
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .Build(),
    TableColumnBuilder.Create<ReportTransactionDTO>('displayReference', 'Reference')
      .Width(150)
      .AddSorter('Text')
      .AddRenderer('Ellipses')
      .AddTextFilterer()
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .Build(),
    TableColumnBuilder.Create<ReportTransactionDTO>('displayName', 'Name')
      .Width(160)
      .AddSorter('Text')
      .AddRenderer('Ellipses')
      .AddTextFilterer()
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .Build(),
    TableColumnBuilder.Create<ReportTransactionDTO>('displayTotal', 'Total')
      .Width(100)
      .ClassName('with-text-align-right')
      .AddSorter('Number')
      .AddRenderer('Currency', true)
      .AddCurrencyFilterer()
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .Build(),
    TableColumnBuilder.Create<ReportTransactionDTO>('displayStatus', 'Status')
      .Width(110)
      .AddRenderer('Ellipses')
      .AddSorter('Text')
      .AddEnumFilterer(tableData.map(x => x.displayStatus).sort((a, b) => a.localeCompare(b)), 'Exact Text')
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .Build(),
    TableColumnBuilder.Create<ReportTransactionDTO>('displayComment', 'Comment')
      .AddRenderer('Ellipses')
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .AddTextFilterer()
      .Build(),
  ];

  const sortedColumn = tableColumns.find(x => x.key === transactionTableSortedColumnQueryParam);
  if(sortedColumn)
    sortedColumn.defaultSortOrder = transactionTableSortOrderQueryParam as SortOrder;

  const transactionColumns: ColumnProps<ReportTransactionItemDTO>[] = [
    TableColumnBuilder.Create<ReportTransactionItemDTO>('displayLineType', 'Line Type')
      .Width(140)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportTransactionItemDTO>('displayDescription', 'Description')
      .Width(540)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportTransactionItemDTO>('displayAmount', 'Amount')
      .Width(100)
      .Align('right')
      .AddRenderer('Currency', true)
      .Build(),
    TableColumnBuilder.Create<ReportTransactionItemDTO>('displayAssignment', 'Assignment')
      .AddRenderer('Ellipses')
      .Build(),
  ];

  return (
    <div className='printing-report-transaction'>
      {renderClearAllFilter()}
      <Table
        rowKey={NameOf<ReportTransactionDTO>('transactionId')}
        className='less-condensed-table striped-table borderless-table'
        rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
        loading={loading}
        pagination={false}
        columns={tableColumns}
        dataSource={filteredTableData}
        expandable={{
          expandedRowKeys: expandedKeys,
          onExpandedRowsChange: keys => (mySetExpandedKeys([...keys])),
          expandIcon: (props) => {
            const onClick = (e: React.MouseEvent<HTMLElement>) => props.onExpand(props.record, e);
            return props.expanded ? <DownOutlined onClick={onClick} className='standard-chevrons' /> : <RightOutlined onClick={onClick} className='standard-chevrons' />;
          },
          expandedRowRender: record => (<div style={{marginLeft:30}}>
            <Table
              rowKey={NameOf<ReportTransactionItemDTO>('displayOrder')}
              className='condensed-table striped-table borderless-table'
              rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
              style={{ margin: '0 30px'}}
              pagination={false}
              columns={transactionColumns}
              dataSource={record.items}
              summary={pageData => {
                return <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align='right' className='with-border-top'>
                    <Typography.Text strong>{TableExpressions.Renderers.Currency('displayItemTotalAmount', true)('', record)}</Typography.Text>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <Typography.Text >{record.displayItemTotalAssignment}</Typography.Text>
                  </Table.Summary.Cell>
                </Table.Summary.Row>;
              }}
            />
          </div>),
        }}
      />
    </div>
  );
};

export default PrintingReportTransactionPage;
