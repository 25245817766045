import React, { useContext, useState } from 'react';
import moment from 'moment';
import { Button, Col, Row } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalStorageUtil, NotificationUtil } from 'src/utils';
import { useInterval } from 'src/hooks';
import { AuthenticationContext } from '../providers/AuthenticationContext';
import RouteConfig from 'src/config/RouteConfig';
import UserController from 'src/api/UserController';

const SessionWatcher: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const [hasShownLogoutWarning, setHasShownLogoutWarning] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleRefresh = async () => {
    // Just do a safe check on the user profile
    setSubmitting(true);
    try {
      const results = await UserController.getMyProfile();
      if (results.status >= 400) {
        throw new Error('Auth error in request');
      }

      // Everything good? Reset the shown flag
      NotificationUtil.close('logoutWarning');
      setHasShownLogoutWarning(false);
    } catch (error) {
      // Oh, not good, I don't feel so good mr stark...
      NotificationUtil.error({
        key: 'SessionWatcher',
        message: 'Session refresh',
        error
      });
      redirectAndNotify();
    }
    setSubmitting(false);
  };

  const redirectAndNotify = async () => {
    // Reset the shown flag as the user might come back
    setHasShownLogoutWarning(false);

    // Show notification so the user knows what happened and navigate
    NotificationUtil.info({
      key: 'logoutWarning',
      message: 'Logged out',
      description: 'You have been logged out due to inactivity',
      duration: 0,
    });
    //clear return url
    await authContext.updateReturnUrl(null);
    navigate({
      pathname: RouteConfig.LOGOUT(),
      search: location.search
    });
  };
  const redirectToken = async () => {
    // Reset the shown flag as the user might come back
    setHasShownLogoutWarning(false);
    //clear return url
    await authContext.updateReturnUrl(null);
    navigate({
      pathname: RouteConfig.LOGOUT(),
      search: location.search
    });
  };

  const showNotification = (authExpirationText: string, allowClose: boolean) => {
    NotificationUtil.info({
      key: 'logoutWarning',
      message: 'Still here?',
      description: <div>
        <p>You will be logged out in {authExpirationText}</p>
        <Row justify='end'>
          <Col>
            <Button type='primary' loading={submitting} onClick={handleRefresh}>Refresh</Button>
          </Col>
        </Row>
      </div>,
      duration: 0,
      closeIcon: allowClose ? undefined : <span></span>,
      closeOnClick: false,
      onClose: allowClose ? () => setHasShownLogoutWarning(true) : undefined
    });
  };

  // Check for login status every 1/3 second
  useInterval(() => {
    const authExpiration = LocalStorageUtil.AuthExpiration;
    const ExpiredToken = sessionStorage.getItem('ExpiredToken');

    if(ExpiredToken === "true")
    {
      sessionStorage.setItem('ExpiredToken', 'false');
      redirectToken();
      return;
    }

    // Sanity checks
    if (authContext.authState !== 'Complete') {
      return;
    }

    if (authExpiration == null || !authExpiration.isValid()) {
      // Yeah, the component is not supposed to handle this case, so just run away
      if (process.env.NODE_ENV !== 'production') {
        console.warn('[SessionWatcher] authExpiration invalid');
      }
      redirectAndNotify();
      return;
    }

    const authExpirationDuration = moment.duration(authExpiration.diff(moment()));
    const authExpirationText = `${authExpirationDuration.minutes()}:${String(authExpirationDuration.seconds()).padStart(2, '0')}`;

    // No more time left, notify and redirect
    if (authExpirationDuration.asSeconds() < 1) {
      redirectAndNotify();
      return;
    }

    // With 30 remaining, we show the message and do not allow them to close it
    if (authExpirationDuration.asSeconds() <= 30) {
      showNotification(authExpirationText, false);
      return;
    }

    // We want to give the user SOME time to react, so 10 minutes was decided
    if (authExpirationDuration.asMinutes() < 10 && !hasShownLogoutWarning) {
      showNotification(authExpirationText, true);
      return;
    }
  }, 333);

  return (null);
};

export default SessionWatcher;
