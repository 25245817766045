import * as uuid from 'uuid';

/**
 * Awful name, I am sorry. Handles the interaction of the return_url and route_id query parameters
 */
class UrlUtil {
  public GenerateRouteId(accountId: string, locationId: string) {
    return this.arrayBufferToBase64([...uuid.parse(accountId) as Uint8Array, ...uuid.parse(locationId) as Uint8Array]);
  }

  public ParseRouteId(routeId: string) {
    // This is just a temporary solution to get something out the door
    let q = this.base64ToArrayBuffer(routeId);
    return {
      accountId: uuid.stringify(Uint8Array.from(q).slice(0, q.length / 2)),
      locationId: uuid.stringify(Uint8Array.from(q).slice(q.length / 2)),
    };
  }

  private arrayBufferToBase64(buffer: ArrayLike<number>): string {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }

  private base64ToArrayBuffer(input: string): ArrayLike<number> {
    let q = window.atob(input);
    let len = q.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = q.charCodeAt(i);
    }
    return bytes;
  }
}

export default new UrlUtil();
