import React from 'react';
import { Button, Checkbox, Col, Divider, Input, Radio, Row, Space, notification } from 'antd';

const StyleGuide: React.FC = (props) => {
  // Notes
  // Need to add a component for page title and add it here

  return (
    <div>
      <h1>Style Guide</h1>
      <p>This is a page to display the various components used in the app and, <code>in code at least</code>, how to use them</p>
      <Divider orientation='left'>Headings</Divider>
      <p className='description'>We will only use headings 1-3</p>
      <h1>Page Heading (H1)</h1>
      <h2>Sub Heading (H2)</h2>
      <h3>In Page Heading (H3)</h3>
      <p>Paragraph Text</p>

      {/* Buttons */}
      <Divider orientation='left'>Buttons</Divider>
      <p className='description'>Added a background and padding to show off the colors</p>
      <Row gutter={[8, 8]} style={{ backgroundColor: '#ddd', padding: 16 }}>
        <Col>
          <Button type='primary'>Standard Button</Button>
        </Col>
        <Col>
          <Button type='primary' danger>Angry Button</Button>
        </Col>
        <Col>
          <Button>Light Button</Button>
        </Col>
        <Col>
          <Button danger>Light Angry Button</Button>
        </Col>
        <Col>
          <Button type='text'>Text Button</Button>
        </Col>
        <Col>
          <Button type='link'>Link Button</Button>
        </Col>
        <Col>
          <Button type='primary' disabled>Disabled Primary Button</Button>
        </Col>
        <Col>
          <Button type='primary' danger disabled>Disabled Angry Button</Button>
        </Col>
      </Row>

      {/* Checkbox & Radio Buttons */}
      <Divider orientation='left'>Checkbox &amp; Radio Button</Divider>
      <p className='description'>Added a background and padding to show off the colors</p>
      <Row gutter={8} style={{ backgroundColor: '#ddd', padding: 16 }}>
        <Col>
          <h3>Horizontal</h3>
          <Radio.Group>
            <Radio value={1}>A</Radio>
            <Radio value={2}>B</Radio>
            <Radio value={3}>C</Radio>
          </Radio.Group>
        </Col>
        <Col>
          <h3>Vertical</h3>
          <Radio.Group>
            <Space direction='vertical'>
              <Radio value={1}>A</Radio>
              <Radio value={2}>B</Radio>
              <Radio value={3}>C</Radio>
            </Space>
          </Radio.Group>
        </Col>
      </Row>
      <Row gutter={8} style={{ backgroundColor: '#ddd', padding: 16 }}>
        <Col>
          <h3>Normal Checkboxes</h3>
          <Checkbox>Potato</Checkbox>
        </Col>
        <Col>
          <h3>Group of Values</h3>
          <Checkbox.Group options={['Potato', 'Carrot', 'Lettuce']} />
        </Col>
        <Col>
          <h3>Group of Values Vertical (Doesn&apos;t work, sorry)</h3>
          <Checkbox.Group className='vertical' options={['Potato', 'Carrot', 'Lettuce']} />
        </Col>
      </Row>

      {/* Inputs */}
      <Divider orientation='left'>Inputs</Divider>
      <Row gutter={8} style={{ padding: 16 }}>
        <Col>
          <h3>Standard with Placeholder</h3>
          <Input placeholder='Potato?' />
        </Col>
      </Row>

      {/* Notifications */}
      <Divider orientation='left'>Notifications</Divider>
      <Row gutter={8} style={{ backgroundColor: '#ddd', padding: 16 }}>
        <Col>
          <Button type='primary' onClick={() => notification.success({ message: 'Success!', description: 'This is a description' })}>Success</Button>
        </Col>
        <Col>
          <Button type='primary' onClick={() => notification.info({ message: 'Info!', description: 'This is a description' })}>Info</Button>
        </Col>
        <Col>
          <Button type='primary' onClick={() => notification.error({ message: 'Error!', description: 'This is a description' })}>Error</Button>
        </Col>
        <Col>
          <Button type='primary' onClick={() => notification.warn({ message: 'Warn!', description: 'This is a description', duration: 0 })}>Warn</Button>
        </Col>
      </Row>

    </div>
  );
};

export default StyleGuide;
