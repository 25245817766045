import axios, { AxiosResponse } from 'axios';
import AccountDTO from 'src/models/generated/AccountDTO';
import { AxiosUtil } from 'src/utils';

class AccountController {
  private prefix: string = "accounts";

  // API_16a
  getAccounts(): Promise<AxiosResponse<AccountDTO[]>> {
    let route = `${this.prefix}`;
    return AxiosUtil.get<AccountDTO[]>(route, items => items.map(AccountDTO.create));
  }

  // API_16.1
  getAccount(accountId: string): Promise<AxiosResponse<AccountDTO>> {
    let route = `${this.prefix}/account/${accountId}`;
    return AxiosUtil.get<AccountDTO>(route, AccountDTO.create);
  }

  // API_17
  saveAccount(accountId: string, displayName: string): Promise<AxiosResponse<AccountDTO>> {
    let route = `${this.prefix}/${accountId}`;
    let data = { displayName } as AccountDTO;
    return AxiosUtil.post<AccountDTO, AccountDTO>(route, data, AccountDTO.create, { withObjectSplit: true });
  }
}

export default new AccountController();
