import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Checkbox, Divider, Form, Input } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { CacheContext } from 'src/providers/CacheContext';
import { NameOf, ObjectUtil, NotificationUtil, ErrorUtil } from 'src/utils';
import RouteConfig from 'src/config/RouteConfig';
import LoginService from './LoginService';
import AuthorizationController from 'src/api/AuthorizationController';

const LoginForgotPasswordStep1Page: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const cacheContext = useContext(CacheContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // OnLoad
  useEffect(() => {
    // Figure out if we are on the right page (happens on reload)
    ensureConsistentRoute();
  }, []);

  const ensureConsistentRoute = async () => {
    const nextRoute = await LoginService.GetRouteWithSetAccountLocation(authContext, cacheContext, location);
    if (nextRoute !== location.pathname) {
      navigate(nextRoute);
    }
  };

  const handleButtonClick = async () => {
    // We have the email from the previous page, so we should be able to safely submit a request from here
    setLoading(true);

    try {
      const result = await AuthorizationController.passwordResetRequest();
      NotificationUtil.close('LoginForgotPasswordStep1Page');
      navigate(RouteConfig.LOGIN_FORGOT_PASSWORD_STEP_2());
    } catch (error) {
      NotificationUtil.error({
        key: 'LoginForgotPasswordStep1Page',
        message: 'Error while sending confirmation email',
        error
      });
    }

    setLoading(false);
  };

  return (
    <div className='login-forgot-password-step-1-page login-flow'>
      <h1>Password Reset</h1>
      {/* <p>Please specify your email address to receive a confirmation code for resetting it. If an account exists by that email, we will send a password reset</p> */}
      <p>We will now send you a confirmation code to the email you have provided ({authContext.auth?.emailAddress}). Please use that on the next page</p>

      <Button type='primary' size='large' block loading={loading} onClick={handleButtonClick}>Continue</Button>
    </div>
  );
};

export default LoginForgotPasswordStep1Page;
