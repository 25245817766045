import React from 'react';
import { FunctionMapper, KeyMapper, Omit } from '../core/CoreTypes';
import { RouteUrl } from '../core/RouteUrl';

/** Takes all keys from **Routes**, removing prototype, returning the remaining keys with a type of string for each */
type T6 = KeyMapper<Omit<typeof RoutesInternal, 'prototype'>, string>;

type T7 = FunctionMapper<Omit<typeof RoutesInternal, 'prototype'>, string>;

type T8 = T7 & { LINK: T6 };

class RoutesInternal {
  /* General */
  public static HOME() { return new RouteUrl('/'); }

  /* Authentication */
  public static LOGIN_EMAIL() { return new RouteUrl('/login'); }
  public static LOGIN_AGENT() { return new RouteUrl('/login/agent'); }
  public static LOGIN_ACTIVATE() { return new RouteUrl('/login/activate'); }
  public static LOGIN_ACTIVATE_SUCCESS() { return new RouteUrl('/login/activate/success'); }
  public static LOGIN_PASSWORD() { return new RouteUrl('/login/password'); }
  public static LOGIN_TWO_FACTOR() { return new RouteUrl('/login/two-factor'); }

  public static LOGIN_FORGOT_PASSWORD_STEP_1() { return new RouteUrl('/login/forgot-password/step-1'); }
  public static LOGIN_FORGOT_PASSWORD_STEP_2() { return new RouteUrl('/login/forgot-password/step-2'); }
  public static LOGIN_FORGOT_PASSWORD_STEP_3() { return new RouteUrl('/login/forgot-password/step-3'); }
  public static LOGIN_ACCOUNT_SELECTION() { return new RouteUrl('/login/select-account'); }
  public static LOGIN_LOCATION_SELECTION() { return new RouteUrl('/login/select-location'); }

  public static LOGIN_TERMS_OF_SERVICE() { return new RouteUrl('/terms-of-service'); }
  public static LOGIN_PRIVACY_STATEMENT() { return new RouteUrl('/privacy-statement'); }

  public static LOGOUT() { return new RouteUrl('/logout'); }

  /* User Profile */
  public static PROFILE_HOME() { return new RouteUrl('/profile'); }
  public static PROFILE_RESET_PASSWORD() { return new RouteUrl('/profile/reset-password'); }
  public static PROFILE_RESET_PASSWORD_CONFIRM() { return new RouteUrl('/profile/reset-password/confirmation'); }
  public static PROFILE_VERIFY_PHONE() { return new RouteUrl('/profile/verify-phone'); }

  /* Misc */
  public static SELECT_ACCOUNT() { return new RouteUrl('/select-account'); }
  public static SELECT_LOCATION() { return new RouteUrl('/select-location'); }

  public static SETTINGS() { return new RouteUrl('/settings'); }
  public static SETTINGS_LOCATION_DETAILS() { return new RouteUrl('/settings?tab=1'); }
  public static SETTINGS_INTEGRATIONS() { return new RouteUrl('/settings?tab=2'); }
  public static SETTINGS_MAPPING() { return new RouteUrl('/settings?tab=3'); }
  public static SETTINGS_ADVANCED_SETTINGS() { return new RouteUrl('/settings?tab=4'); }
  public static AUDIT() { return new RouteUrl('/audit'); }

  /* Reports */
  public static REPORTS() { return new RouteUrl('/reports'); } // Default route
  public static REPORTS_WARNING() { return new RouteUrl('/reports?tab=1'); }
  public static REPORTS_SUMMARY() { return new RouteUrl('/reports?tab=2'); }
  public static REPORTS_TRANSACTIONS() { return new RouteUrl('/reports?tab=3'); }
  public static REPORTS_JOURNALS() { return new RouteUrl('/reports?tab=4'); }
  public static REPORTS_ASSIGNMENTS() { return new RouteUrl('/reports?tab=5'); }

  /* Printing */
  public static PRINTING_REPORT_BASE(reportName: React.Key) { return new RouteUrl('/printing/report/:reportName', { reportName }); }
  public static PRINTING_WARNING_REPORT() { return new RouteUrl('/printing/report/warning'); }
  public static PRINTING_SUMMARY_REPORT() { return new RouteUrl('/printing/report/summary'); }
  public static PRINTING_TRANSACTION_REPORT() { return new RouteUrl('/printing/report/transaction'); }
  public static PRINTING_JOURNAL_REPORT() { return new RouteUrl('/printing/report/journal'); }
  public static PRINTING_ASSIGNMENT_REPORT() { return new RouteUrl('/printing/report/assignment'); }
  public static PRINTING_TILE_REPORT() { return new RouteUrl('/printing/report/tile'); }
  public static PRINTING_AUDIT_TRAIL() { return new RouteUrl('/printing/report/audit'); }
  public static PRINTING_MAPPING_ITEM() { return new RouteUrl('/printing/report/mapping'); }

  /* Unsorted */
  /* Please use or discard */
  public static FORGOT_PASSWORD() { return new RouteUrl('/forgot-password'); }
  // public static REGISTER() { return new RouteUrl('/register'); }

  /* Dev */
  public static STYLE_GUIDE() { return new RouteUrl('/style-guide'); }

  /* Error Handling */
  public static ERROR_PAGE() { return new RouteUrl('/the-handler'); }
  public static PROD_ERROR_PAGE() { return new RouteUrl('/errors'); }
  public static PAGE_NOT_FOUND() { return new RouteUrl('/404'); }
}

const generateRoutes = (): T8 => {
  const getOriginalURL = function (key: string): string {
    const RoutesNoAny = RoutesInternal as any;
    // If function, run it and assume RouteUrl as a return type. Else, check for string and return its result
    if (typeof RoutesNoAny[key] === 'function') {
      const obj: RouteUrl = RoutesNoAny[key]({});
      if (obj != null) {
        return obj.originalUrl;
      }
    } else if (typeof RoutesNoAny[key] === 'string') {
      return RoutesNoAny[key];
    }
    // Uh
    return '';
  };

  const getStringVariant = function (key: string) {
    const RoutesNoAny = RoutesInternal as any;
    if (typeof RoutesNoAny[key] === 'function') {
      return (...args: any[]) => {
        // Actually call the function, which will return a RouteUrl or a string
        let obj = RoutesNoAny[key](...args) as RouteUrl;
        if (obj != null) {
          return obj.toString();
        } else {
          throw new Error(`Non RouteUrl detected. Please use RouteUrl for this key: ${key}`);
        }
      };
    } else if (typeof RoutesNoAny[key] === 'string') {
      return (...args: any[]) => {
        return RoutesNoAny[key](...args) as string;
      };
    }
    // Not a function, so what do we do?
    return null;
  };

  // Keys to be removed. Object functions, prototype and where we are storing the rest
  const spareKeys = ['name', 'length', 'construct', 'prototype', 'LINK'];

  const keys = Object.getOwnPropertyNames(RoutesInternal) // Get all properties from our routes list
    .filter(x => !spareKeys.includes(x)); // Remove props that we don't use

  // Get the LINK representation. Useful for url matching, such as the sidenav/menu
  const routeLinks = keys
    .map(x => ({ [x]: getOriginalURL(x) }))
    .reduce((a, b) => ({ ...a, ...b })) as T6;

  const routeToStrings = keys
    .map(x => ({ [x]: getStringVariant(x) }))
    .reduce((a, b) => ({ ...a, ...b })) as T7;

  return { ...routeToStrings, LINK: routeLinks };
};

const RouteConfig = generateRoutes();
export default RouteConfig;
