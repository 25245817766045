import { v4 as uuidv4 } from 'uuid';
import { ArrayUtil, StringUtil } from 'src/utils';
import ReportMappingItemAssignmentModel from './ReportMappingItemAssignmentModel';
import ReportMappingItemRuleModel from './ReportMappingItemRuleModel';
import ReportMappingItemDTO from '../generated/ReportMappingItemDTO';

interface ReportMappingItemModel {
  key: string;
  displayOrder: number;
  displayName: string;
  displayDefaultAssignments: ReportMappingItemAssignmentModel[];
  customMappingRules: ReportMappingItemRuleModel[];
}
const ReportMappingItemModel = {
  create: (initValues?: Partial<ReportMappingItemModel>): ReportMappingItemModel => {
    const defaults: ReportMappingItemModel = {
      key: '',
      displayOrder: 0,
      displayName: '',
      displayDefaultAssignments: [],
      customMappingRules: [],
    };
    const fixedInitValues: Partial<ReportMappingItemModel> = initValues != null
      ? {
        key: StringUtil.IsNullOrEmpty(initValues.key) ? uuidv4() : initValues.key,
        displayDefaultAssignments: ArrayUtil.EasyMap(initValues.displayDefaultAssignments, ReportMappingItemAssignmentModel.create),
        customMappingRules: ArrayUtil.EasyMap(initValues.customMappingRules, ReportMappingItemRuleModel.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
  toDTO: (model: ReportMappingItemModel): ReportMappingItemDTO => ({
    key: model.key,
    displayOrder: model.displayOrder,
    displayName: model.displayName,
    displayDefaultAssignments: model.displayDefaultAssignments.map(ReportMappingItemAssignmentModel.toDTO),
    customMappingRules: model.customMappingRules.map(ReportMappingItemRuleModel.toDTO),
  }),
  fromDTO: (dto: ReportMappingItemDTO): ReportMappingItemModel => ({
    key: '',
    displayOrder: dto.displayOrder,
    displayName: dto.displayName,
    displayDefaultAssignments: dto.displayDefaultAssignments.map(ReportMappingItemAssignmentModel.fromDTO),
    customMappingRules: dto.customMappingRules.map(ReportMappingItemRuleModel.fromDTO),
  })
};

export default ReportMappingItemModel;
