import axios, { AxiosResponse } from 'axios';
import { ParamKeyValuePair } from 'react-router-dom';
import { KeyValue } from 'src/models/frontend/common/KeyValueAndSimilar';
import AuthProfileDTO from 'src/models/generated/AuthProfileDTO';
import { AxiosUtil } from 'src/utils';

class AuthorizationController {
  private prefix: string = "authorization";

  /** WARNING!~!~!
   *
   * This method differs from all other methods in that it reaches out to a different API.
   */
  // API_1* - This one is technically handled by the Azure Function
  generateBearerToken(): Promise<AxiosResponse<{token: string}>> {
    let route = 'api/generateBearerToken';

    const newAxios = axios.create({
      baseURL: process.env.REACT_APP_BEARER_URL
    });

    return newAxios.get(route);
  }

  // API_2
  doEmailLogin(emailAddress: string): Promise<AxiosResponse<AuthProfileDTO>> {
    let route = `${this.prefix}/authprofile`;
    let params: Record<string, string> = {
      emailAddress: emailAddress
    }
    return AxiosUtil.get<AuthProfileDTO>(route, AuthProfileDTO.create, { params });
  }

  // API_2.1
  doAgentLogin(emailAddress: string): Promise<AxiosResponse<AuthProfileDTO>> {
    let route = `${this.prefix}/authprofile/agent`;
    let params: Record<string, string> = {
      emailAddress: emailAddress
    }
    return AxiosUtil.get<AuthProfileDTO>(route, AuthProfileDTO.create, { params });
  }

  // API_3
  initialActivationRequest(): Promise<AxiosResponse<void>> {
    let route = `${this.prefix}/authprofile/activation/activationrequest`;
    return AxiosUtil.post<void, void>(route);
  }

  //API_4
  // initialActivationResponse(confirmationCode: string, password: string): Promise<AxiosResponse<AuthProfileDTO>> {
  //   let route = `${this.prefix}/authprofile/activation/activationrequest/confirmationcode/${confirmationCode}/password/${password}`;
  //   return AxiosUtil.post<AuthProfileDTO, void>(route, undefined, AuthProfileDTO.create);
  // }
  //API_4a
  initialActivationResponse(confirmationCode: string, password: string): Promise<AxiosResponse<AuthProfileDTO>> {
    let route = `${this.prefix}/authprofile/activation/activationrequest/confirmationcode/${confirmationCode}`;
    const body: KeyValue = 
      {key:'NEWPASSWORD', value:password}
    ;
    return AxiosUtil.post<AuthProfileDTO, KeyValue>(route, body, AuthProfileDTO.create);
  }

  // API_5
  twoFactorRequest(): Promise<AxiosResponse<void>> {
    let route = `${this.prefix}/authprofile/tfarequest`;
    return AxiosUtil.post<void, void>(route);
  }

  // API_6
  twoFactorResponse(confirmationCode: string): Promise<AxiosResponse<AuthProfileDTO>> {
    let route = `${this.prefix}/authprofile/tfarequest/confirmationcode/${confirmationCode}`;
    return AxiosUtil.post<AuthProfileDTO, void>(route, undefined, AuthProfileDTO.create);
  }
  
  // API_7a
  doPasswordLogin(password: string): Promise<AxiosResponse<AuthProfileDTO>> {
    let route = `${this.prefix}/authprofile/login`;
    const body: KeyValue = 
      {key:'PASSWORD', value: password};
  
    return AxiosUtil.post<AuthProfileDTO, KeyValue>(route, body, AuthProfileDTO.create);
  }

  // API_7.1
  logout(): Promise<AxiosResponse<void>> {
    let route = `${this.prefix}/authprofile/logout`;
    return AxiosUtil.post<void, void>(route, undefined);
  }

  // API_8
  passwordResetRequest(): Promise<AxiosResponse<void>> {
    let route = `${this.prefix}/authprofile/login/password/resetrequest`;
    return AxiosUtil.post<void, void>(route);
  }

  // API_9
  passwordResetResponse(confirmationCode: string, password: string): Promise<AxiosResponse<void>> {
    let route = `${this.prefix}/authprofile/login/password/resetrequest/confirmationcode/${confirmationCode}`;
    
    const body: KeyValue = 
      {key:'NEWPASSWORD',value: password}
    ;
    return AxiosUtil.post<void, KeyValue>(route, body);
  }
}

export default new AuthorizationController();
