import moment from 'moment';
import { ArrayUtil, ObjectUtil } from 'src/utils';

export const LinkTypeValues = ['Mapping', 'Transaction'] as const;
export type LinkTypeType = (typeof LinkTypeValues )[number];

interface ReportWarningDTO {
  /** This will be NULL when the 'LinkType' = MAPPING */
  displayDate: moment.Moment | null;
  displayType: string;
  displayDescription: string;
  displayWarning: string;
  displayWarningTimeStamps: Record<string, moment.Moment>|null;
  linkType: LinkTypeType | null;
  /** If 'LinkType' = Transaction then this will be the TransactionId to filter the transaction report for */
  linkId: string;
}
const ReportWarningDTO = {
  create: (initValues?: Partial<ReportWarningDTO>): ReportWarningDTO => {
    const defaults: ReportWarningDTO = {
      displayDate: null,
      displayType: '',
      displayDescription: '',
      displayWarning: '',
      displayWarningTimeStamps: null,
      linkType: null,
      linkId: '',
    };
    const fixedInitValues: Partial<ReportWarningDTO> = initValues != null
      ? {
        displayDate: ObjectUtil.ToMoment(initValues.displayDate),
        displayWarningTimeStamps: ObjectUtil.ToMomentDictionary(initValues.displayWarningTimeStamps),
        linkType: ArrayUtil.PickFromList(initValues.linkType, LinkTypeValues),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default ReportWarningDTO;
