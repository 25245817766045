// Dev Note: Need to change up the nulls

interface AuthProfileDTO {
  emailAddress: string | null;
  userActivated: boolean | null;
  tfaEnabled: boolean | null;
  tfaVerified: boolean | null;
  authenticated: boolean | null;
  role: string | null;
}
const AuthProfileDTO = {
  create: (initValues?: Partial<AuthProfileDTO>): AuthProfileDTO => {
    const defaults: AuthProfileDTO = {
      emailAddress: '',
      userActivated: null,
      tfaEnabled: null,
      tfaVerified: null,
      authenticated: null,
      role: ''
    };
    return { ...defaults, ...initValues };
  },
};

export default AuthProfileDTO;
