import React, { useContext, useEffect, useState } from 'react';
import { Button, Space, Switch, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { CloseCircleFilled, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { NameOf, NotificationUtil, StringUtil, TableExpressions } from 'src/utils';
import { useQueryParam } from 'src/hooks';
import MappingDTO from 'src/models/generated/MappingDTO';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import MappingController from 'src/api/MappingController';
import MultiToolPicker from 'src/components/MultiToolPicker';
import { MappingModal } from 'src/components/modals/mappingModal';
import SimplePopover from 'src/components/SimplePopover';
import MappingReportModal from 'src/components/modals/mappingModal/MappingReportModal';
import PrintIconSVG from 'src/components/svgs/PrintIconSVG';
import './LocationSettingsMapping.less';
import LinkWithQuery from 'src/components/LinkWithQuery';
import RouteConfig from 'src/config/RouteConfig';
import MappingModalNew from 'src/components/modals/mappingModal/MappingModalNew';

const LocationSettingsMapping: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const [tabQueryParam, setTabQueryParam] = useQueryParam('tab');
  const [mappingQueryParam, setMappingQueryParam, getMappingQueryParamAsync] = useQueryParam('mapping');
  const [tableData, setTableData] = useState<MappingDTO[]>([]);
  const [loading, setLoading] = useState(false);
  const [multiSubmitting, setMultiSubmitting] = useState<{ [key: number]: boolean }>({});
  const [processSubmitting, setProcessSubmitting] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  // const [modalVisibleNew, setModalVisibleNew] = useState(false);
  const [reportVisible, setReportVisible] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState<MappingDTO | null>(null);

  // OnLoad
  useEffect(() => {
    loadTableData();
  }, []);

  const loadTableData = async () => {
    setLoading(true);
    try {
      const results = await MappingController.getMappings(authContext.account!.id, authContext.location!.id);

      // Sort data
      const sortedData = [...results.data].sort((a, b) => a.displayOrder - b.displayOrder);

      setTableData(sortedData);
    } catch (error) {
      NotificationUtil.error({
        key: 'LocationSettingsMapping',
        message: 'Error while loading mappings',
        error
      });
    }

    // Reopen the modal if one was open previously
    const mappingQueryParam = await getMappingQueryParamAsync();
    if (!StringUtil.IsNullOrEmpty(mappingQueryParam)) {
      // Using the state functional approach. The tableData is changed during this render cycle, necessitating the function to get the current tableData
      setTableData((tableData) => {
        const found = tableData.find(x => x.key === mappingQueryParam);
        if (found != null) {
          setSelectedMapping(found);
          setModalVisible(true);
        }
        return tableData;
      });
    }

    setLoading(false);
  };

  // This is a multi-loader to handle submitting all of the boxes without disabling the entire UI
  const setSubmitting = (value: boolean, index: number) => {
    // Dev Note: Due to how useState() works, we need the functional approach
    setMultiSubmitting(previous => {
      let temp = { ...previous };
      temp[index] = value;
      return temp;
    });
  };

  const getSubmitting = (index: number) => {
    return multiSubmitting[index] ?? false;
  };

  const handleProcessTypeChange = async (record: MappingDTO, newValue: string, index: number) => {
    setSubmitting(true, index);

    try {
      const myRecord: MappingDTO = { ...record, processType: newValue, processType_IsUpdate: true };
      const result = await MappingController.updateMapping(authContext.account!.id, authContext.location!.id, myRecord.key, myRecord);

    } catch (error) {
      NotificationUtil.error({
        key: 'LocationSettingsMapping',
        message: 'Error while updating mapping item',
        error
      });
    }

    setSubmitting(false, index);
  };

  const handleProcessChange = async (record: MappingDTO, newValue: boolean, index: number) => {
    setLoading(true);

    try {
      const myRecord: MappingDTO = { ...record, process: newValue, process_IsUpdate: true };
      const result = await MappingController.updateMapping(authContext.account!.id, authContext.location!.id, myRecord.key, myRecord);

      // TODO: Might want to split this out into a load data and initializer
      await loadTableData();
    } catch (error) {
      NotificationUtil.error({
        key: 'LocationSettingsMapping',
        message: 'Error while updating mapping item',
        error
      });
    }

    setLoading(false);
  };

  const handleEditClick = (record: MappingDTO) => {
    // We should have a function here which opens the modal and updates the URL to show that it is indeed open
    setSelectedMapping(record);
    setMappingQueryParam(record.key);
    setModalVisible(true);
  };

  // const handleEditClickNew = (record: MappingDTO) => {
  //   // We should have a function here which opens the modal and updates the URL to show that it is indeed open
  //   setSelectedMapping(record);
  //   setMappingQueryParam(record.key);
  //   setModalVisibleNew(true);
  // };

  const handleViewClick = (record?: MappingDTO) => {
    // We should have a function here which opens the modal and updates the URL to show that it is indeed open
    setSelectedMapping(record == null ? null : record);
    setReportVisible(true);
  };

  const handleModalCancel = () => {
    setMappingQueryParam(null);
    setModalVisible(false);
  };

  const handleModalClose = () => {
    setMappingQueryParam(null);
    // setModalVisibleNew(false);
    setModalVisible(false);

    // TODO: Reload the table here
    setTimeout(() => {
      loadTableData();
    }, 1);
  };

  const handleReportCancel = () => {
    setReportVisible(false);
  };

  // Dev Note: In an effort to have these print buttons semi-standard, I am adding the record and doing work here
  const renderPrintButton = (record?: MappingDTO) => {
    // This will give us a return URL, without the route_id
    const url = new URL(location.href);
    url.searchParams.delete('route_id');
    const printingQueryParams: [string, string][] = [
      ['mappingItemId', record?.key ?? ''],
      ['returnUrl', url.pathname + url.search],
    ];

    return <LinkWithQuery openInNewTab to={RouteConfig.PRINTING_MAPPING_ITEM()} additionalQueryParams={printingQueryParams}>
      <Button type='link' icon={<PrintIconSVG />} />
    </LinkWithQuery>;
  };

  // TODO: Convert to new table expressions
  const tableColumns: ColumnProps<MappingDTO>[] = [
    {
      title: 'Name',
      width: 280,
      dataIndex: NameOf<MappingDTO>('displayName'),
      sorter: TableExpressions.StringSorter('displayName'),
      render: (_, record) => {
        return <div className='mapping-item-name'>
          <span>{record.displayName}</span>
          <SimplePopover
            dangerouslySetHTML
            content={record.displayInfo}
          />
        </div>;
      }
    }, {
      title: 'Process',
      width: 100,
      dataIndex: NameOf<MappingDTO>('process'),
      sorter: false,
      render: (_, record, index) => {
        if (record.type === 'List') {
          return null;
        }

        return <Switch
          loading={processSubmitting}
          disabled={processSubmitting}
          defaultChecked={record.process}
          onChange={value => handleProcessChange(record, value, index)}
        />;
      }
    }, {
      title: 'Process Type',
      width: 400,
      dataIndex: NameOf<MappingDTO>('processTypeDisplayType'),
      sorter: false,
      render: (_, record, index) => {
        return <MultiToolPicker
          componentStyle={{ width: 220 }}
          enableSaveBeforeFinish
          loading={getSubmitting(index)}
          disabled={getSubmitting(index)}
          displayType={record.processTypeDisplayType}
          formatType={record.processTypeFormatType}
          defaultValue={record.processType}
          // Dev Note: Not 100% sure what we decided, it was 2 weeks ago, so I am just going with my gut here. I believe this was just future proofing
          enableCarefulEntry={!StringUtil.IsNullOrEmpty(record.processTypeDisplayWarning)}
          warningMessage={record.processTypeDisplayWarning}
          additionalInfo={record.processTypeDisplayInfo}
          options={record.processTypeOptionCollection}
          onFinish={value => {
            return handleProcessTypeChange(record, value, index);
          }} />;
      }
    }, {
      title: 'Status',
      dataIndex: NameOf<MappingDTO>('statusMessage'),
      sorter: false,
      render: (_, record) => {
        // Currently, we have no way to determine if there is an issue except by parsing the incoming message
        if (!record.complete) {
          return <span className='mapping-item-status make-text-red'><CloseCircleFilled style={{ marginRight: 8 }} />{record.statusMessage}</span>;
        }
        return <span className='mapping-item-status'>{record.statusMessage}</span>;
      }
    }, {
      key: 'Edit',
      title: <SimplePopover content='Show All' trigger={'hover'}>
        {renderPrintButton()}
        <Button type='link' icon={<EyeOutlined />} onClick={() => (handleViewClick())} />
      </SimplePopover>,
      width: 140,
      className: 'without-padding',
      render: (_, record) => {
        return <>
          {renderPrintButton(record)}
          <Button type='link' icon={<EyeOutlined />} onClick={() => (handleViewClick(record))} />
          <Button type='link' icon={<EditOutlined />} onClick={() => (handleEditClick(record))} />
          {/* <Button type='link' icon={<EditOutlined style={{color: '#1CCC1C'}} />} onClick={() => (handleEditClickNew(record))} /> */}
        </>;
      }
    }
  ];

  return (
    <div className='location-settings-mapping'>
      <p style={{width:800}}>Accounting Link offers the ultimate flexibility in exporting summarized or detailed transactional information to the connected Accounting System. To make the most of this feature, simply enable or disable each transaction type, select a process type, and complete the mapping for each transaction. If the <b>&lsquo;Mapping is not completed&rsquo;</b> status message is displayed, proceed to the edit page to complete the mapping.</p>
      <Table
        rowKey={NameOf<MappingDTO>('key')}
        className='borderless-table'
        loading={loading}
        pagination={{
          pageSize: 30,
          hideOnSinglePage: true,
          position: ['bottomCenter']
        }}
        columns={tableColumns}
        dataSource={tableData}
      />
      {/* <MappingModal open={modalVisible} mappingKey={selectedMapping?.key ?? ''} onChange={loadTableData} onCancel={handleModalCancel} /> */}
      <MappingModalNew open={modalVisible} mappingKey={selectedMapping?.key ?? ''} onClose={handleModalClose} />
      <MappingReportModal open={reportVisible} mappingKey={selectedMapping?.key ?? ''} onCancel={handleReportCancel} />
    </div>
  );
};

export default LocationSettingsMapping;
