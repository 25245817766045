import { ArrayUtil } from 'src/utils';
import ReportTransactionItemSelectionValueDTO from './ReportTransactionItemSelectionValueDTO';

interface ReportTransactionSelectionValueDTO {
  transactionTypeKey: string;
  transactionItemSelectionValues: ReportTransactionItemSelectionValueDTO[];
}
const ReportTransactionSelectionValueDTO = {
  create: (initValues?: Partial<ReportTransactionSelectionValueDTO>): ReportTransactionSelectionValueDTO => {
    const defaults: ReportTransactionSelectionValueDTO = {
      transactionTypeKey: '',
      transactionItemSelectionValues: [],
    };
    const fixedInitValues: Partial<ReportTransactionSelectionValueDTO> = initValues != null
      ? {
        transactionItemSelectionValues: ArrayUtil.EasyMap(initValues.transactionItemSelectionValues, ReportTransactionItemSelectionValueDTO.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default ReportTransactionSelectionValueDTO;
