interface MappingPickListItemDTO {
  returnValue: string;
  displayValue: string;
  // Based on expected usage, the columns themselves might all be nullable or all but the first, idk
  column1Value: string;
  column2Value: string;
  column3Value: string;
  columnItemTypeValue: string;
}
const MappingPickListItemDTO = {
  create: (initValues?: Partial<MappingPickListItemDTO>): MappingPickListItemDTO => {
    const defaults: MappingPickListItemDTO = {
      returnValue: '',
      displayValue: '',
      column1Value: '',
      column2Value: '',
      column3Value: '',
      columnItemTypeValue: '',
    };
    return { ...defaults, ...initValues };
  },
};

export default MappingPickListItemDTO;
