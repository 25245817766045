import { v4 as uuidv4 } from 'uuid';
import { ArrayUtil, ObjectUtil, StringUtil } from 'src/utils';
import ReportAssignmentItemDTO from '../generated/ReportAssignmentItemDTO';

interface ReportAssignmentItemModel {
  /** Auto Generated field */
  key: string;

  transactionId: string;
  journalId: string;
  displayOrder: number;
  displayDate: moment.Moment | null;
  displayType: string;
  displayJournal: string;
  displayReference: string;
  displayName: string;
  displayLineType: string;
  displayDescription: string;
  displayDebit: number;
  displayCredit: number;
  displayTaxable: string;
  displayMapping: string;
}
const ReportAssignmentItemModel = {
  create: (initValues?: Partial<ReportAssignmentItemModel>): ReportAssignmentItemModel => {
    const defaults: ReportAssignmentItemModel = {
      key: '',
      transactionId: '',
      journalId: '',
      displayOrder: 0,
      displayDate: null,
      displayType: '',
      displayJournal: '',
      displayReference: '',
      displayName: '',
      displayLineType: '',
      displayDescription: '',
      displayDebit: 0,
      displayCredit: 0,
      displayTaxable: '',
      displayMapping: '',
    };
    const fixedInitValues: Partial<ReportAssignmentItemModel> = initValues != null
      ? {
        key: StringUtil.IsNullOrEmpty(initValues.key) ? uuidv4() : initValues.key,
        displayDate: ObjectUtil.ToMoment(initValues.displayDate),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
  toDTO: (model: ReportAssignmentItemModel): ReportAssignmentItemDTO => ({
    transactionId: model.transactionId,
    journalId: model.journalId,
    displayOrder: model.displayOrder,
    displayDate: model.displayDate,
    displayType: model.displayType,
    displayJournal: model.displayJournal,
    displayReference: model.displayReference,
    displayName: model.displayName,
    displayLineType: model.displayLineType,
    displayDescription: model.displayDescription,
    displayDebit: model.displayDebit,
    displayCredit: model.displayCredit,
    displayTaxable: model.displayTaxable,
    displayMapping: model.displayMapping,
  }),
  fromDTO: (dto: ReportAssignmentItemDTO): ReportAssignmentItemModel => ({
    key: '',
    transactionId: dto.transactionId,
    journalId: dto.journalId,
    displayOrder: dto.displayOrder,
    displayDate: dto.displayDate,
    displayType: dto.displayType,
    displayJournal: dto.displayJournal,
    displayReference: dto.displayReference,
    displayName: dto.displayName,
    displayLineType: dto.displayLineType,
    displayDescription: dto.displayDescription,
    displayDebit: dto.displayDebit,
    displayCredit: dto.displayCredit,
    displayTaxable: dto.displayTaxable,
    displayMapping: dto.displayMapping,
  })
};

export default ReportAssignmentItemModel;
