import React, { useEffect, useRef, useState } from 'react';
import { GetCurrentStateAsync } from 'src/utils';

/**
 * Adds an async value getter to the standard useState() hook
 */
export function useStateAsyncWithPrevious<T>(initialState: T | (() => T)): [T, (value: T | ((prevState: T) => T)) => void, () => Promise<T>, T | null | undefined] {
  const [internal, setInternal] = useState<T>(initialState);
  const prevCountRef = useRef<T | null | undefined>();

  useEffect(() => {
    prevCountRef.current = internal;
  }, [internal]);

  // Needs to be wrapped in a function or react will get VERY excited (I recommend you leave this alone)
  const getInternalAsync = () => GetCurrentStateAsync<T>(setInternal);

  return [internal, setInternal, getInternalAsync, prevCountRef.current];
}
