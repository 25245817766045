import React from 'react';
import classNames from 'classnames';
import StandardSVGProps from './StandardSVGProps';

const ResetIconSVG: React.FC<StandardSVGProps> = (props) => {
  return (
    <span style={props.style} className={classNames('reset-icon-svg', 'svg', props.className)}>
      <svg style={{paddingTop:4}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.25123 4.23125C6.24498 2.2375 8.98123 1 12.02 1C18.0975 1 23.0062 5.9225 23.0062 12C23.0062 18.0775 18.0975 23 12.02 23C6.89123 23 2.61497 19.4937 1.39123 14.75H4.25123C5.37873 17.9538 8.43123 20.25 12.02 20.25C16.5712 20.25 20.27 16.5513 20.27 12C20.27 7.44875 16.5712 3.75 12.02 3.75C9.73748 3.75 7.70248 4.69875 6.21748 6.1975L10.645 10.625H1.01998V1L4.25123 4.23125Z" fill="#FFFFFF" />
      </svg>
    </span>
  );
};

export default ResetIconSVG;
