import React, { useContext, useState } from 'react';
import { Button, Col, Descriptions, Input, Modal, Popconfirm, Row, Space } from 'antd';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { LocalStorageUtil, ObjectUtil } from 'src/utils';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { CacheContext } from 'src/providers/CacheContext';
import DebugOverlayItem, { DescriptionType } from './DebugOverlayItem';
import DebugPortal from './DebugPortal';
import './DebugOverlay.less';
import RouteConfig from 'src/config/RouteConfig';

interface DebugOverlayProps {
}

/**
 * Shows debug information as an overlay on the UI
 */
const DebugOverlay: React.FC<DebugOverlayProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const cacheContext = useContext(CacheContext);
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const [windowVisible, setWindowVisible] = useState(false);
  const [tempAuthCookie, setTempAuthCookie] = useState(LocalStorageUtil.AuthCookie ?? '');

  const handleWindowClose = () => {
    // Set window closed and also close the debug panel, user can reopen it
    // Dev Note: I thought about it and if the developer closes the window, they probably dont want the panel to pop back up on them
    setWindowVisible(false);
    setVisible(false);
  };

  const renderGeneral = () => {
    const items: DescriptionType[] = [
      { label: 'Logged In?', showWhenReduced: true, valueFunc: () => authContext.isLoggedIn ? 'Yes' : 'No' },
      { label: 'Bearer Token', showWhenReduced: true, valueFunc: () => LocalStorageUtil.AuthCookie || '[blank]' },
      { label: 'Auth State', showWhenReduced: true, valueFunc: () => authContext.authState || '[blank]' },
      { label: 'User Role', showWhenReduced: true, valueFunc: () => authContext.userRole || '[blank]' },
      { label: 'Redirect Url', valueFunc: () => authContext.redirectUrl?.pathname || '[blank]' },
      {
        label: 'Session Start Time',
        showWhenReduced: true,
        reloadInterval: 'second',
        valueFunc: () => {
          let sessionTime = authContext.loginTimeStamp;
          if (sessionTime == null) {
            return '[blank]';
          }

          const sessionDuration = moment.duration(moment().diff(sessionTime));

          const sessionString = sessionTime.isSameOrAfter(moment(), 'day')
            ? `${sessionTime.format('LT')}` // Session started today
            : `Yesterday at ${sessionTime.format('LT')}`; // Session started yesterday for those of us working over midnight
          return `${sessionString} (${Math.floor(sessionDuration.asMinutes())}:${Math.floor(sessionDuration.asSeconds()) % 60})`;
        }
      },
      {
        label: 'Session End Time',
        reloadInterval: '5 second',
        valueFunc: () => {
          let authExpiration = LocalStorageUtil.AuthExpiration;
          if (authExpiration == null) {
            return '[blank]';
          }

          const authExpirationMoment = moment(authExpiration);
          const authExpirationDuration = moment.duration(authExpirationMoment.diff(moment()));
          const expirationTime = authExpirationMoment.isAfter(moment(), 'day')
            ? `Tomorrow at ${authExpirationMoment.format('LT')}` // Session ends tomorrow
            : `${authExpirationMoment.format('LT')}`;
          return `${expirationTime} (${Math.floor(authExpirationDuration.asMinutes())} minutes remaining)`;
        }
      },
      { label: 'Initialized', valueFunc: () => authContext.isInitialized ? 'Yes' : 'No' },
      {
        label: 'Set Bearer Token', valueFunc: () => (
          <Popconfirm
            title={<div style={{ width: 280 }}>
              <p>You better know what you are doing!</p>
              <Input value={tempAuthCookie} onChange={e => setTempAuthCookie(e.target.value)} />
            </div>}
            icon={null}
            onConfirm={() => {
              // Save and reload
              LocalStorageUtil.AuthCookie = tempAuthCookie;
              LocalStorageUtil.AuthExpiration = moment().add(10, 'minute');
              window.location.reload();
            }}
            okText="Save & Reload"
            cancelText="Cancel"
          >
            <Button>Set</Button>
          </Popconfirm>
        )
      }
    ];

    return <DebugOverlayItem items={items} startReduced />;
  };

  const renderProfile = () => {
    if (authContext.profile == null) {
      return '[blank]';
    }

    const {
      // Important ones at the top
      displayName,
      emailAddress,
      tfaEnabled,
      tfaPreference,

      // Less important ones
      firstName,
      lastName,
      emailAddressValidated,
      phoneNumber,
      phoneNumberValidated,
      notificationsEnabled,
      accountCount,
      locationCount
    } = authContext.profile;

    const items: DescriptionType[] = [
      { label: 'Display Name', valueFunc: () => displayName || '[blank]', showWhenReduced: true },
      { label: 'Email', valueFunc: () => emailAddress || '[blank]', showWhenReduced: true },
      { label: 'Tfa Enabled', valueFunc: () => ObjectUtil.ToBoolean(tfaEnabled) ? 'Yes' : 'No', showWhenReduced: true },
      { label: 'Tfa Preference', valueFunc: () => tfaPreference || '[blank]', showWhenReduced: true },

      { label: 'First Name', valueFunc: () => firstName || '[blank]' },
      { label: 'Last Name', valueFunc: () => lastName || '[blank]' },
      { label: 'Email Validated', valueFunc: () => ObjectUtil.ToBoolean(emailAddressValidated) ? 'Yes' : 'No' },
      { label: 'Phone Number', valueFunc: () => phoneNumber || '[blank]' },
      { label: 'Phone Validated', valueFunc: () => ObjectUtil.ToBoolean(phoneNumberValidated) ? 'Yes' : 'No' },
      { label: 'Notifications?', valueFunc: () => ObjectUtil.ToBoolean(notificationsEnabled) ? 'Yes' : 'No' },
      { label: 'Accounts', valueFunc: () => accountCount || '[blank]' },
      { label: 'Locations', valueFunc: () => locationCount || '[blank]' },
    ];

    return <DebugOverlayItem items={items} startReduced />;
  };

  const renderAccount = () => {
    if (authContext.account == null) {
      return '[blank]';
    }

    const {
      // Important ones at the top
      displayName,
      accountCode,

      // Less important ones
      id,
      partnerKey
    } = authContext.account;

    const items: DescriptionType[] = [
      { label: 'Display Name', valueFunc: () => displayName || '[blank]', showWhenReduced: true },
      { label: 'Account Code', valueFunc: () => accountCode || '[blank]', showWhenReduced: true },

      { label: 'Id', valueFunc: () => id || '[blank]' },
      { label: 'Partner Key', valueFunc: () => partnerKey || '[blank]' },
    ];

    return <DebugOverlayItem items={items} startReduced />;
  };

  const renderLocation = () => {
    if (authContext.location == null) {
      return '[blank]';
    }

    const {
      // Important ones at the top
      displayName,
      partnerAccountNumber,

      // Less important ones
      id,
      locationKey,
      partnerDisplayName,
      beginDate
    } = authContext.location;

    const items: DescriptionType[] = [
      { label: 'Display Name', valueFunc: () => displayName || '[blank]', showWhenReduced: true },
      { label: 'Partner Account Number', valueFunc: () => partnerAccountNumber || '[blank]', showWhenReduced: true },

      { label: 'Id', valueFunc: () => id || '[blank]' },
      { label: 'Location Key', valueFunc: () => locationKey || '[blank]' },
      { label: 'Partner Display Name', valueFunc: () => partnerDisplayName || '[blank]' },
      { label: 'Begin Date', valueFunc: () => beginDate != null ? moment(beginDate).utc().format('LLL z Z') : '[blank]' },
    ];

    return <DebugOverlayItem items={items} startReduced />;
  };

  const renderCache = () => {
    const { accounts, locations, pickLists } = cacheContext;
    const accountCount = accounts.length;
    const locationCount = Object.entries(locations).map(([key, value]) => value.length).reduce((prev, curr) => prev + curr, 0);
    const pickListCount = Object.entries(pickLists).map(([key, value]) => value.pickListItems.length).reduce((prev, curr) => prev + curr, 0);

    const items: DescriptionType[] = [
      { label: 'Account Count', valueFunc: () => accountCount },
      { label: 'Location Count', valueFunc: () => `${locationCount} from ${Object.keys(locations).length} keys` },
      { label: 'PickList Count', valueFunc: () => `${pickListCount} from ${Object.keys(pickLists).length} keys` },
    ];

    return <DebugOverlayItem items={items} />;
  };

  const renderBuild = () => {
    // For this we need to look into the process.env
    // Important ones at the top
    const REACT_APP_BUILD_DATE = process.env.REACT_APP_BUILD_DATE;
    const NODE_ENV = process.env.NODE_ENV;
    const REACT_APP_USE_PROXY = process.env.REACT_APP_USE_PROXY;

    // Less important ones
    const REACT_APP_API_ENVIRONMENT = process.env.REACT_APP_API_ENVIRONMENT;
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const REACT_APP_BEARER_URL = process.env.REACT_APP_BEARER_URL;

    const items: DescriptionType[] = [
      { label: 'Build Date', valueFunc: () => (REACT_APP_BUILD_DATE != null ? moment.utc(REACT_APP_BUILD_DATE).local().format('LLL z Z') : 'Build date not detected!'), showWhenReduced: true },
      { label: 'Node Environment', valueFunc: () => NODE_ENV || '[blank]', showWhenReduced: true },
      { label: 'Use Proxy', valueFunc: () => ObjectUtil.ToBoolean(REACT_APP_USE_PROXY) ? 'Yes' : 'No', showWhenReduced: true },

      { label: 'API Environment', valueFunc: () => REACT_APP_API_ENVIRONMENT || '[blank]' },
      { label: 'API Url', valueFunc: () => REACT_APP_API_URL || '[blank]' },
      { label: 'Bearer Url', valueFunc: () => REACT_APP_BEARER_URL || '[blank]' },
    ];

    return <DebugOverlayItem items={items} />;
  };

  const renderBrowserLocation = () => {
    const {
      // Important ones at the top
      pathname,
      search,
      state,

      // Less important ones
      key,
      hash,
    } = location;

    const items: DescriptionType[] = [
      { label: 'Pathname', valueFunc: () => pathname || '', showWhenReduced: true },
      {
        label: 'Search', valueFunc: () => {
          if (search == null) {
            return '';
          }
          // This is annoying that I have to do it this way but whatever. THe keys are spread, value is retrieved, both put into an array [key, value] and then sent to the fromEntries func to get a real object
          // WHICH IS WHAT I SHOULD HAVE IN THE BEGINNING. WHAT IS THIS STUPID API
          const searchParams = new URLSearchParams(search);
          const searchAsObject = Object.fromEntries([...searchParams.keys()].map(x => ([x, searchParams.get(x)!])));
          return <pre>{JSON.stringify(searchAsObject, null, 2)}</pre>;
        }, showWhenReduced: true
      },
      {
        label: 'State',
        showWhenReduced: true,
        valueFunc: () => {
          if (state == null) {
            return '';
          }
          if (typeof state === 'string') {
            return state;
          }
          if (typeof state === 'object') {
            return <pre>{JSON.stringify(state, null, 2)}</pre>;
          }
        }
      },

      { label: 'Key', valueFunc: () => key || '' },
      { label: 'Hash', valueFunc: () => hash || '' },
    ];

    return <DebugOverlayItem items={items} startReduced />;
  };

  const renderReturnUrl = () => {
    if (authContext.redirectUrl == null) {
      return '[blank]';
    }

    const {
      // Important ones at the top
      pathname,
      search,
      state,

      // Less important ones
      key,
      hash,
    } = authContext.redirectUrl;

    const items: DescriptionType[] = [
      { label: 'Pathname', valueFunc: () => pathname || '', showWhenReduced: true },
      {
        label: 'Search', valueFunc: () => {
          if (search == null) {
            return '';
          }
          // This is annoying that I have to do it this way but whatever. THe keys are spread, value is retrieved, both put into an array [key, value] and then sent to the fromEntries func to get a real object
          // WHICH IS WHAT I SHOULD HAVE IN THE BEGINNING. WHAT IS THIS STUPID API
          const searchParams = new URLSearchParams(search);
          const searchAsObject = Object.fromEntries([...searchParams.keys()].map(x => ([x, searchParams.get(x)!])));
          return <pre>{JSON.stringify(searchAsObject, null, 2)}</pre>;
        }
      },
      {
        label: 'State',
        valueFunc: () => {
          if (state == null) {
            return '';
          }
          if (typeof state === 'string') {
            return state;
          }
          if (typeof state === 'object') {
            return <pre>{JSON.stringify(state, null, 2)}</pre>;
          }
        }
      },

      { label: 'Key', valueFunc: () => key || '' },
      { label: 'Hash', valueFunc: () => hash || '' },
    ];

    return <DebugOverlayItem items={items} startReduced />;
  };

  // Handles rendering all of the above and does so because we are now allowing a second window to be present
  const renderAll = () => {
    return <>
      <h2>Debug Info</h2>
      <Descriptions bordered column={1} size='small' layout='horizontal'>
        <Descriptions.Item label="General">{renderGeneral()}</Descriptions.Item>
        <Descriptions.Item label="Profile">{renderProfile()}</Descriptions.Item>
        <Descriptions.Item label="Account">{renderAccount()}</Descriptions.Item>
        <Descriptions.Item label="Location">{renderLocation()}</Descriptions.Item>
        <Descriptions.Item label="Cache Info">{renderCache()}</Descriptions.Item>
        <Descriptions.Item label="Build Info">{renderBuild()}</Descriptions.Item>
        <Descriptions.Item label="Browser Location">{renderBrowserLocation()}</Descriptions.Item>
        <Descriptions.Item label="Return Url">{renderReturnUrl()}</Descriptions.Item>
      </Descriptions>
    </>;
  };

  // So, for now, we will want to show a small box on the bottom right that will popup with the rest when clicked
  if (!visible) {
    return <Button className='show-debug-panel' onClick={() => setVisible(prev => !prev)}>Dev</Button>;
  }

  if (windowVisible) {
    return <DebugPortal className='debug-overlay' onClose={handleWindowClose}>
      {renderAll()}
    </DebugPortal>;
  }

  return <div className='debug-overlay modal-edition'>
    <Space className='debug-panel-buttons'>
      <Button onClick={() => setWindowVisible(true)}>As Window</Button>
      <Button type='primary' onClick={() => setVisible(prev => !prev)}>Hide</Button>
    </Space>
    {renderAll()}
  </div>;
};

export default DebugOverlay;
