import React, { useContext, useEffect, useState } from 'react';
import { Table, Typography } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { NameOf, NotificationUtil, TableExpressions, TableColumnBuilder } from 'src/utils';
import { useQueryParam } from 'src/hooks';
import ReportController from 'src/api/ReportController';
import ReportJournalDTO from 'src/models/generated/ReportJournalDTO';
import ReportJournalItemDTO from 'src/models/generated/ReportJournalItemDTO';
import { SharedReportPageProps } from './SharedPageProps';
import { FilterValue, SortOrder } from 'antd/lib/table/interface';
import { tz } from 'moment-timezone';

const PrintingReportJournalPage: React.FC<SharedReportPageProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [journalIdQueryParam, setJournalIdQueryParam] = useQueryParam('journalId');
  const [referenceIdQueryParam, setReferenceIdQueryParam] = useQueryParam('journalReferenceId');
  const [tableData, setTableData] = useState<ReportJournalDTO[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<ReportJournalDTO[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
  const [filterValues, setFilterValues] = useState<Record<string, FilterValue | null>>();
  
  const [journalTableFiltersQueryParam     ,setJournalTableFiltersQueryParam] = useQueryParam('journalFilters');
  const [journalTableSortedColumnQueryParam,setJournalTableSortedColumnQueryParam] = useQueryParam('journalSortedColumn');
  const [journalTableSortOrderQueryParam   ,setJournalTableSortOrderQueryParam] = useQueryParam('journalSortOrder');

  useEffect(() => {
    loadTableData();
  }, [props.startDate, props.endDate]);

  // useEffect(() => {
  //   setExpandedKeys(props.expandedKeys);
  // }, [props.expandedKeys]);

  useEffect(() => {
    setSelectedKeys(props.selectedKeys);
  }, [props.selectedKeys]);

  // Handle the filter query params
  useEffect(() => {
    if (journalIdQueryParam != null) {
      const filteredData = tableData.filter(x => x.journalId === journalIdQueryParam);
      setFilteredTableData(filteredData);
      return;
    }

    if (referenceIdQueryParam != null) {
      const filteredData = tableData.filter(x => x.displayReference === referenceIdQueryParam);
      setFilteredTableData(filteredData);
      return;
    }

    setFilteredTableData(tableData);
  }, [tableData, journalIdQueryParam, referenceIdQueryParam]);

  const loadTableData = async () => {
    if (authContext.account == null || authContext.location == null || props.startDate == null && props.endDate == null) {
      return;
    }

    setLoading(true);
    try {
      const results = await ReportController.getReportJournal(authContext.account.id, authContext.location.id, props.startDate!, props.endDate!);

      // Sort data
      let sortedData = [...results.data].map(x => ({
        ...x,
        // Sort inner array
        items: TableExpressions.Methods.FixSortOrderNumber(x.items.sort(TableExpressions.Sorters.NumberSorter('displayOrder')), 'displayOrder')
      }));

      sortedData = sortedData.map(x => {
        for(let key in x.displayCommentTimeStamps){
          x.displayComment = x.displayComment.replace(key, x.displayCommentTimeStamps[key].format('MM/DD/YYYY') + ' at ' + x.displayCommentTimeStamps[key].format('h:mm:ss a ') + tz(tz.guess()).zoneAbbr());
        }
        return x;
      });

      setTableData(sortedData);
      if(journalTableFiltersQueryParam !== ''){
        const filters = JSON.parse(journalTableFiltersQueryParam??'') as Record<string, FilterValue | null>;
        setFilterValues(filters);
      }
      

      setExpandedKeys(JSON.parse(localStorage.getItem('journalExpandedIds')??''));
    } catch (error) {
      NotificationUtil.error({
        key: 'PrintingReportJournal',
        message: 'Error while loading Report data',
        error
      });
    }
    setLoading(false);
  };

  const setFilteredInfo = (filters: Record<string, FilterValue|null>) => {
    console.log('here');
  };

  const tableColumns: ColumnProps<ReportJournalDTO>[] = [
    TableColumnBuilder.Create<ReportJournalDTO>('displayDate', 'Date')
      .Width(100)
      .AddSorter('Date')
      .AddEnumFilterer(tableData.map(x => x.displayDate).sort((a, b) => a?.diff(b) ?? 0).filter(x => x != null && x?.year() > 2000).map(x => x?.format('L') ?? ''), 'Date')
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .AddRenderer('ShortDate')
      .Build(),
    TableColumnBuilder.Create<ReportJournalDTO>('displayType', 'Type')
      .Width(160)
      .AddSorter('Text')
      .AddRenderer('Ellipses')
      .AddEnumFilterer(tableData.map(x => x.displayType).sort((a, b) => a.localeCompare(b)), 'Exact Text')
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .Build(),
    TableColumnBuilder.Create<ReportJournalDTO>('displayName', 'Name')
      .Width(180)
      .AddSorter('Text')
      .AddRenderer('Ellipses')
      .AddTextFilterer()
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .Build(),
    TableColumnBuilder.Create<ReportJournalDTO>('displayReference', 'Reference')
      .Width(180)
      .AddSorter('Text')
      .AddRenderer('Ellipses')
      .AddTextFilterer()
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .Build(),
    TableColumnBuilder.Create<ReportJournalDTO>('displayDebit', 'Debit')
      .Width(80)
      .AddSorter('Number')
      .Align('right')
      .AddRenderer('Currency', true)
      .AddCurrencyFilterer()
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .Build(),
    TableColumnBuilder.Create<ReportJournalDTO>('displayCredit', 'Credit')
      .Width(80)
      .Align('right')
      .AddSorter('Number')
      .AddRenderer('Currency', true)
      .AddCurrencyFilterer()
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .Build(),
    TableColumnBuilder.Create<ReportJournalDTO>('displayStatus', 'Status')
      .Width(130)
      .AddRenderer('Ellipses')
      .AddSorter('Text')
      .AddEnumFilterer(tableData.map(x => x.displayStatus).sort((a, b) => a.localeCompare(b)), 'Exact Text')
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .Build(),
    TableColumnBuilder.Create<ReportJournalDTO>('displayComment', 'Comment')
      .AddRenderer('Ellipses')
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .AddTextFilterer()
      .Build(),
  ];

  const transactionColumns: ColumnProps<ReportJournalItemDTO>[] = [
    TableColumnBuilder.Create<ReportJournalItemDTO>('displayAccount', 'Account')
      .Width(180)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportJournalItemDTO>('displayName', 'Name')
      .Width(160)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportJournalItemDTO>('displayDescription', 'Description')
      .Width(540)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportJournalItemDTO>('displayDebit', 'Debit')
      .Width(100)
      .Align('right')
      .AddRenderer('Currency', true, true)
      .Build(),
    TableColumnBuilder.Create<ReportJournalItemDTO>('displayCredit', 'Credit')
      .Width(100)
      .Align('right')
      .AddRenderer('Currency', true, true)
      .Build(),
  ];

  
  const sortedColumn = tableColumns.find(x => x.key === journalTableSortedColumnQueryParam);
  if(sortedColumn)
  sortedColumn.defaultSortOrder = journalTableSortOrderQueryParam as SortOrder;

  return (
    <div className='printing-report-journal'>
      <Table
        rowKey={NameOf<ReportJournalDTO>('journalId')}
        className='less-condensed-table striped-table borderless-table'
        rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
        loading={loading}
        pagination={false}
        columns={tableColumns}
        dataSource={filteredTableData}
        onChange={(_, filters) => { setFilteredInfo(filters); }}
        expandable={{
          expandedRowKeys: expandedKeys,
          onExpandedRowsChange: keys => (setExpandedKeys([...keys])),
          expandIcon: (props) => {
            const onClick = (e: React.MouseEvent<HTMLElement>) => props.onExpand(props.record, e);
            return props.expanded ? <DownOutlined onClick={onClick} className='standard-chevrons' /> : <RightOutlined onClick={onClick} className='standard-chevrons' />;
          },
          // Might need to enhance this with onExpand to default them all open
          expandedRowRender: record => {
            // Add the first column from the calling row
            const expandedColumns: ColumnProps<ReportJournalItemDTO>[] = [
              TableColumnBuilder.Create<any>('displaySource', '')
                .Width(120)
                .OnCell((_, index) => ({ rowSpan: index === 0 ? 100 : 0 }))
                .AddRenderer('Custom', () => record.displaySource)
                .Build(),
              ...transactionColumns
            ];

            return <div style={{marginLeft:30}} className='with-display-inline-block'>
              <Table
                rowKey={NameOf<ReportJournalItemDTO>('displayOrder')}
                className='condensed-table striped-table borderless-table'
                rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
                pagination={false}
                columns={expandedColumns}
                dataSource={record.items}
                summary={pageData => {
                  return <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align='right' className='with-border-top'>
                      <Typography.Text strong>{TableExpressions.Renderers.Currency('displayItemDebitTotalAmount', true)('', record)}</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align='right' className='with-border-top'>
                      <Typography.Text strong>{TableExpressions.Renderers.Currency('displayItemCreditTotalAmount', true)('', record)}</Typography.Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>;
                }}
              />
            </div>;
          },
        }}
      />
    </div>
  );
};

export default PrintingReportJournalPage;
