import moment from 'moment';
import { ArrayUtil, ObjectUtil } from 'src/utils';
import ReportJournalItemDTO from './ReportJournalItemDTO';

interface ReportJournalDTO {
  journalId: string;
  displaySource: string;
  displayDate: moment.Moment | null;
  displayType: string;
  displayReference: string;
  displayName: string;
  displayDebit: number;
  displayCredit: number;
  displayStatus: string;
  displayComment: string;
  displayCommentTimeStamps: Record<string, moment.Moment>|null;
  displayItemType: string;
  displayItemDebitTotalAmount: number;
  displayItemCreditTotalAmount: number;
  items: ReportJournalItemDTO[];
}
const ReportJournalDTO = {
  create: (initValues?: Partial<ReportJournalDTO>): ReportJournalDTO => {
    const defaults: ReportJournalDTO = {
      journalId: '',
      displaySource: '',
      displayDate: null,
      displayType: '',
      displayReference: '',
      displayName: '',
      displayDebit: 0,
      displayCredit: 0,
      displayStatus: '',
      displayComment: '',
      displayCommentTimeStamps: null,
      displayItemType: '',
      displayItemDebitTotalAmount: 0,
      displayItemCreditTotalAmount: 0,
      items: [],
    };
    const fixedInitValues: Partial<ReportJournalDTO> = initValues != null
      ? {
        displayDate: ObjectUtil.ToMoment(initValues.displayDate),
        displayCommentTimeStamps: ObjectUtil.ToMomentDictionary(initValues.displayCommentTimeStamps),
        items: ArrayUtil.EasyMap(initValues.items, ReportJournalItemDTO.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default ReportJournalDTO;
