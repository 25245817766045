import { Button, DatePicker, Form, Input, Row } from 'antd';
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import LocationController from 'src/api/LocationController';
import ReadOnlyFormItem from 'src/components/ReadOnlyFormItem';
import LocationDTO from 'src/models/generated/LocationDTO';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { CacheContext } from 'src/providers/CacheContext';
import { NameOf, NotificationUtil } from 'src/utils';

const LocationSettingsDetails: React.FC = () => {
  const [form] = Form.useForm();
  const authContext = useContext(AuthenticationContext);
  const cacheContext = useContext(CacheContext);
  const [submitting, setSubmitting] = useState(false);
  const [formDirty, setFormDirty] = useState(false);

  useEffect(() => {
    loadForm();
  }, []);

  const loadForm = async () => {
    // Set the form directly from here. Setting an initial value on the form object will not allow us to reset later
    form.setFields(Object.entries(authContext.location!).map(([key, value]) => ({ name: key, value: value })));
  };

  const onFinish = async (values: LocationDTO) => {
    setSubmitting(true);
    try {
      const result = await LocationController.saveLocation(authContext.account!.id, authContext.location!.id, values);
      cacheContext.updateLocation(authContext.account?.id?? '', result.data);
      authContext.updateLocation(result.data);
      NotificationUtil.success({
        key: 'LocationSettingsDetails',
        message: 'Settings have been updated'
      });
      setFormDirty(false);
    } catch (error: unknown) {
      const message = axios.isAxiosError(error) ? error.message : String(error);
      NotificationUtil.error({
        key: 'LocationSettingsDetails',
        message: message,
        duration: 0
      });
    }
    setSubmitting(false);
  };

  return (
    <div className='location-settings-details standard-form-layout'>
      <p style={{width: 800}}>The fields below contain important account and location information. The Location Key is a unique, abbreviated location name (approximately 3-5 characters) that serves as the identifier for the location. The Location Name is an unabbreviated location name. The Partner Account number is a unique number assigned to your Accounting Link Subscription. Lastly, the Begin Date determines the date at which Accounting Link will begin monitoring activity from the Shop Management System.</p>
      <Form
        size='large'
        layout='horizontal'
        requiredMark={false}
        form={form}
        labelCol={{ flex: '150px' }}
        onFinish={onFinish}
        onFieldsChange={() => setFormDirty(true)} // Indicate that something has changed in the form
      >
        {/* Location Key */}
        <Form.Item
          label='Location Key'
          name={NameOf<LocationDTO>('locationKey')}
        >
          <Input allowClear />
        </Form.Item>

        {/* Location Name */}
        <Form.Item
          label='Location Name'
          name={NameOf<LocationDTO>('displayName')}
          rules={[
            { required: true, message: 'Location Name is required' }
          ]}
        >
          <Input allowClear />
        </Form.Item>

        {/* Partner Account */}
        <Form.Item
          label='Partner Account'
          name={NameOf<LocationDTO>('partnerAccountNumber')}
        >
          <ReadOnlyFormItem />
        </Form.Item>

        {/* Begin Date */}
        <Form.Item
          label='Begin Date'
          name={NameOf<LocationDTO>('beginDate')}
          rules={[
            { required: true, message: 'Begin Date is required' }
          ]}
        >
          <DatePicker allowClear format={['MM/DD/YYYY', 'MM/DD/YY', 'M/D/YYYY', 'M/D/YY', 'MMDDYYYY', 'MMDDYY']} />
        </Form.Item>

        <Row justify='end'>
          <Form.Item>
            <Button type='primary' htmlType="submit" style={{padding: '0 32px'}} loading={submitting} disabled={!formDirty}>Save</Button>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

export default LocationSettingsDetails;
