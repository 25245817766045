import { CheckCircleFilled, CloseCircleFilled, EditFilled } from '@ant-design/icons';
import { Col, Row, Select, Skeleton, Switch } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import MappingController from 'src/api/MappingController';
import MappingPickListItemModel from 'src/models/frontend/MappingPickListItemModal';
import MappingItemAssignmentDTO from 'src/models/generated/MappingItemAssignmentDTO';
import MappingItemDTO from 'src/models/generated/MappingItemDTO';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { NotificationUtil, StringUtil } from 'src/utils';
import PickListSelectorModal from '../PickListSelectorModal';
import { CacheContext } from 'src/providers/CacheContext';

export interface MappingItemDefaultTabNewProps {
  mappingKey: string;
  mappingItem: MappingItemDTO;
  hasUnsavedChanges: boolean;

  /** Called when the Enable Custom Mapping Rules toggle is changed */
  onChange?: (value: MappingItemDTO) => void;
}

const MappingItemDefaultTabNew: React.FC<MappingItemDefaultTabNewProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const cacheContext = useContext(CacheContext);

  // Picklist
  const [selectedAssignment, setSelectedAssignment] = useState<MappingItemAssignmentDTO | null>(null);
  const [pickListModalVisible, setPickListModalVisible] = useState(false);

  // Item Assignments
  const [itemAssignments, setItemAssignments] = useState<MappingItemAssignmentDTO[]>([]);
  const [loadingItemAssignments, setLoadingItemAssignments] = useState(false);

  // Misc
  const [submittingMappingItem, setSubmittingMappingItem] = useState(false);
  const [submittingItemAssignments, setSubmittingItemAssignments] = useState(false);

  useEffect(() => {
    if (StringUtil.IsNullOrEmpty(props.mappingKey)) {
      return;
    }
    loadAssignments();
  }, [props.mappingKey]);

  const loadAssignments = async () => {
    setLoadingItemAssignments(true);
    try {
      const results = await MappingController.getMappingItemAssignments(authContext.account!.id, authContext.location!.id, props.mappingKey, props.mappingItem.key);

      // Sort data
      const sortedData = [...results.data].sort((a, b) => (a.displayOrder - b.displayOrder));

      setItemAssignments(sortedData);
    } catch (error) {
      NotificationUtil.error({
        key: 'MappingItemDefaultTabNew',
        message: 'Error while loading Assignment data',
        error
      });
    }
    setLoadingItemAssignments(false);
  };


  const handlePicklistOpen = async (record: MappingItemAssignmentDTO) => {
    // Open the picklist modal
    setSelectedAssignment(record);
    setPickListModalVisible(true);
  };

  const handlePicklistClose = () => {
    setPickListModalVisible(false);
    setSelectedAssignment(null);
  };

  // Should be called when the user has chosen their selection and will close the modal accordingly
  const handlePickListSelect = async (item: MappingPickListItemModel) => {
    setSubmittingMappingItem(true);

    try {
      if (selectedAssignment === null) {
        return;
      }

      // Update the selected assignment
      const newAssignment: MappingItemAssignmentDTO = {
        ...selectedAssignment!,
        displayValue: item.returnValue,
        selectedItemName: item.displayValue,
        selectedItemType: item.columnItemTypeValue.toUpperCase()
      };


      // Splice into the current array
      let newAssignments = [...itemAssignments];
      const assignmentIndex = newAssignments.findIndex(x => x.key === newAssignment.key);
      newAssignments.splice(assignmentIndex, 1, newAssignment);
      // Submit and save
      const results = await MappingController.saveMappingItemAssignments(authContext.account!.id, authContext.location!.id, props.mappingKey, props.mappingItem.key, newAssignments);
      setItemAssignments(newAssignments);

      // Looks good, propagate the changes up since it holds the collection we used
      props.onChange?.(props.mappingItem);

      NotificationUtil.success({
        key: 'MappingModalItemNew',
        message: `${newAssignment.displayName} has been updated to: ${newAssignment.selectedItemName}`,
      });
    } catch (error) {
      NotificationUtil.error({
        key: 'MappingModalItemNew',
        message: 'Error while saving Mapping Item Assignment',
        error
      });
    }

    setSubmittingMappingItem(false);

    //reload assignments
    loadAssignments();
    // Close the picklist
    handlePicklistClose();
  };

  const handleCustomMappingToggle = async (value: boolean) => {
    setSubmittingMappingItem(true);
    try {
      const mappingItem: MappingItemDTO = {
        ...props.mappingItem,
        enableMappingRules: value,
        enableMappingRules_IsUpdate: true
      };
      const results = await MappingController.saveMappingItem(authContext.account!.id, authContext.location!.id, props.mappingKey, mappingItem);

      // Looks good, propagate the changes up
      props.onChange?.(mappingItem);
    } catch (error) {
      NotificationUtil.error({
        key: 'MappingModalItemNew',
        message: 'Error while saving Mapping Item',
        error
      });
    }
    setSubmittingMappingItem(false);
  };

  const renderCheckmark = (value?: boolean | null) => {
    if (value == null) {
      return null;
    }
    return value
      ? <CheckCircleFilled className='standard-icon icon-with-white-background make-text-green without-margin-important' />
      : <CloseCircleFilled className='standard-icon icon-with-white-background make-text-red without-margin-important' />;
  };

  // Loader
  if (props.mappingItem == null || loadingItemAssignments) {
    return <Skeleton active />;
  }

  return <div className='mapping-item-default-tab'>
    {/* render assignments here */}
    {itemAssignments.map(assignment => (
      <Row key={assignment.key} className='with-margin-bottom'>
        <Col flex='250px'>
          <Row justify='space-between' align='middle' style={{ height: '100%' }}>
            <span style={{ alignSelf: 'flex-start' }}>{assignment.displayName}</span>
            <Row align='middle' style={{ paddingRight: 8 }}>{renderCheckmark(assignment.complete)}</Row>
          </Row>
        </Col>
        <Col>
          <Select
            style={{ width: 220 }}
            options={[]}
            onKeyDown={(e) => (['Space', 'Enter', 'NumpadEnter'].includes(e.code) && handlePicklistOpen(assignment))}
            onClick={() => handlePicklistOpen(assignment)}
            suffixIcon={<EditFilled />}
            open={false} // Prevents dropdown from opening so we can show the modal onclick
            loading={submittingItemAssignments}
            value={assignment.selectedItemName} />
        </Col>
      </Row>))}

    {/* Show the ability to change ths custom mapping when we actually can show the tab, handled by the API */}
    {props.mappingItem.showCustomMapping &&
      <Row className='with-margin-bottom'>
        <Col flex='250px'>Enable Custom Mapping Rules</Col>
        <Col>
          <Switch
            loading={submittingMappingItem}
            disabled={submittingMappingItem}
            checked={props.mappingItem.enableMappingRules}
            onChange={handleCustomMappingToggle}
          />
        </Col>
      </Row>}

    <PickListSelectorModal open={pickListModalVisible} assignment={selectedAssignment} onCancel={handlePicklistClose} onSelect={handlePickListSelect} />
  </div>;
};

export default MappingItemDefaultTabNew;