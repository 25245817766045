import { ArrayUtil } from 'src/utils';
import AuditTrailItemDTO from './AuditTrailItemDTO';

interface AuditTrailDTO {
  warningMessage: string;
  auditTrailItems: AuditTrailItemDTO[];
}
const AuditTrailDTO = {
  create: (initValues?: Partial<AuditTrailDTO>): AuditTrailDTO => {
    const defaults: AuditTrailDTO = {
      warningMessage: '',
      auditTrailItems: [],
    };
    const fixedInitValues: Partial<AuditTrailDTO> = initValues != null
      ? {
        auditTrailItems: ArrayUtil.EasyMap(initValues.auditTrailItems, AuditTrailItemDTO.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default AuditTrailDTO;
