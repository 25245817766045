import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { NameOf, NotificationUtil, TableExpressions, TableColumnBuilder } from 'src/utils';
import ReportController from 'src/api/ReportController';
import ReportWarningModel from 'src/models/frontend/ReportWarningModel';
import { SharedReportPageProps } from './SharedPageProps';
import moment, { isMoment } from 'moment';
import { tz } from 'moment-timezone';

const PrintingReportWarningPage: React.FC<SharedReportPageProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [tableData, setTableData] = useState<ReportWarningModel[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadTableData();
  }, [props.startDate, props.endDate]);

  const loadTableData = async () => {
    if (authContext.account == null || authContext.location == null || props.startDate == null && props.endDate == null) {
      return;
    }

    setLoading(true);
    try {
      const results = await ReportController.getReportWarning(authContext.account.id, authContext.location.id, props.startDate!, props.endDate!);

      // Sort data
      let sortedData = [...results.data].sort(TableExpressions.Sorters.DateSorter('displayDate'));
      
      sortedData = sortedData.map(x => {
        for(let key in x.displayWarningTimeStamps){
          let mo = moment(x.displayWarningTimeStamps[key]);
          x.displayWarning = x.displayWarning.replace(key, mo.format('MM/DD/YYYY') + ' at ' + mo.format('h:mm:ss a ') + tz(tz.guess()).zoneAbbr());
        }
        return x;
      });

      setTableData(sortedData);
    } catch (error) {
      NotificationUtil.error({
        key: 'PrintingReportWarning',
        message: 'Error while loading Report data',
        error
      });
    }
    setLoading(false);
  };

  const tableColumns: ColumnProps<ReportWarningModel>[] = [
    TableColumnBuilder.Create<ReportWarningModel>('displayType', 'Type')
      .Width(180)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportWarningModel>('displayDate', 'Date')
      .Width(180)
      .AddRenderer('ShortDate', false)
      .Build(),
    TableColumnBuilder.Create<ReportWarningModel>('displayDescription', 'Description')
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportWarningModel>('displayWarning', 'Warning')
      .AddRenderer('Ellipses')
      .Build(),
  ];

  return (
    <div className='printing-report-warning'>
      <Table
        rowKey={NameOf<ReportWarningModel>('key')}
        className='less-condensed-table striped-table borderless-table'
        rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
        loading={loading}
        pagination={false}
        columns={tableColumns}
        dataSource={tableData}
      />
    </div>
  );
};

export default PrintingReportWarningPage;
