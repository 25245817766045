import React, { useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { Row, Col, Space,  Switch, Skeleton, Button, Modal, Empty } from 'antd';
import { NotificationUtil, TableExpressions } from 'src/utils';
import { useStateAsync } from 'src/hooks';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import ActivityTile from 'src/components/ActivityTile';
import ActivityController from 'src/api/ActivityController';
import TileModel from 'src/models/frontend/TileModel';
import AscendingIconSVG from 'src/components/svgs/AscendingIconSVG';
import DescendingIconSVG from 'src/components/svgs/DescendingIconSVG';
import SimpleRangeSelector from 'src/components/SimpleRangeSelector';
import './HomePage.less';
import HomePageSVG from 'src/components/svgs/HomePageSVG';
import { AxiosError } from 'axios';

const HomePage: React.FC = () => {
  const authContext = useContext(AuthenticationContext);
  const [tableData, setTableData] = useState<TileModel[]>([]);
  const [selectedDateRange, setSelectedDateRange, selectedDateRangeAsync] = useStateAsync<[moment.Moment | null, moment.Moment | null] | null>([moment().subtract(6, 'day'), moment()]);
  const [showPosted, setShowPosted, showPostedAsync] = useStateAsync(false);
  const [loadingTableData, setLoadingTableData] = useState(true);
  const [sortAscending, setSortAscending] = useState(true);
  const [dateSelected, setDateSelected] = useState(false);
  const [tilesWaitingToPost, setTilesWaitingToPost] = useState<string[]>([]);
  //refresh tiles which are waiting to post every 10 seconds
  useEffect(() => {
    const timer = setInterval(() => {
      if(!loadingTableData && tilesWaitingToPost.length > 0){
        let arr : string[] = [...tilesWaitingToPost];
        arr.forEach(async element => {
          await handleRefresh(element);
        });
      }
    }, 10000);
    return () => clearInterval(timer);
  }, [loadingTableData, tilesWaitingToPost]);
  

  useEffect(() => {
    loadTableData();
  }, [sortAscending, showPosted]);

  useEffect(() =>{
    if(dateSelected){
      loadTableData();
    }
  }, [selectedDateRange, dateSelected]);

  useEffect(() =>{
    let tiles: string[] = [];
    tableData.forEach((e) => {
      if(e.displayStatusMessage.toLowerCase() === 'waiting to be posted')
        {
          tiles.push(e.key);
        }
    });
    setTilesWaitingToPost(tiles);
  }, [tableData]);

  // It's more like tile data, but this is fine
  const loadTableData = async () => {
    setLoadingTableData(true);
    try {
      // Get values for the request
      const dateRange = await selectedDateRangeAsync();
      const showPosted = await showPostedAsync();

      const startDate = (dateRange != null && dateRange[0] != null ? dateRange[0] : moment()).startOf('day');
      const endDate = (dateRange != null && dateRange[1] != null ? dateRange[1] : moment()).startOf('day');

      const results = showPosted
        ? await ActivityController.getPostedActivityTiles(authContext.account!.id, authContext.location!.id, startDate, endDate)
        : await ActivityController.getActivityTiles(authContext.account!.id, authContext.location!.id, !sortAscending);

      // Sort data
      let sortedData = !sortAscending?[...results.data].sort(TableExpressions.Sorters.ReverseNumberSorter('displayDate')).map(x => ({
        ...x,
        // Sort inner array
        items: TableExpressions.Methods.FixSortOrderNumber(x.transactionSummaryItems.sort(TableExpressions.Sorters.NumberSorter('displayOrder')), 'displayOrder')
      })):
      [...results.data].sort(TableExpressions.Sorters.NumberSorter('displayDate')).map(x => ({
        ...x,
        // Sort inner array
        items: TableExpressions.Methods.FixSortOrderNumber(x.transactionSummaryItems.sort(TableExpressions.Sorters.NumberSorter('displayOrder')), 'displayOrder')
      }));
      if(!dateSelected && sortedData.length>0){
        if(sortAscending){
          if(sortedData[0].displayDate != undefined)
          {
          let time = moment(sortedData[0].displayDate);
          let timeb = moment(sortedData[0].displayDate).add(6,'days');
          setSelectedDateRange([time, timeb]);
          }
        }
        else{
          if(sortedData[0].displayDate != undefined)
          {
          let time = moment(sortedData[0].displayDate);
          let timeb = moment(sortedData[0].displayDate).subtract(6,'days');
          setSelectedDateRange([timeb, time]);
          }
        }
      }
      setTableData(sortedData);

      console.log(localStorage);

    } catch (error) {
      NotificationUtil.error({
        key: 'HomePage',
        message: 'Error while loading Activity Tiles',
        error
      });
    }
    setLoadingTableData(false);
  };

  const handleRefresh = async (tileKey: string) => {
    //setLoadingTableData(true);
    try {
      const results = await ActivityController.getActivityTile(authContext.account!.id, authContext.location!.id, tileKey);

      const newTile = results.data.shift();
      if (newTile == null) {
        throw new Error('Failed to load the tile');
      }
      TableExpressions.Methods.FixSortOrderNumber(newTile.transactionSummaryItems.sort(TableExpressions.Sorters.NumberSorter('displayOrder')), 'displayOrder');
      // Parse the data into the array
      setTableData(currentTableData => {
        const foundIndex = currentTableData.findIndex(x => x.key === tileKey);
        if (foundIndex < 0) {
          return currentTableData;
        }

        // Splice the new record in
        const newTableData = [...currentTableData];
        newTableData.splice(foundIndex, 1, newTile);

        return newTableData;
      });
    } catch (error) {
      NotificationUtil.error({
        key: 'HomePage',
        message: 'Error while reloading Activity Tiles',
        error
      });
    }
    //setLoadingTableData(false);
  };

  const handleDateRangeSelect = (values: [moment.Moment | null, moment.Moment | null] | null) => {
    setSelectedDateRange(values);
    setDateSelected(true);
  };

  const handleShowPostedChange = () => {
    setShowPosted(prev => !prev);
    setDateSelected(false);
  };

  const handleSortDirectionChange = () => {
    
    setSortAscending(prev => !prev);
  };

  const handleApprove = async (tile: TileModel) => {
    console.log(tile);
    try{
      const result = await ActivityController.approveActivityTile(authContext.account!.id, authContext.location!.id,tile.key, tile.tileVersion);
      //changed the tile this seems the easiest way to update the list
      await handleRefresh(result.data.key);
    }
    catch(error: any){
      Modal.warning({
        title: 'Warning',
        content: error.response.data,
        okText: 'Ok'
      });
    }
  };

  return (
    <div className='home-page'>
      {/* Header */}
      <Row className='header with-margin-bottom' justify='space-between'>
        <Col>
          <h1>Activity By Day</h1>
        </Col>
        <Col>
          <Space size={12}>
            <Button type='link' disabled={loadingTableData} onClick={handleSortDirectionChange}>{sortAscending ? <AscendingIconSVG /> : <DescendingIconSVG />}</Button>
            {showPosted && <SimpleRangeSelector
              disabled={loadingTableData}
              maxDayRange={31}
              value={selectedDateRange}
              onChange={handleDateRangeSelect}
            />}
            <Switch disabled={loadingTableData} checked={showPosted} onChange={handleShowPostedChange} />
            <span>Show All</span>
          </Space>
        </Col>
      </Row>

      {/* Loader for table */}
      {loadingTableData && <Skeleton active />}

      {/* Content */}
      {!loadingTableData && <Row justify="start">
        {tableData.map((tile, index) => (
          <Col className='tile' key={index} span={24}>
            <ActivityTile tileData={tile} onApprove={tile => handleApprove(tile)} onRefresh={key => handleRefresh(key)} />
          </Col>
        ))}
        
        
        </Row>}
      {!loadingTableData && tableData.length==0 && !showPosted && <Empty imageStyle={{height:200}} description={<><h2><b style={{color:'GrayText'}}>Well done!</b></h2><h4 style={{color:'GrayText'}}>You're all caught up!</h4></>} style={{alignContent:'center', paddingTop:'250px'}} image={<HomePageSVG />} />}
      {!loadingTableData && tableData.length==0 && showPosted && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<>No data</>} style={{alignContent:'center', paddingTop:'250px'}}/>}
    </div>
  );
};

export default HomePage;
