import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { StringUtil } from 'src/utils';
import { ArrayUtil, ObjectUtil } from 'src/utils';
import TileDTO from '../generated/TileDTO';
import TileTransactionSummaryItemModel from './TileTransactionSummaryItemModel';

const DisplayStatusIdValues = ['Warning', 'Unapproved', 'Approved', 'Posted'] as const;
export type DisplayStatusIdType = (typeof DisplayStatusIdValues)[number];

interface TileModel {
  key: string;
  displayDate: moment.Moment | null;
  displayStatusId: DisplayStatusIdType | null;
  displayStatusMessage: string;
  displayStatusInfo: string;
  tileVersion: string;
  transactionSummaryItems: TileTransactionSummaryItemModel[];
}
const TileModel = {
  create: (initValues?: Partial<TileModel>): TileModel => {
    const defaults: TileModel = {
      key: '',
      displayDate: null,
      displayStatusMessage: '',
      displayStatusId: null,
      displayStatusInfo: '',
      tileVersion: '',
      transactionSummaryItems: [],
    };
    const fixedInitValues: Partial<TileModel> = initValues != null
      ? {
        key: StringUtil.IsNullOrEmpty(initValues.key) ? uuidv4() : initValues.key,
        displayDate: ObjectUtil.ToMoment(initValues.displayDate),
        displayStatusId: ArrayUtil.PickFromList(initValues.displayStatusId, DisplayStatusIdValues),
        transactionSummaryItems: ArrayUtil.EasyMap(initValues.transactionSummaryItems, TileTransactionSummaryItemModel.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
  toDTO: (model: TileModel): TileDTO => ({
    key: model.key,
    displayDate: model.displayDate,
    displayStatusId: model.displayStatusId,
    displayStatusMessage: model.displayStatusMessage,
    displayStatusInfo: model.displayStatusInfo,
    tileVersion: model.tileVersion,
    transactionSummaryItems: model.transactionSummaryItems.map(TileTransactionSummaryItemModel.toDTO),
  }),
  fromDTO: (dto: TileDTO): TileModel => ({
    key: dto.key,
    displayDate: dto.displayDate,
    displayStatusId: dto.displayStatusId,
    displayStatusMessage: dto.displayStatusMessage,
    displayStatusInfo: dto.displayStatusInfo,
    tileVersion: dto.tileVersion,
    transactionSummaryItems: dto.transactionSummaryItems.map(TileTransactionSummaryItemModel.fromDTO),
  })
};

export default TileModel;
