import { ArrayUtil, ObjectUtil } from 'src/utils';

const TFAPreferenceValues = ['Email', 'Text'] as const;
export type TFAPreferenceType = (typeof TFAPreferenceValues)[number];

interface UserProfileDTO {
  firstName: string;
  lastName: string;
  displayName: string;
  emailAddress: string;
  emailAddressValidated: boolean;
  phoneNumber: string;
  phoneNumberValidated: boolean;
  tfaEnabled: boolean;
  tfaPreference: TFAPreferenceType;
  notificationsEnabled: boolean;
  accountCount: number;
  locationCount: number;
  role: string;
}
const UserProfileDTO = {
  create: (initValues?: Partial<UserProfileDTO>): UserProfileDTO => {
    const defaults: UserProfileDTO = {
      firstName: '',
      lastName: '',
      displayName: '',
      emailAddress: '',
      emailAddressValidated: false,
      phoneNumber: '',
      phoneNumberValidated: false,
      tfaEnabled: false,
      tfaPreference: 'Email',
      notificationsEnabled: false,
      accountCount: 0,
      locationCount: 0,
      role: '',
    };
    const fixedInitValues: Partial<UserProfileDTO> = initValues != null
      ? {
        emailAddressValidated: ObjectUtil.ToBoolean(initValues.emailAddressValidated),
        phoneNumberValidated: ObjectUtil.ToBoolean(initValues.phoneNumberValidated),
        tfaEnabled: ObjectUtil.ToBoolean(initValues.tfaEnabled),
        tfaPreference: ArrayUtil.PickFromList(initValues.tfaPreference, TFAPreferenceValues),
        notificationsEnabled: ObjectUtil.ToBoolean(initValues.notificationsEnabled),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default UserProfileDTO;
