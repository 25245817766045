import React, { useState } from 'react';
import { Button, ButtonProps } from 'antd';
import { useInterval } from 'src/hooks';

interface DelayButtonProps extends ButtonProps {
  /** Delay in seconds */
  delay: number;
  onClick: () => void;
}

/**
 * A button component that has builtin capacity for a delay!
 *
 * No more mashing the resend code to resend 800 codes
 */
const DelayButton: React.FC<DelayButtonProps> = (props) => {
  const [delay, setDelay] = useState(0);
  const [intervalDelay, setIntervalDelay] = useState<number | null>(null); // This is used only to clear the interval once the delay hits 0

  useInterval(() => {
    setDelay(delay - 1);
    if (delay < 1) {
      // This will clear the interval in the useInterval hook
      setIntervalDelay(null);
    }
  }, intervalDelay);

  const handleOnClick = () => {
    if (delay < 1) {
      setDelay(props.delay);
      setIntervalDelay(1000);

      props.onClick && props.onClick();
    }
  };

  const buttonProps: ButtonProps = {
    ...props,
    disabled: delay > 0 || props.disabled,
    loading: delay > 0 || props.loading,
    onClick: handleOnClick
  };

  // return (
  //   <Button {...buttonProps}>{delay > 0 ? delay : props.children}</Button>
  // );

  // return (<Button {...buttonProps}>{delay > 0 && delay} {props.children}</Button>);

  return (<Button {...buttonProps}>{delay > 0 && delay} {props.children}</Button>);
};

export default DelayButton;
