import { ArrayUtil, ObjectUtil } from 'src/utils';
import ReportTransactionItemAssignmentDTO from './ReportTransactionItemAssignmentDTO';

interface ReportTransactionItemDTO {
  allowAddDelete: boolean;
  transactionItemId: string;
  transactionLineTypeKey: string;
  displayOrder: number;
  displayLineType: string;
  displayDescription: string;
  displayAmount: number;
  displayAssignment: string;
  displayAssignments: ReportTransactionItemAssignmentDTO[];
}
const ReportTransactionItemDTO = {
  create: (initValues?: Partial<ReportTransactionItemDTO>): ReportTransactionItemDTO => {
    const defaults: ReportTransactionItemDTO = {
      allowAddDelete: false,
      transactionItemId: '',
      transactionLineTypeKey: '',
      displayOrder: 0,
      displayLineType: '',
      displayDescription: '',
      displayAmount: 0,
      displayAssignment: '',
      displayAssignments: [],
    };
    const fixedInitValues: Partial<ReportTransactionItemDTO> = initValues != null
      ? {
        displayAssignments: ArrayUtil.EasyMap(initValues.displayAssignments, ReportTransactionItemAssignmentDTO.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default ReportTransactionItemDTO;
