import React, { useContext, useEffect, useState } from 'react';
import { Collapse, Skeleton, Typography } from 'antd';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { NotificationUtil, TableExpressions } from 'src/utils';
import { useQueryParam } from 'src/hooks';
import AuditController from 'src/api/AuditController';
import AuditTrailItemDTO from 'src/models/generated/AuditTrailItemDTO';
import AuditTrailHistoryItem from 'src/components/modals/AuditTrailHistoryItem';
import { SharedReportPageProps } from './SharedPageProps';

const PrintingReportAudit: React.FC<SharedReportPageProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [auditItemIdQueryParam, setAuditItemIdQueryParam] = useQueryParam('auditItemId');
  const [pageData, setPageData] = useState<AuditTrailItemDTO[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadTableData();
  }, [auditItemIdQueryParam]);

  useEffect(() => {
    setExpandedKeys(props.expandedKeys);
  }, [props.expandedKeys]);

  const loadTableData = async () => {
    if (authContext.account == null || authContext.location == null || auditItemIdQueryParam == null) {
      return;
    }

    setLoading(true);
    try {
      const results = await AuditController.getAuditTrailItemHistory(authContext.account.id, authContext.location.id, auditItemIdQueryParam);

      // Sort data
      const sortedData = [...results.data.auditTrailItems].sort(TableExpressions.DateSorter('created')).reverse();

      setPageData(sortedData);
    } catch (error) {
      NotificationUtil.error({
        key: 'PrintingReportAuditTrail',
        message: 'Error while loading Report data',
        error
      });
    }
    setLoading(false);
  };

  return (
    <div className='printing-report-audit'>
      <Skeleton active loading={loading}>
        <div className='with-flex-layout'>
          <Collapse
            activeKey={expandedKeys}
            onChange={keys => (setExpandedKeys([...keys]))}
          >
            {pageData.map((x, i) => <Collapse.Panel key={i} header={<div><Typography.Text strong>{x.created?.format('lll z')} - {x.userDisplayName}</Typography.Text>: {x.name}</div>}>
              <AuditTrailHistoryItem auditTrailItem={x} />
            </Collapse.Panel>)}
          </Collapse>
        </div>
      </Skeleton>
    </div>
  );
};

export default PrintingReportAudit;
