import { ObjectUtil } from 'src/utils';

// Dev Note: I am 100% considering removing this basically useless model... Should just use MappingItemAssignmentDTO
// The reason being that the other DTO has an endpoint and is put into this DTO only when saving to MappingItemRuleDTO
// Sigh...
/** Used to build **MappingItemRuleDTO** and is essentially a **MappingItemAssignmentDTO** */
interface MappingItemRuleAssignmentDTO {
  key: string;
  complete: boolean;
  displayName: string;
  displayDescription: string;
  displayOrder: number;
  displayValue: string;
  required: boolean;
  pickListType: string;
  selectedItemType: string;
  selectedItemName: string;
  pickListItemTypeFilter: string;
  pickListItemTypeFilterRequired: boolean;
}
const MappingItemRuleAssignmentDTO = {
  create: (initValues?: Partial<MappingItemRuleAssignmentDTO>): MappingItemRuleAssignmentDTO => {
    const defaults: MappingItemRuleAssignmentDTO = {
      key: '',
      complete: false,
      displayName: '',
      displayDescription: '',
      displayOrder: 0,
      displayValue: '',
      required: false,
      pickListType: '',
      selectedItemType: '',
      selectedItemName: '',
      pickListItemTypeFilter: '',
      pickListItemTypeFilterRequired: false,
    };
    const fixedInitValues: Partial<MappingItemRuleAssignmentDTO> = initValues != null
      ? {
        complete: ObjectUtil.ToBoolean(initValues.complete),
        required: ObjectUtil.ToBoolean(initValues.required),
        pickListItemTypeFilterRequired: ObjectUtil.ToBoolean(initValues.pickListItemTypeFilterRequired),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default MappingItemRuleAssignmentDTO;
