import { v4 as uuidv4 } from 'uuid';
import { ArrayUtil, StringUtil } from 'src/utils';
import ReportMappingItemModel from './ReportMappingItemModel';
import ReportMappingDTO from '../generated/ReportMappingDTO';

interface ReportMappingModel {
  key: string;
  displayOrder: number;
  displayName: string;
  items: ReportMappingItemModel[];
}
const ReportMappingModel = {
  create: (initValues?: Partial<ReportMappingModel>): ReportMappingModel => {
    const defaults: ReportMappingModel = {
      key: '',
      displayOrder: 0,
      displayName: '',
      items: [],
    };
    const fixedInitValues: Partial<ReportMappingModel> = initValues != null
      ? {
        key: StringUtil.IsNullOrEmpty(initValues.key) ? uuidv4() : initValues.key,
        items: ArrayUtil.EasyMap(initValues.items, ReportMappingItemModel.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
  toDTO: (model: ReportMappingModel): ReportMappingDTO => ({
    key: model.key,
    displayOrder: model.displayOrder,
    displayName: model.displayName,
    items: model.items.map(ReportMappingItemModel.toDTO),
  }),
  fromDTO: (dto: ReportMappingDTO): ReportMappingModel => ({
    key: '',
    displayOrder: dto.displayOrder,
    displayName: dto.displayName,
    items: dto.items.map(ReportMappingItemModel.fromDTO),
  })
};

export default ReportMappingModel;
