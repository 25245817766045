import { v4 as uuidv4 } from 'uuid';
import { ArrayUtil, ObjectUtil, StringUtil } from 'src/utils';
import MappingItemRuleAssignmentDTO from '../generated/MappingItemRuleAssignmentDTO';
import MappingItemRuleFilterDTO from '../generated/MappingItemRuleFilterDTO';
import MappingItemRuleDTO from '../generated/MappingItemRuleDTO';

interface MappingItemRuleModel {
  key: string;
  ruleFilterDisplay: string;
  complete?: boolean;
  displayName: string;
  displayOrder: number;
  displayReportOn: boolean;
  ruleAssignments: MappingItemRuleAssignmentDTO[];
  /** This is the combination of the RuleDefinition + RuleFilterCriteria. Both can be found in the RuleFilterCriteria API call */
  ruleFilters: MappingItemRuleFilterDTO[];
}
const MappingItemRuleModel = {
  create: (initValues?: Partial<MappingItemRuleModel>): MappingItemRuleModel => {
    const defaults: MappingItemRuleModel = {
      key: '',
      ruleFilterDisplay: '',
      // complete: false,
      displayOrder: 0,
      displayName: '',
      displayReportOn: false,
      ruleFilters: [],
      ruleAssignments: [],
    };
    // Generate some of the values, if they aren't provided
    const fixedInitValues: Partial<MappingItemRuleModel> = initValues != null
      ? {
        key: StringUtil.IsNullOrEmpty(initValues.key) ? uuidv4() : initValues.key,
        // Autogenerate the display values if they don't exist
        ruleFilterDisplay: StringUtil.IsNullOrEmpty(initValues.ruleFilterDisplay) ? MappingItemRuleModel.generateRuleFilterDisplay(initValues) : initValues.ruleFilterDisplay,
        displayName: StringUtil.IsNullOrEmpty(initValues.displayName) ? MappingItemRuleModel.generateDefaultDisplayName(initValues) : initValues.displayName,

        // The rest are down here
        ruleAssignments: ArrayUtil.EasyMap(initValues.ruleAssignments, MappingItemRuleAssignmentDTO.create),
        ruleFilters: ArrayUtil.EasyMap(initValues.ruleFilters, MappingItemRuleFilterDTO.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
  toDTO: (model: MappingItemRuleModel): MappingItemRuleDTO => ({
    complete: model.complete || false,
    displayOrder: model.displayOrder,
    displayName: model.displayName,
    displayReportOn: model.displayReportOn,
    ruleFilters: model.ruleFilters,
    ruleAssignments: model.ruleAssignments,
  }),
  fromDTO: (dto: MappingItemRuleDTO): MappingItemRuleModel => ({
    key: '',
    ruleFilterDisplay: MappingItemRuleModel.generateRuleFilterDisplay(dto),
    complete: dto.complete,
    displayOrder: dto.displayOrder,
    displayName: dto.displayName,
    displayReportOn: dto.displayReportOn,
    ruleFilters: dto.ruleFilters,
    ruleAssignments: dto.ruleAssignments,
  }),
  /**
   * Regenerates the `RuleFilterDisplay` value based on the `ruleFilter[]` property
   *
   * Returns the new string, does not update the input object
   */
  generateRuleFilterDisplay: (input: Partial<MappingItemRuleModel> | Partial<MappingItemRuleDTO>): string => {
    return [...input.ruleFilters || []]
      .sort((a, b) => a.definitionOrder - b.definitionOrder)
      .map(filter => filter?.definitionDisplayValue + ': ' + filter?.filterCriteriaDisplayValue || 'N/A').join(' >> ');
  },
  /**
   * Generates the default for the `DisplayName` property based on the `ruleFilter[]` property
   *
   * Returns the new string, does not update the input object
   */
  generateDefaultDisplayName: (input: Partial<MappingItemRuleModel> | Partial<MappingItemRuleDTO>): string => {
    return [...input.ruleFilters || []]
      .sort((a, b) => a.definitionOrder - b.definitionOrder)
      .map(filter => `${filter?.definitionDisplayValue || 'N/A'}: ${filter?.filterCriteriaDisplayValue || 'N/A'}`).join(' >> ');
  }
};

export default MappingItemRuleModel;
