import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row, Space, Table, Tooltip } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { NameOf, NotificationUtil, TableExpressions, TableColumnBuilder } from 'src/utils';
import { useQueryParam } from 'src/hooks';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import ReportController from 'src/api/ReportController';
import ReportAssignmentModel from 'src/models/frontend/ReportAssignmentModel';
import ReportAssignmentItemModel from 'src/models/frontend/ReportAssignmentItemModel';
import LinkWithQuery from 'src/components/LinkWithQuery';
import RouteConfig from 'src/config/RouteConfig';
import PrintIconSVG from 'src/components/svgs/PrintIconSVG';
import ResetIconSVG from 'src/components/svgs/ResetIconSVG';

interface ReportAssignmentsProps {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  refreshTrigger: number;
}

const ReportAssignments: React.FC<ReportAssignmentsProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [tableData, setTableData] = useState<ReportAssignmentModel[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);

  const [filterValues, setFilterValues] = useState<string>('');
  const [assignmentSortedColumnQueryParam, setAssignmentSortedColumnQueryParam] = useState<string>('');
  const [assignmentSortOrderQueryParam, setAssignmentSortOrderQueryParam] = useState<string>('');

  useEffect(() => {
    if (props.startDate != null && props.endDate != null) {
      loadTableData();
    }
  }, [props.startDate, props.endDate]);
  useEffect(() => {
    if(props.refreshTrigger > 0)
      refreshTableData();
  }, [props.refreshTrigger]);

  const loadTableData = async () => {
    if (props.startDate == null || props.endDate == null) {
      return;
    }

    setLoading(true);
    try {
      const results = await ReportController.getReportAssignment(authContext.account!.id, authContext.location!.id, props.startDate, props.endDate);

      // Sort data
      let sortedData = [...results.data].sort(TableExpressions.Sorters.NumberSorter('displayOrder')).map(x => ({
        ...x,
        // Sort inner array
        items: TableExpressions.Methods.FixSortOrderNumber(x.items.sort(TableExpressions.Sorters.NumberSorter('displayOrder')), 'displayOrder')
      }));
      setTableData(sortedData);
    } catch (error) {
      NotificationUtil.error({
        key: 'ReportAssignments',
        message: 'Error while loading Report data',
        error
      });
    }
    setLoading(false);
  };

  const refreshTableData = async () => {
    
    if (props.startDate == null || props.endDate == null) {
      return;
    }
    setLoading(true);
    try {
      const results = await ReportController.getReportAssignment(authContext.account!.id, authContext.location!.id, props.startDate, props.endDate);

      // Sort data
      let sortedData = [...results.data].sort(TableExpressions.Sorters.NumberSorter('displayOrder')).map(x => ({
        ...x,
        // Sort inner array
        items: TableExpressions.Methods.FixSortOrderNumber(x.items.sort(TableExpressions.Sorters.NumberSorter('displayOrder')), 'displayOrder')
      }));
      setTableData(sortedData);
    } catch (error) {
      NotificationUtil.error({
        key: 'ReportTransactions',
        message: 'Error while loading Report data',
        error
      });
    }
    setLoading(false);

  };

  const renderExpandCollapseAll = () => {
    if (expandedKeys.length > 0) {
      return <Button style={{marginBottom: '10px',marginRight:'10px'}} onClick={() => (setExpandedKeys([]))}>Collapse All</Button>;
    }
    return <Button style={{marginBottom: '10px',marginRight:'10px'}} onClick={() => (setExpandedKeys(tableData.map(x => x.displayAccount)))}>Expand All</Button>;
  };

  const renderPrintButton = () => {
    const url = new URL(location.href);
    url.searchParams.delete('route_id');
    const printingQueryParams: [string, string][] = [
      ['reportStartDate', props.startDate?.format('L')?.replaceAll('/', '-') ?? ''],
      ['reportEndDate', props.endDate?.format('L')?.replaceAll('/', '-') ?? ''],
      ['returnUrl', url.pathname + url.search],
      ['assignmentsFilters', filterValues],
      ['assignmentsSortedColumn',assignmentSortedColumnQueryParam],
      ['assignmentsSortOrder',assignmentSortOrderQueryParam],
      ['expandedIds', JSON.stringify(expandedKeys)],
    ];

    return <LinkWithQuery openInNewTab to={RouteConfig.PRINTING_ASSIGNMENT_REPORT()} additionalQueryParams={printingQueryParams}>
      <Button style={{ height: 36 }}><Space><PrintIconSVG /><span>Print</span></Space></Button>
    </LinkWithQuery>;
  };

  const tableColumns: ColumnProps<ReportAssignmentModel>[] = [
    TableColumnBuilder.Create<ReportAssignmentModel>('displayAccount', 'Account')
      .Width(220)
      .AddSorter('Text')
      .AddRenderer('Ellipses')
      .AddTextFilterer()
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentModel>('displayType', 'Type')
      .Width(220)
      .AddSorter('Text')
      .AddRenderer('Ellipses')
      .AddEnumFilterer(tableData.map(x => x.displayType).sort((a, b) => a.localeCompare(b)))
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentModel>('displayDebit', 'Debit')
      .Width(160)
      .ClassName('wide-column')
      .Align('right')
      .AddSorter('Number')
      .AddRenderer('Currency', true, true)
      .AddCurrencyFilterer()
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentModel>('displayCredit', 'Credit')
      .Width(160)
      .ClassName('wide-column')
      .Align('right')
      .AddSorter('Number')
      .AddRenderer('Currency', true, true)
      .AddCurrencyFilterer()
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentModel>()
      .Key('empty-space')
      .Build(),
  ];

  const transactionColumns: ColumnProps<ReportAssignmentItemModel>[] = [
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayDate', 'Date')
      .Width(140)
      .AddRenderer('ShortDate')
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayType', 'Type')
      .Width(160)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayJournal', 'Journal')
      .Width(200)
      .AddRenderer('Ellipses', (record) => <LinkWithQuery to={RouteConfig.REPORTS_JOURNALS()} additionalQueryParams={[['journalId', record.journalId]]} includedQueryParams={['reportStartDate', 'reportEndDate']}>
        <Button type='link'>{record.displayJournal}</Button>
      </LinkWithQuery>)
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayReference', 'Reference')
      .Width(150)
      .AddRenderer('Ellipses', (record) => <LinkWithQuery to={RouteConfig.REPORTS_TRANSACTIONS()} additionalQueryParams={[['transactionId', record.transactionId], ['transactionType', record.displayType]]} includedQueryParams={['reportStartDate', 'reportEndDate']}>
        <Button type='link'>{record.displayReference}</Button>
      </LinkWithQuery>)
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayName', 'Name')
      .Width(140)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayLineType', 'Line Type')
      .Width(160)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayDescription', 'Description')
      .Width(280)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayDebit', 'Debit')
      .Width(90)
      .Align('right')
      .AddRenderer('Currency', true, true)
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayCredit', 'Credit')
      .Width(90)
      .Align('right')
      .AddRenderer('Currency', true, true)
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayTaxable', 'Taxable')
      .Width(70)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayMapping', 'Mapping')
      .AddRenderer('Ellipses')
      .Build(),
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any, extra: { currentDataSource: Array<ReportAssignmentModel> }) => {
    //When printing we want to just show the table as it was with sorts filters and all
    setFilterValues(JSON.stringify(filters));
    setAssignmentSortOrderQueryParam(sorter.order);
    setAssignmentSortedColumnQueryParam(sorter.field);
  };

  return (
    <div className='report-assignment'>
      <Row justify='space-between' style={{ marginBottom: 16 }}>
        <Col>
          <p style={{width:800}}>The totals below provide details, by General Ledger Account, for enabled transactions that have been posted in the Shop Management System for the date range selected. Expand an Account to see the transactions included in the total.</p>
        </Col>
        <Col flex={'100px'}>
          {renderPrintButton()}
        </Col>
      </Row>
      {renderExpandCollapseAll()}
      <Button style={{marginRight:'10px', marginBottom: '10px'}} onClick={refreshTableData}><Space><span>Refresh</span></Space></Button>
      <Table
        rowKey={NameOf<ReportAssignmentModel>('displayAccount')}
        className='condensed-table striped-table borderless-table'
        rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
        loading={loading}
        onChange={handleTableChange as any}
        expandable={{
          expandedRowKeys: expandedKeys,
          onExpandedRowsChange: keys => (setExpandedKeys([...keys])),
          expandIcon: (props) => {
            const onClick = (e: React.MouseEvent<HTMLElement>) => props.onExpand(props.record, e);
            return props.expanded ? <DownOutlined onClick={onClick} className='standard-chevrons' /> : <RightOutlined onClick={onClick} className='standard-chevrons' />;
          },
          expandedRowRender: record => (<div style={{ marginLeft: 30 }}>
            <Table
              rowKey={NameOf<ReportAssignmentItemModel>('key')}
              className='condensed-table striped-table borderless-table assignment-expanded-table'
              rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
              pagination={false}
              columns={transactionColumns}
              dataSource={record.items}
            />
          </div>),
        }}
        pagination={false}
        columns={tableColumns}
        dataSource={tableData}
      />
    </div>
  );
};

export default ReportAssignments;
