import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { StringUtil } from 'src/utils';
import sleep from 'src/utils/core/SleepUtil';

// Much of this component was copied or modified from here: https://medium.com/hackernoon/using-a-react-16-portal-to-do-something-cool-2a2d627b0202
// See popup issues here: https://bugs.chromium.org/p/chromium/issues/detail?id=137681

interface DebugPortalProps {
  className?: string;
  /** Called when the window is closed */
  onClose?: () => void;
}

const DebugPortal: React.FC<React.PropsWithChildren<DebugPortalProps>> = (props) => {
  const windowRef = useRef<Window | null>(null);
  const [container] = React.useState(() => {
    // This will be executed only on the initial render: https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
    const element = document.createElement('div');
    if (!StringUtil.IsNullOrEmpty(props.className)) {
      element.classList.add(props.className);
    }
    return element;
  });

  const newWindowLoadEvent = () => {
    const rootElement = windowRef.current?.document.body.children.namedItem('root');
    if (windowRef.current == null || rootElement == null) {
      return;
    }

    // Remove the react app, which uses 'root' as the element id, and replace it with out fancy react portal container
    windowRef.current.document.body.removeChild(rootElement);
    windowRef.current.document.body.appendChild(container);
  };

  const newWindowVisibilityEvent = async () => {
    if (props.onClose == null || windowRef.current == null) {
      return;
    }

    // First, we need to wait. The event seems to be fired before the window is actually closed
    await sleep(250);
    if (windowRef.current.closed) {
      props.onClose();
      return;
    }

    // It is entirely possible and likely that the browser will not update state for us quick enough. In that case, we should wait a bit longer just to make sure
    // First, check if visibility state has been set to hidden. Otherwise, we are waiting for no reason
    if (windowRef.current.document.visibilityState === 'hidden') {
      await sleep(3000);

      // Check again
      if (windowRef.current.closed) {
        props.onClose();
      }
    }
  };

  React.useEffect(() => {
    // Open the window when we are first rendered. Origin should start the loading process
    let dimensions = {
      height: 400,
      width: 600,
      top: 200,
      left: 0 // Doesn't matter
    };
    // Figuring out left is fun. See the chromium bug above, yikes
    dimensions.left = (window.screen as any).availLeft + window.screen.availWidth - (dimensions.width + 50);

    windowRef.current = window.open(window.location.origin, 'dev-overlay-window', `width=${dimensions.width},height=${dimensions.height},left=${dimensions.left},top=${dimensions.top}`);

    // This event is JUST long enough to load the header and styles for the project but not the rest of the react project... mostly
    windowRef.current?.addEventListener('load', newWindowLoadEvent, true);

    // This can be fired either when the window loses focus or when the window is closed
    windowRef.current?.addEventListener('visibilitychange', newWindowVisibilityEvent);

    // Cleanup! When unmounted, we want to close the window and fire off the onClose handler
    return () => {
      if (windowRef.current != null) {
        windowRef.current.close();
        props.onClose && props.onClose();
      }
    };
  }, []);

  return ReactDOM.createPortal(props.children, container);
};

export default DebugPortal;
