import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Skeleton, Space } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { NameOf, NotificationUtil, StringUtil, TableExpressions, TableColumnBuilder } from 'src/utils';
import ReportController from 'src/api/ReportController';
import ReportMappingModel from 'src/models/frontend/ReportMappingModel';
import ReportMappingItemRuleModel from 'src/models/frontend/ReportMappingItemRuleModel';
import ReportMappingItemModel from 'src/models/frontend/ReportMappingItemModel';
import LinkWithQuery from 'src/components/LinkWithQuery';
import RouteConfig from 'src/config/RouteConfig';
import PrintIconSVG from 'src/components/svgs/PrintIconSVG';

export interface MappingReportModalProps {
  open: boolean;
  mappingKey: string;
  onCancel: () => void;
}

const MappingReportModal: React.FC<MappingReportModalProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [tableData, setTableData] = useState<ReportMappingModel[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!props.open) {
      unloadModalData();
      return;
    }
    loadModalData();
  }, [props.open, props.mappingKey]);

  const loadModalData = async () => {
    setLoading(true);
    try {
      const results = await ReportController.getReportMapping(authContext.account!.id, authContext.location!.id);

      // Sort data
      let sortedData = [...results.data].sort(TableExpressions.Sorters.NumberSorter('displayOrder')).map(x => ({
        ...x,
        // Sort inner array
        items: TableExpressions.Methods.FixSortOrderNumber(x.items.sort(TableExpressions.Sorters.NumberSorter('displayOrder')), 'displayOrder')
      }));

      //sort the inner inner arrays
      sortedData.forEach(x => x.items.forEach(y => {y.customMappingRules.sort((a, b) => a.displayOrder - b.displayOrder); y.displayDefaultAssignments.sort((a, b) => a.displayOrder - b.displayOrder);}));

      if (!StringUtil.IsNullOrEmpty(props.mappingKey)) {
        sortedData = sortedData.filter(x => x.key === props.mappingKey);
      }

      setTableData(sortedData);
    } catch (error) {
      NotificationUtil.error({
        key: 'MappingReportModal',
        message: 'Error while loading Report data',
        error
      });
    }
    setLoading(false);
  };

  const unloadModalData = () => {
    setTableData([]);
  };

  // These are used in the custom mapping table
  const customMappingColumns: ColumnProps<ReportMappingItemRuleModel>[] = [
    TableColumnBuilder.Create<ReportMappingItemRuleModel>('displayRuleName', 'Rule Name')
      .Width(120)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportMappingItemRuleModel>('displayFilterName', 'Filter')
      .Width(120)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportMappingItemRuleModel>('displayRuleAssignments', 'Assignments')
      .Width(120)
      .AddRenderer('Custom', (value, record) => {
        return [...record.displayRuleAssignments]
          .sort(TableExpressions.Sorters.NumberSorter('displayOrder'))
          .map((subRecord, index) => <div key={index}>{subRecord.displayName}</div>);
      })
      .Build(),
  ];

  const tableColumns: ColumnProps<ReportMappingItemModel>[] = [
    TableColumnBuilder.Create<ReportMappingItemModel>('displayName', 'Mapping Item')
      .Width(100)
      .VerticalAlign('top')
      .AddRenderer('Text')
      .Build(),
    TableColumnBuilder.Create<ReportMappingItemModel>('displayDefaultAssignments', 'Default Mapping')
      .Width(140)
      .VerticalAlign('top')
      .AddRenderer('Custom', (value, record) => {
        return [...record.displayDefaultAssignments]
          .sort(TableExpressions.Sorters.NumberSorter('displayOrder'))
          .map((subRecord, index) => <div key={index}>{subRecord.displayName}</div>);
      })
      .Build(),
    TableColumnBuilder.Create<ReportMappingItemModel>('customMappingRules', 'Custom Mapping')
      .Width(580)
      // .ClassName('without-padding')
      .ClassName('without-padding-table-cell')
      .AddRenderer('Custom', (value, record) => {
        // We are actually going to render an entire new table. Fun
        if (!Array.isArray(record.customMappingRules) || record.customMappingRules.length < 1) {
          return null;
        }
        // AntD does some unbelievably sketchy stuff with a sub-table and margins. Took forever to find and the fix was unbelievably easy; put the table in a div container. Sigh...
        return <div>
          <Table
            bordered
            rowKey={NameOf<ReportMappingItemRuleModel>('key')}
            className='condensed-table borderless-table'
            rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
            loading={loading}
            pagination={false}
            columns={customMappingColumns}
            dataSource={record.customMappingRules}
          />
        </div>;
      })
      .Build(),
  ];

  const tables = tableData.map((data, index) => {
    return <>
      <h3>{data.displayName}</h3>
      <Table
        key={index}
        bordered
        rowKey={NameOf<ReportMappingItemModel>('key')}
        className='condensed-table'
        rowClassName={(_, index) => (index % 2 ? 'striped-row' : '')}
        loading={loading}
        pagination={false}
        columns={tableColumns}
        dataSource={data.items}
      />
      <br />
    </>;
  });

  const renderPrintButton = () => {
    const url = new URL(location.href);
    url.searchParams.delete('route_id');
    const printingQueryParams: [string, string][] = [
      ['mappingItemId', props.mappingKey ?? ''],
      ['returnUrl', url.pathname + url.search],
    ];

    return <LinkWithQuery openInNewTab to={RouteConfig.PRINTING_MAPPING_ITEM()} additionalQueryParams={printingQueryParams}>
      <Button style={{ height: 36 }}><Space><PrintIconSVG /><span>Print</span></Space></Button>
    </LinkWithQuery>;
  };

  return <Modal
    className='mapping-report-modal'
    style={{ top: 100 }}
    width={99999} // Endgame boss health right here :D
    closable={false}
    open={props.open}
    onCancel={props.onCancel}
    footer={<>
      <Button type='primary' onClick={props.onCancel}>Done</Button>
    </>}
  >
    {(loading) && <Skeleton active />}
    {!(loading) && <div>
      <Row justify='space-between' align='middle' className='with-margin-bottom'>
        <Col><h2 className='without-margin-bottom'>Mapping Summary</h2></Col>
        <Col>{renderPrintButton()}</Col>
      </Row>
      {tables}
    </div>}
  </Modal>;
};

export default MappingReportModal;
