import React, { createContext, useEffect, useState } from 'react';
import moment from 'moment';
import AuthorizationController from 'src/api/AuthorizationController';
import { SetAccessToken } from 'src/config/ApiConfig';
import AuthProfileDTO from 'src/models/generated/AuthProfileDTO';
import { LocalStorageUtil, StringUtil, UrlUtil } from 'src/utils';
import UserProfileDTO from 'src/models/generated/UserProfileDTO';
import UserController from 'src/api/UserController';
import AccountDTO from 'src/models/generated/AccountDTO';
import LocationDTO from 'src/models/generated/LocationDTO';
import AccountController from 'src/api/AccountController';
import LocationController from 'src/api/LocationController';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStateAsync, useQueryParam, useStateAsyncWithPrevious } from 'src/hooks';
import { Location } from 'history';
import RouteConfig from 'src/config/RouteConfig';
import { AxiosError } from 'axios';

export type AuthStateType = 'None' | 'Logged In' | 'Complete';

export interface AuthenticationContextType {
  /** Indicates that the authContext has finished determining the current state and is ready to use */
  isInitialized: boolean;
  /** Just for you, so you can check if the user is logged in easily */
  isLoggedIn: boolean;
  /** Indicates where the user is in the login flow */
  authState: AuthStateType;
  loginTimeStamp: moment.Moment | null;
  auth: AuthProfileDTO | null;
  profile: UserProfileDTO | null;
  account: AccountDTO | null;
  location: LocationDTO | null;
  /** This is the *Location* that the user entered the browser with */
  redirectUrl: Location | null;
  /** Used in many places, determined on login and from the profile or auth api calls */
  userRole: 'standard' | 'agent';

  // Functions
  logout: () => Promise<void>;
  login_email: (email: string) => Promise<AuthProfileDTO>;
  login_agent: (email: string, password: string, accountNumber: string) => Promise<AuthProfileDTO>;
  login_two_factor: (twoFactor: string) => Promise<AuthProfileDTO>;
  login_password: (password: string) => Promise<AuthProfileDTO>;
  login_activate: (confirmationCode: string, password: string) => Promise<AuthProfileDTO>;

  // Setters
  updateProfile: (profile: UserProfileDTO | null) => Promise<void>;
  updateAccount: (account: AccountDTO | null) => Promise<void>;
  updateLocation: (location: LocationDTO | null) => Promise<void>;
  updateReturnUrl: (location: Location | null) => Promise<void>;

  // Async get
  getAuth: () => Promise<AuthProfileDTO | null>;
  getProfile: () => Promise<UserProfileDTO | null>;
  getAccount: () => Promise<AccountDTO | null>;
  getLocation: () => Promise<LocationDTO | null>;
  getReturnUrl: () => Promise<Location | null>;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AuthenticationContext = createContext<AuthenticationContextType>(undefined!);

export const AuthenticationProvider = (props: React.PropsWithChildren<any>) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const routerLocation = useLocation();
  const navigate = useNavigate();
  const [routeIdQueryParam, setRouteIdQueryParam] = useQueryParam('route_id');
  const [accountNumberQueryParam, setAccountNumberQueryParam] = useQueryParam('account');
  const [profile, setProfile, getProfileAsync] = useStateAsync<UserProfileDTO | null>(null);
  const [auth, setAuth, getAuthAsync] = useStateAsync<AuthProfileDTO | null>(null);
  const [account, setAccount, getAccountAsync, previousAccount] = useStateAsyncWithPrevious<AccountDTO | null>(null);
  const [location, setLocation, getLocationAsync, previousLocation] = useStateAsyncWithPrevious<LocationDTO | null>(null);
  const [loginTimeStamp, setLoginTimeStamp] = useState<moment.Moment | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [returnUrl, setReturnUrl, getReturnUrlAsync] = useStateAsync<Location | null>(null);
  const [authState, setAuthState] = useState<AuthStateType>('None');
  const [userRole, setUserRole, getUserRoleAsync] = useStateAsync<'standard' | 'agent'>('standard');

  // OnLoad
  useEffect(() => {
    // Save the url that the user attempted to load in with
    setReturnUrl(routerLocation);

    // Set role based on what login we attempted. Overridden by initialize() if a user has already passed the password phase
    setUserRole(routerLocation.pathname === RouteConfig.LOGIN_AGENT() ? 'agent' : 'standard');

    initialize();
  }, []);

  // Route_Id Handler
  useEffect(() => {
    if (!isInitialized) {
      return;
    }

    if (authState !== 'Complete') {
      console.log('[RouteId_Watcher] authState is not ready', { authState });
      return;
    }

    // Check account/location for null as it has a simple solution: let login flow deal with it
    if (account == null || location == null) {
      console.log('[RouteId_Watcher] Account or Location are broken, can we fix it?');

      if (StringUtil.IsNullOrEmpty(routeIdQueryParam)) {
        console.log('[RouteId_Watcher] No, we cannot, Route_id is also null');
        navigate(userRole === 'agent' ? RouteConfig.LOGIN_AGENT() : RouteConfig.LOGIN_EMAIL());
        return;
      }

      // Well, we tried. We can only send them to the proper login handler and have it load for them
      console.log('[RouteId_Watcher] Super invalid state', { account, location, routeIdQueryParam });
      setRouteIdQueryParam(null);
      navigate(userRole === 'agent' ? RouteConfig.LOGIN_AGENT() : RouteConfig.LOGIN_EMAIL());
      return;
    }

    const routeId = UrlUtil.GenerateRouteId(account.id, location.id);
    if (routeIdQueryParam !== routeId) {
      console.log('[RouteId_Watcher] Route_id out of date, generating', { routeIdQueryParam, routeId });
      setRouteIdQueryParam(routeId);
      return;
    }
    if(userRole === 'agent' && accountNumberQueryParam !== account.accountCode){
      setAccountNumberQueryParam(account.accountCode);
    }
    

    console.log('[RouteId_Watcher] everything is a okay', { routeIdQueryParam, routeId, routerLocation });
  }, [authState, routeIdQueryParam, account, location, routerLocation]);

  /** Checks if the current auth variables are current and valid */
  const validateLocalStorage = () => {
    // The call to get these items from LocalStorage is incredibly small and requiring them in the params seems less than useful
    let authCookie = LocalStorageUtil.AuthCookie;
    let authExpiration = LocalStorageUtil.AuthExpiration;

    if (StringUtil.IsNullOrEmpty(authCookie)
      // || StringUtil.IsNullOrEmpty(authState) || authState === 'None'
      || authExpiration == null || !authExpiration.isValid() || moment().isAfter(authExpiration)) {
      return false;
    }
    return true;
  };

  const initialize = async () => {
    // Load from localStorage
    let authCookie = LocalStorageUtil.AuthCookie;
    console.log('[Initialize] start');

    // Validate storage values
    if (!validateLocalStorage()) {
      console.log('[Initialize] not valid, clearing cache');
      await logout();
      setIsInitialized(true);
      return;
    }
    // Everything seems valid, so we can load up the data now

    // Auth & Profile block
    try {
      SetAccessToken(authCookie!);

      // We can start with the user info first as it should have the email address where we we can get the user auth from
      const userProfileResult = await UserController.getMyProfile();
      setProfile(userProfileResult.data);

      // Find/set the userRole based on the userProfile api call
      const myUserRole: typeof userRole = (userProfileResult.data.role || '').toLowerCase() === 'agent' ? 'agent' : 'standard';
      setUserRole(myUserRole);

      // We have some guarantee that the user and email will exist
      // Update (spring 2023): We now have roles to account for!
      // Update (may 2023): Role shows up in the profile object now
      const authResult = myUserRole === 'agent'
        ? await AuthorizationController.doAgentLogin(userProfileResult.data.emailAddress)
        : await AuthorizationController.doEmailLogin(userProfileResult.data.emailAddress);

      setAuth(authResult.data);
      setIsLoggedIn(true);
      setAuthState('Logged In');
    } catch (error) {
      // Yeah, not much we can do here
      console.error(error);
      await logout();
      setIsInitialized(true);
      return;
    }

    // Account & Location block
    try {
      // Check account and location, starting with the route_id
      const queryParams = new URLSearchParams(routerLocation.search);

      if (queryParams.has('route_id')) {
        // User has either opened a bookmark or refreshed
        console.log('[Initialize] found route ID');
        const routeId = queryParams.get('route_id');
        const { accountId, locationId } = UrlUtil.ParseRouteId(routeId!);

        if (!StringUtil.IsNullOrEmpty(accountId)) {
          console.log('[Initialize] loading accounts');
          // Get account
          const accountResults = await AccountController.getAccounts();
          const selectedAccount = accountResults.data.find(x => x.id === accountId);
          if (selectedAccount != null) {
            await updateAccount(selectedAccount);
          }

          if(queryParams.has('account')){
            let account = queryParams.get('account');
            const accountResults = await AccountController.getAccount(account!);
            await updateAccount(accountResults.data);
          }

          if (!StringUtil.IsNullOrEmpty(locationId)) {
            console.log('[Initialize] loading locations');
            // Get location
            const locationResults = await LocationController.getLocations(accountId);
            const selectedLocation = locationResults.data.find(x => x.id === locationId);
            if (selectedLocation != null) {
              await updateLocation(selectedLocation);
            }
          }
        }

        setAuthState('Complete');
      } else {
        navigate(userRole === 'agent' ? RouteConfig.LOGIN_AGENT() : RouteConfig.LOGIN_EMAIL());
      }
    } catch (error) {
      console.error(error);
      await logout();
      setIsInitialized(true);
      return;
    }

    // If we made it passed the profile block, then the returnUrl isn't needed anymore
    // Dev Note: idk man, seems to me like the login service uses this
    // setReturnUrl(null);

    console.log('[Initialize] complete');
    // Nothing broke? Then we are officially signed back in
    setLoginTimeStamp(moment());
    setIsInitialized(true);
  };

  /** Handles generating the token as well as setting the related session variables. Will retrieve from localStorage if it exists and is valid */
  // Dev Note: Maybe conbine with the only function that uses this? 'generateBearerToken' seems to dangerous to accidentally be called as it will wipe out any session the user has
  const ensureBearerToken = async () => {
    // Check LocalStorage and verify we have a valid token
    let authCookie = LocalStorageUtil.AuthCookie;
    let authExpiration = LocalStorageUtil.AuthExpiration;

    if (!validateLocalStorage()) {
      try {
        const bearerResults = await AuthorizationController.generateBearerToken();
        authCookie = bearerResults.data.token;

        // Until the user is fully authed, expiration is only 10 minutes
        authExpiration = moment().add(10, 'minute');
        setAuthState('None');

        // Save to LocalStorage
        LocalStorageUtil.AuthCookie = authCookie;
        LocalStorageUtil.AuthExpiration = authExpiration;
      } catch (error) {
        // Well this isn't good
        console.error(error);
      }
    }

    // Finally, set the token for Axios
    if (!StringUtil.IsNullOrEmpty(authCookie)) {
      SetAccessToken(authCookie);
    }
  };

  // Thinking that the functions here will handle everything that isn't UI related. So, following up with another API call is a reasonable process
  const login_email = async (email: string): Promise<AuthProfileDTO> => {
    // Get initial bearerToken
    await ensureBearerToken();

    const emailLoginResult = await AuthorizationController.doEmailLogin(email);
    const authProfile = emailLoginResult.data;

    if (!authProfile.userActivated) {
      // Send initial activation email
      await AuthorizationController.initialActivationRequest();
    } else if (authProfile.tfaEnabled) {
      // Submit tfa request
      await AuthorizationController.twoFactorRequest();
    }

    setAuth(authProfile);
    return authProfile;
  };

  // Thinking that the functions here will handle everything that isn't UI related. So, following up with another API call is a reasonable process
  const login_agent = async (email: string, password: string, accountNumber: string): Promise<AuthProfileDTO> => {
    // Get initial bearerToken
    await ensureBearerToken();

    const emailLoginResult = await AuthorizationController.doAgentLogin(email);
    let authProfile = emailLoginResult.data;

    // We don't expect the shared agent login to ever hit this, but are there JUST incase something changes in the future
    if (!authProfile.userActivated) {
      // Send initial activation email
      await AuthorizationController.initialActivationRequest();
    } else if (authProfile.tfaEnabled) {
      // Submit tfa request
      await AuthorizationController.twoFactorRequest();
    } else {
      const passwordLoginResult = await AuthorizationController.doPasswordLogin(password);
      authProfile = passwordLoginResult.data;

      if (authProfile.authenticated) {
        const userProfileResult = await UserController.getMyProfile();
        setProfile(userProfileResult.data);

        // Completing the password login will make us authenticated, indicate that our token is now valid
        setAuthState('Logged In');
        setLoginTimeStamp(moment());
        setIsLoggedIn(true);

        // Finally, we need to set the account
        if (!StringUtil.IsNullOrEmpty(accountNumber)) {
          console.log('[AgentLogin] loading accounts');
          // Get account
          const accountResults = await AccountController.getAccount(accountNumber);
          await updateAccount(accountResults.data);
          setAccountNumberQueryParam(accountNumber);
        }
      }
    }

    setAuth(authProfile);
    return authProfile;
  };

  const login_two_factor = async (twoFactor: string): Promise<AuthProfileDTO> => {
    const tfaResponseResult = await AuthorizationController.twoFactorResponse(twoFactor);
    const authProfile = tfaResponseResult.data;

    setAuth(authProfile);
    return authProfile;
  };

  const login_password = async (password: string): Promise<AuthProfileDTO> => {
    const passwordLoginResult = await AuthorizationController.doPasswordLogin(password);
    const authProfile = passwordLoginResult.data;

    if (authProfile.authenticated) {
      // We are fully authed when we submit the password, next is the user profile
      const userProfileResult = await UserController.getMyProfile();
      setProfile(userProfileResult.data);

      // Completing the password login will make us authenticated, indicate that our token is now valid
      setAuthState('Logged In');
      setLoginTimeStamp(moment());
      setIsLoggedIn(true);
    }
    // Else is an error, which should be handled by the calling code... probably

    setAuth(authProfile);
    return authProfile;
  };

  const login_activate = async (confirmationCode: string, password: string): Promise<AuthProfileDTO> => {
    const activationResult = await AuthorizationController.initialActivationResponse(confirmationCode, password);
    const authProfile = activationResult.data;
    setAuth(authProfile);

    // We are fully authed, technically, so we can grab the the user profile
    const userProfileResult = await UserController.getMyProfile();
    setProfile(userProfileResult.data);

    // Completing the password login will make us authenticated, indicate that our token is now valid
    setAuthState('Logged In');
    setLoginTimeStamp(moment());
    setIsLoggedIn(true);

    return authProfile;
  };

  const logout = async (): Promise<void> => {
    try {
      const result = await AuthorizationController.logout();
    } catch (error) {
      // Idk, run away???
    }
    setIsLoggedIn(false);
    setLoginTimeStamp(null);
    setProfile(null);
    setAuth(null);
    setAuthState('None');
    // Remove our tokens and saved info
    setAccount(null);
    setLocation(null);
    // TODO: JB - Add this to the util at some point
    LocalStorageUtil.clear();
  };

  const updateProfile = (newProfile: UserProfileDTO | null) => {
    return new Promise<void>(resolve => {
      setProfile(newProfile);
      resolve();
    });
  };

  const updateAccount = (newAccount: AccountDTO | null) => {
    return new Promise<void>(resolve => {
      setAccount(newAccount);
      setAuthState('Logged In');
      resolve();
    });
  };

  const updateLocation = (newLocation: LocationDTO | null) => {
    return new Promise<void>(resolve => {
      setLocation(newLocation);
      if (newLocation != null) {
        setAuthState('Complete');
      }
      resolve();
    });
  };

  const updateReturnUrl = (newReturnUrl: Location | null) => {
    return new Promise<void>(resolve => {
      setReturnUrl(newReturnUrl);
      resolve();
    });
  };

  return (
    <AuthenticationContext.Provider value={{
      isInitialized,
      isLoggedIn,
      authState,
      loginTimeStamp,
      auth,
      profile,
      account,
      location,
      redirectUrl: returnUrl,
      userRole,

      // Functions
      logout,
      login_agent,
      login_email,
      login_two_factor,
      login_password,
      login_activate,

      // Setters
      updateProfile,
      updateAccount,
      updateLocation,
      updateReturnUrl,

      // Async get
      getAuth: getAuthAsync,
      getProfile: getProfileAsync,
      getAccount: getAccountAsync,
      getLocation: getLocationAsync,
      getReturnUrl: getReturnUrlAsync,
    }}>
      {props.children}
    </AuthenticationContext.Provider>
  );
};
