import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal, Skeleton, Transfer } from 'antd';
import { NotificationUtil, TableExpressions } from 'src/utils';
import { TransferItem } from 'antd/lib/transfer';
import MappingItemRuleFilterDefinitionDTO from 'src/models/generated/MappingItemRuleFilterDefinitionDTO';
import { useStateAsync } from 'src/hooks';
import MappingController from 'src/api/MappingController';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import './RuleFilterDefinitionModal.less';

export interface RuleFilterDefinitionModalNewProps {
  open: boolean;
  mappingKey: string;
  mappingItemKey: string;

  onOk?: (newRuleDefinitions: MappingItemRuleFilterDefinitionDTO[]) => void;
  onCancel?: () => void;
}

// The goal of this component is to provide an easy way to edit one simple value. Anything beyond that should be it's own component
const RuleFilterDefinitionModalNew: React.FC<RuleFilterDefinitionModalNewProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [ruleDefinitions, setRuleDefinitions] = useState<MappingItemRuleFilterDefinitionDTO[]>([]);
  const [tableData, setTableData] = useState<TransferItem[]>([]);
  const [targetKeys, setTargetKeys] = useState<string[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // This will reset the form when the visibility or defaultValues have changed
  useEffect(() => {
    if (!props.open) {
      unloadModalData();
      return;
    }
    loadTableData();
  }, [props.open]);

  const loadTableData = async () => {
    setLoading(true);
    try {
      const results = await MappingController.getMappingItemRuleDefinitions(authContext.account!.id, authContext.location!.id, props.mappingKey, props.mappingItemKey);
      const newRuleDefinitions = results.data;
      setRuleDefinitions(newRuleDefinitions);

      // Split out the data into selected and not selected
      const tableData = newRuleDefinitions.map<TransferItem>(x => ({ key: x.key, title: x.displayValue }));
      const selectedKeys = newRuleDefinitions.filter(x => x.selected).sort(TableExpressions.NumberSorter('selectedOrder')).map(x => x.key);
      setTableData(tableData);
      setTargetKeys(selectedKeys);
    } catch (error) {
      NotificationUtil.error({
        key: 'RuleFilterDefinitionModalNew',
        message: 'Error while loading Rule Definitions',
        error
      });
    }
    setLoading(false);
  };

  const unloadModalData = () => {
    setTableData([]);
    setTargetKeys([]);
    setLoading(false);
  };

  const handleModalOk = async () => {
    setSubmitting(true);
    try {
      // Order the definitions based on their order in the array
      const data = ruleDefinitions.map(item => {
        const foundIndex = targetKeys.findIndex(x => x === item.key);
        return {
          ...item,
          selected: foundIndex >= 0,
          selectedOrder: foundIndex + 1 // This works beautifully because 0 is the not selected order and "not found" + 1 is 0
        };
      });

      // Save, because why not?
      setRuleDefinitions(data);

      const ruleDefinitionResults = await MappingController.saveMappingItemRuleDefinitions(authContext.account!.id, authContext.location!.id, props.mappingKey, props.mappingItemKey, data);

      // Looks good, propagate the changes up since this is the "most up to date" rule definition collection
      props.onOk?.(data);
      NotificationUtil.success({
        key: 'MappingItemCustomTabNew',
        message: 'Rule Definitions have been updated',
      });
    } catch (error) {
      NotificationUtil.error({
        key: 'MappingItemCustomTabNew',
        message: 'Error while saving Rule Definitions',
        error
      });
    }
    setSubmitting(false);
  };

  const handleModalCancel = () => {
    props.onCancel?.();
  };

  return <Modal
    destroyOnClose // Indicates that we do not want to cache anything when reopening the modal
    className='rule-filter-definition-modal'
    style={{ top: 225 }}
    width={450}
    open={props.open}
    closable={!submitting}
    onCancel={handleModalCancel} // Handles clicks outside the modal window
    bodyStyle={{ paddingBottom: 'unset' }} // Makes the modal JUST a bit cleaner
    footer={<>
      <Button type='default' disabled={submitting} onClick={handleModalCancel}>Cancel</Button>
      <Button type='primary' disabled={loading || submitting} onClick={handleModalOk}>Save</Button>
    </>}
  >
    <Skeleton active loading={loading}>
      <h2>Rule Definition</h2>
      <p>Note: Select items in order of importance. Once rule definitions are determined and saved, rule filters can be assigned for specific account breakout based on the hierarchy selected. Any item that does not fit any rule filter will use the Default assignment.</p>
      <Transfer
        showSelectAll={false}
        titles={['Available', 'Selected']}
        render={item => item.title ?? '[Title]'} // Please look at, thanks
        locale={{ notFoundContent: null }} // Clears out the 'not found' content
        listStyle={{
          width: 320,
          height: 280
        }}
        dataSource={tableData}
        selectedKeys={selectedKeys}
        targetKeys={targetKeys}
        onSelectChange={(leftSelectedKeys, rightSelectedKeys) => {
          if (leftSelectedKeys.length > 0) {
            // Move the item to the right
            setTargetKeys(prev => [...prev, ...leftSelectedKeys]);
          } else {
            // Move the item to the left
            setTargetKeys(prev => prev.filter(x => x !== rightSelectedKeys[0]));
          }
        }}
      />
      <br />
      <p><b>Warning:</b> Once the configuration is saved, no changes can be made unless all custom rule filters have been deleted.</p>
    </Skeleton>
  </Modal>;
};

export default RuleFilterDefinitionModalNew;
