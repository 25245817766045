import React from 'react';
import classNames from 'classnames';
import StandardSVGProps from './StandardSVGProps';

const VoidIconSVG: React.FC<StandardSVGProps> = (props) => {
  return (
    <span style={props.style} className={classNames('void-icon-svg', 'svg', props.className)}>
      <svg style={{paddingTop:4}} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 1C5.92489 1 1 5.92485 1 12C1 18.0751 5.92489 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92489 18.0751 1 12 1ZM17.7709 6.22908C20.6739 9.13202 20.8758 13.569 18.688 16.6807L7.31923 5.312C10.4331 3.12282 14.8696 3.32779 17.7709 6.22908ZM6.22908 17.7709C3.32615 14.868 3.12424 10.431 5.31196 7.31928L16.6808 18.688C13.5669 20.8772 9.13042 20.6723 6.22908 17.7709Z" fill="#FFFFFF" />
      </svg>
    </span>
  );
};

export default VoidIconSVG;
