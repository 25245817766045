import { v4 as uuidv4 } from 'uuid';
import { ArrayUtil, ObjectUtil, StringUtil } from 'src/utils';
import ReportSummaryDTO from '../generated/ReportSummaryDTO';
import ReportSummaryItemDTO from '../generated/ReportSummaryItemDTO';

interface ReportSummaryModel {
  /** Auto Generated field */
  key: string;

  displayItemTotalAmount: number;
  displayItemTotalCount: number;
  displayMessage: string;
  displayName: string;
  displayOrder: number;
  showCountColumn: boolean;
  items: ReportSummaryItemDTO[];
}
const ReportSummaryModel = {
  create: (initValues?: Partial<ReportSummaryModel>): ReportSummaryModel => {
    const defaults: ReportSummaryModel = {
      key: '',
      displayItemTotalAmount: 0,
      displayItemTotalCount: 0,
      displayMessage: '',
      displayName: '',
      displayOrder: 0,
      showCountColumn: false,
      items: [],
    };
    const fixedInitValues: Partial<ReportSummaryModel> = initValues != null
      ? {
        key: StringUtil.IsNullOrEmpty(initValues.key) ? uuidv4() : initValues.key,
        showCountColumn: ObjectUtil.ToBoolean(initValues.showCountColumn),
        items: ArrayUtil.EasyMap(initValues.items, ReportSummaryItemDTO.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
  toDTO: (model: ReportSummaryModel): ReportSummaryDTO => ({
    displayItemTotalAmount: model.displayItemTotalAmount,
    displayItemTotalCount: model.displayItemTotalCount,
    displayMessage: model.displayMessage,
    displayName: model.displayName,
    displayOrder: model.displayOrder,
    showCountColumn: model.showCountColumn,
    items: model.items,
  }),
  fromDTO: (dto: ReportSummaryDTO): ReportSummaryModel => ({
    key: '',
    displayItemTotalAmount: dto.displayItemTotalAmount,
    displayItemTotalCount: dto.displayItemTotalCount,
    displayMessage: dto.displayMessage,
    displayName: dto.displayName,
    displayOrder: dto.displayOrder,
    showCountColumn: dto.showCountColumn,
    items: dto.items,
  })
};

export default ReportSummaryModel;
