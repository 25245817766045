import { v4 as uuidv4 } from 'uuid';
import { ArrayUtil, ObjectUtil, StringUtil } from 'src/utils';
import ReportMappingItemRuleAssignmentModel from './ReportMappingItemRuleAssignmentModel';
import ReportMappingItemRuleDTO from '../generated/ReportMappingItemRuleDTO';

interface ReportMappingItemRuleModel {
  key: string;
  displayOrder: number;
  displayRuleName: string;
  displayFilterName: string;
  displayRuleAssignments: ReportMappingItemRuleAssignmentModel[];
}
const ReportMappingItemRuleModel = {
  create: (initValues?: Partial<ReportMappingItemRuleModel>): ReportMappingItemRuleModel => {
    const defaults: ReportMappingItemRuleModel = {
      key: '',
      displayOrder: 0,
      displayRuleName: '',
      displayFilterName: '',
      displayRuleAssignments: [],
    };
    const fixedInitValues: Partial<ReportMappingItemRuleModel> = initValues != null
      ? {
        key: StringUtil.IsNullOrEmpty(initValues.key) ? uuidv4() : initValues.key,
        displayRuleAssignments: ArrayUtil.EasyMap(initValues.displayRuleAssignments, ReportMappingItemRuleAssignmentModel.create),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
  toDTO: (model: ReportMappingItemRuleModel): ReportMappingItemRuleDTO => ({
    displayOrder: model.displayOrder,
    displayRuleName: model.displayRuleName,
    displayFilterName: model.displayFilterName,
    displayRuleAssignments: model.displayRuleAssignments.map(ReportMappingItemRuleAssignmentModel.toDTO),
  }),
  fromDTO: (dto: ReportMappingItemRuleDTO): ReportMappingItemRuleModel => ({
    key: '',
    displayOrder: dto.displayOrder,
    displayRuleName: dto.displayRuleName,
    displayFilterName: dto.displayFilterName,
    displayRuleAssignments: dto.displayRuleAssignments.map(ReportMappingItemRuleAssignmentModel.fromDTO),
  })
};

export default ReportMappingItemRuleModel;
