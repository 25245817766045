import axios, { AxiosResponse } from 'axios';
import { KeyValue } from 'src/models/frontend/common/KeyValueAndSimilar';
import UserProfileDTO from 'src/models/generated/UserProfileDTO';
import { AxiosUtil } from 'src/utils';

class UserController {
  private prefix: string = "user";

  // API_10
  getMyProfile(): Promise<AxiosResponse<UserProfileDTO>> {
    let route = `${this.prefix}/userprofile`;
    return AxiosUtil.get<UserProfileDTO>(route, UserProfileDTO.create);
  }

  // API_11
  saveMyProfile(userProfile: UserProfileDTO): Promise<AxiosResponse<UserProfileDTO>> {
    let route = `${this.prefix}/userprofile`;
    return AxiosUtil.post<UserProfileDTO, UserProfileDTO>(route, userProfile, UserProfileDTO.create, { withObjectSplit: true });
  }

  // API_12 - this one has been removed

  // API_13a
  passwordResetResponse(currentPassword: string, newPassword: string): Promise<AxiosResponse<void>> {
    let route = `${this.prefix}/userprofile/password/resetrequest`;
    return AxiosUtil.post<void, KeyValue[]>(route,[{"key":"NEWPASSWORD","value":newPassword}, {"key":"OLDPASSWORD","value":currentPassword}]);
  }

  // API_14
  addTwoFactorPhoneRequest(phonenumber: string): Promise<AxiosResponse<void>> {
    let route = `${this.prefix}/userprofile/phone/validationrequest/phonenumber/${phonenumber}`;
    return AxiosUtil.post<void, void>(route);
  }

  // API_15
  addTwoFactorPhoneResponse(phonenumber: string, confirmationCode: string): Promise<AxiosResponse<UserProfileDTO>> {
    let route = `${this.prefix}/userprofile/phone/validationrequest/phonenumber/${phonenumber}/confirmationcode/${confirmationCode}`;
    return AxiosUtil.post<UserProfileDTO, void>(route, undefined, UserProfileDTO.create);
  }
}

export default new UserController();
