import React, { useContext, useEffect, useState } from 'react';
import { Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { NameOf, NotificationUtil, TableExpressions, TableColumnBuilder } from 'src/utils';
import ReportController from 'src/api/ReportController';
import ReportAssignmentModel from 'src/models/frontend/ReportAssignmentModel';
import ReportAssignmentItemModel from 'src/models/frontend/ReportAssignmentItemModel';
import { SharedReportPageProps } from './SharedPageProps';
import { useQueryParam } from 'src/hooks';
import { FilterValue, SortOrder } from 'antd/lib/table/interface';

const PrintingReportAssignmentPage: React.FC<SharedReportPageProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [tableData, setTableData] = useState<ReportAssignmentModel[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState(false);
    
  const [assignmentsTableFiltersQueryParam, setAssignmentsTableFiltersQueryParam] = useQueryParam('assignmentsFilters');
  const [assignmentsTableSortedColumnQueryParam, setAssignmentsSortedColumnQueryParam] = useQueryParam('assignmentsSortedColumn');
  const [assignmentsTableSortOrderQueryParam, setAssignmentsSortOrderQueryParam] = useQueryParam('assignmentsSortOrder');
  const [filterValues, setFilterValues] = useState<Record<string, FilterValue | null>>();
  useEffect(() => {
    loadTableData();
  }, [props.startDate, props.endDate]);

  useEffect(() => {
    setExpandedKeys(props.expandedKeys);
  }, [props.expandedKeys]);

  useEffect(() => {
    setSelectedKeys(props.selectedKeys);
  }, [props.selectedKeys]);

  const loadTableData = async () => {
    if (authContext.account == null || authContext.location == null || props.startDate == null && props.endDate == null) {
      return;
    }

    setLoading(true);
    try {
      const results = await ReportController.getReportAssignment(authContext.account.id, authContext.location.id, props.startDate!, props.endDate!);

      // Sort data
      let sortedData = [...results.data].sort(TableExpressions.Sorters.NumberSorter('displayOrder')).map(x => ({
        ...x,
        // Sort inner array
        items: TableExpressions.Methods.FixSortOrderNumber(x.items.sort(TableExpressions.Sorters.NumberSorter('displayOrder')), 'displayOrder')
      }));
      if(assignmentsTableFiltersQueryParam !== ''){
        const filters = JSON.parse(assignmentsTableFiltersQueryParam??'') as Record<string, FilterValue | null>;
        setFilterValues(filters);
      }
      

      setTableData(sortedData);
    } catch (error) {
      NotificationUtil.error({
        key: 'PrintingReportAssignment',
        message: 'Error while loading Report data',
        error
      });
    }
    setLoading(false);
  };

  const tableColumns: ColumnProps<ReportAssignmentModel>[] = [
    TableColumnBuilder.Create<ReportAssignmentModel>('displayAccount', 'Account')
      .Width(220)
      .AddSorter('Text')
      .AddTextFilterer()
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentModel>('displayType', 'Type')
      .Width(220)
      .AddSorter('Text')
      .AddRenderer('Ellipses')
      .AddEnumFilterer(tableData.map(x => x.displayType).sort((a, b) => a.localeCompare(b)))
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentModel>('displayDebit', 'Debit')
      .Width(160)
      .ClassName('wide-column')
      .Align('right')
      .AddRenderer('Currency', true, true)
      .AddCurrencyFilterer()
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .AddSorter('Number')
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentModel>('displayCredit', 'Credit')
      .Width(160)
      .ClassName('wide-column')
      .Align('right')
      .AddSorter('Number')
      .AddRenderer('Currency', true, true)
      .AddCurrencyFilterer()
      .FilteredValue(filterValues as Record<string, FilterValue|null>)
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentModel>()
      .Key('empty-space')
      .Build(),
  ];

  const transactionColumns: ColumnProps<ReportAssignmentItemModel>[] = [
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayDate', 'Date')
      .Width(140)
      .AddRenderer('ShortDate')
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayType', 'Type')
      .Width(130)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayJournal', 'Journal')
      .Width(200)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayReference', 'Reference')
      .Width(120)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayName', 'Name')
      .Width(140)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayLineType', 'Line Type')
      .Width(160)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayDescription', 'Description')
      .Width(280)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayDebit', 'Debit')
      .Width(90)
      .Align('right')
      .AddRenderer('Currency', true, true)
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayCredit', 'Credit')
      .Width(90)
      .Align('right')
      .AddRenderer('Currency', true, true)
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayTaxable', 'Taxable')
      .Width(70)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportAssignmentItemModel>('displayMapping', 'Mapping')
      .AddRenderer('Ellipses')
      .Build(),
  ];
  const sortedColumn = tableColumns.find(x => x.key === assignmentsTableSortedColumnQueryParam);
  if(sortedColumn)
    sortedColumn.defaultSortOrder = assignmentsTableSortOrderQueryParam as SortOrder;

  return (
    <div className='printing-report-assignment'>
      <Table
        rowKey={NameOf<ReportAssignmentModel>('displayAccount')}
        className='less-condensed-table striped-table borderless-table'
        rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
        loading={loading}
        expandable={{
          expandedRowKeys: expandedKeys,
          onExpandedRowsChange: keys => (setExpandedKeys([...keys])),
          expandIcon: (props) => {
            const onClick = (e: React.MouseEvent<HTMLElement>) => props.onExpand(props.record, e);
            return props.expanded ? <DownOutlined onClick={onClick} className='standard-chevrons' /> : <RightOutlined onClick={onClick} className='standard-chevrons' />;
          },
          expandedRowRender: record => (<div style={{ marginLeft: 30 }}>
            <Table
              rowKey={NameOf<ReportAssignmentItemModel>('key')}
              className='condensed-table striped-table borderless-table'
              rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
              pagination={false}
              columns={transactionColumns}
              dataSource={record.items}
            />
          </div>)
        }}
        pagination={false}
        columns={tableColumns}
        dataSource={tableData}
      />
    </div>
  );
};

export default PrintingReportAssignmentPage;
