/**
 * Utility used to format various things in a uniform manner
 */
class FormatUtil {
  // References: https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
  /**
   * Attempts to format the given string as a phone number. Supports country code display (example, +1 for USA) if enough characters are provided.
   *
   * Function will only accept numbers and will automatically remove spaces
   * @param value A series of numbers that are supposed to be the phone number
   */
  public AsPhoneNumber(value: string) {
    // Remove all but numbers
    const cleaned = String(value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{1,3})?(\d{3})?(\d{3})(\d{4})$/);

    // We only accept a certain range of values so just return the value
    if (cleaned.length < 7 || cleaned.length > 13 || !match) {
      if (process.env.NODE_ENV === 'development') {
        console.error('FormatUtil: invalid phone number received', { value });
      }
      return cleaned;
    }

    // Match[1] will be the country code for longer numbers, but match the area code for shorter numbers
    if (match[1] && match[2]) {
      return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
    } else if (match[1] && !match[2]) {
      return `(${match[1]}) ${match[3]}-${match[4]}`;
    }
    return `${match[3]}-${match[4]}`;
  }

  public AsCurrency(value: string, removeCurrencySymbol: boolean = false, renderBlankIfZero: boolean = false, renderSimpleZero: boolean = false) {
    // Swiped from here: https://stackoverflow.com/questions/149055/how-to-format-numbers-as-currency-strings
    // However, it adds the dollar sign and the project does not like dollar signs
    const currency = Number.parseInt(value);
    if (Number.isNaN(currency)) {
      return '';
    }

    if (currency === 0) {
      if (renderBlankIfZero) {
        return '';
      }
      if (renderSimpleZero) {
        return '0';
      }
    }

    const formatted = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      currencySign: 'accounting',
      currencyDisplay: 'symbol'
    })
      .format(currency);

    // Remove the currency symbol as needed. This is the best we can do without writing our own
    return removeCurrencySymbol ? formatted.replaceAll('$', '') : formatted;
  }
}

export default new FormatUtil();
