import React, { useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';

/**
 * Mimics the soon to be useId from react 18 with the uuid package
 */
export function useId(): string {
  const ref = useRef(uuidv4());

  return ref.current ?? '';
}
