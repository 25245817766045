/** Provides the nameof functionality from C# with the ease of use of Typescript */
function NameOf<T>(key: keyof T): keyof T;
function NameOf<T>(...keys: (keyof T)[]): (keyof T)[];
function NameOf<T>(...keys: (keyof T)[]): keyof T | (keyof T)[] {
  if (keys.length === 1) {
    return keys[0];
  }
  return keys;
}

export default NameOf;
