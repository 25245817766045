import React, { useContext, useEffect, useState } from 'react';
import { Skeleton, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { NameOf, NotificationUtil, TableExpressions, TableColumnBuilder, StringUtil } from 'src/utils';
import { useQueryParam } from 'src/hooks';
import ReportController from 'src/api/ReportController';
import ReportMappingModel from 'src/models/frontend/ReportMappingModel';
import ReportMappingItemModel from 'src/models/frontend/ReportMappingItemModel';
import ReportMappingItemRuleModel from 'src/models/frontend/ReportMappingItemRuleModel';
import { SharedReportPageProps } from './SharedPageProps';

const PrintingReportMappingItem: React.FC<SharedReportPageProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [mappingItemIdQueryParam, setMappingItemIdQueryParam] = useQueryParam('mappingItemId');
  const [tableData, setTableData] = useState<ReportMappingModel[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadTableData();
  }, []);

  const loadTableData = async () => {
    if (authContext.account == null || authContext.location == null) {
      return;
    }

    setLoading(true);
    try {
      const results = await ReportController.getReportMapping(authContext.account.id, authContext.location.id);

      // Sort data
      let sortedData = [...results.data].sort(TableExpressions.Sorters.NumberSorter('displayOrder')).map(x => ({
        ...x,
        // Sort inner array
        items: TableExpressions.Methods.FixSortOrderNumber(x.items.sort(TableExpressions.Sorters.NumberSorter('displayOrder')), 'displayOrder')
      }));

      if (!StringUtil.IsNullOrEmpty(mappingItemIdQueryParam)) {
        sortedData = sortedData.filter(x => x.key === mappingItemIdQueryParam);
      }

      //sort the inner inner arrays
      sortedData.forEach(x => x.items.forEach(y => {y.customMappingRules.sort((a, b) => a.displayOrder - b.displayOrder); y.displayDefaultAssignments.sort((a, b) => a.displayOrder - b.displayOrder);}));

      setTableData(sortedData);
    } catch (error) {
      NotificationUtil.error({
        key: 'PrintingReportMappingItem',
        message: 'Error while loading Report data',
        error
      });
    }
    setLoading(false);
  };

  // These are used in the custom mapping table
  const customMappingColumns: ColumnProps<ReportMappingItemRuleModel>[] = [
    TableColumnBuilder.Create<ReportMappingItemRuleModel>('displayRuleName', 'Rule Name')
      .Width(120)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportMappingItemRuleModel>('displayFilterName', 'Filter')
      .Width(120)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportMappingItemRuleModel>('displayRuleAssignments', 'Assignments')
      .Width(120)
      .AddRenderer('Custom', (value, record) => {
        return [...record.displayRuleAssignments]
          .sort(TableExpressions.Sorters.NumberSorter('displayOrder'))
          .map((subRecord, index) => <div key={index}>{subRecord.displayName}</div>);
      })
      .Build(),
  ];

  const tableColumns: ColumnProps<ReportMappingItemModel>[] = [
    TableColumnBuilder.Create<ReportMappingItemModel>('displayName', 'Mapping Item')
      .Width(100)
      .VerticalAlign('top')
      .AddRenderer('Text')
      .Build(),
    TableColumnBuilder.Create<ReportMappingItemModel>('displayDefaultAssignments', 'Default Mapping')
      .Width(140)
      .VerticalAlign('top')
      .AddRenderer('Custom', (value, record) => {
        return [...record.displayDefaultAssignments]
          .sort(TableExpressions.Sorters.NumberSorter('displayOrder'))
          .map((subRecord, index) => <div key={index}>{subRecord.displayName}</div>);
      })
      .Build(),
    TableColumnBuilder.Create<ReportMappingItemModel>('customMappingRules', 'Custom Mapping')
      .Width(580)
      // .ClassName('without-padding')
      .ClassName('without-padding-table-cell')
      .AddRenderer('Custom', (value, record) => {
        // We are actually going to render an entire new table. Fun
        if (!Array.isArray(record.customMappingRules) || record.customMappingRules.length < 1) {
          return null;
        }
        // AntD does some unbelievably sketchy stuff with a sub-table and margins. Took forever to find and the fix was unbelievably easy; put the table in a div container. Sigh...
        return <div>
          <Table
            bordered
            rowKey={NameOf<ReportMappingItemRuleModel>('key')}
            className='condensed-table borderless-table'
            rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
            loading={loading}
            pagination={false}
            columns={customMappingColumns}
            dataSource={record.customMappingRules}
          />
        </div>;
      })
      .Build(),
  ];

  // TODO: Attach a loader to the table render event
  return (
    <div className='printing-report-mapping'>
      <Skeleton active loading={loading}>
        {tableData.map((record, index) => (
          <div key={index} className='with-margin-bottom'>
            <h3>{record.displayName}</h3>
            <Table
              key={index}
              bordered
              rowKey={NameOf<ReportMappingItemModel>('key')}
              className='condensed-table'
              rowClassName={(_, index) => (index % 2 ? 'striped-row' : '')}
              loading={loading}
              pagination={false}
              columns={tableColumns}
              dataSource={record.items}
            />
          </div>
        ))}
      </Skeleton>
    </div>
  );
};

export default PrintingReportMappingItem;
