import { v4 as uuidv4 } from 'uuid';
import { StringUtil } from 'src/utils';
import MappingPickListItemDTO from '../generated/MappingPickListItemDTO';

interface MappingPickListItemModel {
  // Dev Note: This is the only real difference from the DTO. The table REALLY wants a key, so we are going to give it one
  key: string;
  returnValue: string;
  displayValue: string;
  column1Value: string;
  column2Value: string;
  column3Value: string;
  columnItemTypeValue: string;
}
const MappingPickListItemModel = {
  create: (initValues?: Partial<MappingPickListItemModel>): MappingPickListItemModel => {
    const defaults: MappingPickListItemModel = {
      key: '',
      returnValue: '',
      displayValue: '',
      column1Value: '',
      column2Value: '',
      column3Value: '',
      columnItemTypeValue: '',
    };
    const fixedInitValues: Partial<MappingPickListItemModel> = initValues != null
      ? {
        key: StringUtil.IsNullOrEmpty(initValues.key) ? uuidv4() : initValues.key,
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
  toDTO: (model: MappingPickListItemModel): MappingPickListItemDTO => ({
    returnValue: model.returnValue,
    displayValue: model.displayValue,
    column1Value: model.column1Value,
    column2Value: model.column2Value,
    column3Value: model.column3Value,
    columnItemTypeValue: model.columnItemTypeValue,
  }),
  fromDTO: (dto: MappingPickListItemDTO): MappingPickListItemModel => ({
    key: '',
    returnValue: dto.returnValue,
    displayValue: dto.displayValue,
    column1Value: dto.column1Value,
    column2Value: dto.column2Value,
    column3Value: dto.column3Value,
    columnItemTypeValue: dto.columnItemTypeValue,
  })
};

export default MappingPickListItemModel;
