import { v4 as uuidv4 } from 'uuid';
import { ArrayUtil, ObjectUtil, StringUtil } from 'src/utils';
import ReportMappingItemRuleAssignmentDTO from '../generated/ReportMappingItemRuleAssignmentDTO';

interface ReportMappingItemRuleAssignmentModel {
  key: string;
  displayOrder: number;
  displayName: string;
}
const ReportMappingItemRuleAssignmentModel = {
  create: (initValues?: Partial<ReportMappingItemRuleAssignmentModel>): ReportMappingItemRuleAssignmentModel => {
    const defaults: ReportMappingItemRuleAssignmentModel = {
      key: '',
      displayOrder: 0,
      displayName: '',
    };
    const fixedInitValues: Partial<ReportMappingItemRuleAssignmentModel> = initValues != null
      ? {
        key: StringUtil.IsNullOrEmpty(initValues.key) ? uuidv4() : initValues.key,
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
  toDTO: (model: ReportMappingItemRuleAssignmentModel): ReportMappingItemRuleAssignmentDTO => ({
    displayOrder: model.displayOrder,
    displayName: model.displayName,
  }),
  fromDTO: (dto: ReportMappingItemRuleAssignmentDTO): ReportMappingItemRuleAssignmentModel => ({
    key: '',
    displayOrder: dto.displayOrder,
    displayName: dto.displayName,
  })
};

export default ReportMappingItemRuleAssignmentModel;
