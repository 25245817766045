import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Col, Empty, Row, Skeleton, Space, Spin, Table, Typography } from 'antd';
import { ColumnProps} from 'antd/lib/table';
import { AuthenticationContext } from 'src/providers/AuthenticationContext';
import { NameOf, NotificationUtil, TableExpressions, TableColumnBuilder } from 'src/utils';
import ReportController from 'src/api/ReportController';
import ReportSummaryDTO from 'src/models/generated/ReportSummaryDTO';
import ReportSummaryItemDTO from 'src/models/generated/ReportSummaryItemDTO';
import LinkWithQuery from 'src/components/LinkWithQuery';
import RouteConfig from 'src/config/RouteConfig';
import PrintIconSVG from 'src/components/svgs/PrintIconSVG';

interface ReportSummaryProps {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
  refreshTrigger: number;
}

const ReportSummary: React.FC<ReportSummaryProps> = (props) => {
  const authContext = useContext(AuthenticationContext);
  const [tableData, setTableData] = useState<ReportSummaryDTO[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.startDate != null && props.endDate != null) {
      loadTableData();
    }
  }, [props.startDate, props.endDate]);
  
  useEffect(() => {
    if(props.refreshTrigger > 0)
      loadTableData();
  }, [props.refreshTrigger]);

  const loadTableData = async () => {
    if (props.startDate == null || props.endDate == null) {
      return;
    }

    setLoading(true);
    try {
      const results = await ReportController.getReportSummary(authContext.account!.id, authContext.location!.id, props.startDate, props.endDate);

      // Sort data
      let sortedData = [...results.data].sort(TableExpressions.Sorters.NumberSorter('displayOrder')).map(x => ({
        ...x,
        // Sort inner array
        items: TableExpressions.Methods.FixSortOrderNumber(x.items.sort(TableExpressions.Sorters.NumberSorter('displayOrder')), 'displayOrder')
      }));

      // Now sort the outer array
      sortedData = TableExpressions.Methods.FixSortOrderNumber(sortedData, 'displayOrder');
      setTableData(sortedData);
    } catch (error) {
      NotificationUtil.error({
        key: 'ReportSummary',
        message: 'Error while loading Report data',
        error
      });
    }
    setLoading(false);
  };

  const renderPrintButton = () => {
    const url = new URL(location.href);
    url.searchParams.delete('route_id');
    const printingQueryParams: [string, string][] = [
      ['reportStartDate', props.startDate?.format('L')?.replaceAll('/', '-') ?? ''],
      ['reportEndDate', props.endDate?.format('L')?.replaceAll('/', '-') ?? ''],
      ['returnUrl', url.pathname + url.search],
    ];

    return <LinkWithQuery openInNewTab to={RouteConfig.PRINTING_SUMMARY_REPORT()} additionalQueryParams={printingQueryParams}>
      <Button style={{ height: 36 }}><Space><PrintIconSVG /><span>Print</span></Space></Button>
    </LinkWithQuery>;
  };

  const tableColumns: ColumnProps<ReportSummaryItemDTO>[] = [
    TableColumnBuilder.Create<ReportSummaryItemDTO>('displayType', 'Type')
      .Width(300)
      .AddRenderer('Ellipses')
      .Build(),
    TableColumnBuilder.Create<ReportSummaryItemDTO>('displayCount', 'Count')
      .Width(40)
      .Align('right')
      .Build(),
    TableColumnBuilder.Create<ReportSummaryItemDTO>('displayAmount', 'Amount')
      .Width(160)
      .Align('right')
      .AddRenderer('Currency', true, true)
      .Build(),
  ];

  // Naming is hard, and I am being a bit lazy here but a proper implementation is messy
  const tableWithoutCountColumns: ColumnProps<ReportSummaryItemDTO>[] = [tableColumns[0], tableColumns[2]];

  return (
    <div className='report-summary'>
      <Row justify='space-between' style={{ marginBottom: 16 }}>
        <Col>
          <p style={{width: 800}}>The totals below represent the summary of enabled activity associated with recorded transactions for a specific date range within the Shop Management System. To make changes to the display of transactional information, see Mapping Custom Rules within Settings.</p>
        </Col>
        <Col flex={'100px'}>
          {renderPrintButton()}
        </Col>
      </Row>

      <Spin spinning={loading}>
      <Button style={{marginRight:'10px', marginBottom: '10px'}} onClick={loadTableData}><Space><span>Refresh</span></Space></Button>
        {tableData.map((record, index) => (
          <div key={index} className='with-margin-bottom-extra-large'>
            {/* This forces the title and table into the same row */}
            <Space direction='vertical'>
              <h2>{record.displayName}</h2>
              
              <Table
                rowKey={NameOf<ReportSummaryItemDTO>('displayOrder')}
                className='condensed-table striped-table borderless-table'
                rowClassName={(record, index) => (index % 2 ? 'striped-row' : '')}
                size='small'
                pagination={false}
                columns={record.showCountColumn ? tableColumns : tableWithoutCountColumns}
                dataSource={record.displayMessage? undefined: record.items}
                locale={{emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}}
                summary={pageData => {
                  if (record.showCountColumn) {
                    return <Table.Summary.Row>
                      <Table.Summary.Cell index={0}>
                        <Typography.Text strong>Total</Typography.Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={1} align='right' className='with-border-top'>
                        <Typography.Text strong>{record.displayItemTotalCount}</Typography.Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell index={2} align='right' className='with-border-top'>
                        <Typography.Text strong>{TableExpressions.Renderers.Currency('displayItemTotalAmount', true)('', record)}</Typography.Text>
                      </Table.Summary.Cell>
                    </Table.Summary.Row>;
                  }

                  return <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>
                      <Typography.Text strong>Total</Typography.Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align='right' className='with-border-top'>
                      <Typography.Text strong>{TableExpressions.Renderers.Currency('displayItemTotalAmount', true)('', record)}</Typography.Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>;
                }}
              />
            </Space>
          </div>
        ))}
      </Spin>
    </div>
  );
};

export default ReportSummary;
