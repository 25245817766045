import moment from 'moment';
import { ObjectUtil } from 'src/utils';

interface IntegrationDTO {
  key: string;
  displayName: string;
  displayOrder: string;
  connected: boolean;
  connectedDate: moment.Moment | null;
  connectedMessage: string;
}
const IntegrationDTO = {
  create: (initValues?: Partial<IntegrationDTO>): IntegrationDTO => {
    const defaults: IntegrationDTO = {
      key: '',
      displayName: '',
      displayOrder: '',
      connected: false,
      connectedDate: null,
      connectedMessage: '',
    };
    const fixedInitValues: Partial<IntegrationDTO> = initValues != null
      ? {
        connected: ObjectUtil.ToBoolean(initValues.connected),
        connectedDate: ObjectUtil.ToMoment(initValues.connectedDate),
      }
      : {};
    return { ...defaults, ...initValues, ...fixedInitValues };
  },
};

export default IntegrationDTO;
