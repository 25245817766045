import { ObjectUtil } from 'src/utils';

interface ReportJournalItemDTO {
  displayOrder: number;
  displayAccount: string;
  displayName: string;
  displayDescription: string;
  displayDebit: number;
  displayCredit: number;
}
const ReportJournalItemDTO = {
  create: (initValues?: Partial<ReportJournalItemDTO>): ReportJournalItemDTO => {
    const defaults: ReportJournalItemDTO = {
      displayOrder: 0,
      displayAccount: '',
      displayName: '',
      displayDescription: '',
      displayDebit: 0,
      displayCredit: 0,
    };
    return { ...defaults, ...initValues };
  },
};

export default ReportJournalItemDTO;
